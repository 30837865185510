import React, { useState, useEffect } from 'react'
import styles from './RefinaryLanding.module.scss'
import RefinaryGrm from '../../components/Refinery/RefinaryGrm/RefinaryGrm'
import RefinaryOperatingCost from '../../components/Refinery/RefinaryOperatingCost/RefinaryOperatingCost'
import RefinaryDistillateYield from '../../components/Refinery/RefinaryDistillateYield/RefinaryDistillateYield'
import RefinaryEnergyIndex from '../../components/Refinery/RefinaryEnerygyIndex/RefinaryEnergyIndex'
import RefinaryProduction from '../../components/Refinery/RefinaryProduction/RefinaryProduction'
import RefinaryInfographic from '../../components/Refinery/RefinaryInfographic/RefinaryInfographic'
import { Dropdown } from 'react-bootstrap'
import dropdownArrows from '../../assets/images/dropdownArrows.png'
import { fetchRefineryLanding } from '../../services/Refinery'

const RefinaryLanding = () => {

    document.title = 'Refinery | BPCL'
    const [location, setLocation] = useState('mumbai')
    const [year, setyear] = useState('2020')
    // const [yearPrev, setyearPrev] = useState('2019')
    const [yearNext, setyearNext] = useState('2021')
    const [yearCurrent, setyearCurrent] = useState('2020')
  
    // function switchLocation() {
    //     if (location === 'mumbai') {
    //         setLocation('kochi')
    //     }
    //     if (location === 'kochi') {
    //         setLocation('mumbai')
    //     }
    // }

    function switchLocationMumbai() {
        setLocation('mumbai')
    }
    function switchLocationKochi() {
        setLocation('kochi')
    }
    function switchLocationBina() {
        setLocation('bina')
    }

    useEffect(() => {
        fetchRefineryLanding().then(data => {
            if (data.length === 0) {
                setyear('2020')
            }else{
                setyear(data.year.toString())
                //setyearPrev((data.year - 1).toString())
                setyearNext((data.current_finance_year).toString())
                if(data.year === data.current_finance_year){
                    setyearCurrent((data.year - 1).toString())
                }else{
                    setyearCurrent(data.year.toString())
                }
            }
        }).catch(error => console.debug(error))
    }, [])

    return (
        <div className={`${styles.refinaryLandingContainer}`}>
            <div className={`d-flex align-items-center justify-content-center ${styles.refinaryHeading}`}>
            <a href="/">Refinery KPI</a>
                <Dropdown className={`${styles.gheaderDropdownYear}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{year} - {Number(year.slice(2)) + 1}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
                        {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearPrev)}>{yearPrev} - {yearCurrent.slice(2)}</Dropdown.Item> */}
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearCurrent)}>{yearCurrent} - {yearNext.slice(2)}</Dropdown.Item>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearNext)}>{yearNext} - {Number(yearNext.slice(2)) + 1}</Dropdown.Item>
                        {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2022')}>2022</Dropdown.Item>
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2023')}>2023</Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
                {/* <div onClick={() => switchLocation()} className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}>
                    <div className={`d-flex justify-content-center align-items-center ${location === 'mumbai' ? styles.mumbaiLeftEnabled : styles.mumbaiLeftDisabled}`}>
                        Mumbai
                    </div>
                    <div className={`d-flex align-items-center justify-content-center ${location === 'kochi' ? styles.kochiRightEnabled : styles.kochiRightDisabled}`} >
                        Kochi
                    </div>
                </div> */}
                <div onClick={() => switchLocationMumbai()} className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}>
                    <div className={`d-flex justify-content-center align-items-center ${location === 'mumbai' ? styles.mumbaiLeftEnabled : styles.mumbaiLeftDisabled}`}>
                        Mumbai
                    </div>
                </div>
                <div onClick={() => switchLocationKochi()} className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}>
                <div className={`d-flex align-items-center justify-content-center ${location === 'kochi' ? styles.kochiRightEnabled : styles.kochiRightDisabled}`} >
                        Kochi
                    </div>
                </div>
                <div onClick={() => switchLocationBina()} className={`d-flex align-items-center  ${styles.mumbaiBinaButton}`}>
                    <div className={`d-flex justify-content-center align-items-center ${location === 'bina' ? styles.binaRightmostEnabled : styles.binaRightmostDisabled}`}>
                        Bina
                    </div>
                </div>
            </div>
            <div className={`d-flex ${styles.refinaryGraphs}`}>
                <RefinaryGrm location={location} year={year}></RefinaryGrm>
                <RefinaryOperatingCost location={location} year={year}></RefinaryOperatingCost>
                <RefinaryEnergyIndex location={location} year={year}></RefinaryEnergyIndex>
                <RefinaryDistillateYield location={location} year={year}></RefinaryDistillateYield>
                <RefinaryProduction location={location} year={year}></RefinaryProduction>
            </div>
            <RefinaryInfographic location={location} year={year}></RefinaryInfographic>
        </div>
    )
}
export default RefinaryLanding
