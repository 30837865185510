import React, { useState, useEffect, useCallback } from "react";
import styles from "../../components/OrgFinance/OrgGAs.module.scss";
import ExpandIcon from "../../assets/images/expandIcon.png";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PRupnagar from "../../assets/images/gas/Rupnagar.png";
import HRohtak from "../../assets/images/gas/Rohtak.png";
import MAhmednagar from "../../assets/images/gas/Ahmednagar.png";
import MAuragabad from "../../assets/images/gas/Auragabad.png";

// import { NoDataFound } from '../../DataNotFound/nodatafound'
// import greenTick from '../../../assets/images/greenTick.png'
// import { isEmpty } from 'lodash'
// import "../../../Common.scss"

const OrgMap2 = () => {
  // const [loading, setloading] = useState(true)
  const states = [
    {
      name: "Punjab",
      district: ["Rupnagar"],
    },
    {
      name: "Haryana",
      district: ["Rohtak"],
    },
    {
      name: "Maharashtra",
      district: ["Ahmednagar", "Aurangabad"],
    },
  ];
  const [state, setState] = useState("Punjab");
  const [district, setDistrict] = useState("Rupnagar");
  const [districts, setDistricts] = useState(["Rupnagar"]);
  const changeState = (e) => {
    setState(e.target.value);
    setDistricts(states.find((sta) => sta.name === e.target.value).district);
  };

  const changeDistrict = (e) => {
    setDistrict(e.target.value);
  };
  const handle = useFullScreenHandle();
  const [attr, setattr] = useState("handleenter");
  const fullScrn = () => {
    if (attr === "handleenter") {
      setattr("handleexit");
      handle.enter();
      document.body.style.zoom = window.innerWidth / window.outerWidth;
    } else if (attr === "handleexit") {
      setattr("handleenter");
      handle.exit();
      document.body.style.zoom = 0;
    }
  };
  const reportChange = useCallback(
    (state) => {
      try {
        if (state === false) {
          document.body.style.zoom = 0;
        }
      } catch (exception) {
        console.debug(exception);
      }
    },
    [handle]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <FullScreen handle={handle} onChange={reportChange}>
      <div className={`${styles.EmployeeDataAndRecruitmentContainer}`}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.EmployeeDataAndRecruitmentHeader3}`}
        >
          <span>BPCL GAs District Map</span>

          {/* {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>} */}
          {/* <img
            src={ExpandIcon}
            alt="Expand Employee Data And Recruitment"
            id="fullSrnedr"
            data-fs="handleenter"
            onClick={FullScreen}
          ></img> */}
        </div>
        <div className={`${styles.cascadingDrop}`}>
          {/* <select
            className={`${styles.state}`}
            value={state}
            onChange={changeState}
          > */}
          {/* <option>--State--</option> */}
          {/* {states.map((sta) => (
              <option value={sta.name}>{sta.name}</option>
            ))}
          </select> */}
          <div style={{ display: "flex" }}>
            <div style={{ padding: "20px", marginLeft: "875px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#FDCA0A",
                    fontWeight: 500,
                    padding: "10px 25px 10px 25px",
                    marginRight: "-10px",
                    zIndex: 1,
                    fontSize: 20,
                  }}
                >
                  State
                </div>
                <select
                  style={{
                    backgroundColor: "white",
                    padding: "10px 65px 10px 25px",
                    fontWeight: 500,
                    fontFamily: "sans-serif",
                    fontSize: "20px",
                    color: "grey",
                    borderRadius: "0 10px 10px 0",
                  }}
                  value={state}
                  onChange={changeState}
                >
                  {states.map((sta) => (
                    <option value={sta.name}>{sta.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ padding: "20px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#FDCA0A",
                    fontWeight: 500,
                    padding: "10px 25px 10px 25px",
                    marginRight: "-10px",
                    zIndex: 1,
                    fontSize: 20,
                  }}
                >
                  District
                </div>
                <select
                  style={{
                    backgroundColor: "white",
                    padding: "10px 65px 10px 25px",
                    fontWeight: 500,
                    fontFamily: "sans-serif",
                    fontSize: "20px",
                    color: "grey",
                    borderRadius: "0 10px 10px 0",
                  }}
                  value={district}
                  onChange={changeDistrict}
                >
                  <option>--District--</option>
                  {districts.map((dist) => (
                    <option value={dist}>{dist}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <br />
          {/* <select
            className={`${styles.district}`}
            value={district}
            onChange={changeDistrict}
          >
            <option>--District--</option>
            {districts.map((dist) => (
              <option value={dist}>{dist}</option>
            ))}
          </select> */}
        </div>
        {district === "Rupnagar" && (
          <img src={PRupnagar} className={`${styles.disImg}`} />
        )}
        {district === "Rohtak" && (
          <img src={HRohtak} className={`${styles.disImg}`} />
        )}
        {district === "Ahmednagar" && (
          <img src={MAhmednagar} className={`${styles.disImg}`} />
        )}
        {district === "Aurangabad" && (
          <img src={MAuragabad} className={`${styles.disImg}`} />
        )}
      </div>
    </FullScreen>
  );
};
export default OrgMap2;
