import React, { useEffect, useState, useRef } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './BuIncInventory.module.scss'
import { isEmpty } from 'lodash'
import { numberFormatEnIn } from '../../../utility/utility'

const BuIncInventoryGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
  
  
    useEffect(() => {
        const companyNames = []
        const growthArray = []

        const { graphData } = props
        
        if (!isEmpty(graphData)) {
            graphData.forEach((item) => {
                const companyName = item.company_name.toLowerCase()
                const firstLetter = companyName.charAt(0)
                const firstLetterCap = firstLetter.toUpperCase()
                const remainingLetters = companyName.slice(1)
                const capitalizedCompanyName = firstLetterCap + remainingLetters
                companyNames.push(capitalizedCompanyName)
                growthArray.push(item.sales ? Number(item.sales).toFixed(2) : 0)
            })
        }

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let gradientColor = ctx.createLinearGradient(0, 0, 0, 500)
        gradientColor.addColorStop(1, 'rgb(0, 168, 236)')
        gradientColor.addColorStop(0, 'rgb(0, 224, 188)')

        let chartConfig = {
            type: 'horizontalBar',
            data: {
                //labels: ["Company 1", "Company 2", "Company 3", "Company 4", "Company 5", "Company 6", "Company 7", "Company 8", "Company 9", "Company 10"],
                labels: companyNames,
                datasets: [
                    {
                        label: 'Sales',
                        stack: 'Stack 2',
                        backgroundColor: gradientColor,
                        data:growthArray,
                        //data: [5390.25, 5096.06, 5000.56, 4900.21, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 20,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'end',
                            color: '#dee5ef',
                            font: {
                                size: 18
                            },
                            formatter: function(value, context){
                                return `${value} MT`
                            }
                        }
                    }
                ],
            },
            options: {
                tooltips: {
                    mode: 'label',
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label
                            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                            return  `${label} : ${numberFormatEnIn(value)} MT`
                        }
                    }
                },
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            // scaleLabel: {
                            //     display: true,
                            //     labelString: 'Company',
                            //     fontColor: '#64779c',
                            //     fontSize: 24,
                            // },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 9,
                                fontColor: 'white',
                                fontSize: '22',
                            }

                        }
                    ]
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph2}`}>
            <span className={`${styles.graphHeader}`} style={{paddingLeft : '20px', color: '#64779c'}}>Companies</span>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}

export default BuIncInventoryGraph
