import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import styles from './BuIncOutstanding.module.scss'
import BuIncOutstandingPieChart from './BuIncOutstandingPieChart'
import { fetchBuIncOutstandingPayment, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isNumber, isEmpty } from 'lodash'
import { numberFormatNoSeparator } from '../../../utility/utility'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

function BuIncOutstanding(props) {
    const [graphData, setGraphData] = useState([])
    const [lastItem, setLastItem] = useState([])
    const [attr, setattr] = useState('handleEnter')
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }

    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);

    const prepareGraphData = () => {
        fetchBuIncOutstandingPayment(props.year)
            .then(data => {
                if (data.success){
                    //var dataArray = []
                    // var total = 0
                    // var totalValue = 0
                    setGraphData(data.data[data.data.length - 1])
                    // data.data.forEach((item) => {
                    //     total += item.zero_to_three_months
                    //     total += item.three_to_six_months
                    //     total += item.six_to_twelve_months
                    //     total += item.twelve_to_thirty_six_months
                    //     total += item.greater_than_thirty_six_months
                    //     totalValue = total
                    //     dataArray.push(total ? parseFloat(total).toFixed(2) : '')
                    //     dataArray.push(isNumber(item.growth) ? numberFormatNoSeparator(item.growth) : 'N/A')
                    // })
                    setLastItem(data.data[data.data.length - 1])
                }
            })
            .catch(error => console.debug(error))
    }

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "inc" && res.kpi_name === "Outstanding" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        prepareGraphData()
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuLubesOthersContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-auto flex-grow-1'}>
                            <span>Outstanding - Monthly</span>
                        </div>
                        {
                            lastItem ?
                                <div className={`col-auto mt-2 mr-4 ${styles.headerRight}`}>
                                    <span>Total : {Number(lastItem.zero_to_three_months + lastItem.three_to_six_months + lastItem.six_to_twelve_months + lastItem.twelve_to_thirty_six_months + lastItem.greater_than_thirty_six_months).toFixed(2)} INR Cr </span>
                                    {/* <span className={'ml-4'} style={{ fontSize: '20px', color: `${Math.sign(lastItem.growth) === 1 ? '#74ce12' : '#e13219'}` }} >{parseFloat(lastItem.growth).toFixed(2)}</span> */}
                                </div> : ''
                        }
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    </div>
                    <div className={'row'}>
                        <div className={`col-9 mt-2 d-flex align-items-center ${styles.headerLabels}`}>
                            {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot1}`}></span>Not Due</div> */}
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot2}`}></span>0-3 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot3}`}></span>3-6 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot4}`}></span>6-12 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot5}`}></span>12-36 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot6}`}></span>{`>36m`}</div>
                        </div>
                    </div>
                </div>
                <div className={`d-flex align-items-center justify-content-around ${styles.graphContainer}`}>
                    <div className="d-flex flex-column align-items-center">
                        {isEmpty(graphData)
                            ?
                            <NoDataFound />
                            :
                            <BuIncOutstandingPieChart graphData={graphData}></BuIncOutstandingPieChart>
                        }
                    </div>
                </div>
                <div className={'d-flex justify-content-end'}>
                    <img className={`${styles.expandIcon}`} onClick={fullScrn} src={expandIcon} alt='Expand Icon'></img>
                </div>
            </FullScreen>
        </div>
    )
}
export default BuIncOutstanding