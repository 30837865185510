import React, { useContext, useState, useEffect } from "react";
import styles from "../Login.module.scss";
import bpclogo from "../assets/bpcl-logo.png";
import { useHistory, Link, Redirect } from "react-router-dom";

import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loginUser, getCaptchaFromUser } from "../../../services/auth";
import ApplicationContext from "../../../ApplicationContext";
import CryptoJS from "crypto-js";

const Login = () => {
  const context = useContext(ApplicationContext);
  const history = useHistory();
  const [userEmail, setUser] = useState("");
  const [userPassword, setuserPass] = useState("");
  const [error, setError] = useState("");
  const [signInttext, setSignintext] = useState("SIGN IN");
  const [showPopup, setshowPopup] = useState(0);
  const [userCaptcha, setUserCaptcha] = useState("");
  const [userRandomCaptcha, setUserRandomCaptcha] = useState("");
  useEffect(() => {
    
  let charMap = '';
  let retVal = "";
  let captcha_value = "";
  let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  if (charMap === "upper") {
    charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  } else if (charMap === "lower") {
    charset = "abcdefghijklmnopqrstuvwxyz0123456789";
  }
  else if (charMap === "numbers") {
    charset = "0123456789";
  }
  else if (charMap === "special_char") {
    charset = "~`!@#$%^&*()_+-=[]{}\|:'<>,.?/";
  }

  let length = 6;

  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  let captcha = retVal;
  captcha_value = captcha;
  setUserRandomCaptcha(captcha_value);
  }, []);
  const handleCaptcha = (e) => {
    if (e.target.value.length === 1) {
      getCaptchaFromUser(userEmail, userRandomCaptcha)
    }
    setshowPopup(0);
    setUserCaptcha(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setshowPopup(0);
    setUser(e.target.value);
  };
  const handleChangePass = (e) => {
    setshowPopup(0);
    setuserPass(e.target.value);
  };
  const loginSubmit = async (e) => {
    e.preventDefault();
    setSignintext("SIGNING IN..");
    let emailregex = /^[a-zA-Z0-9._-]+@bharatpetroleum.in$/;
    if (!userEmail && !userPassword) {
      setSignintext("SIGN IN");
      setshowPopup(1);
      setError("Enter Email Id and Password.");
    } else if (!userEmail) {
      setSignintext("SIGN IN");
      setshowPopup(1);
      setError("Enter your Email Id.");
    } else if (!userPassword) {
      setSignintext("SIGN IN");
      setshowPopup(1);
      setError("Enter your Password.");
    } else if (!emailregex.test(userEmail)) {
      setSignintext("SIGN IN");
      setshowPopup(1);
      setError("Invalid Credentials!");
    } else if (userRandomCaptcha === userCaptcha) {
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userPassword), "Bpcl_Crypto@key2023").toString();
      var ciphertextUserRandomCaptcha = CryptoJS.AES.encrypt(JSON.stringify(userRandomCaptcha), "Bpcl_Crypto@key2023").toString();
      loginUser(userEmail, ciphertext, ciphertextUserRandomCaptcha)
        .then((data) => {
          if (data.success === 1) {
            context.setLoggedInUser(data);
            setTimeout(() => {
              history.push("/");
            }, 500);
          } else {
            context.setLoggedInUser(null);
            setSignintext("SIGN IN");
            setshowPopup(1);
            setError(data.message);
          }
        })
        .catch((error) => {
          console.debug(error);
        });
      setUserCaptcha("");
    } else {
      setSignintext("SIGN IN");
      setshowPopup(1);
      setUserRandomCaptcha("")
      let charMap = '';
      let retVal = "";
      let captcha_value = "";
      let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      if (charMap === "upper") {
        charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      } else if (charMap === "lower") {
        charset = "abcdefghijklmnopqrstuvwxyz0123456789";
      }
      else if (charMap === "numbers") {
        charset = "0123456789";
      }
      else if (charMap === "special_char") {
        charset = "~`!@#$%^&*()_+-=[]{}\|:'<>,.?/";
      }

      let length = 6;

      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      let captcha = retVal;
      captcha_value = captcha;
      setUserRandomCaptcha(captcha_value);
      setError("Captcha Does Not Match.");
      //   alert("Captcha Does Not Match");

      setUserCaptcha("");
    }
  };
  if (context.loggedInUser) {
    return <Redirect to="/" />;
  }
  return (
    <div
      className={`container d-flex justify-content-center ${styles.adminLogin}`}
    >
      <div className={`${styles.adminLboc}`}>
        <div>
          <img src={bpclogo} alt="Logo" />
        </div>
        <div className={`${styles.bpclbox}`}>
          <h1>SIGN IN</h1>
          <hr className={`${styles.linebrk}`}></hr>
          <form className={`${styles.adminfrm}`} onSubmit={loginSubmit}>
            <div className={`form-goup ${styles.admindiv}`}>
              <label>EMAIL ID</label>
              <input
                type="email"
                className={"form-control"}
                name="email"
                onChange={(e) => handleChangeEmail(e)}
              />
            </div>
            <div className={`form-goup ${styles.admindiv}`}>
              <label>PASSWORD</label>
              <input
                type="password"
                className={"form-control"}
                name="password"
                onChange={(e) => handleChangePass(e)}
              />
            </div>
            <div className={`form-goup ${styles.admincaptchadiv}`}>
            <label> CAPTCHA </label>
              <input
                type="text"
                readOnly="readonly"
                className={`form-control ${styles.admincaptchainput}`}
                name="captcha"
                value={userRandomCaptcha}
                disabled="disabled"
              ></input>
            </div>
    { (userEmail && userPassword) ?
    <div className={`form-goup ${styles.admindiv}`}>
              <label>ENTER CAPTCHA </label>
              <input
                type="text"
                className={"form-control"}
                name="captcha"
                placeholder="Enter Captcha"
                value={userCaptcha}
                onChange={(e) => handleCaptcha(e)}
              ></input>
            </div>:<div></div>
}
            <div className={`button ${styles.adminbtn}`}>
              <button type="submit" className={`btn${styles.adminloginbtn}`}>
                {signInttext}
              </button>
            </div>
            {showPopup === 1 && (
              <div className={`${styles.popuperr}`}>
                <p className={`${styles.errorad}`}>
                  <span>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </span>
                  {error}
                </p>
              </div>
            )}
          </form>
          {/* Disabling the Register functionality  */}
          {/* <div style={{ paddingTop: "25px" }}>
            <span className={`${styles.linkText}`}>
              Don't have an account?{" "}
              <Link style={{ fontWeight: "800" }} to="/register">
                Register Here.
              </Link>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Login;
