// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Checkbox_checkbox__1W4fV .Checkbox_label__3lUfz {\n  margin-left: 10px; }\n\n.Checkbox_checkbox__1W4fV > input[type=\"checkbox\"] {\n  display: none; }\n", ""]);
// Exports
exports.locals = {
	"checkbox": "Checkbox_checkbox__1W4fV",
	"label": "Checkbox_label__3lUfz"
};
module.exports = exports;
