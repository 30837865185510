// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ItrmOceanLoss_itrmOceanLossContainer__3VDRh {\n  width: 1051px;\n  height: 406px;\n  font-family: Titillium Web;\n  color: white; }\n  .ItrmOceanLoss_itrmOceanLossContainer__3VDRh .ItrmOceanLoss_headerContainer__y4O2k {\n    padding-left: 67px;\n    height: 75px;\n    padding-right: 36px; }\n    .ItrmOceanLoss_itrmOceanLossContainer__3VDRh .ItrmOceanLoss_headerContainer__y4O2k .ItrmOceanLoss_headerLeft__19Y8h .ItrmOceanLoss_heading__2hZ2J {\n      font-size: 35px;\n      color: white;\n      font-weight: bold; }\n    .ItrmOceanLoss_itrmOceanLossContainer__3VDRh .ItrmOceanLoss_headerContainer__y4O2k .ItrmOceanLoss_headerRight__1cSJt {\n      font-size: 24px;\n      color: #b76df1;\n      font-weight: 600; }\n  .ItrmOceanLoss_itrmOceanLossContainer__3VDRh .ItrmOceanLoss_graphContainer__WT8M_ {\n    border-top: solid 0.5px #959595;\n    border-bottom: solid 0.5px #959595;\n    border-right: solid 0.5px #959595;\n    height: 333px;\n    background-color: #141923; }\n  .ItrmOceanLoss_itrmOceanLossContainer__3VDRh .ItrmOceanLoss_mumbaiTheme__15tW0 {\n    color: #06bee1; }\n  .ItrmOceanLoss_itrmOceanLossContainer__3VDRh .ItrmOceanLoss_kochiTheme__3GYaM {\n    color: #b76df1; }\n  .ItrmOceanLoss_itrmOceanLossContainer__3VDRh .ItrmOceanLoss_statusUpdatedImg__1Q0Fl {\n    margin-right: -1066px;\n    margin-top: -61px; }\n", ""]);
// Exports
exports.locals = {
	"itrmOceanLossContainer": "ItrmOceanLoss_itrmOceanLossContainer__3VDRh",
	"headerContainer": "ItrmOceanLoss_headerContainer__y4O2k",
	"headerLeft": "ItrmOceanLoss_headerLeft__19Y8h",
	"heading": "ItrmOceanLoss_heading__2hZ2J",
	"headerRight": "ItrmOceanLoss_headerRight__1cSJt",
	"graphContainer": "ItrmOceanLoss_graphContainer__WT8M_",
	"mumbaiTheme": "ItrmOceanLoss_mumbaiTheme__15tW0",
	"kochiTheme": "ItrmOceanLoss_kochiTheme__3GYaM",
	"statusUpdatedImg": "ItrmOceanLoss_statusUpdatedImg__1Q0Fl"
};
module.exports = exports;
