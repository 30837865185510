import React, {useState, useEffect} from 'react'
import styles from './GrowthKpi1.module.scss'
import DeltaValuesOrgGrowth from '../../DeltaValuesOrg/DeltaValuesOrgGrowth'
import GrowthKpiGraph1 from './GrowthKpiGraph1'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import {numberFormatEnIn} from '../../../utility/utility'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const GrowthKpi1 = (props) => {
    useEffect(() => {
    }, [props.loading])
    return (
        <div className={`${styles.GrowthKpi1Container}`}>
            <div className={`d-flex align-items-center justify-content-between ${styles.GrowthKpi1Header}`}>
                <div className={`${styles.kpiTitle}`}>{props.kpiName}</div>
                <div className={`d-flex flex-column ${styles.kpiOverallDetails}`}>
                    <span className={`${styles.totalValues}`}>{props.kpiName === 'GRM' ? `Last Quarter:  ${props.totalGRM.value ? numberFormatEnIn(parseFloat(props.totalGRM.value).toFixed(2)) : ''} ${props.totalGRM.unit ? '$/bbl' : ''}` : props.kpiName === 'Throughput' ? `Total: ${props.totalThrough.value ? numberFormatEnIn(parseFloat(props.totalThrough.value).toFixed(2)) : ''} ${props.totalThrough.unit ? props.totalThrough.unit : ''}` : props.kpiName === 'Capex' && `Total: ${props.totalCAGR.value ? 'INR' : ''} ${props.totalCAGR.value ? numberFormatEnIn(parseFloat(props.totalCAGR.value).toFixed(2)) : ''} ${props.totalCAGR.unit ? props.totalCAGR.unit : ''}`}</span>
                    {/* <DeltaValuesOrgGrowth quater={
                        props.kpiName === 'GRM' ? 
                            props.totalGRM.quater && 
                                parseFloat(props.totalGRM.quater).toFixed(2) 
                            : props.kpiName === 'Throughput' ? 
                                props.totalThrough.quater &&
                                    parseFloat(props.totalThrough.quater).toFixed(2)
                                : props.kpiName === 'Capex' && 
                            props.totalCAGR.quater &&
                                parseFloat(props.totalCAGR.quater).toFixed(2)
                    } year={
                        props.kpiName === 'GRM' ? 
                            props.totalGRM.year && 
                                    parseFloat(props.totalGRM.year).toFixed(2)
                            : props.kpiName === 'Throughput' ? 
                                props.totalThrough.year &&
                                    parseFloat(props.totalThrough.year).toFixed(2)
                                : props.kpiName === 'Capex' && 
                                props.totalCAGR.year &&
                                    parseFloat(props.totalCAGR.year).toFixed(2)
                    }></DeltaValuesOrgGrowth> */}
                </div>
                {(props.kpiName === 'GRM' && props.statusGrm === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
                {(props.kpiName === 'Throughput' && props.statusThroughput === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
                {(props.kpiName === 'Capex' && props.statusCapex === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
            </div>
            <div className={`${styles.GrowthKpi1ContentContainer}`}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={`mt-2 d-flex align-items-right ${styles.headerLabels}`}>
                        <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot2}`}></span>FY {Number(props.year.slice(2)) + 1}</div>
                        <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot1}`}></span>FY {props.year.slice(2)}</div>
                        {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot3}`}></span>Target</div> */}
                        {/* {
              props.kpiName === 'GRM' && <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot4}`}></span>ARGM</div>
            } */}

                    </div>
                </div>
                <div className={`${styles.graphContainer}`}>
                    {props.loading ? 
                        // <NoDataFound />
                        <QuarterlyDataNotFound />
                        :
                        <GrowthKpiGraph1 kpiName={props.kpiName} graphData={props.graphData} year={props.year}></GrowthKpiGraph1>
                    }
                </div>
            </div>
        </div>

    )
}
export default GrowthKpi1