import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuLpgCustomerPopulation.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import { Spinner } from 'react-bootstrap'
import BuLpgCustPopulationGraph from './BuLpgCustPopulationGraph'
import { fetchLpgCustomerPopulation, fetchLpgOthers, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty } from 'lodash'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { numberFormatNoSeparator, numberFormatEnIn } from '../../../utility/utility'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuLpgCustomerPopulation = (props) => {
    const [graphData, setGraphData] = useState([])
    const [connectionData, setConnectionItem] = useState([])
    const [sbcData, setSbcItem] = useState([])
    const [dbcData, setDbcItem] = useState([])
    const [dprData, setDprItem] = useState([])
    const [othersData, setOthersItem] = useState([])
    const [lastItem, setTotalLastItem] = useState([])
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)

    const [setBacklogData] = useState([])
    const [setDigitalData] = useState([])
    const [setBottlingData] = useState([])
    const [updatedStatus, setUpdatedStatus] = useState('')

    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "lpg" && res.kpi_name === "Customer Populations" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        const prepareGraphData = () => {
            fetchLpgCustomerPopulation(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setGraphData(response.data)
                        setTotalLastItem(response.data[response.data.length - 1])
                        setConnectionItem(response.data[response.data.length - 1])
                        setSbcItem(response.data[response.data.length - 1])
                        setDbcItem(response.data[response.data.length - 1])
                        setDprItem(response.data[response.data.length - 1])
                        setOthersItem(response.data[response.data.length - 1])
                        setIsNoDataAvailable(false)
                    }

                })
                .catch((error) => console.debug(error))
        }
        prepareGraphData()
    }, [props.year])

    return (
        <div className={`${styles.BuOperatingCostContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col flex-grow-1'}>
                            <span>Customer Population - Daily</span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                        {
                            isNoDataAvailable ||
                            <div className={`mt-2 mr-3 ${styles.headerRight}`}>
                                <span>Total: {numberFormatEnIn(lastItem.bpcl !== null ? Number(lastItem.bpcl) : 'No Data')}</span>
                                <span className={'ml-4'} style={{ color: `${Math.sign(lastItem !== undefined && lastItem.total_growth) === 1 ? '#74ce12' : '#e13219'}` }}>{lastItem.total_growth !== null && numberFormatEnIn(lastItem.total_growth) + '%'}</span>

                            </div>
                        }
                    </div>
                    <div className={'row pt-2'}>
                        <div
                            className={`col-9 d-flex align-items-center ${styles.headerLabels}`}
                        >
                            <div className="d-flex mr-3 align-items-center ">
                                <span className={`mr-2 ${styles.bpclDot}`}></span>New Customer
                            </div>
                            {/* <div className="d-flex mr-3 align-items-center ">
                                <span className={`mr-2 ${styles.hpclDot}`}></span>HPCL
                            </div>
                            <div className="d-flex mr-3 align-items-center ">
                                <span className={`mr-2 ${styles.ioclDot}`}></span>IOCL
                            </div>
                            <div className="d-flex mr-3 align-items-center ">
                                <span className={`${styles.bpclLabel}`}>--- BPCL Target</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                    isNoDataAvailable ||
                    <div className="col-12" style={{ top: '12px', fontSize: '16px', left: '0px' }}>
                        {connectionData ? (
                            < >
                                <span className={'mr-2'}>
                                    Connections : {connectionData.connection_actual ? numberFormatEnIn(connectionData.connection_actual) : 'N/A'}
                                </span>
                                <span
                                    style={{
                                        color: `${Math.sign(connectionData.connection_growth) === 1
                                            ? '#74ce12'
                                            : '#e13219'
                                            }`, padding: ' 0px 10px 0px 0px'
                                    }}
                                >
                                    {connectionData.connection_growth ? numberFormatEnIn(connectionData.connection_growth) + '%' : 'N/A'}
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                        {sbcData ? (
                            < >
                                <span className={'mr-2'}>SBC : {sbcData.sbc_actual ? numberFormatEnIn(sbcData.sbc_actual) : 'N/A'} </span>
                                <span
                                    style={{
                                        color: `${Math.sign(sbcData.sbc_growth) === 1
                                            ? '#74ce12'
                                            : '#e13219'
                                            }`, padding: ' 0px 10px 0px 0px'
                                    }}
                                >
                                    {sbcData.sbc_growth ? numberFormatEnIn(sbcData.sbc_growth) + '%' : '/N/A'}
                                </span>
                            </>
                        ) : (
                            ''
                        )}

                        {dbcData ? (
                            < >
                                <span className={'mr-2'}>DBC : {dbcData.dbc_actual ? numberFormatEnIn(dbcData.dbc_actual) : 'N/A'}</span>
                                <span
                                    style={{
                                        color: `${Math.sign(dbcData.dbc_growth) === 1
                                            ? '#74ce12'
                                            : '#e13219'
                                            }`, padding: ' 0px 10px 0px 0px'
                                    }}
                                >
                                    {dbcData.dbc_growth ? numberFormatEnIn(dbcData.dbc_growth) + '%' : 'N/A'}
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                        {dprData ? (
                            < >
                                <span className={'mr-2'}>DPR : {dprData.dpr_actual ? numberFormatEnIn(dprData.dpr_actual) : 'N/A'}</span>
                                <span
                                    style={{
                                        color: `${Math.sign(dprData.dpr_growth) === 1
                                            ? '#74ce12'
                                            : '#e13219'
                                            }`, padding: ' 0px 10px 0px 0px'
                                    }}
                                >
                                    {dprData.dpr_growth ? numberFormatEnIn(dprData.dpr_growth) + '%' : 'N/A'}
                                </span>
                            </>
                        ) : (
                            ''
                        )}

                        {othersData ? (
                            < >
                                <span className={'mr-2'}>Others : {othersData.others_actual ? numberFormatEnIn(othersData.others_actual) : 'N/A'}</span>
                                <span
                                    style={{
                                        color: `${Math.sign(othersData.others_growth) === 1
                                            ? '#74ce12'
                                            : '#e13219'
                                            }`, padding: ' 0px 10px 0px 0px'
                                    }}
                                >
                                    {othersData.others_growth ? numberFormatEnIn(othersData.others_growth) + '%' : 'N/A'}
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                }
                {isNoDataAvailable ? (
                    <NoDataFound height={507} />
                ) : (
                    <>
                        <div className={`d-flex ${styles.graphContainer}`}>

                            {graphData ? (
                                <BuLpgCustPopulationGraph
                                    graphData={graphData}
                                ></BuLpgCustPopulationGraph>
                            ) : (
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            )}
                        </div>
                        <div
                            className={`d-flex justify-content-end ${styles.expandIconContainer}`}
                        >
                            <img
                                className={`${styles.expandIcon}`}
                                src={expandIcon}
                                alt="Expand Icon"
                                data-fs="handleenter"
                                onClick={fullScrn}
                            ></img>
                        </div>
                    </>
                )}
            </FullScreen>
        </div>
    )
}
export default BuLpgCustomerPopulation
