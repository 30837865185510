import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './LubesOthersPieChart.module.scss'
import {get} from 'lodash'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const BuDepotInventoryChart = (props) => {
    const [showPercentages] = useState(Boolean(props.showPercentages))
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
    let tooltipLabelCallback = (tooltipItems, data) => {
        return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
    }

    let dataLabelFormatterCallback = (value) => (value != 0) ? value : ''
    if (showPercentages) {
        const total = get(props, 'graphData.zero_to_three_months' + props, 'graphData.three_to_six_months' + props, 'graphData.six_to_twelve_months' + props, 'graphData.twelve_to_thirty_six_months' + props, 'graphData.greater_than_thirty_six_months') 
        const getPercentageValue = (value) => {
            value = Number(
                ((value * 100) / total) || 0
            ).toFixed()

            return isNaN(value) ? '': `${value}%`
        }
        dataLabelFormatterCallback = getPercentageValue
        tooltipLabelCallback = (tooltipItems, data) => {
            return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + ' : ' + getPercentageValue(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])
        }
    }
    useEffect(() => {
        let actualArray = [props.graphData.zero_to_three_months ? Number(props.graphData.zero_to_three_months).toFixed(2) : '', props.graphData.three_to_six_months ? Number(props.graphData.three_to_six_months).toFixed(2) : '', props.graphData.six_to_twelve_months ? Number(props.graphData.six_to_twelve_months).toFixed(2) : '', props.graphData.twelve_to_thirty_six_months ? Number(props.graphData.twelve_to_thirty_six_months).toFixed(2) : '', props.graphData.greater_than_thirty_six_months ? Number(props.graphData.greater_than_thirty_six_months).toFixed(2) : '']
  
        // const prepareGraphData = (apiGraphData) => {
        //     apiGraphData.forEach((item, index) => {
        //         actualArray.push(Number(item.amount).toFixed(2))
        //     })
        // }
        // prepareGraphData(props.graphData)

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let lessThanTwoGrad = ctx.createLinearGradient(0, 0, 0, 500)
        lessThanTwoGrad.addColorStop(1, '#00a8ec')
        lessThanTwoGrad.addColorStop(0, '#00e0bc')

        let twoToFiveGrad = ctx.createLinearGradient(0, 0, 0, 500)
        twoToFiveGrad.addColorStop(0, '#ffbf45')
        twoToFiveGrad.addColorStop(1, '#ff8309')

        let sixToTenGrad = ctx.createLinearGradient(0, 0, 0, 500)
        sixToTenGrad.addColorStop(0, '#ff7bda')
        sixToTenGrad.addColorStop(1, '#e640b7')

        let moreThanTenGrad = ctx.createLinearGradient(0, 0, 0, 500)
        moreThanTenGrad.addColorStop(0, '#ff588c')
        moreThanTenGrad.addColorStop(1, '#fac159')

        let moreThanthirtySix = ctx.createLinearGradient(0, 0, 0, 500)
        moreThanthirtySix.addColorStop(0, '#632897')
        moreThanthirtySix.addColorStop(1, '#b76df1')

        const chartConfig = {
            type: 'pie',
            data: {
                datasets: [
                    {
                        label: ['0-3 m', '3-6 m', '6-12 m', '12-36 m', '>36 m'],
                        data: actualArray,
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            font: {
                                size: 16,
                            },
                            color: 'white'
                        },
                        backgroundColor: [lessThanTwoGrad, moreThanTenGrad, twoToFiveGrad, sixToTenGrad, moreThanthirtySix],
                        borderWidth: 0,
                        // borderWidth: [0, 2, 2, 2],
                        borderColor: '#202c3f',
                        // borderColor: ["00e0bc", "#202c3f", "#202c3f", "#202c3f"],
                        weight: 4,
                        hoverBorderWidth: 0,
                        hoverBorderColor: [lessThanTwoGrad, moreThanTenGrad, twoToFiveGrad, sixToTenGrad, moreThanthirtySix],
                        hoverBackgroundColor: [lessThanTwoGrad, moreThanTenGrad, twoToFiveGrad, sixToTenGrad, moreThanthirtySix],
                    },

                ]
            },
            options: {
                plugins: {
                    datalabels: {
                        formatter: dataLabelFormatterCallback
                    }
                },
                layout: {
                    padding: {
                        left: 30,
                        right: 30,
                        top: 30,
                        bottom: 30
                    }
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                rotation: 3,
                responsive: true,
                maintainAspectRatio: false,
                tooltips : {
                    enabled : true,
                    mode : 'label',
                    callbacks: {
                        label: tooltipLabelCallback
                    }
                }
            },
            plugins: [{
                afterUpdate: function (chart) {
                    let maxNo = 0
                    let maxNoIndex = 0
                    for (let i in chart.config.data.datasets) {
                        let dataset = chart.config.data.datasets[i]
                        maxNo = Math.max(...dataset.data)
                        maxNoIndex = dataset.data.indexOf(maxNo)

                        for (let j = chart.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                            // let model = chart.getDatasetMeta(i).data[j]._model;
                            if (j === maxNoIndex) {
                                // chart.getDatasetMeta(i).data[maxNoIndex]._model.outerRadius = chart.outerRadius + 30
                            }
                        }
                    }
                },

            }],
            legend: {
                display: false
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData, props.activeTab, showPercentages]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.graph}`}>
            <canvas
                ref={chartContainer}
                height="300"
                width="300"
            />
        </div>
    )
}
export default BuDepotInventoryChart