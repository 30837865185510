import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import styles from './BuAtfOthers.module.scss'
import AtfOthersPieChart from './AtfOthersPieChart'
import { fetchAtfOhers, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuAtfOthers = (props) => {
    const [setOverdueData] = useState([])
    const [outstandingData, setOutstandingData] = useState([])
    const handle = useFullScreenHandle()
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "AVIATION" && res.kpi_name === "Outstanding" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        const prepareGraphData = (kpi_name) => {
            fetchAtfOhers(kpi_name, props.year)
                .then(response => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                        setOutstandingData([])
                    }else{
                        setIsNoDataAvailable(false)
                        response.data.forEach((item, index) => {
                            if (item.kpi_name === 'overdue payment') {
                                setOverdueData(response.data)
                            }
                            else if (item.kpi_name === 'outstanding payment') {
                                setOutstandingData(response.data[response.data.length - 1])
                            }
                        })
                    }
                })
                .catch(error => console.debug(error))
        }
        //prepareGraphData('overdue payment')
        prepareGraphData('outstanding payment')
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuLubesOthersContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex flex-column justify-content-center ${styles.BuCcsHeader}`}>
                    <div className={'row'}>
                        <div className={'col-7'}>
                            <div className={`${styles.HeaderTop}`}>Outstanding - Monthly
                                {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                                </div>}
                            </div>
                        </div>
                        {
                                isNoDataAvailable ||
                                <div className={`col-5 mt-2 ${styles.headerRight}`}>
                                    <span>Total : {Number(outstandingData.zero_to_three_months + outstandingData.three_to_six_months + outstandingData.six_to_twelve_months + outstandingData.twelve_to_thirty_six_months + outstandingData.greater_than_thirty_six_months).toFixed(2)} INR Cr </span>
                                    {/* <span className={'ml-4'} style={{ color: `${Math.sign(outstandingData.growth) === 1 ? '#74ce12' : '#e13219'}` }} >{parseFloat(outstandingData.growth).toFixed(2)}%</span> */}
                                </div> 
                        }
                    </div>
                    <div className={`d-flex ${styles.legendsContainer}`}>
                        <div className="mr-4 d-flex align-items-center">
                            <span className={`mr-2 ${styles.dot1}`}></span> {'0-3 m'}
                        </div>
                        <div className="mr-4 d-flex align-items-center">
                            <span className={`mr-2 ${styles.dot2}`}></span>3-6 m
                    </div>
                        <div className="mr-4 d-flex align-items-center">
                            <span className={`mr-2 ${styles.dot3}`}></span>6-12 m
                    </div>
                        <div className="mr-3 d-flex align-items-center">
                            <span className={`mr-2 ${styles.dot4}`}></span>{'12-36 m'}
                        </div>
                        <div className="mr-3 d-flex align-items-center">
                            <span className={`mr-2 ${styles.dot5}`}></span>{'>36 m'}
                        </div>
                    </div>
                </div>
                <div className={`d-flex align-items-center justify-content-around ${styles.graphContainer}`}>
                    {/* <div className="d-flex flex-column align-items-center">
                    {overdueData.length > 0
                        ?
                        <div>
                            <AtfOthersPieChart graphData={overdueData}></AtfOthersPieChart>
                            <span className={`${styles.pieChartTitle}`}></span>
                        </div>
                        :
                        <NoDataFound />
                    }
                </div> */}
                    <div className="d-flex flex-column align-items-center">
                        {isEmpty(outstandingData)
                            ?
                            <NoDataFound />
                            :
                            <div>
                                <AtfOthersPieChart graphData={outstandingData}></AtfOthersPieChart>
                                <p className={`${styles.pieChartTitle}`} style={{ textAlign: "center" }}>Outstanding</p>
                            </div>
                        }
                    </div>

                    {/* <div className={`d-flex flex-column ${styles.contentRow1}`}>
                    <div className={`d-flex flex-column justify-content-center ${styles.graphHeaderSection}`}>
                        <div className={`${styles.graphHeaderSectionTop}`}>
                            <span className={`${styles.topLeft}`}>330</span>
                            <span style={{ color: '#e13219' }} className={`${styles.topRight}`}>+2%</span>
                        </div>
                        <div className={`${styles.graphHeaderSectionBottom}`}><span>Overdue</span></div>
                    </div>
                    <div className={`d-flex flex-column justify-content-center ${styles.graphHeaderSection}`}>
                        <div className={`${styles.graphHeaderSectionTop}`}>
                            <span className={`${styles.topLeft}`}>330</span>
                            <span style={{ color: '#e13219' }} className={`${styles.topRight}`}>+0.003%</span>
                        </div>
                        <div className={`${styles.graphHeaderSectionBottom}`}><span>Outstanding</span></div>
                    </div>
                </div> */}

                </div>
                <div className={'d-flex justify-content-end'}>
                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' onClick={fullScrn}></img>
                </div>
            </FullScreen>
        </div>
    )
}
export default BuAtfOthers