// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EthicalHrBar_ehrGr__hj2SI {\n  position: relative;\n  width: 820px;\n  height: 330px;\n  margin-left: 15px;\n  margin-top: 20px;\n  margin-right: 15px; }\n", ""]);
// Exports
exports.locals = {
	"ehrGr": "EthicalHrBar_ehrGr__hj2SI"
};
module.exports = exports;
