import React, { useEffect, useState, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuMarketShareGraph from './BuMarketShareGraph'
import styles from './BuMarketShare.module.scss'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchRetailMarketShare, fetchupdatedStatus } from '../../../services/bu'
import { isEmpty, last } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuMarketShare = (props) => {
    let product = props.product || 'ms'
    product = product === 'ms' ? 'ms' : product === 'export ms' ? 'ms' : 'hsd'; // excluding export data for removing market share dependecy on export dropdown 
 
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [publicVar, setPublicStatus] = useState(true)
    const [privateVar, setPrivateStatus] = useState(true)
    const [graphData, setGraphData] = useState([])
    const [lastItem, setLastItem] = useState([])
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    // let filterData = null
    // let lastItem = {}
    // if (!isEmpty(graphData)) {
    //     filterData = filter(graphData, each => each.product === product)
    //     lastItem = last(filterData)
    // }

    // const onCheckStateChanged = (event) => {
    //     if (event.name === 'public') {
    //         setPublicStatus(event.checkState)
    //     }
    //     if (event.name === 'private') {
    //         setPrivateStatus(event.checkState)
    //     }

    //     if (event.name === 'both') {
    //         if (event.checkState) {
    //             setPrivateStatus(true)
    //             setPublicStatus(true)
    //         }
    //         else {
    //             setPrivateStatus(false)
    //             setPublicStatus(false)
    //         }
    //     }
    // }


    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            // console.log(response)
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "retail" && res.kpi_name === "Market share" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchRetailMarketShare(product, props.year)
            .then(response => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                } else {
                    setGraphData(response.data)
                    setLastItem(last(response.data))
                    setIsNoDataAvailable(false)
                }
            })
            .catch(error => console.debug(error))
    }, [props.product, props.year])

    return (
        <div className={`${styles.BuMarketShareContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-9'}>
                            <span>Market Share - Monthly </span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                        {
                            isNoDataAvailable ||
                            <div className={`mt-2 mr-3 ${styles.headerRight}`} style={{ fontSize: '24px' }}>
                                <span>Total: {lastItem && lastItem.total !== null ? Number(lastItem.total).toFixed(2) + '%' : 'No Data'}</span>
                                {/* <span className={'ml-3'} style={{ fontSize: '24px', color: `${Math.sign(lastItem !== undefined && lastItem.percentage_growth) === 1 ? '#74ce12' : '#e13219'}` }}>{lastItem && lastItem.percentage_growth !== null && Number(lastItem.percentage_growth).toFixed(2) + '%'}</span> */}
                            </div>
                        }
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-9 d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.BpclDot}`}></span>BPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.HpclDot}`}></span>HPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.IoclDot}`}></span>IOCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.RilDot}`}></span>RBML</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.NelDot}`}></span>NEL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.targetDot}`}></span></div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.targetDot}`}></span></div>
                        </div>
                    </div>
                </div>
                {
                    isNoDataAvailable ?
                        <NoDataFound height={507} />
                        :
                        (
                            <>
                                <div className={`d-flex  ${styles.graphContainer}`}>
                                    {
                                        graphData ?
                                            <BuMarketShareGraph graphData={graphData}></BuMarketShareGraph>
                                            :
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>

                                    }

                                    {/* <div className={`d-flex flex-column justify-content-center ${styles.graphFilters}`}>
                                        <Checkbox
                                            size="sm"
                                            name="public"
                                            label="Public"
                                            checked={publicVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="private"
                                            label="Private"
                                            checked={privateVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="both"
                                            label="Both"
                                            checked={publicVar && privateVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                    </div> */}
                                    <div className={`${styles.MsHsdContainer}`}>
                                        <div className={`${styles.HsdCircle}`}><span className={`${styles.Hsd}`}>{product.toLocaleUpperCase()}</span></div>
                                    </div>
                                </div>
                                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' id="fullMs" data-fs="handleenter" onClick={fullScrn}></img>
                                </div>
                            </>
                        )
                }

            </FullScreen>
        </div>
    )
}
export default BuMarketShare