// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BuIncLossGainGraph_grmGraph__2DPYA {\n  position: relative;\n  width: 900px;\n  height: 420px;\n  margin-left: 45px;\n  margin-top: 32px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "BuIncLossGainGraph_grmGraph__2DPYA"
};
module.exports = exports;
