// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeltaValuesOrg_deltaValuesContainer__3zqAi {\n  color: #97a4ba;\n  font-size: 22px; }\n  .DeltaValuesOrg_deltaValuesContainer__3zqAi .DeltaValuesOrg_container__3ILH3 .DeltaValuesOrg_title__14YU5 {\n    margin-right: 1px;\n    font-size: 18px; }\n    .DeltaValuesOrg_deltaValuesContainer__3zqAi .DeltaValuesOrg_container__3ILH3 .DeltaValuesOrg_title__14YU5 .DeltaValuesOrg_deltaIcon__1qY0L {\n      margin-bottom: 6px;\n      width: 18px; }\n  .DeltaValuesOrg_deltaValuesContainer__3zqAi .DeltaValuesOrg_container__3ILH3 .DeltaValuesOrg_value__3C51c {\n    font-size: 18px; }\n", ""]);
// Exports
exports.locals = {
	"deltaValuesContainer": "DeltaValuesOrg_deltaValuesContainer__3zqAi",
	"container": "DeltaValuesOrg_container__3ILH3",
	"title": "DeltaValuesOrg_title__14YU5",
	"deltaIcon": "DeltaValuesOrg_deltaIcon__1qY0L",
	"value": "DeltaValuesOrg_value__3C51c"
};
module.exports = exports;
