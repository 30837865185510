import React, { useEffect, useState } from 'react'
import styles from './MouLanding.module.scss'
import KpisList from '../../components/Mou/KpisList/KpiList'
import { fetchMouLanding } from '../../services/Mou'
import { Dropdown } from 'react-bootstrap'
import dropdownArrows from '../../assets/images/dropdownArrows.png'

const MouLanding = () => {
    const [year, setyear] = useState('2020')
    // const [yearPrev, setyearPrev] = useState('2019')
    const [yearNext, setyearNext] = useState('2021')
    const [yearCurrent, setyearCurrent] = useState('2020')

    useEffect(() => {
        fetchMouLanding().then(data => {
            if (data.length === 0) {
                setyear('2020')
            }else{
                setyear(data.year.toString())
                //setyearPrev((data.year - 1).toString())
                setyearNext((data.current_finance_year).toString())
                if(data.year === data.current_finance_year){
                    setyearCurrent((data.year - 1).toString())
                }else{
                    setyearCurrent(data.year.toString())
                }
            }
        }).catch(error => console.debug(error))
    }, [])
    // const [totalScore, setTotalScore] = useState([])
    document.title = 'MOU | BPCL'
    // useEffect(() => {
    //     fetchMouGetOverall().then(data => setTotalScore(data))
    // }, [])
    const mouMandatoryKpis = {
        title: 'Mandatory Kpis',
        //kpiList: ["Turnover", "Operating Profit", "ROI"]
        kpiList: [
            { kpi_name: 'Revenue from Net Operations Exclusive CPSE OMC Sales', kpi_chart_type: 'bar', accordian_name: 'Revenue from Operations (Net) excluding  CPSE OMC Sales' },
            { kpi_name: 'Operating Profit as % of Revenue from Net Operations', kpi_chart_type: 'bar', accordian_name: 'Operating Profit as % of Revenue from Operations (Net)' },
            { kpi_name: 'PAT as % of Average Net Worth', kpi_chart_type: 'bar', accordian_name: 'PAT as % of Average Net worth' },
            { kpi_name: 'Crude Throughput', kpi_chart_type: 'bar', accordian_name: 'Crude Throughput' },
            { kpi_name: 'CAPEX', kpi_chart_type: 'bar', accordian_name: 'Capex' },
            { kpi_name: 'Project w/o time & Cost over run', kpi_chart_type: 'bar', accordian_name: 'Project W/O Time & Cost Over Run' },
            { kpi_name: 'Operational Availability', kpi_chart_type: 'bar', accordian_name: 'Operational Availability' },
            { kpi_name: '% of Ethanol Blending', kpi_chart_type: 'bar', accordian_name: 'Percentage of Ethanol Blending' },
            { kpi_name: 'PNG Connections', kpi_chart_type: 'bar', accordian_name: 'PNG Connections' },
            { kpi_name: 'CNG stations', kpi_chart_type: 'bar', accordian_name: 'CNG Stations' },
            { kpi_name: 'Work order for CNG Stations under DODO Policy', kpi_chart_type: 'bar', accordian_name: 'Work Order For CNG Stations Under DODO Policy' },
            { kpi_name: 'Financial Closure of CBG plants', kpi_chart_type: 'bar', accordian_name: 'Financial closure of CBG Plants' },
            { kpi_name: 'New Retail Outlets', kpi_chart_type: 'bar', accordian_name: 'New Retail Outlets' },
            { kpi_name: 'Dividend declared as % of dividend paid in previous year', kpi_chart_type: 'bar', accordian_name: 'Dividend declared as % of dividend paid In previous year' },
            { kpi_name: 'Procurement through GeM portal', kpi_chart_type: 'bar', accordian_name: 'Procurement through GeM Portal' },
        ]
    }
    //console.debug("turnover", mouMandatoryKpis);
    // const mouOtherKpis = {
    //   title: "Others",
    //   kpiList: [
    //     { kpi_name: "Crude Throughput", kpi_chart_type: "line" },
    //     { kpi_name: "Operation Availability", kpi_chart_type: "bar" },
    //     { kpi_name: "Ethanol Blending", kpi_chart_type: "bar" },
    //     { kpi_name: "Digital Payment at RO LPG", kpi_chart_type: "line" },
    //     { kpi_name: "% of NANO Retail outlet", kpi_chart_type: "line" },
    //     { kpi_name: "Capex", kpi_chart_type: "line" },
    //     { kpi_name: "PNG Connections", kpi_chart_type: "line" },
    //     { kpi_name: "CNG stations", kpi_chart_type: "line" },
    //     { kpi_name: "Initiative for Women", kpi_chart_type: "line" },
    //     { kpi_name: "5&4 star LPG distributors", kpi_chart_type: "bar" },
    //   ]
    // }
    return (
        <>
            <div className={`container p-0 border ${styles.landing}`}>
                <div className={`d-flex align-items-center justify-content-center ${styles.kpi_section_header_container}`}>
                    {/* <span>MOU</span> */}
                    <a href="/">MOU</a>
                    <Dropdown className={`${styles.gheaderDropdownYear}`}>
                        <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                            <span className={`${styles.gdropdownName}`}>{year} - {Number(year.slice(2)) + 1}</span>
                            <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                                <img src={dropdownArrows} alt='All region dropdown'></img>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
                            {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearPrev)}>{yearPrev} - {yearCurrent.slice(2)}</Dropdown.Item> */}
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearCurrent)}>{yearCurrent} - {yearNext.slice(2)}</Dropdown.Item>
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearNext)}>{yearNext} - {Number(yearNext.slice(2)) + 1}</Dropdown.Item>
                            {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2022')}>2022</Dropdown.Item>
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2023')}>2023</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {/* <div className={`row m-0 d-flex flex-row justify-content-between align-items-stretch ${styles.kpi_section_overview_container}`}>
                    <div className={`col-6 m-0 p-0 pl-2 d-flex align-items-center justify-content-center ${styles.kpi_section_overview_left}`}>
                        <span className={`${styles.performance_title}`}>Overall Performance: </span>
                        <span className={`${styles.performance_value}`} style={{ color: getColor('very good') }}>{totalScore.remark}</span>
                    </div>
                    <div className={`col-6 m-0 p-0 pr-2 d-flex align-items-center justify-content-center ${styles.kpi_section_overview_right}`}>
                        <span className={styles.score_title}>Score:</span>
                        <span className={styles.score_value}>{totalScore.scored}/{totalScore.total}</span>
                    </div>
                </div> */}
                <KpisList year={year} kpiListData={mouMandatoryKpis} ></KpisList>
                {/* <KpisList kpiListData={mouOtherKpis} lastupdate={totalScore.date_added}></KpisList> */}
            </div>
        </>
    )
}

export default MouLanding
