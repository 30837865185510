import React, { useState, useEffect } from 'react'
import styles from './Region.module.scss'
import regionStyles from './Map.module.scss'
import {fetchItrmCountryData} from '../../services/Itrm'

const CountryNamesComponent = (props) => {
    const [data, setData] = useState([])

    const makeProgressBar = (res) => {
        let localData = []
        res.map((item) => {
            localData.push({
                countryCode: item.country_code,
                percent: item.overall_percentage,
                term_hs: item.term_hs,
                term_ls: item.term_ls,
                spot_hs: item.spot_hs,
                spot_ls: item.spot_ls,
                indigenous_hs: item.indigenous_hs,
                indigenous_ls: item.indigenous_ls,
            })
        })
        setData(localData)
    }

    const renderProgressBar = (countryCode, term_hs, term_ls, spot_hs, spot_ls, indigenous_hs, indigenous_ls) => {
        if (
            document.querySelector(`#progressbarDataForTerm${countryCode}`) !== null
        ) {
            document.querySelector(
                `#progressbarDataForTerm${countryCode}`
            ).style.width = '75px'

            document.querySelector(
                `#progressbarDataForTerm${countryCode}`
            ).innerHTML = `<span id="hsTerm${countryCode}" style="font-size:16px;float:left; font-style: italic;font-weight: 700">${Number(term_hs).toFixed(2)}</span><span id="lsTerm${countryCode}" style="font-size:16px;float:right; font-style: italic;left:90px;position:absolute;font-weight: 700">${Number(term_ls).toFixed(2)}</span>`
        }
        if (
            document.querySelector(`#progressbarDataForSpot${countryCode}`) !== null
        ) {
            document.querySelector(
                `#progressbarDataForSpot${countryCode}`
            ).style.width = '75px'
            document.querySelector(
                `#progressbarDataForSpot${countryCode}`
            ).innerHTML = `<span id="hsSpot${countryCode}" style="font-size:16px;float:left; font-style: italic;font-weight: 700">${Number(spot_hs).toFixed(2)}</span><span id="lsSpot${countryCode}" style="font-size:16px;float:right; font-style: italic;left:90px;position:absolute;font-weight: 700">${Number(spot_ls).toFixed(2)}</span>`
        }

        if (
            document.querySelector(`#progressbarDataForIndigenous${countryCode}`) !==
      null
        ) {
            document.querySelector(
                `#progressbarDataForIndigenous${countryCode}`
            ).style.width = '75px'
            document.querySelector(
                `#progressbarDataForIndigenous${countryCode}`
            ).innerHTML = `<span id="hsIndigenous${countryCode}" style="font-size:16px;float:left; font-style: italic;font-weight: 700">${Number(indigenous_hs).toFixed(2)}</span><span id="lsIndigenous${countryCode}" style="font-size:16px;float:right; font-style: italic;left:90px;position:absolute;font-weight: 700">${Number(indigenous_ls).toFixed(2)}</span>`
        }
    }

    useEffect(() => {
        fetchItrmCountryData(props.regionDetails.region, props.location, props.year)    
            .then((response) => {
                if (response.data.length > 0) {
                    const countryCodeMap = {}
                    response.data.forEach((data) => {
                        countryCodeMap[data.country_code] = data
                    })        
                    makeProgressBar(Object.values(countryCodeMap))
                } else {
                    return false
                }
            })
            .catch((error) => console.debug(error))
    }, [props.regionDetails.region,props.location, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    //console.debug(data);
    return props.regionDetails.countries.map((country, index) => {
        let regionStyle = country.v.replace(/\s/g, '').toLowerCase()

        if (styles[regionStyle]) {
            return (
                <div key={index} className={`${styles[regionStyle]}`}>
                    {data.length > 0 ? (
                        data.map((el, index) => {
                            if (el.countryCode === country.v) {
                                setTimeout(function () {
                                    renderProgressBar(el.countryCode, el.term_hs, el.term_ls, el.spot_hs, el.spot_ls, el.indigenous_hs, el.indigenous_ls)
                                })
                                return (
                                    <div key={index}>
                                        <div style={{ transform: `scale(${0.7})` }}>
                                            <div className={`${regionStyles.chartDataFont}`}>
                                                {el ? parseFloat(el.percent).toFixed(2) + '%' : 0}
                                            </div>
                                            <div style={{ fontSize: '21px', fontWeight: 'bold', position: 'relative',  bottom: '9px' }}>{country.f}</div>
                                            { el.countryCode === "IN" ?
                                                <div
                                                    className={`${regionStyles.progressbarIndigenousForIndia}`}
                                                    id={`indText${el.countryCode}`}
                                                >
                                                    <div
                                                    className={`${regionStyles.progressbarDataForIndia}`}
                                                    id={`progressbarDataForIndigenous${el.countryCode}`}
                                                    ></div>
                                                </div> 
                                                :
                                                <React.Fragment>
                                                <div
                                                className={`${regionStyles.progressbarTermForIndia}`}
                                                id={`termText${el.countryCode}`}
                                                style={{ marginTop: '5px' }}
                                            >
                                                <div
                                                    className={`${regionStyles.progressbarDataForIndia}`}
                                                    id={`progressbarDataForTerm${el.countryCode}`}
                                                ></div>{' '}
                                            </div>
                                            
                                            <div
                                                className={`${regionStyles.progressbarSpotForIndia}`}
                                                id={`spotText${el.countryCode}`}
                                            >
                                                <div
                                                    className={`${regionStyles.progressbarDataForIndia}`}
                                                    id={`progressbarDataForSpot${el.countryCode}`}
                                                ></div>
                                            </div></React.Fragment>
                                            }
                                            
                                            {/*  */}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    ) : (
                        <div style={{ marginBottom: '0', fontSize: '24px' }}>
                            {country.f}
                        </div>
                    )}
                </div>
            )
        }
    })
}

export default CountryNamesComponent
