import React, { useState, useEffect } from 'react'
import styles from './Map.module.scss'

const ChartData = (props) => {
    const [loading, setLoading] = useState(false)

    const { chartData } = props
    const renderProgressBar = (regionCode, term_hs, term_ls, spot_hs, spot_ls, indigenous_hs, indigenous_ls) => {
        if (
            document.querySelector(`#progressbarDataForTerm${regionCode}`) !== null
        ) {
            document.querySelector(
                `#progressbarDataForTerm${regionCode}`
            ).style.width = '75px'

            document.querySelector(
                `#progressbarDataForTerm${regionCode}`
            ).innerHTML = `<span id="hsTerm${regionCode}" style="font-size:16px;float:left; font-style: italic; z-index: 1; font-weight: 700">${Number(term_hs).toFixed(2)}</span><span id="lsTerm${regionCode}" style="font-size:16px;float:right; font-style: italic;left:100px;position:absolute; z-index: 1; font-weight: 700">${Number(term_ls).toFixed(2)}</span>`
        }
        if (
            document.querySelector(`#progressbarDataForSpot${regionCode}`) !== null
        ) {
            document.querySelector(
                `#progressbarDataForSpot${regionCode}`
            ).style.width = '75px'
            document.querySelector(
                `#progressbarDataForSpot${regionCode}`
            ).innerHTML = `<span id="hsSpot${regionCode}" style="font-size:16px;float:left; font-style: italic; z-index: 1; font-weight: 700">${Number(spot_hs).toFixed(2)}</span><span id="lsSpot${regionCode}" style="font-size:16px;float:right; font-style: italic;left:100px;position:absolute; z-index: 1; font-weight: 700">${Number(spot_ls).toFixed(2)
                }</span>`
        }

        if (
            document.querySelector(`#progressbarDataForIndigenous${regionCode}`) !== null
        ) {
            document.querySelector(
                `#progressbarDataForIndigenous${regionCode}`
            ).style.width = '75px'
            document.querySelector(
                `#progressbarDataForIndigenous${regionCode}`
            ).innerHTML = `<span id="hsIndigenous${regionCode}" style="font-size:16px;float:left; font-style: italic; font-weight: 700">${Number(indigenous_hs).toFixed(2)}</span><span id="lsIndigenous${regionCode}" style="font-size:16px;float:right; font-style: italic;left:100px;position:absolute; font-weight: 700; width: 100px">${Number(indigenous_ls).toFixed(2)
                }</span>`
        }
    }

    const onLegendClickHandler = (regionData) => () => {
        props.legendClickHandler(regionData)
    }

    useEffect(() => {
        setLoading(true)
    }, [props.chartData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {/* <div className={`${styles.refineryLocale}`}>
                <span>{props.location.toUpperCase()}</span>
                <div className={`${styles.progressbarIndigenousIndia}`}>
                    <div className={`${styles.progressbarData}`}></div>{' '}
                </div>
            </div> */}
            {loading && chartData.length > 0 ? (
                chartData.map((data, index) => {
                    // console.debug(data);
                    let regionStyle = data.region.replace(/\s/g, '').toLowerCase()
                    // console.debug(data.regionCode);
                    setTimeout(function () {
                        renderProgressBar(
                            data.regionCode,
                            data.term_hs,
                            data.term_ls,
                            data.spot_hs,
                            data.spot_ls,
                            data.indigenous_hs,
                            data.indigenous_ls,
                        )
                    }, 300)
                    if (styles[regionStyle]) {
                        return (
                            <div
                                key={index}
                                className={`${styles[regionStyle]}`}
                                id='progressBarSet'
                            >
                                <div className={`${styles.chartDataFont}`}>
                                    {data ? parseFloat(data.overall_percentage).toFixed(2) + "%" : 0}
                                </div>
                                <div style={{ fontSize: '20px', cursor: 'pointer', fontWeight: 'bold' }} onClick={onLegendClickHandler(data)}>
                                    {data ? data.region : ''}{' '}
                                </div>


                                {
                                    data.regionCode === '034' ?
                                        <div
                                            className={`${styles.progressbarIndigenous}`}
                                            id={`indText${data.regionCode}`}
                                        >
                                            <div
                                                className={`${styles.progressbarData}`}
                                                id={`progressbarDataForIndigenous${data.regionCode}`}
                                            ></div>
                                        </div> :
                                        <React.Fragment>
                                            <div
                                                className={`${styles.progressbarTerm}`}
                                                id={`termText${data.regionCode}`}
                                            >
                                                <div
                                                    className={`${styles.progressbarData}`}
                                                    id={`progressbarDataForTerm${data.regionCode}`}
                                                ></div>{' '}
                                            </div>

                                            <div
                                                className={`${styles.progressbarSpot}`}
                                                id={`spotText${data.regionCode}`}
                                            >
                                                <div
                                                    className={`${styles.progressbarData}`}
                                                    id={`progressbarDataForSpot${data.regionCode}`}
                                                ></div>
                                            </div>
                                        </React.Fragment>
                                }

                            </div>
                        )
                    }
                })
            ) : (
                <div>Loading....</div>
            )}
        </div>
    )
}

export default ChartData
