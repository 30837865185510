import axios from 'axios'
import { BASE_URL } from '../environment'
import { withAuthorizationHeaders } from '../components/Authentication/services/authHeaders'

export const persistLoggedInUser = (payload) => {
    localStorage.setItem('loggedInUser', JSON.stringify(payload))
    localStorage.setItem('bpclAccessToken', payload.accessToken)
}

export const clearPersistedLoggedInUser = () => {
    localStorage.removeItem('loggedInUser')
    localStorage.removeItem('bpclAccessToken')
}

export const getLoggedInUser = () => {
    try {
        return JSON.parse(localStorage.getItem('loggedInUser'))
    } catch (error) {
        console.debug('Failed to get logged in user')
    }
}

export const loginUser = (email, password, captcha) => axios.post(`${BASE_URL}/api/auth/signin`, {
    email,
    password, 
    captcha
}).then(response => response.data)

export const loginAdminUser = (email, password) => axios.post(`${BASE_URL}/api/auth/Logadminusers`, {
    adminuemail: email,
    adminupass: password
}).then(response => response.data)

export const addNewAdmin = (name, email, password) => axios.post(`${BASE_URL}/api/auth/addadmin`, {
    name,
    email,
    password
}).then(response => response.data)

export const registerUser = (name, email, password, otpuser) => axios.post(`${BASE_URL}/api/auth/signup`, {
    name,
    email,  
    password,
    otpuser
}).then(response => response.data)

export const sendOTP = (email) => axios.post(`${BASE_URL}/api/auth/otpsend`, {
    email,  
}).then(response => response.data)

export const getSessionName = async () => {
    let url =  `${BASE_URL}/api/auth/getSessionuname`
    let options = {
        bpcluserid: JSON.parse(
            localStorage.getItem('loggedInUser')
        ).name 
    }
    const response = await axios.post(url, options, {
        headers: withAuthorizationHeaders()
    })
    return response.data
}

export const getAllUsers = async () => {
    let url =  `${BASE_URL}/api/auth/getAllUsers`
    const response = await axios.get(url, {
        headers: withAuthorizationHeaders()
    })
    return response.data
}

export const acceptUser = async (id, emailid, user) => {
    let acptusr = {acpt : id, acpt_email : emailid, acpt_name : user}
    let url =  `${BASE_URL}/api/auth/Acptuser`
    const response = await axios.post(url, acptusr, {
        headers: withAuthorizationHeaders()
    })
    return response.data
}

export const rejectUser = async (id) => {
    let rejctusr = {rjpt : id}
    let url =  `${BASE_URL}/api/auth/Rejctuser`
    const response = await axios.post(url, rejctusr, {
        headers: withAuthorizationHeaders()
    })
    return response.data
}

export const deleteUsers = async (users = []) => {
    let url =  `${BASE_URL}/api/auth/deleteUsers`
    const response = await axios.post(url, {
        userids: users
    }, {
        headers: withAuthorizationHeaders()
    })
    return response.data
}

export const getCurrentUser = async () => {
    let url =  `${BASE_URL}/api/auth/profile`
    const response = await axios.get(url, {
        headers: withAuthorizationHeaders()
    })
    return response.data.data
}
export const getCaptchaFromUser = (email, userRandomCaptcha) => axios.post(`${BASE_URL}/api/auth/getCaptchaFromUser`, {
    email, userRandomCaptcha
}).then(response => response.data)
