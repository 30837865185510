import React from 'react'
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.css'

import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import ErrorBoundary from './ErrorBoundary'

ReactDOM.render(
    <ErrorBoundary fallback='Something went wrong. Please try again!'>
<App />
</ErrorBoundary>
, document.getElementById('root'))
// require('dotenv').config();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
