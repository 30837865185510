import React, { useState, useEffect } from 'react'
import styles from './BuLpgGraphs.module.scss'
import { Dropdown } from 'react-bootstrap'
import { Tab, TabPanel, Tabs, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'
import BuLpgSalesRevenue from './BuLpgSalesRevenue/BuLpgSalesRevenue'
import BuLpgCommercialDiscount from './BuLpgCommercialDiscount/BuLpgCommercialDiscount'
import BuLpgCustomerPopulation from './BuLpgCustomerPopulation/BuLpgCustomerPopulation'
import BuLpgCcs from './BuLpgCcs/BuLpgCcs'
import BuLpgDepotInventory from './BuLpgDepotInventory/BuLpgDepotInventory'
import BuLpgOperatingCost from './BuLpgOperatingCost/BuLpgOperatingCost'
import BuLpgMarketShare from './BuLpgMarketShare/BuLpgMarketShare'
import BuLpgProjects from './BuLpgProjects/BuLpgProjects'
import BuLpgHsse from './BuLpgHsse/BuLpgHsse'
import { fetchLpgOthers } from '../../services/bu'
import { isEmpty } from 'lodash'

const BuLpgGraphs = (props) => {

    const [backlogData, setBacklogData] = useState([])
    const [digitalData, setDigitalData] = useState([])
    const [bottlingData, setBottlingData] = useState([])
    const [isNoDataAvailable,setIsNoDataAvailable] = useState(false)

    const [activeTab, setActiveTab] = useState('all_tab')

    function activateTab(tabId) {
        setActiveTab(tabId)
    }

    useEffect(() => {
        fetchLpgOthers(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                    setBacklogData([])
                    setDigitalData([])
                    setBottlingData([])
                } else {
                    response.data.forEach((item, index) => {
                        if (item.kpi_name === 'backlog') {
                            setBacklogData(item)

                        } else if (item.kpi_name === 'digital payments') {
                            setDigitalData(item)

                        } else if (item.kpi_name === 'total bottling') {
                            setBottlingData(item)
                        }
                    })
                    setIsNoDataAvailable(false)
                }
            })
            .catch((error) => console.debug(error))
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuLpgGraphsContainer}`}>
            <Tabs defaultTab="all_tab" onChange={(tabId) => activateTab(tabId)}>
                <div
                    className={`d-flex justify-content-between  ${styles.BuGraphsHeader}`}
                >
                    <TabList className={`d-flex ${styles.tabHeader}`}>
                        <Tab
                            tabFor="all_tab"
                            className={`${activeTab === 'all_tab' && styles.tabButtonActive
                                } ${styles.tabButton}`}
                        >
                            All
                        </Tab>
                        {/* <Tab tabFor="bmcg_tab" className={`${activeTab === 'bmcg_tab' && styles.tabButtonActive} ${styles.tabButton}`}>BMCG</Tab> */}
                        <Tab
                            tabFor="bulk_tab"
                            className={`${activeTab === 'bulk_tab' && styles.tabButtonActive
                                } ${styles.tabButton}`}
                        >
                            Bulk
                        </Tab>
                        <Tab
                            tabFor="commercial_tab"
                            className={`${activeTab === 'commercial_tab' && styles.tabButtonActive
                                } ${styles.tabButton}`}
                        >
                            Commercial
                        </Tab>
                        <Tab
                            tabFor="domestic_tab"
                            className={`${activeTab === 'domestic_tab' && styles.tabButtonActive
                                } ${styles.tabButton}`}
                        >
                            Domestic
                        </Tab>
                        {/* <Tab tabFor="ftl_tab" className={`${activeTab === 'ftl_tab' && styles.tabButtonActive} ${styles.tabButton}`}>FTL</Tab> */}
                    </TabList>
                    <div style={{ fontSize: '28px', backgroundColor: 'black', height: '70px', padding: '10px', width: '1740px', marginLeft: '1px' }}>
                            <span style={{ padding: '27px', fontSize: '35px', height: '80px', fontWeight: '600' }}>Others</span>
                            <span style={{ borderLeft: ' 2px solid gray', height: '80px' }}></span>

                    {
                        isNoDataAvailable ||
                        <>
                            <span>&nbsp; Digital Payments: </span>
                            <span className={'mr-4'}>{digitalData.actual ? parseFloat(digitalData.actual).toFixed(2) + '%' : 'N/A'}</span>
                            <span
                                style={{
                                    color: `${Math.sign(digitalData.growth) === 1
                                        ? '#74ce12'
                                        : '#e13219'
                                        }`, padding: '25px'
                                }}
                            >
                                {digitalData.growth ? parseFloat(digitalData.growth).toFixed(2) + '%' : 'N/A'}
                            </span>
                            <span style={{ borderLeft: ' 2px solid gray', height: '80px' }}></span>
                            <span>&nbsp; Backlog: </span>
                            <span className={'mr-4'}>{backlogData.actual ? parseFloat(backlogData.actual).toFixed(2) + ' ' : 'N/A'}</span>
                            {/* <span
                                style={{
                                    color: `${Math.sign(backlogData.growth) === 1
                                        ? '#74ce12'
                                        : '#e13219'
                                        }`, padding: '25px'
                                }}
                            >
                                {backlogData.growth ? parseFloat(backlogData.growth).toFixed(2) + '%' : 'N/A'}
                            </span> */}
                            <span style={{ borderLeft: ' 2px solid gray', height: '80px' }}></span>
                            <span>&nbsp; Total Bottling: </span>
                            <span className={'mr-4'}>{bottlingData.actual ? Number(bottlingData.actual).toFixed(2) : 'N/A'}</span>
                            <span
                                style={{
                                    color: `${Math.sign(bottlingData.growth) === 1
                                        ? '#74ce12'
                                        : '#e13219'
                                        }`, padding: '25px'
                                }}
                            >
                                {bottlingData.growth ? parseFloat(bottlingData.growth).toFixed(2) + '%' : 'N/A'}
                            </span>
                        </>
                    }
                    </div>
                    
                    <div className={`d-flex ${styles.mapHeader}`}>
                        <Dropdown className={`${styles.headerDropdown}`}>
                            <Dropdown.Toggle
                                variant="none"
                                id="dropdown-basic"
                                className={`d-flex align-items-center ${styles.dropdownButton}`}
                            >
                                <span className={`${styles.dropdownName}`}>YTM</span>
                                {/* <div
                                    className={`d-flex align-items-center justify-content-center ${styles.dropdownIconContainer}`}
                                >
                                    <img src={dropdownArrows} alt="All region dropdown"></img>
                                </div> */}
                            </Dropdown.Toggle>
                            {/* <Dropdown.Menu className={`${styles.dropdownMenuContainer}`}>
                                <Dropdown.Item className={`${styles.menuItem}`}>
                  YTM
                                </Dropdown.Item>
                                <Dropdown.Item className={`${styles.menuItem}`}>
                  MTD
                                </Dropdown.Item>
                            </Dropdown.Menu> */}
                        </Dropdown>
                    </div>
                </div>
                <div className={'d-flex'}>
                    <TabPanel tabId={activeTab}>
                        <BuLpgSalesRevenue year={props.year} activeTab={activeTab}></BuLpgSalesRevenue>
                        <BuLpgCommercialDiscount year={props.year} activeTab={activeTab}></BuLpgCommercialDiscount>
                        <BuLpgMarketShare year={props.year} activeTab={activeTab}></BuLpgMarketShare>
                    </TabPanel>
                    <div className={''}>
                    <BuLpgCustomerPopulation year={props.year}
                                    className={`${styles.BuGraphsRow1}`}
                                ></BuLpgCustomerPopulation>
                        <BuLpgHsse year={props.year}></BuLpgHsse>
                        <BuLpgCcs year={props.year} activeTab={props.activeTab}></BuLpgCcs>
                        {/* <BuLpgOthers ></BuLpgOthers> */}

                    </div>

                    <div className={''}>
                        <BuLpgOperatingCost year={props.year}></BuLpgOperatingCost>
                        <BuLpgDepotInventory year={props.year}
                            activeTab={props.activeTab}
                            className={`${styles.BuGraphsRow1}`}
                        ></BuLpgDepotInventory>
                        <BuLpgProjects year={props.year} product={props.activeTab} className={`${styles.BuGraphsRow1}`}></BuLpgProjects>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}

export default BuLpgGraphs
