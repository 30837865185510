// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BuLpgCustPopulationGraph_grmGraph__3si2j {\n  position: relative;\n  width: 850px;\n  height: 420px;\n  margin-left: 45px;\n  margin-top: 30px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "BuLpgCustPopulationGraph_grmGraph__3si2j"
};
module.exports = exports;
