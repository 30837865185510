// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LearningDevelopmentLineGraph_grmGraph__1fCav {\n  position: relative;\n  width: 820px;\n  height: 250px;\n  margin-left: 15px;\n  margin-top: 30px;\n  margin-right: 15px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "LearningDevelopmentLineGraph_grmGraph__1fCav"
};
module.exports = exports;
