import React from 'react'
import styles from '../../OrgListAccordian/OrgListAccordian.module.scss'

import { Accordion, Card } from 'react-bootstrap'
import DeltaValuesOrg from '../../DeltaValuesOrg/DeltaValuesOrg'

const OrgListFR = (props) => {
    return (
        <Accordion className={`${styles.listContainer}`}>
            <Card className={`${styles.cardContainer}`}>
                <Accordion.Toggle as={Card.Header} eventKey="1" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Debt/Equity</div>
                    <div className={`${styles.kpiValue}`}>{props.de.value ? (props.de.value).toFixed(2) : ''}</div>
                    {/* <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.de.quaterly ? parseFloat(props.de.quaterly).toFixed(2) : ''} year={props.de.yearly ? parseFloat(props.de.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div> */}
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="1" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="2" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Current</div>
                    <div className={`${styles.kpiValue}`}>{props.cr.value ? parseFloat(props.cr.value).toFixed(2) : ''}</div>
                    {/* <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.cr.quaterly ? parseFloat(props.cr.quaterly).toFixed(2) : ''} year={props.cr.quaterly ? parseFloat(props.cr.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div> */}
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="2" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="3" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Interest Coverage</div>
                    <div className={`${styles.kpiValue}`}>{props.icr.value ? parseFloat(props.icr.value).toFixed(2) : ''}</div>
                    {/* <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.icr.quaterly ? parseFloat(props.icr.quaterly).toFixed(2) : ''} year={props.icr.yearly ? parseFloat(props.icr.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div> */}
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="3" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="4" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Inventory Turnover</div>
                    <div className={`${styles.kpiValue}`}>{props.it.value ? parseFloat(props.it.value).toFixed(2) : ''}</div>
                    {/* <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.it.quaterly ? parseFloat(props.it.quaterly).toFixed(2) : ''} year={props.it.yearly ? parseFloat(props.it.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div> */}
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="4" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="5" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Debtors Turnover</div>
                    <div className={`${styles.kpiValue}`}>{props.dt.value ? parseFloat(props.dt.value).toFixed(2) : ''}</div>
                    {/* <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.dt.quaterly ? parseFloat(props.dt.quaterly).toFixed(2) : ''} year={props.dt.yearly ? parseFloat(props.dt.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div> */}
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="5" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
            </Card>
        </Accordion>
    )
}
export default OrgListFR
