// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotFound_background__2lBwJ {\n  color: #fff;\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  font-family: Titillium Web;\n  display: flex;\n  background-color: #293348;\n  position: absolute;\n  justify-content: center;\n  align-content: center; }\n\n.NotFound_container__KSOrB {\n  width: 80%;\n  height: -moz-fit-content;\n  height: fit-content;\n  text-align: center;\n  justify-content: center; }\n\n.NotFound_heading__2lNQV {\n  font-size: 10rem; }\n\n.NotFound_body__2td6Q {\n  font-size: 2rem; }\n\n.NotFound_button__zuGog {\n  width: 10rem;\n  margin-top: 2rem; }\n", ""]);
// Exports
exports.locals = {
	"background": "NotFound_background__2lBwJ",
	"container": "NotFound_container__KSOrB",
	"heading": "NotFound_heading__2lNQV",
	"body": "NotFound_body__2td6Q",
	"button": "NotFound_button__zuGog"
};
module.exports = exports;
