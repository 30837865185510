
import React, { useState } from 'react'
import styles from '../Register.module.scss'
import bpclogo from '../assets/bpcl-logo.png'
import { useHistory, Link } from 'react-router-dom'
import recaptcha from '../../../assets/images/recaptcha.png'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { registerUser, sendOTP } from '../../../services/auth'

const Register = () => {
    let history = useHistory()
    const [userName, setUserName] = useState('')
    const [user, setUser] = useState('')
    const [userPass, setuserPass] = useState('')
    const [userotp, setuserotp] = useState('')
    const [captext, setcaptext] = useState('')
    const [error, setError] = useState('')
    const [signupttext, setSignuptext] = useState('SIGN UP')
    const [showPopup, setshowPopup] = useState(0)
    const [captchaText] = useState(Number(Math.floor(Math.random() * 100)))
    const [captchaText1] = useState(Number(Math.floor(Math.random() * 100)))
    const [OTPsection, setOTPsection] = useState(true)
    const [otptext, setopttext] = useState('SEND OTP TO MAIL')

    const handleChangeName = (e) => {
        setshowPopup(0)
        setUserName(e.target.value)
    }
    const handleChangeEmail = (e) => {
        setshowPopup(0)
        setUser(e.target.value)
    }
    const handleChangePass = (e) => {
        setshowPopup(0)
        setuserPass(e.target.value)
    }
    const handleChangeOTP = (e) => {
        setshowPopup(0)
        setuserotp(e.target.value)
    }
    const handleChangeQuest = (e) => {
        setshowPopup(0)
        setcaptext(e.target.value)
    }
    const OTPsubmit = async (e) => {
        e.preventDefault()
        setopttext('SENDING..')
        let emailregex = /^[a-zA-Z0-9._-]+@bharatpetroleum.in$/
        if (!user) {
            setopttext('SEND OTP TO MAIL')
            setshowPopup(1)
            setError('Enter your E-mail Id.')
        } else if (!emailregex.test(user)) {
            setopttext('SEND OTP TO MAIL')
            setshowPopup(1)
            setError('Invalid Credentials!')
        } else{
           sendOTP(user).then(response => {
                if(response.success === 1){
                    setshowPopup(0)
                    setError('')
                    setuserotp('')
                    setOTPsection(false)
                }else{
                    setOTPsection(true)
                }
           }).catch(error => {
                console.debug(error)
           }) 
        }
    }
    const registerSubmit = async (e) => {
        e.preventDefault()
        setSignuptext('SIGNING UP..')
        let user_Pattern = /^[a-zA-Z]{3,20}$/
        let otp_pattern = /^[0-9]{2,5}$/
        let emailregex = /^[a-zA-Z0-9._-]+@bharatpetroleum.in$/
        let passwordCheck = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
        let captchaTotal = captchaText + captchaText1
        if (!user && !userPass && !userName) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Name, e-mail id and password are required .')
        } else if (!user && !userPass) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('E-mail id and password are required .')
        } else if (!user && !userName) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Name and e-mail id are required .')
        } else if (!userName && !userPass) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Name and password are required .')
        } else if (!user) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Enter your E-mail Id.')
        } else if (!userPass) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Enter your Password.')
        } else if (!user_Pattern.test(userName)) {
            setSignuptext('SIGN UP')
            setshowPopup(3)
            setError('Username should be : \n 1. alphabetical characters. \n 2. greater than 3 characters and lower than 20 characters')
        } else if (!passwordCheck.test(userPass)) {
            setSignuptext('SIGN UP')
            setshowPopup(2)
            setError('Password must contain: \n 1. atleast 1 lowercase alphabetical character \n 2. atleast 1 uppercase alphabetical character \n 3. at least 1 numeric character \n 4. at least 1 special character \n 5 The password must be 8 characters or longer.')
        } else if (!userName) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Enter your Name.')
        } else if (!emailregex.test(user)) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Invalid Credentials!')
        } else if (!captext) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Captcha Error..')
        } else if (Number(captext) !== captchaTotal) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Wrong Answer. Captcha Error..')
        } else if (!userotp) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Enter OTP')
        } else if (!otp_pattern.test(userotp)) {
            setSignuptext('SIGN UP')
            setshowPopup(1)
            setError('Enter Valid OTP')
        } else {
            registerUser(userName, user, userPass, userotp).then(data => {
                if (data.success === 1) {
                    localStorage.setItem('bpclAccessToken', data.accessToken)
                    setError(data.message)
                } else {
                    setSignuptext('SIGN UP')
                    setshowPopup(1)
                    console.debug('error', data)
                    setError(data.message)
                }
            })
        }
    }
    return (
        <div className={`container d-flex justify-content-center ${styles.adminLogin}`}>
            <div className={`${styles.adminLboc}`}>
                <div>
                    <img src={bpclogo} alt="Logo" />
                </div>
                <div className={`${OTPsection === true ? styles.otpbox : styles.bpclbox}`}>
                    <h1>SIGN UP</h1>
                    <hr className={`${styles.linebrk}`}></hr>
                    {OTPsection === true ? 
                        <form className={`${styles.adminfrm}`} onSubmit={OTPsubmit}>
                            <div className={`form-goup ${styles.admindiv}`}>
                                <label>EMAIL ID</label>
                                <input type="email" className={'form-control'} name="email" onChange={(e) => handleChangeEmail(e)} />
                            </div>
                            <div className={`button ${styles.adminbtn}`}>
                                <button type="submit" className={`btn${styles.adminloginbtn}`} id="usersgn">{otptext}</button>
                            </div>
                            {showPopup === 1 &&
                                <div className={`${styles.popuperrmsg}`} id="popuperr">
                                    <p className={`${styles.errormsg}`}><span><FontAwesomeIcon icon={faExclamationCircle} /></span>{error}</p>
                                </div>
                            }
                        </form>
                    :
                    <form className={`${styles.adminfrm}`} onSubmit={registerSubmit}>
                        <div className={`form-goup ${styles.admindiv}`}>
                            <label>NAME</label>
                            <input type="text" className={'form-control'} name="name" onChange={(e) => handleChangeName(e)} />
                        </div>
                        <div className={`form-goup ${styles.admindiv}`}>
                            <label>PASSWORD</label>
                            <input type="password" className={'form-control'} name="password" onChange={(e) => handleChangePass(e)} />
                        </div>
                        <div className={`form-goup ${styles.admindiv}`}>
                            <label>OTP</label>
                            <input type="tel" className={'form-control'} name="userotp" onChange={(e) => handleChangeOTP(e)} />
                        </div>
                        <div className={`${styles.captdiv}`}>
                            <p className={`${styles.captitle}`}>Please answer the question below to continue</p>
                            <div className={`d-flex justify-content-between align-items-center form-goup`}>
                                <img src={recaptcha} />
                                <div className={`d-flex justify-content-between align-items-center ${styles.caldiv}`}>
                                    <p>{captchaText}+{captchaText1}<span>:</span></p>
                                    <input type="text" className={`${styles.captcha}`} name="captcha" required title={'please complete the captcha.'} onChange={(e) => handleChangeQuest(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={`button ${styles.adminbtn}`}>
                            <button type="submit" className={`btn${styles.adminloginbtn}`} id="usersgn">{signupttext}</button>
                        </div>
                        {(showPopup === 1 && error === 'Request received. Thank you.') ?
                            <div className={`${styles.popuperr}`} id="popuperr">
                                <p className={` ${styles.successGreen} `}>
                                    <span className={`${styles.errorad}`}>{error}</span>
                                </p>
                                <p className={` ${styles.successText} `}>
                                    <span>Your request has been verified and awaiting approval.<br /> Expect notification upon approval. You may close this window.</span>
                                </p>
                            </div>
                            : showPopup === 1 ?
                            <div className={`${styles.popuperrmsg}`} id="popuperr">
                                <p className={`${styles.errormsg}`}><span><FontAwesomeIcon icon={faExclamationCircle} /></span>{error}</p>
                            </div>
                            : showPopup === 2 ? 
                            <div className={`${styles.popuppasserr}`}>
                                <p className={`${styles.errormsg}`}>{error}</p>
                            </div>
                            : showPopup === 3 &&
                            <div className={`${styles.popupuserr}`}>
                                <p className={`${styles.errormsg}`}>{error}</p>
                            </div>
                        }
                    </form>
                    }
                    <div style={{ paddingTop: '25px' }}>
                        <span className={`${styles.linkText}`}>
                            Already registered? <Link style={{ fontWeight: '800' }} to='/login'>Click here to login</Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Register