import React, { useState, useEffect } from 'react'
import mapStyles from './mapStyles'
import styles from './GoogleMapComponent.module.scss'
import {
    GoogleMap,
    Marker,
    Polygon,
    InfoWindow
} from 'react-google-maps'
import statesJson from './indiaRegions.json'
// import StateMap from './StateMap'
import { getColourFromRegion } from '../../../utility/buMapUtility'
import { Spinner } from 'react-bootstrap'
import depotBlackIcon from '../../../assets/images/depot-alert-02.png'
import depotWhiteIcon from '../../../assets/images/depot-ok-02.png'
import bioFuelIcon from '../../../assets/images/mapIcon/Biofuel-48.png'
import gasIcon from '../../../assets/images//mapIcon/Gas02-48.png'
import hrsIcon from '../../../assets/images//mapIcon/HRS-48.png'
import lpgIcon from '../../../assets/images//mapIcon/LPG-48.png'
import lubesIcon from '../../../assets/images//mapIcon/Lubes-48.png'
import pipelineIcon from '../../../assets/images//mapIcon/Pipeline-48.png'
import refineriesIcon from '../../../assets/images//mapIcon/Refineries01-48.png'
import renewableEnergyIcon from '../../../assets/images//mapIcon/RenewableEnergy-48.png'
import retailIcon from '../../../assets/images//mapIcon/Retail01-48.png'
import allIcon from '../../../assets/images/mapIcon/All-48.png'
import { fetchGoogleMap } from '../../../services/bu'
import { fetchupdatedStatus } from '../../../services/Org'
import {isEmpty} from 'lodash'
import OrgMapDetailTable from '../OrgMapDetailTable/OrgMapDetailTable'
import Radio from "../../Itrm/ItrmDemurrage/Radio";
import greenTick from '../../../assets/images/greenTick.png'


function GoogleMapComponent(props) {
    const [activeKpi, setactiveKpi] = useState('all') 
    const [responseData, setresponseData] = useState([]) 
    const [allResponseData, setAllResponseData] = useState([]) 
    const [bioFuelResult, setBioFuelData] = useState(false) 
    const [gasData, setGasData] = useState(false)
    const [renewableEnergyData, setrenewableEnergyData] = useState(false) 
    const [pipelineData, setpipelineData] = useState(false) 
    const [lpgData, setlpgData] = useState(false) 
    const [retailData, setretailData] = useState(false) 
    const [lubeData, setlubeData] = useState(false) 
    const [refineriesData, setrefineriesData] = useState(false) 
    const [hrsData, sethrsData] = useState(false) 
    const [allData, setallData] = useState(true) 
    const [loading, setloading] = useState(true)  
    const [allNewResult, setallNewResult] = useState(false) 
    const [allContinuedResult, setallContinuedResult] = useState(false)
    const [allNewAndContinuedResult, setallNewAndContinuedResult] = useState(true) 
    const [allDelayed, setAllDelayed] = useState(false)
    const [statusBu, setallStatusBu] = useState("allNewAndContinued") 
    const [showInfoIndex, setShowInfoIndex] = useState({infoIndex: undefined}) 
    const [updatedStatus, setUpdatedStatus] = useState('')


    const [refineriesTable, setRefineriesTable] = useState({}) 
    const [retailTable, setRetailTable] = useState({}) 
    const [lpgTable, setLpgTable] = useState({}) 
    const [lubeTable, setLubeTable] = useState({}) 
    const [gasTable, setGasTable] = useState({}) 
    const [renewableTable, setRenewableTable] = useState({}) 
    const [hrsTable, setHRSTable] = useState({}) 
    const [pipelinesTable, setPipelinesTable] = useState({}) 
    const [biofuelTable, setBiofuelTable] = useState({})  
    const onCheckStateChangeBu = (event) => {
        setresponseData([])
        let newData 
        if(statusBu === "allNew"){
            newData = "new" 
        }
        if(statusBu === "allContinued"){
            newData = "continuing" 
        }
        if(statusBu === "allDelayed"){
            newData = "delayedproject" 
        }
        if(event.name === 'bioFuelBox'){
            setBioFuelData(true)
            setGasData(false)
            setrenewableEnergyData(false) 
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            if(statusBu === "allNewAndContinued"){ 
            setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Bio Fuel'))
            }
            else{
            setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Bio Fuel' && res[newData] === "Yes"))
            }
        }
        if(event.name === 'gasBox'){
            setGasData(true)
            setBioFuelData(false)
            setrenewableEnergyData(false) 
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            if(statusBu === "allNewAndContinued"){ 
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Gas'))
                }else{
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Gas' && res[newData] === "Yes"))
                }        
            }
        if(event.name === 'pipelineBox'){
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false) 
            setpipelineData(true)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            if(statusBu === "allNewAndContinued"){ 
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Pipeline'))
                }else{
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Pipeline' && res[newData] === "Yes"))
                }
            }
        if(event.name === 'renewableEnergyBox'){
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(true) 
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            if (statusBu === "allNewAndContinued") {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Renewable Energy'))
            } else {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Renewable Energy' && res[newData] === "Yes"))
            }
        }
        if (event.name === 'lpgBox') {
            setBioFuelData(false)
            setGasData(false)
            setrenewableEnergyData(false)
            setpipelineData(false)
            setlpgData(true)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false)
            if (statusBu === "allNewAndContinued") {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'LPG'))
            } else {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'LPG' && res[newData] === "Yes"))
            }
        }
        if (event.name === 'retailBox') {
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false)
            setpipelineData(false)
            setlpgData(false)
            setretailData(true)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false)
            if (statusBu === "allNewAndContinued") {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Retail'))
            } else {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Retail' && res[newData] === "Yes"))
            }
        }
        if (event.name === 'lubeBox') {
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false)
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(true)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false)
            if (statusBu === "allNewAndContinued") {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Lube'))
            } else {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Lube' && res[newData] === "Yes"))
            }
        }
        if (event.name === 'refineriesBox') {
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false)
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(true)
            sethrsData(false)
            setallData(false)
            if (statusBu === "allNewAndContinued") {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Refineries'))
            } else {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'Refineries' && res[newData] === "Yes"))
            }
        }
        if (event.name === 'hrsBox') {
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false)
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(true)
            setallData(false)
            if (statusBu === "allNewAndContinued") {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'HRS'))
            } else {
                setresponseData(allResponseData.filter((res) => res.sbu_Entity === 'HRS' && res[newData] === "Yes"))
            }        
        }
        if (event.name === 'allBox') {
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false)
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(true)
            // setresponseData(allResponseData)   
            if (statusBu === "allNewAndContinued") {
                setresponseData(allResponseData)
            } else {
                setresponseData(allResponseData.filter((res) => res[newData] === "Yes"))
            }   
        }
    }
    const onCheckStateChangeStatus = (event) => {

        if (event.name === 'allNewAndContinued') {
            setallStatusBu("allNewAndContinued")
            setBioFuelData(false)
            setGasData(false)
            setrenewableEnergyData(false) 
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            setallNewAndContinuedResult(true)
            setallNewResult(false)
            setallContinuedResult(false)
            setAllDelayed(false)
            setresponseData(allResponseData)
        }
        if (event.name === 'allNew') {
            setallStatusBu("allNew")
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false) 
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            setallNewResult(true)
            setallNewAndContinuedResult(false)
            setallContinuedResult(false)
            setAllDelayed(false)
            setresponseData(allResponseData.filter((res) => res.new === 'Yes'))
        }
        if (event.name === 'allContinued') {
            setallStatusBu("allContinued")
            setGasData(false)
            setBioFuelData(false)
            setrenewableEnergyData(false) 
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            setallContinuedResult(true)
            setallNewResult(false)
            setallNewAndContinuedResult(false)
            setAllDelayed(false)
            setresponseData(allResponseData.filter((res) => res.continuing === 'Yes'))
        }
        if (event.name === 'allDelayed') {
            setallStatusBu("allDelayed")
            setBioFuelData(false)
            setGasData(false)
            setrenewableEnergyData(false) 
            setpipelineData(false)
            setlpgData(false)
            setretailData(false)
            setlubeData(false)
            setrefineriesData(false)
            sethrsData(false)
            setallData(false) 
            setallNewAndContinuedResult(false)
            setallNewResult(false)
            setallContinuedResult(false)
            setAllDelayed(true)
            setresponseData(allResponseData.filter((res) => res.delayedproject === 'Yes'))
        }
    }
    //onRegionClick = onRegionClick.bind(this)
    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
                response.filter((res) => res.entity === "Corp Planning" && res.kpi_name === "projectMap" ? setUpdatedStatus(res.status) : false);
            } else {
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchGoogleMap(props.year)
            .then(response => {
                if(isEmpty(response)){
                    setresponseData([])
                    setloading(true)
                }else{
                    setresponseData(response)
                    setAllResponseData(response)
                    // const initialValue = 0;
                    // response.reduce((accumulator, currentValue) => 
                    // {
                    //     const abc = accumulator + currentValue.projectCost
                    //     console.log(abc, currentValue, ":ssssssssssssssss")
                    // }
                    // )
                    let projectCostSum = 0
                    let totalExptillLastYrSum = 0
                    let actualExpCurrentFYSum = 0
                    let projectCostRetailSum = 0
                    let totalExptillLastYrRetailSum = 0
                    let actualExpCurrentFYRetailSum = 0
                    let projectCostLpgSum = 0
                    let totalExptillLastYrLpgSum = 0
                    let actualExpCurrentFYLpgSum = 0
                    let projectCostLubeSum = 0
                    let totalExptillLastYrLubeSum = 0
                    let actualExpCurrentFYLubeSum = 0
                    let projectCostGasSum = 0
                    let totalExptillLastYrGasSum = 0
                    let actualExpCurrentFYGasSum = 0
                    let projectCostRenewableSum = 0
                    let totalExptillLastYrRenewableSum = 0
                    let actualExpCurrentFYRenewableSum = 0
                    let projectCostHRSSum = 0
                    let totalExptillLastYrHRSSum = 0
                    let actualExpCurrentFYHRSSum = 0
                    let projectCostPipelinesSum = 0
                    let totalExptillLastYrPipelinesSum = 0
                    let actualExpCurrentFYPipelinesSum = 0
                    let projectCostBioFuelSum = 0
                    let totalExptillLastYrBioFuelSum = 0
                    let actualExpCurrentFYBioFuelSum = 0
                    response.forEach((el) => {
                        if (el.sbu_Entity === "Refineries") {
                            projectCostSum += el.projectCost
                            totalExptillLastYrSum += el.totalExptillLastYr
                            actualExpCurrentFYSum += el.actualExpCurrentFY
                            setRefineriesTable({
                                projectCost: projectCostSum,
                                totalExptillLastYr: totalExptillLastYrSum,
                                actualExpCurrentFY: actualExpCurrentFYSum
                            })
                        }
                        if (el.sbu_Entity === "Retail") {
                            projectCostRetailSum += el.projectCost
                            totalExptillLastYrRetailSum += el.totalExptillLastYr
                            actualExpCurrentFYRetailSum += el.actualExpCurrentFY
                            setRetailTable({
                                projectCost: projectCostRetailSum,
                                totalExptillLastYr: totalExptillLastYrRetailSum,
                                actualExpCurrentFY: actualExpCurrentFYRetailSum
                            })
                        }
                        if (el.sbu_Entity === "LPG") {
                            projectCostLpgSum += el.projectCost
                            totalExptillLastYrLpgSum += el.totalExptillLastYr
                            actualExpCurrentFYLpgSum += el.actualExpCurrentFY
                            setLpgTable({
                                projectCost: projectCostLpgSum,
                                totalExptillLastYr: totalExptillLastYrLpgSum,
                                actualExpCurrentFY: actualExpCurrentFYLpgSum
                            })
                        }
                        if (el.sbu_Entity === "Lube") {
                            projectCostLubeSum += el.projectCost
                            totalExptillLastYrLubeSum += el.totalExptillLastYr
                            actualExpCurrentFYLubeSum += el.actualExpCurrentFY
                            setLubeTable({
                                projectCost: projectCostLubeSum,
                                totalExptillLastYr: totalExptillLastYrLubeSum,
                                actualExpCurrentFY: actualExpCurrentFYLubeSum
                            })
                        }
                        if (el.sbu_Entity === "Gas") {
                            projectCostGasSum += el.projectCost
                            totalExptillLastYrGasSum += el.totalExptillLastYr
                            actualExpCurrentFYGasSum += el.actualExpCurrentFY
                            setGasTable({
                                projectCost: projectCostGasSum,
                                totalExptillLastYr: totalExptillLastYrGasSum,
                                actualExpCurrentFY: actualExpCurrentFYGasSum
                            })
                        }
                        if (el.sbu_Entity === "Renewable Energy") {
                            projectCostRenewableSum += el.projectCost
                            totalExptillLastYrRenewableSum += el.totalExptillLastYr
                            actualExpCurrentFYRenewableSum += el.actualExpCurrentFY
                            setRenewableTable({
                                projectCost: projectCostRenewableSum,
                                totalExptillLastYr: totalExptillLastYrRenewableSum,
                                actualExpCurrentFY: actualExpCurrentFYRenewableSum
                            })
                        }
                        if (el.sbu_Entity === "HRS") {
                            projectCostHRSSum += el.projectCost
                            totalExptillLastYrHRSSum += el.totalExptillLastYr
                            actualExpCurrentFYHRSSum += el.actualExpCurrentFY
                            setHRSTable({
                                projectCost: projectCostHRSSum,
                                totalExptillLastYr: totalExptillLastYrHRSSum,
                                actualExpCurrentFY: actualExpCurrentFYHRSSum
                            })
                        }
                        if (el.sbu_Entity === "Pipeline") {
                            projectCostPipelinesSum += el.projectCost
                            totalExptillLastYrPipelinesSum += el.totalExptillLastYr
                            actualExpCurrentFYPipelinesSum += el.actualExpCurrentFY
                            setPipelinesTable({
                                projectCost: projectCostPipelinesSum,
                                totalExptillLastYr: totalExptillLastYrPipelinesSum,
                                actualExpCurrentFY: actualExpCurrentFYPipelinesSum
                            })
                        }
                        if (el.sbu_Entity === "Bio Fuel") {
                            projectCostBioFuelSum += el.projectCost
                            totalExptillLastYrBioFuelSum += el.totalExptillLastYr
                            actualExpCurrentFYBioFuelSum += el.actualExpCurrentFY
                            setBiofuelTable({
                                projectCost: projectCostBioFuelSum,
                                totalExptillLastYr: totalExptillLastYrBioFuelSum,
                                actualExpCurrentFY: actualExpCurrentFYBioFuelSum
                            })
                        }
                    })
                    setloading(false)
                }
            })
            .catch(error => console.debug(error))
    }, [props.mapOf, props.year])

    const onRegionClick = (e, region) => {
        console.debug(e)
        props.handleRegionDropdown(region.name, e.latLng, 6.2)
    }
    const onMarkerClick = (id) => {
        console.log(id);
    };

   const showInfo = (i) => {
    setShowInfoIndex({infoIndex: i })
   }

        return (
            loading
                ?
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                :
                <GoogleMap
                    className={`${styles.noCloseButton}`}
                    center={props.mapCenter}
                    // zoom={props.mapZoom}
                    zoom={5.4}
                    defaultOptions={{
                        styles: mapStyles,
                        mapTypeControl: false,
                        disableDoubleClickZoom: true,
                        zoomControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false
                    }}
                    ref={props.onMapMounted}
                    onClick={(e) => { console.debug('FROM MAP', e.latLng) }}

                >
                    <div className={`${styles.mapTableContainer2}`}>
                        <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                                <Radio
                                    size="sm"
                                    name="allNewAndContinued"
                                    label="All"
                                    checked={allNewAndContinuedResult}
                                    onClick={(e) => onCheckStateChangeStatus(e)
                                    }
                                />
                            </div>
                            <div className={`${styles.dataCell}`}>
                                <Radio
                                    size="sm"
                                    name="allNew"
                                    label="New"
                                    checked={allNewResult}
                                    onClick={(e) => onCheckStateChangeStatus(e)
                                    }
                                />
                            </div>
                            <div className={`${styles.dataCell}`}>
                                <Radio
                                    size="sm"
                                    name="allContinued"
                                    label="Continued"
                                    checked={allContinuedResult}
                                    onClick={(e) => onCheckStateChangeStatus(e)
                                    }
                                />
                            </div>
                            <div className={`${styles.dataCellRight}`}>
                                <Radio
                                    size="sm"
                                    name="allDelayed"
                                    label="Delayed"
                                    checked={allDelayed}
                                    onClick={(e) => onCheckStateChangeStatus(e)
                                    }
                                />
                            </div> &nbsp; &nbsp;
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                    </div>
                    <div className={`${styles.mapTableContainer1}`}>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-54px'}}>{<img src={allIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="allBox"
                                    label="All"
                                    checked={allData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-26px'}}>{<img src={bioFuelIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="bioFuelBox"
                                    label="Biofuel"
                                    checked={bioFuelResult}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-6px'}}>{<img src={refineriesIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="refineriesBox"
                                    label="Refineries"
                                    checked={refineriesData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.mapTableContainer3}`}>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-258px'}}>{<img src={lpgIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="lpgBox"
                                    label="LPG"
                                    checked={lpgData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-226px'}}>{<img src={pipelineIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="pipelineBox"
                                    label="Pipelines"
                                    checked={pipelineData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-242px'}}>{<img src={lubesIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="lubeBox"
                                    label="Lubes"
                                    checked={lubeData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.mapTableContainer5}`}>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px',marginLeft:'-396px'}}>{<img src={retailIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="retailBox"
                                    label="Retail"
                                    checked={retailData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                        <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-403px'}}>{<img src={hrsIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="hrsBox"
                                    label="HRS"
                                    checked={hrsData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-358px'}}>{<img src={renewableEnergyIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="renewableEnergyBox"
                                    label="Renewable"
                                    checked={renewableEnergyData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.mapTableContainer6}`}>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                            <div className={`${styles.dataCell}`}>
                            <span style={{marginRight:'12px', marginLeft:'-600px'}}>{<img src={gasIcon} width="26" height="26" alt="Icon"/>}</span>
                                <Radio
                                    size="sm"
                                    name="gasBox"
                                    label="Gas"
                                    checked={gasData}
                                    onClick={(e) => onCheckStateChangeBu(e)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.mapTableContainer4}`}>
                        <div className={`d-flex flex-column ${styles.BuRetailMapTableContainer}`}>
                            <div className={`d-flex flex-column ${styles.tableHeaderRowContainer}`}>
                                <div className={`${styles.tableHeaderRow}`}>
                                    <div className={`${styles.headerCell1}`}>BU (Rs. in Cr.)</div>
                                    <div className={`${styles.headerCell}`}>Refineries</div>
                                    <div className={`${styles.headerCell}`}>Retail</div>
                                    <div className={`${styles.headerCell}`}>LPG</div>
                                    <div className={`${styles.headerCell}`}>Lubes</div>
                                    <div className={`${styles.headerCell}`}>Gas</div>
                                    <div className={`${styles.headerCell}`}>Renewable</div>
                                    <div className={`${styles.headerCell}`}>HRS</div>
                                    <div className={`${styles.headerCell}`}>Pipelines</div>
                                    <div className={`${styles.headerCell}`}>Biofuel</div>
                                    <div className={`${styles.headerCell}`}>Total</div>
                                </div>


                                <div className={` d-flex flex-column ${styles.tableDataRowContainer}`}>
                                    <div className={`d-flex ${styles.tableDataRow}`}>
                                        <div className={`${styles.dataCell1}`}>
                                            <span>Project Cost</span>
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(refineriesTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(retailTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(lpgTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(lubeTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(gasTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(renewableTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(hrsTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(pipelinesTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(biofuelTable.projectCost).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(refineriesTable.projectCost + retailTable.projectCost + lpgTable.projectCost + lubeTable.projectCost + gasTable.projectCost + renewableTable.projectCost + hrsTable.projectCost + pipelinesTable.projectCost + biofuelTable.projectCost).toFixed(0)}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.tableDataRow1}`}>
                                        <div className={`${styles.dataCell2}`}>
                                            <span>Exp. in FY23</span>
                                        </div>
                                        
                                        <div className={`${styles.dataCell}`}>
                                            {Number(refineriesTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(retailTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(lpgTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(lubeTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(gasTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(renewableTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(hrsTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(pipelinesTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(biofuelTable.totalExptillLastYr).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(refineriesTable.totalExptillLastYr + retailTable.totalExptillLastYr + lpgTable.totalExptillLastYr + lubeTable.totalExptillLastYr + gasTable.totalExptillLastYr + renewableTable.totalExptillLastYr + hrsTable.totalExptillLastYr + pipelinesTable.totalExptillLastYr + biofuelTable.totalExptillLastYr).toFixed(0)}
                                        </div>

                                    </div>
                                    <div className={`d-flex ${styles.tableDataRow}`}>
                                        <div className={`${styles.dataCell2}`}>
                                            <span>Exp. in FY24</span>
                                        </div>
                                            
                                        <div className={`${styles.dataCell}`}>
                                            {Number(refineriesTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(retailTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(lpgTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(lubeTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(gasTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(renewableTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(hrsTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(pipelinesTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(biofuelTable.actualExpCurrentFY).toFixed(0)}
                                        </div>
                                        <div className={`${styles.dataCell}`}>
                                            {Number(refineriesTable.actualExpCurrentFY + retailTable.actualExpCurrentFY + lpgTable.actualExpCurrentFY + lubeTable.actualExpCurrentFY + gasTable.actualExpCurrentFY + renewableTable.actualExpCurrentFY + hrsTable.actualExpCurrentFY + pipelinesTable.actualExpCurrentFY + biofuelTable.actualExpCurrentFY).toFixed(0)}
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(activeKpi === 'depot') && <div className={`d-flex align-items-center justify-content-center p-2 ${styles.depotTabLabelContainer}`}>
                        <div className="d-flex align-items-center mr-3"><img src={depotBlackIcon} alt="Below Target" className="mr-2" />Below</div>
                        <div className="d-flex align-items-center ml-3"><img src={depotWhiteIcon} alt="Above Target" className="mr-2" />Above</div>
                    </div>}
                    {/* <div className={`${activeKpi === 'depot' ? styles.mapTableContainerDepot : styles.mapTableContainer}`}>
                    <OrgMapDetailTable year={props.year} mapOf={props.mapOf} activeKpi={activeKpi}></OrgMapDetailTable>
                    </div> */}
                    {
                        // (props.mapOf === 'all regions' && props.activeTab === 'retail_tab')
                        //     ?
                            statesJson.regions.map((regionItem, index) => {
                                let responseRegion = responseData.find((responseDataItem) => {
                                    return responseDataItem.region === regionItem.name
                                })
                                return (
                                    <React.Fragment key={index}>
                                        {activeKpi === 'depot' && <Marker position={{ lat: 18, lng: 80 }} icon={depotBlackIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 24.7, lng: 77.4 }} icon={depotWhiteIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 10.4, lng: 78.75 }} icon={depotBlackIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 20.0, lng: 84.5 }} icon={depotWhiteIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 19.9, lng: 74.7 }} icon={depotBlackIcon}></Marker>}
                                        {/* {responseRegion &&
                                            <CustomInfoBox
                                                kpiSelection={activeKpi}
                                                locationData={responseRegion}
                                                anchorPosition={regionItem.data.markers[0].coordinates.slice(-1)[0]}
                                                markerPixelOffset={{ x: 135, y: 120 }}
                                            ></CustomInfoBox>
                                        } */}
                                        {regionItem.data.polygons.map((polygon, index) => {
                                            return (
                                                <Polygon
                                                    key={index}
                                                    path={polygon.coordinates}
                                                    options={{
                                                        // fillColor: `${getColourFromRegion(responseRegion ? responseRegion.region : '')}`,
                                                        fillColor:'#0051ff',
                                                        fillOpacity: 1,
                                                        strokeColor: '#141923',
                                                        strokeOpacity: 1,
                                                        strokeWeight: 0.5,
                                                    }}
                                                //onClick={(e) => this.onRegionClick(e, regionItem)}
                                                >
                                                </Polygon>)
                                        })}
                                        {responseData.map((mapProjectData, index) =>
                                            <Marker
                                                key={index}
                                                options={{ icon: mapProjectData.sbu_Entity ==='Bio Fuel' ? bioFuelIcon :
                                                  mapProjectData.sbu_Entity === 'Gas' ? gasIcon : 
                                                  mapProjectData.sbu_Entity === 'HRS' ? hrsIcon :
                                                  mapProjectData.sbu_Entity === 'LPG' ? lpgIcon : 
                                                  mapProjectData.sbu_Entity === 'Lube' ? lubesIcon :
                                                  mapProjectData.sbu_Entity === 'Pipeline' ? pipelineIcon : 
                                                  mapProjectData.sbu_Entity === 'Refineries' ? refineriesIcon :
                                                  mapProjectData.sbu_Entity === 'Renewable Energy' ? renewableEnergyIcon : 
                                                  mapProjectData.sbu_Entity === 'Retail' ? retailIcon : depotWhiteIcon
                                                }}
                                                position={mapProjectData}
                                                onClick={() => { showInfo(index) }}
                                            >
                                                {showInfoIndex.infoIndex === index ?
                                                    <InfoWindow className={`${styles.popupOut}`}
                                                        onCloseClick={() => {
                                                            showInfo(null);
                                                        }}>
                                                        <div>
                                                            <h3 className={`${styles.popupHeader}`}> {mapProjectData.projectDescription}</h3>
                                                            <ol type='a'>
                                                                {/* <li className={`${styles.popupLine}`}>Project Type(Continue/New): { mapProjectData.continuing ? "Continue" : "New"}</li> */}
                                                                <li className={`${styles.popupLine}`}>Project Cost:&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {mapProjectData.projectCost.toFixed(2)} Cr</li>
                                                                {(mapProjectData.continuing === "Yes") ? <li className={`${styles.popupLine}`}>Till Last FY Expenditure:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mapProjectData.totalExptillLastYr.toFixed(2)} Cr</li> : ""}
                                                                <li className={`${styles.popupLine}`}>Current FY Expenditure:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mapProjectData.actualExpCurrentFY.toFixed(2)} Cr</li>
                                                                <li className={`${styles.popupLine}`}>Completion Percentage:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mapProjectData.physicalProgress.toFixed(2)}%</li>
                                                                <li className={`${styles.popupLine}`}>Completion Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{(new Date((mapProjectData.completiondate)).toLocaleDateString('en-GB'))}</li>
                                                                <li className={`${styles.popupLine}`}>Areas of Concern:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mapProjectData.areasofconcern}</li>
                                                            </ol>
                                                        </div>
                                                    </InfoWindow> : null }
                                            </Marker>
                                        )}
                                        {/* <Polyline
                                            path={
                                                regionItem.data.markers[0].coordinates
                                            }
                                            options={{
                                                strokeColor: '#ffffff',
                                                strokeOpacity: 1,
                                                strokeWeight: 1,
                                            }}
                                        >
                                        </Polyline> */}
                                    </React.Fragment>
                                )
                            })
                            // :
                            // props.activeTab === 'retail_tab' && <StateMap
                            //     activeTab={props.activeTab}
                            //     kpiSelection={activeKpi} 
                            //     regionName={props.mapOf}
                            //     year={props.year}
                            //     >
                            // </StateMap>
                    }
                </GoogleMap>
        )
}

export default GoogleMapComponent
