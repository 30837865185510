// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Itrmpricegraph_grmGraph__2liQ7 {\n  position: relative;\n  width: 1000px;\n  height: 450px;\n  margin-top: 10px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "Itrmpricegraph_grmGraph__2liQ7"
};
module.exports = exports;
