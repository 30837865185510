import React from 'react'
import styles from './ExpandedKpiTable.module.scss'
import { Table } from 'react-bootstrap'

const getPrecisionFormat = (kpiName, value) => {
    const zeroPresionKpis = new Set([
        'CNG stations',
        'Work order for CNG Stations under DODO Policy',
        'Financial Closure of CBG plants',
        'New Retail Outlets',
    ])

    return zeroPresionKpis.has(kpiName) ? value.toString().replace(/\..*$/, '') : value
}
const numberFormatEnIn = (value) => {
    return Number(value).toLocaleString(
        'en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }
    )
}
const ExpandedKpiTable = (props) => {
    function makeHeader() {
        if (props.currentData.title === 'Others') {
            return (
                <>
                    <td>Apr</td>
                    <td>May</td>
                    <td>Jun</td>
                    <td>Jul</td>
                    <td>Aug</td>
                    <td>Sep</td>
                    <td>Oct</td>
                    <td>Nov</td>
                    <td>Dec</td>
                    <td>Jan</td>
                    <td>Feb</td>
                    <td>Mar</td>
                </>
            )
        } else {
            return (
                <>
                    {props.expandedKpiData.map((kpiTitle, index) => (
                        kpiTitle ? <td key={index}>Q{index + 1}</td> : <td>-</td>
                    ))}
                </>
            )
        }
    }
    return (
        <div className={`row m-0 d-flex flex-row justify-content-between align-items-stretch ${styles.table_container}`}>
            <div className={'col-3 px-0 pt-5'}>
                <Table className={`table ${styles.table_left_container_style}`}>
                    <tbody>
                        <tr>
                            <td style={{ border: 'none' }}>Excellent Target ({props.kpiData.unit})</td>
                        </tr>
                        <tr>
                            <td style={{ border: 'none' }}>Actual Achievements ({props.kpiData.unit})</td>
                        </tr>
                        <tr>
                            <td style={{ border: 'none' }}>Scores</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className={'col-9'}>
                <table className={`table table-bordered ${styles.table_container_style}`}>
                    <tbody>
                        <React.Fragment>
                            <tr>
                                {makeHeader()}
                            </tr>
                            <tr>
                                {
                                    props.expandedKpiData.map((kpiTitle, index) => {
                                        let getYear = new Date(kpiTitle.date).getFullYear()
                                        var value = (getYear) ?
                                            <td key={index}><span>{kpiTitle.quaterly_target >= 0 ? getPrecisionFormat(kpiTitle.kpi_name, numberFormatEnIn(parseFloat(kpiTitle.quaterly_target).toFixed(2))) : '-'}</span></td>
                                            : <td key={index} style={{ display: 'none' }}></td>

                                        return (value)
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    props.expandedKpiData.map((kpiTitle, index) => {
                                        let getYear = new Date(kpiTitle.date).getFullYear()
                                        var value = (getYear) ?
                                            <td key={index}><span>{kpiTitle.actual_achievement >= 0 ? getPrecisionFormat(kpiTitle.kpi_name, numberFormatEnIn(parseFloat(kpiTitle.actual_achievement).toFixed(2))) : '-'}</span></td>
                                            : <td key={index} style={{ display: 'none' }}></td>

                                        return (value)
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    props.expandedKpiData.map((kpiTitle, index) => {
                                        let getYear = new Date(kpiTitle.date).getFullYear()
                                        var value = (getYear) ?
                                            <td key={index}><span>{kpiTitle.score >= 0 ? Number(kpiTitle.score).toFixed(2) : '-'}</span></td>
                                            : <td key={index} style={{ display: 'none' }}></td>
                                        return (value)
                                    })
                                }
                            </tr>
                        </React.Fragment>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ExpandedKpiTable