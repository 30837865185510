// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomInfoBox_infoWindowContainer__ZVYZ- {\n  padding: 0;\n  font-family: Titillium Web;\n  width: 287px;\n  height: 255px;\n  background-color: rgba(24, 30, 42, 0);\n  color: \"white\";\n  border: none; }\n  .CustomInfoBox_infoWindowContainer__ZVYZ- .CustomInfoBox_markerDot__2y9Py {\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    background-color: #b9c8e1;\n    margin-bottom: 5px; }\n  .CustomInfoBox_infoWindowContainer__ZVYZ- .CustomInfoBox_infoWindowTitle__kD1Vw {\n    font-size: 28px;\n    color: #b9c8e1; }\n  .CustomInfoBox_infoWindowContainer__ZVYZ- .CustomInfoBox_titleLine__1vs8Z {\n    border-color: #b9c8e1;\n    width: 40px;\n    height: 1px;\n    margin: 6px 0px 18px 0px; }\n  .CustomInfoBox_infoWindowContainer__ZVYZ- .CustomInfoBox_infoWindowContent__1zmfX {\n    color: #b9c8e1;\n    font-size: 22px;\n    margin-bottom: 2px; }\n  .CustomInfoBox_infoWindowContainer__ZVYZ- .CustomInfoBox_depotInfoWindow__3Eqku {\n    color: #b9c8e1;\n    font-size: 22px;\n    width: 178px; }\n  .CustomInfoBox_infoWindowContainer__ZVYZ- .CustomInfoBox_contentTableTitle__ZR0uZ {\n    border-bottom: #b9c8e1 1px dashed;\n    border-top: #b9c8e1 1px dashed;\n    font-size: 22px;\n    font-weight: bold;\n    margin-right: 10px;\n    margin-top: 10px; }\n  .CustomInfoBox_infoWindowContainer__ZVYZ- .CustomInfoBox_contentTitle__TgygO {\n    margin-right: 10px;\n    color: #b9c8e1;\n    font-size: 22px; }\n", ""]);
// Exports
exports.locals = {
	"infoWindowContainer": "CustomInfoBox_infoWindowContainer__ZVYZ-",
	"markerDot": "CustomInfoBox_markerDot__2y9Py",
	"infoWindowTitle": "CustomInfoBox_infoWindowTitle__kD1Vw",
	"titleLine": "CustomInfoBox_titleLine__1vs8Z",
	"infoWindowContent": "CustomInfoBox_infoWindowContent__1zmfX",
	"depotInfoWindow": "CustomInfoBox_depotInfoWindow__3Eqku",
	"contentTableTitle": "CustomInfoBox_contentTableTitle__ZR0uZ",
	"contentTitle": "CustomInfoBox_contentTitle__TgygO"
};
module.exports = exports;
