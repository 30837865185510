import React from 'react'

import styles from './InfographicKpi5.module.scss'

function InfographicKpi5(props) {
    return (
        <div className={`d-flex justify-content-center align-items-center ${styles.ytdMtdtable}`} style={{ top: `${props.top}`, right: `${props.right}`, bottom: `${props.bottom}`, left: `${props.left}`, height : `${props.height}`, width : `${props.width}` }}>
            <table className={`${styles.ytmmtdtable}`}>
                <thead className={`${styles.ytmmtdtablehead}`}>
                    <tr className={`${styles.wfull}`}>
                        <th><div className={`d-flex px-1 align-items-center ${styles.ytdtablehead}`}>{props.tableheadytm}</div></th>
                        <th><div className={`d-flex px-1 align-items-center ${styles.ytdtablehead}`}>{props.tableheadmtd}</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className={`d-flex px-1 align-items-center ${styles.ytdtable}`}>
                                <span className={`${styles.valuetable}`} style={{color:`${props.textColor}`}}>{props.crudeProcYtmData}</span>
                                <span className={`${styles.percentagetable}`} style={{ color: `${props.crudeProcYtmColor}` }}>
                                    {props.crudeProcYtmDelta}
                                    { props.location !== "bina" ? "%" : ""}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className={`d-flex px-1 align-items-center ${styles.mtdtable}`}>
                                <span className={`${styles.valuetable}`} style={{ color: `${props.textColor}` }}>{props.crudeProcMtdData}</span>
                                <span className={`${styles.percentagetable}`} style={{ color: `${props.crudeProcMtdColor}` }}  >
                                    {props.crudeProcMtdDelta}
                                    { props.location !== "bina" ? "%" : ""}
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default InfographicKpi5
