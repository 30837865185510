// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BpclMarket_BpclMarketsection__3k1qF {\n  width: 100%;\n  height: 100%; }\n  .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclGraphSection__29M4F {\n    width: 100%;\n    height: 60%; }\n    .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclGraphSection__29M4F .BpclMarket_BpclGraph__3dTh1 {\n      width: 100%;\n      height: 100%; }\n  .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclContent__1wzgW {\n    width: 100%;\n    height: 40%;\n    padding: 15px; }\n    .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclContent__1wzgW .BpclMarket_BpclContenthead__1nsAO {\n      color: #8fa9db;\n      margin-bottom: 0; }\n      .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclContent__1wzgW .BpclMarket_BpclContenthead__1nsAO .BpclMarket_BpclContentheadspan__3TW0S {\n        color: #74ce12; }\n      .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclContent__1wzgW .BpclMarket_BpclContenthead__1nsAO .BpclMarket_BpclContentheadspanred__3_6yh {\n        color: #ff5b4f; }\n    .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclContent__1wzgW .BpclMarket_BpclContentp__2tYSe {\n      color: #fff;\n      font-size: 20px; }\n      .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclContent__1wzgW .BpclMarket_BpclContentp__2tYSe .BpclMarket_BpclContentpspan__1_o0R {\n        color: #74ce12;\n        margin-left: 15px; }\n      .BpclMarket_BpclMarketsection__3k1qF .BpclMarket_BpclContent__1wzgW .BpclMarket_BpclContentp__2tYSe .BpclMarket_BpclContentpspanred__AT699 {\n        color: #ff5b4f;\n        margin-left: 15px; }\n", ""]);
// Exports
exports.locals = {
	"BpclMarketsection": "BpclMarket_BpclMarketsection__3k1qF",
	"BpclGraphSection": "BpclMarket_BpclGraphSection__29M4F",
	"BpclGraph": "BpclMarket_BpclGraph__3dTh1",
	"BpclContent": "BpclMarket_BpclContent__1wzgW",
	"BpclContenthead": "BpclMarket_BpclContenthead__1nsAO",
	"BpclContentheadspan": "BpclMarket_BpclContentheadspan__3TW0S",
	"BpclContentheadspanred": "BpclMarket_BpclContentheadspanred__3_6yh",
	"BpclContentp": "BpclMarket_BpclContentp__2tYSe",
	"BpclContentpspan": "BpclMarket_BpclContentpspan__1_o0R",
	"BpclContentpspanred": "BpclMarket_BpclContentpspanred__AT699"
};
module.exports = exports;
