import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import {numberFormatEnIn} from '../../../utility/utility'

const EthicalHrGraph = (props) => {

    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }
        let lddata = []
        let func_array = []
        let beh_array = []
        const getLDdata = (data) => {
            switch(data.training_type){
                case 'Functional':
                        func_array.push(Number(data.mandays).toFixed(2))
                        break
                case 'Behavioral':
                        beh_array.push(Number(data.mandays).toFixed(2))
                        break
                default:
                    return data.training_type
            }
        }
        props.graphdata.forEach(item => {
            if (props.allGraph) {
                if(item.business_unit === 'all'){
                    getLDdata(item)
                    return
                }
            }
            if (props.retailGraph) {
                if(item.business_unit === 'retail'){
                    getLDdata(item)
                    return
                }
            }
            if (props.lpgGraph) {
                if(item.business_unit === 'lpg'){
                    getLDdata(item)
                    return
                }
            }
            if (props.atfGraph) {
                if(item.business_unit === 'atf'){
                    getLDdata(item)
                    return
                }
            }
            if (props.lubesGraph) {
                if(item.business_unit === 'lubes'){
                    getLDdata(item)
                    return
                }
            }
            if (props.refineryGraph) {
                if(item.business_unit === 'refinery'){
                    getLDdata(item)
                    return
                }
            }
            if (props.incGraph) {
                if(item.business_unit === 'inc'){
                    getLDdata(item)
                    return
                }
            }
            if (props.entiGraph) {
                if(item.business_unit === 'entities'){
                    getLDdata(item)
                    return
                }
            }
        })
        
        let func_data = func_array[0]
        let beh_data = beh_array[0]
        lddata.push(Number(func_data).toFixed(2), Number(beh_data).toFixed(2))

        const chartConfig = {
            type: 'doughnut',
            data: {
                datasets: [{
                    label : ['Functional', 'Behaviour'],
                    data: lddata,
                    backgroundColor: ['#ffbf45', '#2281e8'],
                    borderWidth: 0,
                    datalabels: {
                        color: '#fafafa',
                        anchor:'center',
                        fontSize: 22,
                        formatter: function(value, context){
                            return `${value}`
                        }
                    }
                }],
            },
            options: {
                elements: {
                    center: {
                        text: `Total: ${lddata.reduce((a,b) => (parseFloat(a) + parseFloat(b)).toFixed(2), 0)}`,
                        color: 'white', //Default black
                        fontStyle: 'Titillium Web', //Default Arial
                        sidePadding: 30//Default 20 (as a percentage)
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 65,
                title: {
                    display: true,
                    text:props.title, 
                    fontColor: 'white',
                    fontSize: 18,
                    position: 'bottom'
                },
                tooltips:{
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                        }
                    }
                }
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(chartContainer.current, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphdata, props.allGraph, props.retailGraph, props.lpgGraph, props.lubesGraph, props.atfGraph, props.refineryGraph, props.incGraph, props.title]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <canvas ref={chartContainer} height={`245px`} width={`100%`} />
    )
}

Chart.pluginService.register({
    beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
            //Get ctx from string
            let ctx = chart.chart.ctx

            //Get options from the center object in options
            let centerConfig = chart.config.options.elements.center
            let fontStyle = centerConfig.fontStyle || 'Titillium Web'
            let txt = centerConfig.text

            // orangeGradient = ctx.createLinearGradient(0, 0, 0, 400);
            // orangeGradient.addColorStop(1, '#25ced9');
            // orangeGradient.addColorStop(0, '#0ed099');

            let color = centerConfig.color || '#000'
            // let color = orangeGradient || '#000';

            let sidePadding = centerConfig.sidePadding || 20
            let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = '30px ' + fontStyle

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            let stringWidth = ctx.measureText(txt).width
            let elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated

            // Find out how much the font can grow in width.
            let widthRatio = elementWidth / stringWidth
            let newFontSize = Math.floor(30 * widthRatio)
            let elementHeight = (chart.innerRadius * 2)

            // Pick a new font size so it will not be larger than the height of label.
            let fontSizeToUse = Math.min(newFontSize, elementHeight)

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
            let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)
            ctx.font = fontSizeToUse + 'px ' + fontStyle
            ctx.fillStyle = color

            //Draw text in center
            ctx.fillText(txt, centerX, centerY)
        }
    }
})

export default EthicalHrGraph