import React, { useState, useCallback, useEffect } from 'react'
import styles from './BuProjects.module.scss'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import BuProjectPlanningTab from './BuProjectPlanningTab'
import BuProjectExpenditureTab from './BuProjectExpenditureTab'
import { isArray, isEmpty, reduce } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import { fetchupdatedStatus } from '../../../services/bu'
import "../../../Common.scss"

export const aggregateProjectsData = (data) => reduce(data, (acc, eachData) => {
    const kpiName = eachData['kpi_name'] || ''
    const subKpiType = eachData['sub_kpi_type'] || ''

    if (kpiName === 'projects expenditure' && subKpiType === 'expenditure') {
        acc['expenditure'] = eachData
    }

    if (kpiName === 'projects planning' && subKpiType === 'planned fy') {
        acc['planningFy'] = eachData
    }

    if (kpiName === 'projects planning' && subKpiType === 'ongoing') {
        const estimatedCompletion = (eachData['estd_completion'] || '').toLowerCase()
        if (!isArray(acc['ongoing'])) {
            acc['ongoing'] = []
        }
        switch (estimatedCompletion) {
            case '0-3':
                acc['ongoing'][0] = eachData
                break
            case '3-6':
                acc['ongoing'][1] = eachData
                break
            case '>6':
                acc['ongoing'][2] = eachData
                break
            case 'delayed':
                acc['ongoing'][3] = eachData
                break
        }
    }
    return acc
}, {})

const Legends = ({ legendsFor }) => {
    if (legendsFor === 'projects') {
        return (
            <div className={`d-flex ${styles.legendsContainer}`}>
                <div className="mr-4 d-flex align-items-center">
                    <span className={`mr-2 ${styles.dot1}`}></span>{'0-3 months'}
                </div>
                <div className="mr-4 d-flex align-items-center">
                    <span className={`mr-2 ${styles.dot2}`}></span>{'3-6 months'}
                </div>
                <div className="mr-4 d-flex align-items-center">
                    <span className={`mr-2 ${styles.dot3}`}></span>{'>6 months'}
                </div>
                <div className="mr-3 d-flex align-items-center">
                    <span className={`mr-2 ${styles.dot4}`}></span>{'Delayed'}
                </div>
            </div>
        )
    } else if (legendsFor === 'expenditure') {
        return (
            <div className={`d-flex ${styles.legendsContainer}`}>
                <div className="mr-4 d-flex align-items-center">
                    <span className={`mr-2 ${styles.dotActual}`}></span>Actual expenditure
                </div>
                <div className="mr-4 d-flex align-items-center">
                    <span className={`mr-2 ${styles.dotTarget}`}></span>Estimated expenditure
                </div>
            </div>
        )
    }

    return null
}

const BuProjects = (props) => {
    const [showExpenditure, setShowExpenditure] = useState(false)
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [aggregatedGraphData, setaggregatedGraphData] = useState([])
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')
    const [attr, setattr] = useState('handleEnter')
    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "Corp Planning" && res.kpi_name === "BU Project" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        let graphData;
        if (props.graphData.kpi_name) {
            graphData = aggregateProjectsData(props.graphData)
        }
        if (isEmpty(graphData)) {
            setaggregatedGraphData([])
            setIsNoDataAvailable(true)
        }else{
            setaggregatedGraphData(graphData)
            setIsNoDataAvailable(false)
        }
    }, [props.graphData, props.product])
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps

    const toggleTabs = () => setShowExpenditure(prev => !prev)
    return (
        <div className={`${styles.BuOperatingCostContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex align-items-center justify-content-between ${styles.headerContainer}`}>
                    <div className={`${styles.headerLeft} flex-grow-1 `}>
                        <div className={`${styles.heading}`}>{showExpenditure ? 'Expenditures' : 'Projects - Monthly'}
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                            </div>
                        <Legends legendsFor={showExpenditure ? 'expenditure' : 'projects'} />
                    </div>
                    {/* <div className={'justify-content-end'}> */}
                        {/* <div className={styles.toggleBtn}> */}
                        {/* <div className={styles.toggleBtn} onClick={toggleTabs}> */}
                            {/* <span className={`${styles.toggleBtnSwitch} ${!showExpenditure ? styles.active : ''}`}>Projects</span> */}
                            {/* <span className={`${styles.toggleBtnSwitch} ${showExpenditure ? styles.active : ''}`}>Expenditure</span> */}
                        {/* </div> */}
                    {/* </div> */}
                </div>
                {
                    props.isLoading ?
                        (
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                height: 507,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        )
                        :
                        (
                            isNoDataAvailable ?
                                <NoDataFound height={507} />
                                :
                                showExpenditure ?
                                    <BuProjectExpenditureTab graphData={aggregatedGraphData} fullScrn={fullScrn} /> :
                                    <BuProjectPlanningTab graphData={aggregatedGraphData} year={props.year} fullScrn={fullScrn} businessUnit={props.product} />
                        )
                }
            </FullScreen>
        </div>
    )
}
export default BuProjects