import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuIncLossGain.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuLossAndGainGraph from './BuLossAndGainGraph'
import { fetchIncLossGain, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
import "../../../Common.scss"

function BuIncLossGain(props) {
    const [graphData, setGraphData] = useState([])
    const [attr, setattr] = useState('handleEnter')
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }

    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "inc" && res.kpi_name === "Loss Gain Analysis" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        const prepareGraphData = () => {
            fetchIncLossGain(props.year)
                .then(response => {
                    setGraphData(response.data)
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData()
    }, [props.activeTab,props.year])

    return (
        <div className={`${styles.BuLubesLossGainAnalysisContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex flex-column justify-content-center ${styles.BuCcsHeader}`}>
                    <div className={`${styles.HeaderTop}`}>Loss & Gain Analysis - Monthly
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                    </div>
                    <div className={`d-flex ${styles.legendsContainer}`}>
                    <div className={`d-flex mr-4 align-items-center ${styles.legendsContainer}`}><span className={`mr-2 ${styles.dot4}`}></span> Net Gain</div>
                    <div className={`d-flex mr-4 align-items-center ${styles.legendsContainer}`}><span className={`mr-2 ${styles.dot5}`}></span> Net Loss</div>
                     </div>
                    </div>
                <div className={`d-flex ${styles.graphContainer}`}>
                    {
                        graphData.length > 0 ?
                            <BuLossAndGainGraph graphData={graphData}></BuLossAndGainGraph>
                            :
                            <div style={{ width: '100%' }}>
                                <NoDataFound />
                            </div>
                    }
                </div>
                <div className={'d-flex justify-content-end'}>
                    <img src={expandIcon} alt='Expand Icon' onClick={fullScrn}></img>
                </div>
            </FullScreen>
        </div >
    )
}
export default BuIncLossGain