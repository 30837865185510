import React from 'react'
import styles from './OrgListTile.module.scss'
import DeltaValuesOrg from '../DeltaValuesOrg/DeltaValuesOrg'
import {numberFormatEnIn} from '../../utility/utility'

const OrgListTile = (props) => {
    return (
        <div>
            <div className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                <div className={`${styles.kpiTitle}`}>Total Oil Bond Holding</div>
                <div className={`${styles.kpiValue}`}>INR {props.oilbond.value && numberFormatEnIn(parseFloat(props.oilbond.value).toFixed(2))} Cr</div>
                {/* <div className={`${styles.deltaComponent}`}>
                    <DeltaValuesOrg quater={props.oilbond.quater ? parseFloat(props.oilbond.quater).toFixed(2) : ''} year={props.oilbond.year ? parseFloat(props.oilbond.year).toFixed(2) : '' }></DeltaValuesOrg>
                </div> */}
            </div>
            <div className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                <div className={`${styles.kpiTitle}`}>Total Capital Employed</div>
                <div className={`${styles.kpiValue}`}>INR {props.captl.value && numberFormatEnIn(parseFloat(props.captl.value).toFixed(2))} Cr</div>
                {/* <div className={`${styles.deltaComponent}`}>
                    <DeltaValuesOrg quater={props.captl.quater && parseFloat(props.captl.quater).toFixed(2)} year={props.captl.year && parseFloat(props.captl.year).toFixed(2)}></DeltaValuesOrg>
                </div> */}
            </div>
        </div>
    )
}
export default OrgListTile