// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AtfCustomerGrowthGraphLastYr_grmGraph__jHpjg {\n  position: relative;\n  width: 715px;\n  height: 420px;\n  margin-top: 35px; }\n  .AtfCustomerGrowthGraphLastYr_grmGraph__jHpjg .AtfCustomerGrowthGraphLastYr_graphHeader__aENYL {\n    font-weight: 600;\n    font-size: 24px;\n    color: #fafafa;\n    margin-left: 7px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "AtfCustomerGrowthGraphLastYr_grmGraph__jHpjg",
	"graphHeader": "AtfCustomerGrowthGraphLastYr_graphHeader__aENYL"
};
module.exports = exports;
