import React, { useState, useEffect } from 'react'
import {
    Polygon,
    Polyline,
} from 'react-google-maps'
import CustomInfoBox from './CustomInfoBox'
import statesJson from './indiaRegions.json'
import { getColourFromRegion } from '../../../utility/buMapUtility'
import { Spinner } from 'react-bootstrap'
import { fetchLpgStateMap } from '../../../services/bu'
import heatmapLegend from '../../../assets/images/HMap.png'
import styles from './stateMap.module.scss'
function StateMap(props) {

    const [responseRegionData, setResponseRegionData] = useState([])
    const [loading, setLoading] = useState(true)

    let regionJson = props.statesJsonDb.regions.find((regionFromJson) => {
        return regionFromJson.name === props.regionName
    })

    useEffect(() => {
        fetchLpgStateMap(props.regionName, props.year)
            .then(response => {
                // console.debug(response);
                setResponseRegionData(response.data)
                setLoading(false)
            })
            .catch(error => console.debug(error))
        // return () => {
        //   cleanup
        // }
    }, [props.regionName, props.year])

    //Jugaad for top 5 states in North India
    // let topFiveStates = ["delhi", "punjab", "haryana", "uttar pradesh", "himachal pradesh"]

    return (
        loading
            ?
            <Spinner animation="border" role="status">
                ...Loading
            </Spinner>
            :
            regionJson.data.polygons.map((stateItem, index) => {
                let responseState = responseRegionData.find((responseDataItem) => {
                    // console.debug("State from JSON: ", stateItem.name);
                    // console.debug("State from Backend: ", responseDataItem.state);
                    return (responseDataItem.state.toLowerCase() === stateItem.name.toLowerCase())
                })
                return (
                    responseState && <React.Fragment key={index}>
                        {
                            <CustomInfoBox
                                kpiSelection={props.kpiSelection}
                                locationData={responseState}
                                anchorPosition={stateItem.annotationMarkers[0].coordinates.slice(-1)[0]}
                                markerPixelOffset={{ x: 135, y: 122 }}
                            ></CustomInfoBox>
                        }
                        {index===0 &&
                            // <div style={{ position: 'relative', bottom: '900px', left: '300px' }}><img style={{ height: '54px' }} src={heatmapLegend} />
                            //     <div>{min} TKL</div>
                            //     <div>{max} TKL</div>
                            // </div>
                            <div className={styles.heatmapLegend}><img className={styles.heatmapImg} src={heatmapLegend} />
                            <div className={styles.heatmapMax}>{responseRegionData[0].sales_actual !== null ? responseRegionData[0].sales_actual.toFixed(): 0} TKL</div>
                            {/* <div className={styles.heatmapMiddle}>{(responseData[0].sales_actual/2).toFixed()}</div> */}
                            <div className={styles.heatmapMin}>{responseRegionData[responseRegionData.length - 1].sales_actual !== null ? responseRegionData[responseRegionData.length - 1].sales_actual.toFixed() : 0} TKL</div>
                         </div>
                        }
                        <Polygon
                            path={stateItem.coordinates}
                            options={{
                                fillColor: `${getColourFromRegion(stateItem.name, stateItem.color)}`,
                                fillOpacity: 1,
                                strokeColor: '#ffffff',
                                strokeOpacity: 0.2,
                                strokeWeight: 1,
                            }}
                            onClick={(e) => { console.debug('FROM component', e.latLng) }}
                        >
                        </Polygon>
                        <Polyline
                            path={
                                stateItem.annotationMarkers[0].coordinates
                            }
                            options={{
                                strokeColor: '#ffffff',
                                strokeOpacity: 1,
                                strokeWeight: 1,
                                zIndex: 1
                            }}
                        >
                        </Polyline>
                    </React.Fragment>
                )
            })
    )
}
export default StateMap