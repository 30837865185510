import React, { useState, useEffect } from 'react'
import styles from './ItrmWorlMap.module.scss'
import GoogleChart from '../../../charts/Map/Charts'
import data from '../ITRM_CrudeProcurement/polygonData'

const ItrmWorlMap = (props) => {
    const [location, setLocation] = useState('')
    const getRegionNameOnClick = (el) => {
        //alert();
        props.fetchRegionName(el)
    }

    useEffect(() => {

        setLocation(props.location)
    }, [props.location, props.year]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className={styles.mapContainer}>
            <GoogleChart
                getRegionNameOnClick={getRegionNameOnClick}
                location={props.location}
                year={props.year}
            ></GoogleChart>
        </div>
    )
}

export default ItrmWorlMap
