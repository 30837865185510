import React, { useState, useCallback, useEffect } from 'react'
import styles from './BuProjects.module.scss'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import BuProjectPlanningTab from './BuProjectPlanningTab'
import BuProjectExpenditureTab from './BuProjectExpenditureTab'
import { isArray, isEmpty, reduce } from 'lodash'
import { fetchupdatedStatus, fetchGoogleMap } from '../../../services/bu'

import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const Legends = ({ legendsFor }) => {
    return null
}

const BuProjectsCount = (props) => {
    const [showExpenditure, setShowExpenditure] = useState(false)
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [aggregatedGraphData, setaggregatedGraphData] = useState([])
    const [aggregatedretailGraphData, setaggregatedretailGraphData] = useState([])
    const [updatedStatus, setUpdatedStatus] = useState('')
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "Corp Planning" && res.kpi_name === "BU Project" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchGoogleMap(props.year)
        .then(response => {
            if (!isEmpty(response)) {
                const retailData = response.filter((res) => res.sbu_Entity === "Retail");
                setaggregatedretailGraphData(retailData)
            }
        })
        .catch(error => console.debug(error))
        let graphData = props.graphData
        if (isEmpty(graphData)) {
            setaggregatedGraphData([])
            setIsNoDataAvailable(true)
        }else{
            setaggregatedGraphData(graphData)
            setIsNoDataAvailable(false)
        }
    }, [props.graphData, props.product])
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps

    const toggleTabs = () => setShowExpenditure(prev => !prev)
    return (
        <div className={`${styles.BuOperatingCostContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex align-items-center justify-content-between ${styles.headerContainer}`}>
                    <div className={`${styles.headerLeft} flex-grow-1 `}>
                        <div className={`${styles.heading}`}>{showExpenditure ? 'Expenditures' : 'Projects - Monthly'}
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}</div>
                        {props.product === "retail_tab" ?
                            <div className={`d-flex ${styles.legendsContainer}`}>
                                <div className={`${styles.headerLeft} mr-4 d-flex align-items-center`}>
                                    <span className={`mr-2 ${styles.projectCostDot}`}></span>Project Cost (Cr.)
                                </div>
                                <div className={`${styles.headerLeft} mr-4 d-flex align-items-center`}>
                                    <span className={`mr-2 ${styles.totalExpensesDot}`}></span>Total Expenses (Cr.)
                                </div>
                                <div className={`${styles.headerLeft} mr-4 d-flex align-items-center`}>
                                    <span className={`mr-2 ${styles.completionDot}`}></span>Completion %
                                </div>
                            </div> :
                            <div className={`d-flex ${styles.legendsContainer}`}>
                            <div className={`${styles.headerLeft} mr-4 d-flex align-items-center`}>
                                    <span>No Of Projects: {aggregatedGraphData && aggregatedGraphData.no_of_project && aggregatedGraphData.no_of_project !== null ? Number(aggregatedGraphData.no_of_project) : 'No Data'}</span>
                                </div>
                                <div className={`${styles.headerLeft} mr-4 d-flex align-items-center`}>
                                    <span>No Of Delayed Projects: {aggregatedGraphData && aggregatedGraphData.no_of_delayed_project && aggregatedGraphData.no_of_delayed_project !== null ? Number(aggregatedGraphData.no_of_delayed_project) : 'No Data'}</span>
                                </div>
                                <div className={`${styles.headerLeft} mr-4 d-flex align-items-center`}>
                                    <span>Project Cost: {aggregatedGraphData && aggregatedGraphData.cost_of_project && aggregatedGraphData.cost_of_project !== null ? Math.round(Number(aggregatedGraphData.cost_of_project)) + ' Cr' : 'No Data'}</span>
                                </div>
                            </div>
                        }
                        <Legends legendsFor={showExpenditure ? 'expenditure' : 'projects'} />
                    </div>
                    {/* <div className={'justify-content-end'}>
                        <div className={styles.toggleBtn}>
                         <div className={styles.toggleBtn} onClick={toggleTabs}> 
                             <span className={`${styles.toggleBtnSwitch} ${!showExpenditure ? styles.active : ''}`}>Projects</span> 
                             <span className={`${styles.toggleBtnSwitch} ${showExpenditure ? styles.active : ''}`}>Expenditure</span>
                        </div>
                    </div> */}
                </div>
                <div style={{marginTop:'72px'}}>
                {
                    props.isLoading ?
                        (
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                height: 507,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        )
                        :
                        (
                            isNoDataAvailable ?
                                <NoDataFound height={507} />
                                :
                                <BuProjectPlanningTab graphData={aggregatedGraphData} retailGraphData={aggregatedretailGraphData} year={props.year} fullScrn={fullScrn} businessUnit={props.product} />
                        )
                }
                </div>
            </FullScreen>
        </div>
    )
}
export default BuProjectsCount