import React from 'react'
import styles from './DeltaValuesOrg.module.scss'

const DeltaValuesOrg = (props) => {
    return (
        <div className={`d-flex flex-column ${styles.kpiOverallDetails}`}>
            <span className={`${styles.totalValues}`}>{props.totalData} {props.curfromat}</span>
        </div>

    )
}
export default DeltaValuesOrg