// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomInfoBox_infoWindowContainer__1Czyp {\n  padding: 0;\n  font-family: Titillium Web;\n  width: 287px;\n  height: 255px;\n  background-color: rgba(24, 30, 42, 0);\n  color: \"white\";\n  border: none; }\n  .CustomInfoBox_infoWindowContainer__1Czyp .CustomInfoBox_markerDot__EuIIq {\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    background-color: #b9c8e1;\n    margin-bottom: 5px; }\n  .CustomInfoBox_infoWindowContainer__1Czyp .CustomInfoBox_infoWindowTitle__2-L-p {\n    font-size: 28px;\n    color: #b9c8e1; }\n  .CustomInfoBox_infoWindowContainer__1Czyp .CustomInfoBox_titleLine__fZ5_g {\n    border-color: #b9c8e1;\n    width: 40px;\n    height: 1px;\n    margin: 6px 0px 18px 0px; }\n  .CustomInfoBox_infoWindowContainer__1Czyp .CustomInfoBox_infoWindowContent__g8Zh2 {\n    color: #b9c8e1;\n    font-size: 22px;\n    margin-bottom: 2px; }\n  .CustomInfoBox_infoWindowContainer__1Czyp .CustomInfoBox_depotInfoWindow__Ff1HX {\n    color: #b9c8e1;\n    font-size: 22px;\n    width: 178px; }\n  .CustomInfoBox_infoWindowContainer__1Czyp .CustomInfoBox_contentTableTitle__SRfIj {\n    border-bottom: #b9c8e1 1px dashed;\n    border-top: #b9c8e1 1px dashed;\n    font-size: 22px;\n    font-weight: bold;\n    margin-right: 10px;\n    margin-top: 10px; }\n  .CustomInfoBox_infoWindowContainer__1Czyp .CustomInfoBox_contentTitle__23dFr {\n    margin-right: 10px;\n    color: #b9c8e1;\n    font-size: 22px; }\n", ""]);
// Exports
exports.locals = {
	"infoWindowContainer": "CustomInfoBox_infoWindowContainer__1Czyp",
	"markerDot": "CustomInfoBox_markerDot__EuIIq",
	"infoWindowTitle": "CustomInfoBox_infoWindowTitle__2-L-p",
	"titleLine": "CustomInfoBox_titleLine__fZ5_g",
	"infoWindowContent": "CustomInfoBox_infoWindowContent__g8Zh2",
	"depotInfoWindow": "CustomInfoBox_depotInfoWindow__Ff1HX",
	"contentTableTitle": "CustomInfoBox_contentTableTitle__SRfIj",
	"contentTitle": "CustomInfoBox_contentTitle__23dFr"
};
module.exports = exports;
