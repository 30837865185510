// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".datanotfound_datanotfound__25_MI {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  color: #fff; }\n  .datanotfound_datanotfound__25_MI h4 {\n    font-size: 50px;\n    padding-top: 30px; }\n  .datanotfound_datanotfound__25_MI p {\n    font-size: 30px;\n    padding-top: 15px; }\n", ""]);
// Exports
exports.locals = {
	"datanotfound": "datanotfound_datanotfound__25_MI"
};
module.exports = exports;
