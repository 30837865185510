// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomInfoBox_infoWindowContainer__14bE- {\n  padding: 0;\n  font-family: Titillium Web;\n  width: 287px;\n  height: 255px;\n  background-color: rgba(24, 30, 42, 0);\n  color: \"white\";\n  border: none; }\n  .CustomInfoBox_infoWindowContainer__14bE- .CustomInfoBox_markerDot__3OVAo {\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    background-color: #b9c8e1;\n    margin-bottom: 5px; }\n  .CustomInfoBox_infoWindowContainer__14bE- .CustomInfoBox_infoWindowTitle__15PPl {\n    font-size: 28px;\n    color: #b9c8e1; }\n  .CustomInfoBox_infoWindowContainer__14bE- .CustomInfoBox_titleLine__2Lcxy {\n    border-color: #b9c8e1;\n    width: 40px;\n    height: 1px;\n    margin: 6px 0px 18px 0px; }\n  .CustomInfoBox_infoWindowContainer__14bE- .CustomInfoBox_infoWindowContent__2D2qU {\n    color: #b9c8e1;\n    font-size: 22px;\n    margin-bottom: 2px; }\n  .CustomInfoBox_infoWindowContainer__14bE- .CustomInfoBox_depotInfoWindow__3uWqU {\n    color: #b9c8e1;\n    font-size: 22px;\n    width: 178px; }\n  .CustomInfoBox_infoWindowContainer__14bE- .CustomInfoBox_contentTableTitle__1Puwz {\n    border-bottom: #b9c8e1 1px dashed;\n    border-top: #b9c8e1 1px dashed;\n    font-size: 22px;\n    font-weight: bold;\n    margin-right: 10px;\n    margin-top: 10px; }\n  .CustomInfoBox_infoWindowContainer__14bE- .CustomInfoBox_contentTitle__1tLQk {\n    margin-right: 10px;\n    color: #b9c8e1;\n    font-size: 22px; }\n", ""]);
// Exports
exports.locals = {
	"infoWindowContainer": "CustomInfoBox_infoWindowContainer__14bE-",
	"markerDot": "CustomInfoBox_markerDot__3OVAo",
	"infoWindowTitle": "CustomInfoBox_infoWindowTitle__15PPl",
	"titleLine": "CustomInfoBox_titleLine__2Lcxy",
	"infoWindowContent": "CustomInfoBox_infoWindowContent__2D2qU",
	"depotInfoWindow": "CustomInfoBox_depotInfoWindow__3uWqU",
	"contentTableTitle": "CustomInfoBox_contentTableTitle__1Puwz",
	"contentTitle": "CustomInfoBox_contentTitle__1tLQk"
};
module.exports = exports;
