import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './EmployeeDemographicPieChart.module.scss'
import {get} from 'lodash'
Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const EmployeeDemographicPieChart = (props) => {

    let tooltipLabelCallback = (tooltipItems, data) => {
        return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
    }

    let dataLabelFormatterCallback = (value) => (value != 0) ? value : ''
    const showPercentages = 2

    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

  
    useEffect(() => {
        let actualArray = [props.refineries, props.bu, props.entities]
  
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')

        let refineriesGradient = ctx.createLinearGradient(0, 0, 0, 500)
        refineriesGradient.addColorStop(1, '#00e0bc')//Teal 
        refineriesGradient.addColorStop(0, '#00a8ec') //dark blue

        let buGradient = ctx.createLinearGradient(0, 0, 0, 500)
        buGradient.addColorStop(0, '#ffbf45')//green
        buGradient.addColorStop(1, '#ff8309') //brownyellow

        let entitiesGradient = ctx.createLinearGradient(0, 0, 0, 500)
        entitiesGradient.addColorStop(0, '#ff7bda')//purple
        entitiesGradient.addColorStop(1, '#e640b7')//pink

        const chartConfig = {
            type: 'pie',
            data: {
                datasets: [
                    {
                        label: ["Refinieries", "Bu's", "Entities"],                    
                        data: actualArray,
                        datalabels: {
                            anchor: 'center',
                            align: 'center',
                            font: {
                                size: 21,
                            },
                            color: 'white'
                        },
                        //backgroundColor: ["#00a8ec", "#ffbf45", "#ff7bda", "#ff588c"],
                        backgroundColor: [refineriesGradient, buGradient, entitiesGradient],
                        borderWidth: 0,
                        // borderWidth: [0, 2, 2, 2],
                        borderColor: '#202c3f',
                        // borderColor: ["00e0bc", "#202c3f", "#202c3f", "#202c3f"],
                        weight: 4,
                        hoverBorderWidth: 0,
                        hoverBorderColor: [refineriesGradient, buGradient, entitiesGradient],
                        hoverBackgroundColor: [refineriesGradient, buGradient, entitiesGradient]
                    },

                ]
            },
            options: {
                plugins: {
                    datalabels: {
                        formatter: dataLabelFormatterCallback
                    }
                },
                layout: {
                    padding: {
                        left: 30,
                        right: 30,
                        top: 30,
                        bottom: 30
                    }
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                rotation: -1,
                responsive: true,
                maintainAspectRatio: false,
                tooltips : {
                    enabled : true,
                    mode : 'label',
                    callbacks: {
                        label: tooltipLabelCallback
                    }
                }
            },
            legend: {
                display: false
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.graph}`}>
            <canvas
                ref={chartContainer}
                height="300"
                width="300"
            />
        </div>
    )
}
export default EmployeeDemographicPieChart