// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ExpandedKpiTable_table_container__2_jJq {\n  padding: 30px 5px 10px 10px;\n  background-color: #090a0c;\n  text-align: left;\n  font-family: Titillium Web;\n  font-size: 20px;\n  color: #b8b8b8; }\n  .ExpandedKpiTable_table_container__2_jJq .ExpandedKpiTable_table_container_style__1Fqum {\n    font-family: Titillium Web;\n    font-size: 20px;\n    color: #b8b8b8;\n    text-align: center; }\n  .ExpandedKpiTable_table_container__2_jJq .ExpandedKpiTable_table_left_container_style__1CdH3 {\n    font-family: Titillium Web;\n    font-size: 20px;\n    color: #b8b8b8;\n    text-align: left; }\n  .ExpandedKpiTable_table_container__2_jJq table.ExpandedKpiTable_PerformanceTable__9Zx5m {\n    table-layout: fixed;\n    width: 500px; }\n  .ExpandedKpiTable_table_container__2_jJq table.ExpandedKpiTable_PerformanceTable__9Zx5m td.ExpandedKpiTable_PerformanceCell__1zdkV {\n    width: 75px; }\n\n.ExpandedKpiTable_emptyCell__VthlI {\n  border-top: 1px solid #090a0c; }\n", ""]);
// Exports
exports.locals = {
	"table_container": "ExpandedKpiTable_table_container__2_jJq",
	"table_container_style": "ExpandedKpiTable_table_container_style__1Fqum",
	"table_left_container_style": "ExpandedKpiTable_table_left_container_style__1CdH3",
	"PerformanceTable": "ExpandedKpiTable_PerformanceTable__9Zx5m",
	"PerformanceCell": "ExpandedKpiTable_PerformanceCell__1zdkV",
	"emptyCell": "ExpandedKpiTable_emptyCell__VthlI"
};
module.exports = exports;
