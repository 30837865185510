import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import styles from './BuLubesDiscountVsSalesGrowth.module.scss'
import { fetchLubesDiscount, fetchupdatedStatus } from '../../../services/bu'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty, last } from 'lodash'
import BuLubesCommercialDiscountGraph from './BuLubesCommercialDiscountGraph'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuLubesDiscountVsSalesGrowth = (props) => {
    const [graphData, setGraphData] = useState([])
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [lastItem, setLastItem] = useState({})
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps


    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "lubes" && res.kpi_name === "Discount" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        function prepareGraphData(kpi_name) {
            fetchLubesDiscount(kpi_name, props.year)
                .then(response => {

                    if (!isEmpty(response.data)) {
                        const total = {
                            actual: response.data[response.data.length - 1].total
                        }
                        response.data.forEach((item, index) => {
                            if (item.kpi_name === 'discount vs sales growth') {
                                setGraphData(response.data)
                                setLastItem(total)

                            }
                        })
                        setIsNoDataAvailable(false)

                    }
                    else {
                        setIsNoDataAvailable(true)
                    }
                })
                .catch((error) => console.debug(error))
        }
        prepareGraphData('discount vs sales growth')
    }, [props.ActiveTab, props.year])

    return (
        <div className={`${styles.salesRevenueContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col flex-grow-1'}>
                            <span>Discount - Daily </span>
                        </div>
                        {
                            isNoDataAvailable ||
                            <div className={`mt-2 mr-4 ${styles.headerRight}`}>
                                <span>Total: {lastItem.actual !== null ? Number(lastItem.actual).toFixed(2) + '%' : 'No Data'}</span>
                                
                                {/* <span className={'ml-4'} style={{ color: `${Math.sign(lastItem !== undefined && lastItem.growth) === 1 ? '#74ce12' : '#e13219'}` }}>{lastItem.growth !== null ? Number(lastItem.growth).toFixed(2) + '%' : 'No Data'}</span> */}
                            </div>
                        }
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-8 d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="d-flex mr-2 align-items-center "><span className={`mr-2 ${styles.actualDot}`}></span>Discount</div>
                            {/* <div className={'d-flex mr-2 align-items-center'}>   --- Target:{lastItem.target !== null ? Number(lastItem.target).toFixed(2)  : 'No Data'}</div> */}
                        </div>
                    </div>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                </div>
                {isNoDataAvailable ? (
                    <NoDataFound height={507} />
                ) : (
                    <>

                        <div className={`row ${styles.salesRevenueGraph}`}>
                            {graphData ? (
                                <BuLubesCommercialDiscountGraph
                                    graphData={graphData}
                                ></BuLubesCommercialDiscountGraph>
                            ) : (
                                <NoDataFound />
                            )}
                        </div>
                        <div
                            className={`d-flex justify-content-end ${styles.expandIconContainer}`}
                        >
                            <img
                                className={`${styles.expandIcon}`}
                                src={expandIcon}
                                alt="Expand Icon"
                                onClick={fullScrn}
                            ></img>
                        </div>
                    </>
                )}
            </FullScreen>
        </div>
    )
}
export default BuLubesDiscountVsSalesGrowth