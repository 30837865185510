import React, { useState, useEffect } from 'react'
import Chart from 'react-google-charts'
import styles from './Map.module.scss'
import { fetchItrmCountryData } from '../../services/Itrm'
import CountryNamesComponent from './CountryNamesComponent'
import LegendsProgressBar from './LegendsProgressBar'
import MapTable from './Table'
const CountryChart = (props) => {
    const [countryData, setCountryData] = useState([['Country', 'Percent']])
    const { region, countries, overall_percentage } = props.regionClicked
    const [loading, setLoading] = useState(false)
    const [regionCode, setRegionCode] = useState('') 

    //console.debug(region + loading + regionCode);
    let temp = []
    const makeRegionData = () => {
        countries.map((item, index) => {
            temp.push([item.v, overall_percentage])
            setCountryData([...countryData, ...temp])
        })
        setRegionCode()
    }

    let result = []
    const storeCountryData = (cData) => {
    // console.debug(cData);

        //debugger;
        cData.map((data) => {
            result.push([data.country_code, data.overall_percentage])
            setCountryData([...countryData, ...result])
        })
    }

    useEffect(() => {
        fetchItrmCountryData(props.regionClicked.region, props.location, props.year)
            .then((response) => {
                if (response.data.length > 0) {
                    setCountryData([['Country', 'Percent']])
                    storeCountryData(response.data)
                } else {
                    makeRegionData()
                }
            })
            .catch((error) => console.debug(error))
        setLoading(true)
    }, [props.regionClicked.region, props.location, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    // console.debug(countryData);
    // console.debug("Crude Procurement for ",props.regionClicked);

    return (
        <div style={{ position: 'relative' }}>
      (
            <div className={`${styles.countryChartHeading}`}>
                {props.regionClicked.region}
                <span style={{display:'none'}}>{region}, {loading}, {regionCode}</span>
            </div>
            <Chart
                options={{
                    region: props.regionClicked.regionCode,
                    colorAxis: {
                        colors: [
                            '#006aff',
                            '#00aaff',
                            '#00ddff',
                            '#00ffff',
                            '00ffcc',
                            // '#fffabd',
                        ],
                    },
                    datalessRegionColor: '#000000',
                    // strokeColor: "#141923",
                    // strokeOpacity: 1,
                    // strokeWeight: 0.5,
                    backgroundColor: {
                        fill: '#141923',
                        strokeWidth: 200,
                        stroke: '#141923',
                        strokeOpacity: 10,
                        strokeWeight: 10.5
                    },
                    tooltip: { trigger: 'none' },
                    legend: 'none',
                    displayMode: 'regions',
                }}
                chartType='GeoChart'
                mapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
                data={countryData}
                style={{
                    transform: `scale(${0.85})`,
                    bottom: '110px',
                    position: 'relative',
                    cursor: 'pointer',
                    width: '100%',
                }}
            />
            <CountryNamesComponent totalper={props.totalper} year={props.year} location={props.location} regionDetails={props.regionClicked} ></CountryNamesComponent>
            {countryData.length > 1 ? (
                <div>
                    <LegendsProgressBar />
                </div>
            ) : (
                <div></div>
            )}
            {/* <div className={`${styles.countryChartTable}`}>
                <MapTable year={props.year} location={props.location} regionName={props.regionClicked.region} />
            </div> */}
      )
        </div>
    )
}
export default CountryChart
