import React, { useState, useEffect } from 'react'
import ItrmWorlMap from '../../components/Itrm/ItrmWorldMap/ItrmWorldMap'
import ItrmPriceCurves from '../../components/Itrm/ItrmPriceCurves/ItrmPriceCurves'
import styles from './ItrmLanding.module.scss'
import ItrmLandedCost from '../../components/Itrm/ItrmLandedCost/ItrmLandedCost'
import ItrmOceanLoss from '../../components/Itrm/ItrmOceanLoss/ItrmOceanLoss'
import ItrmDemurrage from '../../components/Itrm/ItrmDemurrage/ItrmDemurrage'
import ItrmCracksTable from '../../components/Itrm/ItrmCracksTable/ItrmCracksTable'
import ItrmCracksChart from '../../components/Itrm/ItrmCracksChart/ItrmCracksChart'
import { Dropdown } from 'react-bootstrap'
import dropdownArrows from '../../assets/images/dropdownArrows.png'
import {fetchItrmlandedCost, getTableDataFuture, getProductCrack, getForwardCurveData, fetchItrmLanding} from '../../services/Itrm'

function ITRMLanding() {
    const [location, setLocation] = useState('all')
    const [oceanLossData, setOceanLossData] = useState([])
    const [demurrageData, setDemurrageData] = useState([])
    const [landedCostData, setLandedCostData] = useState([])
    const [lastData, setLastData] = useState([])
    const [OClastData, setOCLastData] = useState([])
    const [selectedRegionName, setSelectedRegionName] = useState('All regions')
    const [nymex, setNymex] = useState([])
    const [iceBrent, setIceBrent] = useState([])
    const [pr_gasoline, setpr_gasoline] = useState([])
    const [pr_naptha, setpr_naptha] = useState([])
    const [pr_jetkero, setpr_jetkero] = useState([])
    const [pr_gasoil, setpr_gasoil] = useState([])
    const [pr_fo, setpr_fo] = useState([])
    const [datedb, setdatedb] = useState([])
    const [dubaid, setdubaid] = useState([])
    const [timeStamp, setTimeStamp] = useState([])
    const [year, setyear] = useState('2020')
    // const [yearPrev, setyearPrev] = useState('2019')
    const [yearNext, setyearNext] = useState('2021')
    const [yearCurrent, setyearCurrent] = useState('2020')

    // function switchLocation() {
    //     if (location === 'mumbai') {
    //         setLocation('kochi')
    //     }
    //     if (location === 'kochi') {
    //         setLocation('mumbai')
    //     }
    // }
    function switchLocationAll (){
        setLocation('all')
    }
    function switchLocationMumbai (){
        setLocation('mumbai')
    }
    function switchLocationKochi (){
        setLocation('kochi')
    }
    function switchLocationBina (){
        setLocation('bina')
    }
    const fetchRegionNameOnClick = (val) => {
        console.debug('landing page', val)
        setSelectedRegionName(val)
    }
    useEffect(() => {
        fetchItrmLanding().then(data => {
            if (data.length === 0) {
                setyear('2020')
            }else{
                setyear(data.year.toString())
                //setyearPrev((data.year - 1).toString())
                setyearNext((data.current_finance_year).toString())
                if(data.year === data.current_finance_year){
                    setyearCurrent((data.year - 1).toString())
                }else{
                    setyearCurrent(data.year.toString())
                }
            }
        }).catch(error => console.debug(error))
        
        // bottom graphs
        let datedbr = []
        setTimeout(() => {
            getTableDataFuture().then(response => {
                datedbr.push(response.pc_dated[6],response.pc_dated[7],response.pc_dated[8], response.pc_dated[9], response.pc_dated[10], response.pc_dated[11], response.pc_dated[0], response.pc_dated[1], response.pc_dated[2], response.pc_dated[3], response.pc_dated[4], response.pc_dated[5])
                setdatedb(datedbr)
                setdubaid(response.pc_dubai)
            }).catch(exception => console.debug(exception))
        }, 1000)

        setTimeout(() => {
            getForwardCurveData()
            .then(response => {
                setNymex(response.pc_nymex)
                setIceBrent(response.pc_icebrent)
            }).catch((error) => console.debug(error))
        },2000)

        setTimeout(() => {
            getProductCrack().then((response) => {
                setTimeStamp(response.pc_gasoil[0].date)
                setpr_gasoline(response.pc_gasoline)
                setpr_gasoil(response.pc_gasoil)
                setpr_naptha(response.pc_naptha)
                setpr_jetkero(response.pc_jetkro)
                setpr_fo(response.pc_fuelbrent)
            }).catch(exception => console.debug(exception))
        }, 3000)
    }, [])

    useEffect(() => {
        document.title = 'ITRM | BPCL'
        fetchItrmlandedCost(location, year)
            .then((response) => {
                if (response.data.length > 0) {
                    let oceanLossArray = []
                    let demurrageArray = []
                    let landedCostArray = []
                    let oceanlossytmarray = []
                    let landedcostytmarray = []
                    for (let i of response.data) {
                        oceanLossArray.push(i.ocean_loss)
                        oceanlossytmarray.push(i.ocean_loss_ytm)
                        landedcostytmarray.push(i.landed_cost_ytm)
                        // console.debug("ocean", oceanLossArray);
                        demurrageArray.push({ lp: i.lp_in_hr, dp: i.dp_in_hr, lp_rs:i.lp_in_rs, dp_rs:i.dp_in_rs })
                        landedCostArray.push({
                            crude_cost_actual: i.crude_cost_actual,
                            crude_cost_target: i.crude_cost_target,
                            freight_cost_actual: i.freight_cost_actual,
                            freight_cost_target: i.freight_cost_target,
                            taxes_actual: i.taxes_actual,
                            taxes_target: i.taxes_target,
                        })
                    }
                    setOceanLossData(oceanLossArray)
                    setDemurrageData(demurrageArray)
                    setLandedCostData(landedCostArray)
                    setLastData(landedcostytmarray)
                    setOCLastData(oceanlossytmarray)
                }else{
                    setOceanLossData([])
                    setDemurrageData([])
                    setLandedCostData([])
                    setLastData([])
                    setOCLastData([])
                }
                return false
            })
            .catch((error) => console.debug(error))
    }, [location, year])

    return (
        <div className={`${styles.itrmLandingContainer}`}>
                <Dropdown className={`${styles.gheaderDropdownYear}`}>
                        <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                            <span className={`${styles.gdropdownName}`}>{year} - {Number(year.slice(2)) + 1}</span>
                            <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                                <img src={dropdownArrows} alt='All region dropdown'></img>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
                            {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearPrev)}>{yearPrev} - {yearCurrent.slice(2)}</Dropdown.Item> */}
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearCurrent)}>{yearCurrent} - {yearNext.slice(2)}</Dropdown.Item>
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearNext)}>{yearNext} - {Number(yearNext.slice(2)) + 1}</Dropdown.Item>
                            {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2022')}>2022</Dropdown.Item>
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2023')}>2023</Dropdown.Item> */}
                        </Dropdown.Menu>
                </Dropdown>
            <div
                className={`d-flex align-items-center justify-content-center ${styles.itrmHeading}`}
            >
                {/* <div
                    onClick={() => switchLocation()}
                    className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}
                >
                    <div
                        className={`d-flex justify-content-center align-items-center ${
                            location === 'mumbai'
                                ? styles.mumbaiLeftEnabled
                                : styles.mumbaiLeftDisabled
                        }`}
                    >
            Mumbai
                    </div>
                    <div
                        className={`d-flex align-items-center justify-content-center ${
                            location === 'kochi'
                                ? styles.kochiRightEnabled
                                : styles.kochiRightDisabled
                        }`}
                    >
            Kochi
                    </div>
                </div>  */}
                 <div
                    onClick={() => switchLocationAll()}
                    className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}>
                <div
                        className={`d-flex align-items-center justify-content-center ${
                            location === 'all'
                                ? styles.allLeftEnabled
                                : styles.allLeftDisabled
                        }`}
                    >
            All
                    </div>
                    </div>
                <div
                    onClick={() => switchLocationMumbai()}
                    className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}>
                <div
                        className={`d-flex align-items-center justify-content-center ${
                            location === 'mumbai'
                                ? styles.mumbaiLeftEnabled
                                : styles.mumbaiLeftDisabled
                        }`}
                    >
            Mumbai
                    </div>
                    </div>
                <div
                    onClick={() => switchLocationKochi()}
                    className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}>
                <div
                        className={`d-flex align-items-center justify-content-center ${
                            location === 'kochi'
                                ? styles.kochiRightEnabled
                                : styles.kochiRightDisabled
                        }`}
                    >
            Kochi
                    </div>
                    </div>
                <div
                    onClick={() => switchLocationBina()}
                    className={`d-flex align-items-center  ${styles.mumbaiKochiButton}`}>
                <div
                        className={`d-flex align-items-center justify-content-center ${
                            location === 'bina'
                                ? styles.binaRightmostEnabled
                                : styles.binaRightmostDisabled
                        }`}
                    >
            Bina
                    </div>
                    </div>
                    <a href="/">International Trade and Risk Management</a>
                
                <div className={`${styles.timeStamp}`}>
                    {/* { location === 'mumbai' ? <>Last Updated on {new Date(timeStamp).toLocaleDateString("en-GB")}</> : <>Last Updated on {new Date(timeStamp).toLocaleDateString("en-GB")}</> } */}
                </div>
            </div>
            <div className={`d-flex ${styles.mapAndGraphContainer}`}>
                <ItrmWorlMap
                    fetchRegionName={fetchRegionNameOnClick}
                    location={location}
                    year={year}
                ></ItrmWorlMap>
                <div className={`d-flex flex-column ${styles.rightGraphContainer}`}>
                    <ItrmLandedCost
                        landedCostData={landedCostData}
                        location={location}
                        lastData={lastData}
                        year={year}
                    ></ItrmLandedCost>
                    <ItrmOceanLoss
                        oceanLossData={oceanLossData}
                        location={location}
                        lastData={OClastData}
                        year={year}
                    ></ItrmOceanLoss>
                    <ItrmDemurrage
                        demurrageData={demurrageData}
                        location={location}
                        year={year}
                    ></ItrmDemurrage>
                </div>
            </div>
            <div className='d-flex'>
                <ItrmPriceCurves datedb={datedb} dubaid={dubaid} location={location}></ItrmPriceCurves>
                <ItrmCracksChart nymex={nymex} iceBrent={iceBrent} location={location}></ItrmCracksChart>
                <ItrmCracksTable pr_gasoline={pr_gasoline} pr_fo={pr_fo} pr_gasoil={pr_gasoil} pr_jetkero={pr_jetkero} pr_naptha={pr_naptha} pr_fo={pr_fo} location={location}></ItrmCracksTable>
            </div>
        </div>
    )
}

export default ITRMLanding
