import React, { useEffect } from 'react'
import CracksGraph from './CracksGraph'
import styles from './ItrmCracksChart.module.scss'
const ItrmCracksChart = (props) => {
    //const [textColor, setTextColor] = useState("#06bee1");
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    let nextMonth = currentMonth + 2;
    let nextYear = currentYear;
    if (nextMonth > 11) {
        nextMonth = 0;
        nextYear += 1;
      }
    const nextMonthDate = new Date(nextYear, nextMonth, 1);
    const name = nextMonthDate.toLocaleString('default', { month: 'short' });
    useEffect(() => { }, [props.location, props.nymex, props.iceBrent])

    return (
        <div className={`${styles.itrmPriceCurvesContainer}`}>
            <div className={`${styles.curvesHeader}`}>
                <h1>Crude Oil Futures ($/Barrel)</h1>
            </div>
            <div className={`${styles.itrmTableContainer}`}>
                <div className={'row'} style={{ margin: '0px 0px 10px 0px' }}>
                    <div className={'col-8 pl-0'}>
                        <span className={`${styles.header}`}></span>
                    </div>
                    {/* <div className={`col-4 p-0`}>
            {props.location === "mumbai" ? (
              <img
                style={{ float: "right" }}
                src={mumbaiArrow}
                alt="Legend for Landed Cost"
              ></img>
            ) : (
              <img
                style={{ float: "right" }}
                src={kochiArrow}
                alt="Legend for Landed Cost"
              ></img>
            )}
          </div> */}
                </div>
                <div className={`col-12 d-flex align-items-center ${styles.headerLabels}`}>
                    <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.nymex}`}></span>Nymex WTI</div>
                    <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.icebrent}`}></span>ICE Brent</div>
                    <div className="d-flex mr-3 align-items-center ">M1 = {name}</div>
                </div>
                {/* <div className={'row'} style={{ margin: '0px 0px 10px 0px' }}>
                    <div className={'col-12 pl-0'}>
                        <span
                            className={`${styles.header} ${
                                props.location === 'mumbai'
                                    ? styles.mumbaiTheme
                                    : styles.mumbaiTheme
                            }`}
                        >
              - NYMEX WTI
                        </span>
                        <span
                            className={`pl-5 ${styles.header} ${
                                props.location === 'mumbai'
                                    ? styles.mumbaiIceBrentTheme
                                    : styles.mumbaiIceBrentTheme
                            }`}
                        >
              -- ICE Brent
                        </span>
                    </div>
                </div> */}

                <div className={'row'} style={{ margin: '40px 0px 10px 0px' }}>
                    <CracksGraph
                        pr_nymex={props.nymex !== undefined && props.nymex}
                        pr_icebrent={props.iceBrent !== undefined && props.iceBrent}
                        location={props.location}
                    />
                </div>
            </div>
        </div>
    )
}
export default ItrmCracksChart
