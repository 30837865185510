// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RefinaryGrm_GrmContainer__3BAY2 {\n  border: 0.5px #a7a7a7 solid;\n  border-bottom: none;\n  font-family: Titillium Web;\n  width: 644px;\n  height: 653px;\n  background-color: #141923;\n  color: #dee5ef;\n  position: relative; }\n  .RefinaryGrm_GrmContainer__3BAY2 .RefinaryGrm_graphTitle__1L92K {\n    height: 88px;\n    font-size: 35px;\n    border: 0.5px #a7a7a7 solid;\n    background-color: black;\n    font-weight: bold;\n    padding-left: 15px; }\n  .RefinaryGrm_GrmContainer__3BAY2 .RefinaryGrm_grmFooter__lwz18 {\n    border: 0.5px #a7a7a7 solid;\n    height: 64px;\n    background-color: #283040; }\n    .RefinaryGrm_GrmContainer__3BAY2 .RefinaryGrm_grmFooter__lwz18 .RefinaryGrm_footerLeft__286mL {\n      cursor: pointer;\n      margin-left: 36px;\n      color: #ffffff;\n      font-size: 22px;\n      text-decoration: underline; }\n    .RefinaryGrm_GrmContainer__3BAY2 .RefinaryGrm_grmFooter__lwz18 .RefinaryGrm_footerRight__2qJZi {\n      margin-right: 36px;\n      font-size: 22px;\n      font-style: italic; }\n  .RefinaryGrm_GrmContainer__3BAY2 .RefinaryGrm_statusUpdatedImg__2p218 {\n    margin-left: 10px;\n    margin-top: -21px; }\n", ""]);
// Exports
exports.locals = {
	"GrmContainer": "RefinaryGrm_GrmContainer__3BAY2",
	"graphTitle": "RefinaryGrm_graphTitle__1L92K",
	"grmFooter": "RefinaryGrm_grmFooter__lwz18",
	"footerLeft": "RefinaryGrm_footerLeft__286mL",
	"footerRight": "RefinaryGrm_footerRight__2qJZi",
	"statusUpdatedImg": "RefinaryGrm_statusUpdatedImg__2p218"
};
module.exports = exports;
