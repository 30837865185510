import React, { useEffect, useState } from 'react'
import styles from './ItrmPriceCurves.module.scss'
import kochiArrow from '../../../assets/images/kochiArrow.png'
import mumbaiArrow from '../../../assets/images/mumbaiArrow.png'
import environment from '../../../environment'
import ItrmPriceGraph from './Itrmpricegraph'

function ItrmPriceCurves(props) {
    const [textColor, setTextColor] = useState('#06bee1')
    const [dubaidata,setdubaidata] = useState([])
    const [dbdata,setdbdata] = useState([])
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    let nextMonth = currentMonth + 1;
    let nextYear = currentYear;
    if (nextMonth > 11) {
        nextMonth = 0;
        nextYear += 1;
      }
    const nextMonthDate = new Date(nextYear, nextMonth, 1);
    const name = nextMonthDate.toLocaleString('default', { month: 'short' });

    useEffect(() => {
        if (props.location === 'mumbai') {
            setTextColor('#06bee1')
        } else if (props.location === 'kochi') {
            setTextColor('#b76df1')
        }
    }, [props.location, props.datedb, props.dubaid])

    return (
        <div className={`${styles.itrmPriceCurvesContainer}`}>
            <div className={`${styles.curvesHeader}`}>
                <h1>Crude Oil Forward Curves ($/Barrel)</h1>
            </div>
            <div className={`${styles.itrmTableContainer}`}>
                <div className={`row pt-2 ${styles.legendCrack}`}>
                    <div className={`col-12 d-flex align-items-center ${styles.headerLabels}`}>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.staffCostDot}`}></span>Dated Brent</div>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.othersDot}`}></span>Dubai</div>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.rmDot}`}></span>Brent Dubai Diff</div>
                        <div className="d-flex mr-3 align-items-center ">M1 = {name}</div>
                    </div>
                </div>
                <div className={'row'} style={{ margin: '40px 0px 10px 0px' }}>
                    <ItrmPriceGraph 
                        pr_dated={props.datedb !== undefined && props.datedb} 
                        pr_dubai={props.dubaid !== undefined && props.dubaid} 
                        location={props.location} />
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}
export default ItrmPriceCurves
