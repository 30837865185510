import React, { useEffect, useState, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuRetailOutletGraph from './BuRetailOutletGraph'
import styles from './BuRetailOutlet.module.scss'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchRetailOutlets, fetchupdatedStatus } from '../../../services/bu'
import { isEmpty, isNumber } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { numberFormatEnIn, numberFormatNoSeparator } from '../../../utility/utility'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuRetailOutlet = (props) => {
    const [graphData, setGraphData] = useState(null)
    const [isDataNotAvailable, setIsDataNotAvailable] = useState(false)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
            .then(response => {
                if (!isEmpty(response)) {
                    response.filter((res) => res.entity === "retail" && res.kpi_name === "NRO Sales" ? setUpdatedStatus(res.status) : false);
                } else {
                    setUpdatedStatus("Not Found")
                }
            })
        .catch(error => console.debug(error))
        fetchRetailOutlets(props.year).then(response => {
            if (isEmpty(response.data)) {
                setIsDataNotAvailable(true)
            } else {
                setGraphData(response.data)
                setIsDataNotAvailable(false)
            }
        }).catch(console.error)
    }, [props.year])
    return (
        <div className={`${styles.BuTransportationContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-8'}>
                            <span>Retail Outlets - Monthly</span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-8 d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="d-flex mr-4 align-items-center ">
                                <span className={`${styles.actualDot1}`}></span>
                                <span className={`mr-2 ${styles.targetDot1}`}></span> NRO
                            </div>
                            <div className="d-flex ml-4 align-items-center ">
                                <span className={`${styles.actualDot2}`}></span>
                                <span className={`mr-2 ${styles.targetDot2}`}></span> Loyalty Sales
                            </div>

                        </div>
                    </div>
                </div>
                {
                    isDataNotAvailable ?
                        <div style={{ height: 507 }}>
                            <NoDataFound />
                        </div>
                        :
                        (
                            <React.Fragment>
                                <div className={`d-flex ${styles.tableAndGraphContainer}`}>
                                    {
                                        graphData ?
                                            (
                                                <div className={`${styles.graphContainer}`}>
                                                    <div className={`d-flex ${styles.graphHeaderContainer}`}>
                                                        <div className={`d-flex flex-column justify-content-center ${styles.graphHeaderSection}`}>
                                                            <div className={`${styles.graphHeaderSectionTop}`}><span>{!isNumber(graphData.nro_commissioned) ? 'N/A' : numberFormatEnIn(graphData.nro_commissioned)}</span></div>
                                                            <div className={`${styles.graphHeaderSectionBottom}`}><span>NRO Commissioned</span></div>
                                                        </div>
                                                        <div className={`d-flex flex-column justify-content-center ${styles.graphHeaderSection1}`}>
                                                            <div className={`${styles.graphHeaderSectionTop}`} ><span>{!isNumber(graphData.digital_transactions) ? ' ' : numberFormatEnIn((graphData.digital_transactions))}%</span></div>
                                                            <div className={`${styles.graphHeaderSectionBottom}`}><span>Digital Transactions</span></div>
                                                        </div>
                                                    </div>

                                                    <div className={`d-flex justify-content-between ${styles.graphAndValueContainer}`}>
                                                        {
                                                            graphData ?
                                                                <BuRetailOutletGraph graphData={graphData}></BuRetailOutletGraph>
                                                                :
                                                                <Spinner animation="border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </Spinner>
                                                        }
                                                        <div className={`d-flex flex-column justify-content-around ${styles.valueContainer}`}>
                                                            <div className={`d-flex flex-column ${styles.valueContainerTop}`}>
                                                                <p><span>{isNumber(graphData.nro_sales_contribution) ? numberFormatEnIn(graphData.nro_sales_contribution) : 'N/A'}% NRO Sales Contr.</span></p>
                                                                {/* <span style={{ color: `${Math.sign(graphData.nro_volume_growth) === 1 ? '#74ce12' : '#e13219'}` }} >{
                                                                    isNumber(graphData.nro_volume_growth) ? graphData.nro_volume_growth + '%': 'N/A'
                                                                } Vol. Growth</span> */}
                                                            </div>
                                                            <div className={`d-flex flex-column ${styles.valueContainerBottom}`}>
                                                            <p><span>{isNumber(graphData.loyalty_sales_contribution) ? numberFormatNoSeparator(graphData.loyalty_sales_contribution) : 'N/A'}% Loyalty Sales Contr. (MS+HSD)</span></p>
                                                                {/* <span style={{ color: `${Math.sign(graphData.loyalty_volume_growth) === 1 ? '#74ce12' : '#e13219'}` }}>{
                                                                    isNumber(graphData.loyalty_volume_growth) ? graphData.loyalty_volume_growth + '%': 'N/A'
                                                                } Vol. Growth</span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                    }
                                    {/* <div className={`${styles.MsHsdContainer}`}>
                                        <div className={`${styles.HsdCircle}`}><span className={`${styles.Hsd}`}>HSD</span></div>
                                        <div className={`${styles.MsCircle}`}><span className={`${styles.Ms}`}>MS</span></div>
                                    </div> */}
                                </div>
                                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' id="fullRo" data-fs="handleenter" onClick={fullScrn}></img>
                                </div>
                            </React.Fragment>
                        )
                }

            </FullScreen>
        </div>
    )
}
export default BuRetailOutlet