import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import classes from './quarterlydatanotfound.module.scss'

export const QuarterlyDataNotFound = (props) => {
    const rootWrapperOverrides = {
        height: props.height || '100%',
        width: props.width || '90%'
    }

    return (
        <div className={`${classes.datanotfound}`} style={rootWrapperOverrides}>
            <FontAwesomeIcon icon={faExclamationCircle} size="3x" />
            <p>Updated only after quarterly
                results are published</p>
        </div>
    )
}