import React, { useState, useEffect, useCallback } from "react";
import styles from "../../components/OrgFinance/OrgGAs.module.scss";
import ExpandIcon from "../../assets/images/expandIcon.png";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CNGStation from "../../assets/images/gas/CNGStation.mp4";
import InfographicKpi1 from "../../components/Refinery/InfographicKpi1/InfographicKpi1";
import { fetchCgdData } from '../../services/bu'


// import { NoDataFound } from '../../DataNotFound/nodatafound'
// import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
// import "../../../Common.scss"

const OrgMap1 = (props) => {
console.log(props)
  // useEffect(() => {
  //   fetchCgdData("EDC-ANALOG")
  //   .then(abc => {
  //     setCGDData(abc)

  // // if (abc.length > 0) {
  // //   const foundAa = abc.find(item => item.name === 'ROHTAK-CNG-232880-C1-ID-SUC-PRSR');
  // //   const foundBb = abc.find(item => item.name === 'ROHTAK-CNG-232880-C1-IDSUC-FLOWRATE');
  // //   setAbcData({ aa: foundAa, bb: foundBb });
  // // } else {
  // //   setAbcData({ aa: null, bb: null });
  // // }
  //   })
  //   .catch(error => console.debug(error))
  //   }, [])

  // const [loading, setloading] = useState(true)

  const handle = useFullScreenHandle();
  const [attr, setattr] = useState("handleenter");
  const fullScrn = () => {
    if (attr === "handleenter") {
      setattr("handleexit");
      handle.enter();
      document.body.style.zoom = window.innerWidth / window.outerWidth;
    } else if (attr === "handleexit") {
      setattr("handleenter");
      handle.exit();
      document.body.style.zoom = 0;
    }
  };
  const reportChange = useCallback(
    (state) => {
      try {
        if (state === false) {
          document.body.style.zoom = 0;
        }
      } catch (exception) {
        console.debug(exception);
      }
    },
    [handle]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <FullScreen handle={handle} onChange={reportChange}>
      <div className={`${styles.EmployeeDataAndRecruitmentContainer}`}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.EmployeeDataAndRecruitmentHeader}`}
        >
          <span>CNG Station</span>

          {/* {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>} */}
          {/* <img
            src={ExpandIcon}
            alt="Expand Employee Data And Recruitment"
            id="fullSrnedr"
            data-fs="handleenter"
            onClick={FullScreen}
          ></img> */}
        </div>
        <video
          style={{
            position: "relative",
            padding: "15px",
            bottom: "45px",
            width: "100%",
            height: "100%",
          }}
          autoPlay
          loop
          src={CNGStation}
        ></video>
        {/* <InfographicKpi1
          top="46px"
          right=""
          bottom=""
          left="1165px"
          kpiName="CDU IV"
          textColor="Red"
          kpiValue="220"
        ></InfographicKpi1> */}
        <div className={`${styles.key1}`}>High Bank Pressure </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_HBNK_PRSR_Value} Bar </div>
        <div className={`${styles.key1}`}>Medium Bank Pressure </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_MBNK_PRSR_Value} Bar </div>
        <div className={`${styles.key1}`}>Low Bank Pressure </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_LBNK_PRSR_Value} Bar </div>
        <div className={`${styles.key1}`}>1st Stage Discharge Pressure </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_STAGE1_DISC_PRSR_Value} Bar </div>
        <div className={`${styles.key1}`}>2nd Stage Discharge Pressure </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_STAGE2_DISC_PRSR_Value} Bar </div>
        <div className={`${styles.key1}`}>3rd Stage Discharge Pressure </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_STAGE3_DISC_PRSR_Value} Bar </div>
        <div className={`${styles.key1}`}>1st Stage Discharge Temp </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_STAGE1_DISC_TEMP_Value} Deg C </div>
        <div className={`${styles.key1}`}>2nd Stage Discharge Temp </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_STAGE2_DISC_TEMP_Value} Deg C </div>
        <div className={`${styles.key1}`}>3rd Stage Discharge Temp </div>
        <div className={`${styles.value1}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_STAGE3_DISC_TEMP_Value} Deg C</div>
        <div className={`${styles.key2}`}>Discharge Flow </div>
        <div className={`${styles.value2}`}>{props.cgdData.ROHTAK_CNG_180143_C1_CP_DISC_FLOWRATE_Value} Kg/Hr</div>
        <div className={`${styles.key31}`}>Camp Run Lamp </div>
        : {(props.cgdDataDigital.ROHTAK_CNG_180143_C1_CP_MAINMTR_RUN_FB_Value === "0") ?
          <div className={`${styles.value3}`}>
            : NOT ACTIVATED
          </div>
          :
          <div className={`${styles.value3}`}>
            : ACTIVATED
          </div>
        }
        <div className={`${styles.key3}`}>Ready to Start Lamp </div>
        {(props.cgdDataDigital.ROHTAK_CNG_180143_C1_CP_RDY_TO_STR_Value === "0") ?
          <div className={`${styles.value3}`}>
            : NOT ACTIVATED
          </div>
          :
          <div className={`${styles.value3}`}>
            : ACTIVATED
          </div>
        }
        <div className={`${styles.key3}`}>Camp Running hours </div>
        <div className={`${styles.value3}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_RUN_HR_Value}</div>
        <div className={`${styles.value4}`}>:&nbsp; {props.cgdData.ROHTAK_CNG_180143_C1_CP_RUN_MIN_Value}</div>
        <div className={`${styles.value5}`}>{props.cgdData.ROHTAK_CNG_180143_D1_CM_ARMA_FLWRATE_Value} kg/min</div>
        <div className={`${styles.value6}`}>{props.cgdData.ROHTAK_CNG_180143_D1_CM_ARMB_FLWRATE_Value} kg/min</div>
        <div className={`${styles.value7}`}>{props.cgdData.ROHTAK_CNG_180143_D2_CM_ARMA_FLWRATE_Value} kg/min</div>
        <div className={`${styles.value8}`}>{props.cgdData.ROHTAK_CNG_180143_D2_CM_ARMB_FLWRATE_Value} kg/min</div>
        <div className={`${styles.value9}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_GD1_GASDET_Value} %</div>
        <div className={`${styles.value10}`}>: {props.cgdData.ROHTAK_CNG_180143_C1_CP_GD2_GASDET_Value} %</div>
        <div className={`${styles.value11}`}>{props.cgdData.ROHTAK_CNG_180143_C1_CP_SUC_PRSR_Value} Bar</div>
        <div className={`${styles.value12}`}>{props.cgdData.ROHTAK_CNG_180143_C1_CP_SUC_FLOWRATE_Value} Kg/Hr</div>
        <div className={`${styles.value13}`}></div>
        <div className={`${styles.value14}`}></div>
        {(props.cgdDataDigital.ROHTAK_CNG_180143_C1_CP_FD1_FLMDET_Value === "0") ?
          <div className={`${styles.value15}`}>
            NOT ACTIVATED
          </div>
          :
          <div className={`${styles.value15}`}>
            ACTIVATED
          </div>
        }
        {(props.cgdDataDigital.ROHTAK_CNG_180143_C1_CP_FD2_FLMDET_Value === "0") ?
          <div className={`${styles.value16}`}>
            NOT ACTIVATED
          </div>
          :
          <div className={`${styles.value16}`}>
            ACTIVATED
          </div>
        }
        <div className={`${styles.value17}`}></div>
        <div className={`${styles.value18}`}>{props.cgdData.ROHTAK_CNG_180143_C1_CP_HYD_OIL_TEMP_Value}</div>
        <div className={`${styles.Header1}`}>24*7 FILLING STATION -BPCL-180143-O5</div>
      </div>
    </FullScreen>
  );
};
export default OrgMap1;
