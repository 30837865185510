import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuIncCcs.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import { Spinner } from 'react-bootstrap'
import { isEmpty, get } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchIncCcsReceived, fetchIncCcsResolved, fetchupdatedStatus } from '../../../services/bu'
import BuIncCcsPieChart from './BuIncCcsPieChart'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuIncCcs = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [isIssueApi, setIsIssueApi] = useState(false)
    const [resolvedComplaintsGraphData, setResolvedComplaintsGraphData] = useState(null)
    const [resolvedComplaintsGraphDataDate, setResolvedComplaintsGraphDataDate] = useState(null)
    const [openComplaintsGraphData, setOpenCompaintsGraphData] = useState(null)
    const [topTenCompaintsGraphData, setTopTenCompaintsGraphData] = useState([])

    const [attr, setattr] = useState('handleEnter')
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }

    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "inc" && res.kpi_name === "Customer Complaints" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchIncCcsReceived(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                } else {
                    setOpenCompaintsGraphData(response.data)
                    setTopTenCompaintsGraphData(response.topTenDetail)
                    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"];
                    var dateStr = {}
                    dateStr.date = month[new Date(response.data.date).getMonth()] + "-" + new Date(response.data.date).getFullYear();
                    setResolvedComplaintsGraphDataDate(dateStr)
                    setIsNoDataAvailable(false)
                }
            })
            .catch((error) =>
                console.debug('Failed to fetch Retail Ccs Received', error)
            )

        fetchIncCcsResolved(props.year)
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)

                } else if (!isEmpty(response.data)) {
                    setResolvedComplaintsGraphData(response.data)
                    setIsNoDataAvailable(false)
                } else {
                    setIsIssueApi(true)
                }
            })
            .catch((error) => console.debug('failed to fetch Ccs Resolved', error))
    }, [props.activeTab,props.year])

    return (
        <div className={`${styles.BuCcsContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex justify-content-between ${styles.BuCcsHeader}`}>
                   
                    <div
                        className={`d-flex flex-column justify-content-center ${styles.BuCcsHeaderLeft}`}
                    >
                        <div className={`${styles.HeaderTop}`}>Customer Complaints - YTD
                        </div>
                        
                        <div className={`d-flex ${styles.legendsContainer}`}>
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot1}`}></span> {'< 2 days'}
                            </div>
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot2}`}></span>2-5 Days
                            </div>
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot3}`}></span>6-10 Days
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot4}`}></span>
                                {'> 10 Days'}
                            </div>
                        </div>
                    </div>
                    {
                        isNoDataAvailable || (props.year < 2023) &&
                    <div
                        hidden={isNoDataAvailable}
                        className={` d-flex flex-column justify-content-between ${styles.BuCcsHeaderRight}`}
                    >
                        <span className={`${styles.BuCcsHeaderResolved}`}>
                            Complaints Resolved: {get(resolvedComplaintsGraphData, 'resolved_cases', '')}
                        </span>
                        <span className={`${styles.BuCcsHeaderReceived}`}>
                            Complaints Received: {get(resolvedComplaintsGraphData, 'complaints_received', '')}
                        </span>
                        {/* <span className={`${styles.BuCcsHeaderResolved}`}>Data Upto: {get(resolvedComplaintsGraphDataDate, 'date', '')}</span> */}
                    </div>
                    }
                     {
                        isNoDataAvailable || (props.year >= 2023) &&
                        <div hidden={isNoDataAvailable} className={` d-flex flex-column justify-content-between ${styles.BuCcsHeaderRight}`}>
                            <span className={`${styles.BuCcsHeaderResolved}`}>Complaints Resolved: {get(resolvedComplaintsGraphData, 'COMPLAINTS_CLOSED', '')}</span>
                            <span className={`${styles.BuCcsHeaderReceived}`}>Complaints Received: {get(resolvedComplaintsGraphData, 'COMPLAINTS_RECEIVED', '')}</span>
                        </div>
                    }
                    {isIssueApi ? <div>.</div> : ''}
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                </div>
                {isNoDataAvailable ? (
                    <NoDataFound height={507} />
                ) : (
                    <>
                        <div
                            className={`d-flex align-items-center justify-content-center ${styles.graphContainer}`}
                        >
                            <div className="d-flex flex-column align-items-center mr-4">
                                {resolvedComplaintsGraphData ? (
                                    <BuIncCcsPieChart showPercentages={true} graphData={openComplaintsGraphData} openStatus={true} year={props.year} topTenDetails={topTenCompaintsGraphData} bu={"inc"}></BuIncCcsPieChart>
                                ) : (
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                )}
                                {(props.year < 2023) &&
                                <div className={`${styles.pieChartTitle}`}>
                                    Open ({get(openComplaintsGraphData, 'resolved_cases', '')})
                                </div>
                                }
                                {(props.year >= 2023) &&
                                    <div className={`${styles.pieChartTitle}`}>Open ({get(openComplaintsGraphData, 'COMPLAINTS_OPENED', '')})</div>
                                 }
                            </div>
                            <div className="d-flex flex-column align-items-center mr-4">
                                {resolvedComplaintsGraphData ? (
                                    <BuIncCcsPieChart showPercentages graphData={resolvedComplaintsGraphData} resolvedStatus={true} year={props.year} topTenDetails={[]} bu={"inc"}></BuIncCcsPieChart>
                                ) : (
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                )}
                                {(props.year < 2023) &&
                                <div className={`${styles.pieChartTitle}`}>
                                    Resolved ({get(resolvedComplaintsGraphData, 'resolved_cases', '')})
                                </div>
                                }
                                {(props.year >= 2023) &&
                                    <div className={`${styles.pieChartTitle}`}>Resolved ({get(resolvedComplaintsGraphData, 'COMPLAINTS_CLOSED', '')})</div>
                                }
                            </div>
                        </div>
                        <div
                            className={`d-flex justify-content-end ${styles.expandIconContainer}`}
                        >
                            <img
                                className={`${styles.expandIcon}`}
                                src={expandIcon}
                                alt="Expand Icon"
                                onClick={fullScrn}
                            ></img>
                        </div>
                    </>
                )}
            </FullScreen>
        </div>
    )
}
export default BuIncCcs



// import React, { useState, useEffect } from 'react'
// import expandIcon from '../../../assets/images/expandIcon.png'
// import styles from './BuIncCcs.module.scss'
// import BuIncCCsPieChart from './BuIncCcsPieChart'
// import { Spinner } from 'react-bootstrap'
// import { fetchIncCcsReceived, fetchIncCcsResolved } from '../../../services/bu'
// import { NoDataFound } from '../../DataNotFound/nodatafound'

// function BuIncCcs(props) {
//     const [graphData, setGraphData] = useState([])
//     const [resolvedComplaints, setResolvedComplaints] = useState(0)
//     const [receivedComplaints, setReceivedComplaints] = useState(0)


//     useEffect(() => {
//         const prepareGraphData = () => {
//             fetchIncCcsReceived ()
//                 .then(data => {
//                     if (data.success) {
//                         setGraphData(data.data)
//                         setResolvedComplaints(data.data.resolved_cases)
//                         setReceivedComplaints(data.data.total_complaints)
//                     }
//                 })
//                 .catch(error => console.debug(error))
//             fetchIncCcsResolved ()
//                 .then(data => {
//                     if (data.success) {
//                         setGraphData(data.data)
//                         setResolvedComplaints(data.data.resolved_cases)
//                         setReceivedComplaints(data.data.total_complaints)
//                     }
//                 })
//                 .catch(error => console.debug(error))
//         }
//         prepareGraphData()
//     }, [props.activeTab])

//     return (
//         <div className={`${styles.BuDepotInventoryContiner}`}>
//             <div className={`pt-2 ${styles.headerContainer}`}>
//                 <div className={'row'}>
//                     <div className={'col-8'}>
//                         <span>CCS</span>
//                     </div>
//                     <div className={`col-4 mt-2 ${styles.headerRight}`}>
//                         <span>Complaints Resolved :</span>
//                         <span className={'ml-4'} >{resolvedComplaints}</span>
//                     </div>
//                 </div>
//                 <div className={'row'}>
//                     <div className={`col-8 d-flex align-items-center ${styles.headerLabels}`}>
//                         <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot1}`}></span>{'< 2 Days'}</div>
//                         <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot2}`}></span>2-5 Days</div>
//                         <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot3}`}></span>6-10 Days</div>
//                         <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot4}`}></span>{'> 10 Days'}</div>
//                     </div>
//                     <div className={`col-4 mt-2 ${styles.headerRight}`}>
//                         <span>Complaints Received :</span>
//                         <span className={'ml-4'} >{receivedComplaints}</span>
//                     </div>
//                 </div>
//             </div>
//             <div className={`d-flex align-items-center justify-content-center ${styles.graphContainer}`}>
//                 {
//                     graphData ?
//                         <BuIncCCsPieChart graphData={graphData}></BuIncCCsPieChart>
//                         :
//                         <NoDataFound />
//                 }
//             </div>
//             <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
//                 <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon'></img>
//             </div>
//         </div>
//     )
// }
// export default BuIncCcs