import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import {getFinancialYear, numberFormatNoSeparator} from '../../../utility/utility'
import { get, isNumber } from 'lodash'
import classes from './BuProjects.module.scss'
Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const yLabels = ['Projects Planning']

const BuProjectsPlannedFYChart = ({graphData}) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
  
    useEffect(() => {
        let actualCompletion = get(graphData, 'planningFy.actual_completion_total') || 0
        let plannedCompletion = get(graphData, 'planningFy.planned_completion_total') || 0

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = {
            type: 'horizontalBar',
            data: {
                labels: yLabels,
                datasets: [
                    {
                        yAxisID: 'bar-x-axis3',
                        label: 'Projects planned',
                        backgroundColor: 'rgba(0, 175, 231, 0.4)',
                        categoryPercentage: 0.8,
                        barPercentage: 1,
                        data: [plannedCompletion],
                        borderWidth: 0,
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            color: 'white',
                            font: {
                                size: 20
                            },
                            formatter: value => isNumber(value) ? numberFormatNoSeparator(value) : 'N/A'
                        },
                    },
                    {
                        yAxisID: 'bar-x-axis1',
                        label: 'Projects completed',
                        backgroundColor: 'rgba(0, 175, 231, 1)',
                        categoryPercentage: 0.6,
                        data: [actualCompletion],
                        borderWidth: 0,
                        datalabels: {
                            anchor: 'end',
                            align: 'start',
                            color: 'white',
                            font: {
                                size: 20
                            },
                            formatter: value => isNumber(value) ? numberFormatNoSeparator(value) : 'N/A'
                        },
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            display: true,
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                padding: 20,
                            },
                            gridLines: {
                                display: false,
                            }
                        },
                    ],

                    yAxes: [
                        {
                            id: 'bar-x-axis1',
                            beginAtZero: true,
                            display: false,
                            offset: true,
                            type: 'category',
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                        {
                            id: 'bar-x-axis3',
                            beginAtZero: true,
                            display: false,
                            offset: true,
                            type: 'category',
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                        {
                            display: false,
                            offset: true,
                            id: 'bar-x-axis2',
                            type: 'category',
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ]
                },
                legend: {
                    display: false
                }
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, graphData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={'d-flex'} style={{
            minHeight: 115,
            width: 930,
        }}>
            <div className='col-2 align-content-center' style={{
                fontSize: 20,
                padding: '20px 0'
            }}>
                Projects planned vs actual (FY {getFinancialYear()})
            </div>
            <div className='col-6'>
                <canvas
                    ref={chartContainer}
                />
            </div>
            <div className='col-4' style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <div className={`d-flex ${classes.legendsContainer}`} style={{
                    flexDirection: 'column'
                }}>
                    <div className="mr-4 d-flex align-items-center">
                        <span className={`mr-2 ${classes.dotActual}`}></span>Projects completed
                    </div>
                    <div className="mr-4 d-flex align-items-center">
                        <span className={`mr-2 ${classes.dotTarget}`}></span>Projects planned
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BuProjectsPlannedFYChart