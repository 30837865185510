import { Route, useHistory } from 'react-router-dom'
import React, { useContext } from 'react'
import ApplicationContext from '../ApplicationContext'
import * as Roles from './Roles'

export const ProtectedRoute = ({isAdminRoute, ...props}) => {
    const context = useContext(ApplicationContext)
    const loggedInUser = context.loggedInUser
    const history = useHistory()
    const currentUrl = document.location.href

    if (loggedInUser || (isAdminRoute && loggedInUser.role === Roles.ADMIN)) {
        return <Route {...props}/>
    } else {
        return <Route exact={props.exact} path={props.path} render={() => {
            history.push(`/login?redirectUri=${currentUrl}`)
            return null
        } }/>
    }
}