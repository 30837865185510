import React from 'react'
import styles from './Nifty.module.scss'
import NiftGraph from './NiftyGraph'

const Nifty = (props) => {
    return(
        <div className={`${styles.Niftysection}`}>
            <div className={`${styles.NiftyGraphSection}`}>
                <NiftGraph dropdown={props.dropdown} deltavalue={props.niftyDelta} niftyDate={props.niftyDate} niftyClose={props.niftyClose} />
            </div>
            <div className={`${styles.NiftyContent}`}>
                <h4 className={`${styles.NiftyContenthead}`}>Nifty {props.niftyDelta && props.niftyDelta.includes('-') === true ? <span className={`${styles.NiftyContentheadspanred}`}>&#x2B07;</span> : <span className={`${styles.NiftyContentheadspan}`}>&#x2B06;</span> }</h4>
                <p className={`${styles.NiftyContentp}`}>{props.niftyClose && parseFloat(props.niftyClose[props.niftyClose.length - 1]).toFixed(2)} INR {
                    props.niftyDelta && props.niftyDelta.includes('-') === true ? 
                        <span className={`${styles.NiftyContentpspanred}`}> {props.niftyDelta} ({props.niftyPerc}%)</span> 
                        :<span className={`${styles.NiftyContentpspan}`}> +{props.niftyDelta} ({props.niftyPerc}%)</span>
                } 
                </p>
            </div>
        </div>
    )
}
export default Nifty