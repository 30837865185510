// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Sensex_Sensexsection__3_lsV {\n  width: 100%;\n  height: 100%; }\n  .Sensex_Sensexsection__3_lsV .Sensex_SensexGraphSection__2ousr {\n    width: 100%;\n    height: 60%; }\n    .Sensex_Sensexsection__3_lsV .Sensex_SensexGraphSection__2ousr .Sensex_SensexGraph__161yH {\n      width: 100%;\n      height: 100%; }\n  .Sensex_Sensexsection__3_lsV .Sensex_SensexContent__20SM0 {\n    width: 100%;\n    height: 40%;\n    padding: 15px; }\n    .Sensex_Sensexsection__3_lsV .Sensex_SensexContent__20SM0 .Sensex_SensexContenthead__246T0 {\n      color: #8fa9db;\n      margin-bottom: 0; }\n      .Sensex_Sensexsection__3_lsV .Sensex_SensexContent__20SM0 .Sensex_SensexContenthead__246T0 .Sensex_SensexContentheadspan__1jdJh {\n        color: #74ce12; }\n      .Sensex_Sensexsection__3_lsV .Sensex_SensexContent__20SM0 .Sensex_SensexContenthead__246T0 .Sensex_SensexContentheadspanred__2O9lD {\n        color: #ff5b4f; }\n    .Sensex_Sensexsection__3_lsV .Sensex_SensexContent__20SM0 .Sensex_SensexContentp__1tt59 {\n      color: #fff;\n      font-size: 20px; }\n      .Sensex_Sensexsection__3_lsV .Sensex_SensexContent__20SM0 .Sensex_SensexContentp__1tt59 .Sensex_SensexContentpspan__1dVRf {\n        color: #74ce12;\n        margin-left: 15px;\n        font-size: 15px; }\n      .Sensex_Sensexsection__3_lsV .Sensex_SensexContent__20SM0 .Sensex_SensexContentp__1tt59 .Sensex_SensexContentpspanred__1arSF {\n        color: #ff5b4f;\n        margin-left: 15px;\n        font-size: 15px; }\n", ""]);
// Exports
exports.locals = {
	"Sensexsection": "Sensex_Sensexsection__3_lsV",
	"SensexGraphSection": "Sensex_SensexGraphSection__2ousr",
	"SensexGraph": "Sensex_SensexGraph__161yH",
	"SensexContent": "Sensex_SensexContent__20SM0",
	"SensexContenthead": "Sensex_SensexContenthead__246T0",
	"SensexContentheadspan": "Sensex_SensexContentheadspan__1jdJh",
	"SensexContentheadspanred": "Sensex_SensexContentheadspanred__2O9lD",
	"SensexContentp": "Sensex_SensexContentp__1tt59",
	"SensexContentpspan": "Sensex_SensexContentpspan__1dVRf",
	"SensexContentpspanred": "Sensex_SensexContentpspanred__1arSF"
};
module.exports = exports;
