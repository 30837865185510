import React, { useState, useEffect } from "react";
import styles from "./OrgFinance.module.scss";
import FinanceGrowth from "./FinanceGrowth/FinanceGrowth";
import FinanceMarket from "./FinanceMarket/FinanceMarket";
import FinanceTreasurey from "./FinanceTreasurey/FinanceTreasurey";
import ProfitLoss from "./ProfitLoss/ProfitLoss";
import TotalCapex from "./TotalCapex/TotalCapex";
import BalanceSheets from "./BalanceSheets/BalanceSheets";
import FinancialRation from "./FinancialRatio/FinancialRatio";
import OrgMap1 from "./OrgMap1";
import OrgMap2 from "./OrgMap2";
import OrgS1 from "./OrgS1";
import OrgS2 from "./OrgS2";
import { fetchCgdData } from '../../services/bu'

// import IndiaMap from '../../assets/images/gas/IndiaMap.PNG'
const OrgFinance = (props) => {
  const [cgdData, setCGDData] = useState(null)
  
  const [cgdDataDigital, setCgdDataDigital] = useState(null)

  const [ofdropdown] = useState("YTM");
  const [menu, setMenu] = useState("Finance");
  useEffect(() => {
    
    fetchCgdData("EDC-ANALOG")
    .then(response => {
      setCGDData(response.cgdData)
      setCgdDataDigital(response.cgdDataDigital)

    })
    .catch(error => console.debug(error))
   
    }, [])

  return (
    <div className={`${styles.financeContainer}`}>
      <div
        className={`d-flex align-items-center justify-content-center ${styles.financeHeader1}`}
      >
        {/* <Dropdown className={`${styles.gheaderDropdownYear}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{hr}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setHR('HR')}>HR</Dropdown.Item>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setHR('Projects')}>PROJECTS</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
        <div className={`${styles.gheaderDropdownYear}`}>
          {/* <div variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{hr}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </div> */}
          <div className={`${styles.gdropdownMenuContainer}`}>
            <button
              className={`${styles.gmenuItem}`}
              onClick={() => setMenu("Finance")}
            >
              FINANCE
            </button>
            <button
              className={`${styles.gmenuItem}`}
              onClick={() => setMenu("Cgd")}
            >
              CGD
            </button>
            {/* <button
              className={`${styles.gmenuItem}`}
              onClick={() => setMenu("CrossBU")}
            >
              X BU SALES
            </button> */}
          </div>
        </div>
        {/* <Dropdown className={`${styles.hrheaderDropdown}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.hrdropdownButton}`}>
                        <span className={`${styles.hrdropdownName}`}>View as {ohrdropdown}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.hrdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.hrdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.hrmenuItem}`} onClick={() => setohrdropdown('YTM')} >YTM</Dropdown.Item>
                        <Dropdown.Item className={`${styles.hrmenuItem}`} onClick={() => setohrdropdown('MTD')}>MTD</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
      </div>
      {menu === "Finance" ? (
        <div className={`d-flex flex-column ${styles.financeContentContainer}`}>
          <div className={`d-flex ${styles.growthAndMarketContainer}`}>
            <FinanceGrowth
              dropdown={ofdropdown}
              year={props.year}
            ></FinanceGrowth>
            <FinanceMarket
              dropdown={ofdropdown}
              year={props.year}
            ></FinanceMarket>
          </div>
          <div className={`d-flex ${styles.restOfFinanceContainer}`}>
            <div
              className={`d-flex flex-column justify-content-between ${styles.column1}`}
            >
              <ProfitLoss dropdown={ofdropdown} year={props.year}></ProfitLoss>
              <TotalCapex dropdown={ofdropdown} year={props.year}></TotalCapex>
            </div>
            <FinanceTreasurey
              dropdown={ofdropdown}
              year={props.year}
            ></FinanceTreasurey>
            <div
              className={`d-flex flex-column justify-content-between ${styles.column3}`}
            >
              <FinancialRation
                dropdown={ofdropdown}
                year={props.year}
              ></FinancialRation>
              <BalanceSheets
                dropdown={ofdropdown}
                year={props.year}
              ></BalanceSheets>
            </div>
          </div>
        </div>
      ) : menu === "Cgd" ? (
        // <iframe src="https://gas.hellobpcl.in/login" width={3120} height={1900}></iframe>
        <div className={`${styles.gasContainer}`}>
          <div className={`${styles.gasB1}`}>
            {/* <img src={IndiaMap}/> */}
            <OrgS2 cgdData={cgdData} cgdDataDigital={cgdDataDigital} />
          </div>
          <div className={`${styles.gasB2}`}>
            <OrgS1 />
          </div>
          <div className={`${styles.gasB3}`}>
            <OrgMap1 />
          </div>
          <div className={`${styles.gasB4}`}>
            <OrgMap2 />
          </div>
        </div>
      ) : (
        <div>
          <iframe
            title="Sales Analysis"
            width="3110"
            height="1890"
            src="https://app.powerbi.com/reportEmbed?reportId=8a1efaaf-f344-4673-85c7-2f755facad64&autoAuth=true&ctid=222f3a7c-d45e-4818-9aa4-33d44420ec32"
            frameborder="0"
            allowFullScreen="true"
            sandbox
            className={`${styles.pBi}`}
          ></iframe>
          {/* <iframe title="Sample Report Demo" width="3110" height="1890" src="https://playground.powerbi.com/sampleReportEmbed" frameborder="0" allowFullScreen="true"></iframe> */}
        </div>
      )}
    </div>
  );
};
export default OrgFinance;
