import React, { useEffect, useRef, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import { get } from 'lodash'

const BuProjectsExpenditureQWiseChart = ({ graphData }) => {
    const chartContainer = useRef()
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }

        const unit = 'INR CR'
        const plannedExpenditure = [
            (get(graphData, 'expenditure.q1_planned_expenditure') || 0),
            (get(graphData, 'expenditure.q2_planned_expenditure') || 0),
            (get(graphData, 'expenditure.q3_planned_expenditure') || 0),
            (get(graphData, 'expenditure.q4_planned_expenditure') || 0)
        ]
        const actualExpenditure = [
            (get(graphData, 'expenditure.q1_actual_expenditure') || 0),
            (get(graphData, 'expenditure.q2_actual_expenditure') || 0),
            (get(graphData, 'expenditure.q3_actual_expenditure') || 0),
            (get(graphData, 'expenditure.q4_actual_expenditure') || 0)
        ]

        const ctx = chartContainer.current.getContext('2d')

        const chartConfig = {
            type: 'bar',
            data: {
                labels: ['Q1', 'Q2', 'Q3', 'Q4'],
                datasets: [
                    {
                        label: 'Planned expenditure',
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        backgroundColor: 'rgba(27, 112, 192, 0.5)',
                        borderWidth: 1,
                        barPercentage: 0.6,
                        data: plannedExpenditure,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Actual expenditure',
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        backgroundColor: 'rgba(27, 112, 192, 1)',
                        borderWidth: 1,
                        barPercentage: 0.4,
                        data: actualExpenditure,
                        datalabels: {
                            display: false
                        }
                    }
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    mode: 'label',
                    callbacks: {
                        label: function (tooltipItems, data) {
                            return `${data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]} ${unit}`
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            id: 'bar-x-axis1',
                            type: 'category',
                            display: true,
                            stacked: true,
                            offset: true,
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                        {
                            id: 'bar-x-axis2',
                            display: false,
                            stacked: true,
                            offset: true,
                            type: 'category',
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            display: true,
                            id: 'bar-y-axis1',
                            beginAtZero: true,
                            scaleLabel: {
                                display: true,
                                labelString: `Expenditure (in ${unit})`,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit: 5
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1
                            }
                        },
                        {
                            id: 'bar-y-axis2',
                            beginAtZero: true,
                            display: false,
                            scaleLabel: {
                                display: true,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit: 5
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#2f3b51',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, graphData])// eslint-disable-next-line react-hooks/exhaustive-deps


    return (
        <div style={{
            height: 330,
            width: 800,
            marginTop: 15
        }}>
            <canvas
                ref={chartContainer}
                height={330}
                width={800}
            />
        </div>

    )
}

export default BuProjectsExpenditureQWiseChart