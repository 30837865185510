import React, { useEffect } from 'react'
import styles from './BuAtfGraphs.module.scss'
import BuAtfSalesRevenue from './BuAtfSalesRevenue/BuAtfSalesRevenue'
import BuAtfMarketShare from './BuAtfMarketShare/BuAtfMarketShare'
import BuAtfOperatingCost from './BuAtfOperatingCost/BuAtfOperatingCost'
import BuAtfCustomerGrowth from './BuAtfCustomerGrowth/BuAtfCustomerGrowth'
import BuAtfCustomerGrowthInter from './BuAtfCustomerGrowth/BuAtfCustomerGrowthInter'
import BuAtfCustomerGrowthLastYr from './BuAtfCustomerGrowth/BuAtfCustomerGrowthLastYr'
import BuAtfTankInventory from './BuAtfTankInventory/BuAtfTankInventory'
import BuAtfOthers from './BuAtfOthers/BuAtfOthers'
import BuAtfHsse from './BuAtfHsse/BuAtfHsse'
import BuAtfLossAndGain from './BuAtfLossAndGain/BuAtfLossAndGain'
import { Dropdown } from 'react-bootstrap'
import BuAtfProjects from './BuAtfProjects/BuAtfProjects'

function BuSections(props) {
    useEffect(() => {
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuGraphsContainer}`}>
            <div className={`d-flex justify-content-end ${styles.BuGraphsHeader}`}>
                <div className={`d-flex ${styles.mapHeader}`}>
                    <Dropdown className={`${styles.headerDropdown}`}>
                        <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.dropdownButton}`}>
                            <span className={`${styles.dropdownName}`}>YTM</span>
                            {/* <div className={`d-flex align-items-center justify-content-center ${styles.dropdownIconContainer}`}>
                                <img src={dropdownArrows} alt='All region dropdown'></img>
                            </div> */}
                        </Dropdown.Toggle>
                        {/* <Dropdown.Menu className={`${styles.dropdownMenuContainer}`}>
                            <Dropdown.Item className={`${styles.menuItem}`} >YTM</Dropdown.Item>
                            <Dropdown.Item className={`${styles.menuItem}`} >MTD</Dropdown.Item>
                        </Dropdown.Menu> */}
                    </Dropdown>
                </div>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow1}`}>
                <BuAtfSalesRevenue year={props.year} mapOf={props.mapOf}></BuAtfSalesRevenue>
                <BuAtfTankInventory year={props.year} activeTab={props.activeTab}></BuAtfTankInventory>
                {/* <BuAtfMarketShare year={props.year}></BuAtfMarketShare> */}
                <BuAtfOperatingCost year={props.year}></BuAtfOperatingCost>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow2}`}>
                <BuAtfHsse year={props.year} activeTab={props.activeTab}></BuAtfHsse>
                {/* <BuAtfCustomerGrowth year={props.year} activeTab={props.activeTab}></BuAtfCustomerGrowth> */}
                <BuAtfLossAndGain year={props.year} activeTab={props.activeTab}></BuAtfLossAndGain>
                {/* <BuAtfTankInventory year={props.year} activeTab={props.activeTab}></BuAtfTankInventory> */}
                <BuAtfMarketShare year={props.year}></BuAtfMarketShare>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow3}`}>
                {props.year >= 2023 ?
                    <BuAtfCustomerGrowth year={props.year} activeTab={props.activeTab}></BuAtfCustomerGrowth>
                    :
                    <BuAtfCustomerGrowthLastYr year={props.year} activeTab={props.activeTab}></BuAtfCustomerGrowthLastYr>
                }
                {/* <BuAtfHsse year={props.year} activeTab={props.activeTab}></BuAtfHsse> */}
                {props.year >= 2023 ?
                    <BuAtfCustomerGrowthInter year={props.year} activeTab={props.activeTab}></BuAtfCustomerGrowthInter>
                    :
                    <BuAtfCustomerGrowthLastYr year={props.year} activeTab={props.activeTab}></BuAtfCustomerGrowthLastYr>
                }
                <BuAtfOthers year={props.year} activeTab={props.activeTab}></BuAtfOthers>
                {/* <BuAtfCcs activeTab={props.activeTab}></BuAtfCcs> */}
                {/* <BuAtfProjects year={props.year} product={props.activeTab} /> */}
                {/* <BuAtfCustomerGrowth year={props.year} activeTab={props.activeTab}></BuAtfCustomerGrowth> */}
            </div>
        </div>
    )
}
export default BuSections