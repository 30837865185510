import React, { useState, useEffect } from 'react'
import styles from './BuGraphs.module.scss'
import BuSalesRevenue from './BuSalesRevenue/BuSalesRevenue'
import BuMarketShare from './BuMarketShare/BuMarketShare'
import BuOperatingCost from './BuOperatingCost/BuOperatingCost'
import BuCcs from './BuCcs/BuCcs'
import BuDepotInventory from './BuDepotInventory/BuDepotInventory'
import BuRetailProjects from './BuProjects/BuRetailProjects'
import BuHsse from './BuHsse/BuHsse'
import BuRetailOutlet from './BuRetailOutlet/BuRetailOutlet'
import BuEthanolBlending from './BuEthanolBlending/BuEthanolBlending'
import { Dropdown } from 'react-bootstrap'
import dropdownArrows from '../../assets/images/dropdownArrows.png'

const BuSections = (props) => {
    const [product, setProduct] = useState('ms')
    useEffect(() => {}, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuGraphsContainer}`}>
            <div className={`d-flex justify-content-end ${styles.BuGraphsHeader}`}>
                <div className={`d-flex ${styles.mapHeader}`}>
                    <Dropdown className={`${styles.headerDropdown}`}>
                        <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.dropdownButton}`}>
                            <span className={`${styles.dropdownName}`}>{product.toLocaleUpperCase()}</span>
                            <div className={`d-flex align-items-center justify-content-center ${styles.dropdownIconContainer}`}>
                                <img src={dropdownArrows} alt='All region dropdown'></img>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`${styles.dropdownMenuContainer}`}>
                            <Dropdown.Item className={`${styles.menuItem}`} onClick={() => setProduct('ms')} >MS</Dropdown.Item>
                            <Dropdown.Item className={`${styles.menuItem}`} onClick={() => setProduct('hsd')} >HSD</Dropdown.Item>
                            <Dropdown.Item className={`${styles.menuItem}`} onClick={() => setProduct('export ms')} >MS Export</Dropdown.Item>
                            <Dropdown.Item className={`${styles.menuItem}`} onClick={() => setProduct('export hsd')} >HSD Export</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className={`${styles.headerDropdown}`}>
                        <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.dropdownButton}`}>
                            <span className={`${styles.dropdownName}`}>YTM</span>
                            {/* <div className={`d-flex align-items-center justify-content-center ${styles.dropdownIconContainer}`}>
                                <img src={dropdownArrows} alt='All region dropdown'></img>
                            </div> */}
                        </Dropdown.Toggle>
                        {/* <Dropdown.Menu className={`${styles.dropdownMenuContainer}`}>
                            <Dropdown.Item className={`${styles.menuItem}`} >YTM</Dropdown.Item>
                            <Dropdown.Item className={`${styles.menuItem}`} >MTD</Dropdown.Item>
                        </Dropdown.Menu> */}
                    </Dropdown>
                </div>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow1}`}>
                <BuSalesRevenue year={props.year} mapOf={props.mapOf} product={product}></BuSalesRevenue>
                {/* <BuMarketShare year={props.year} product={product}></BuMarketShare> */}
                <BuDepotInventory activeTab={props.activeTab} year={props.year}></BuDepotInventory>
                <BuOperatingCost year={props.year} mapOf={props.mapOf} product={product}></BuOperatingCost>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow2}`}>
            <BuEthanolBlending activeTab={props.activeTab} year={props.year} product={product}></BuEthanolBlending>
                {/* <BuRetailOutlet year={props.year}></BuRetailOutlet> */}
                <BuHsse year={props.year}></BuHsse>
                <BuMarketShare year={props.year} product={product}></BuMarketShare>
                {/* <BuCcs year={props.year}></BuCcs> */}
                {/* <BuDepotInventory activeTab={props.activeTab} year={props.year}></BuDepotInventory> */}
            </div>
            <div className={`d-flex ${styles.BuGraphsRow3}`}>
            <BuCcs year={props.year}></BuCcs>
            <BuRetailOutlet year={props.year}></BuRetailOutlet>
                {/* <BuHsse year={props.year}></BuHsse> */}
                {/* <BuEthanolBlending activeTab={props.activeTab} year={props.year} product={product}></BuEthanolBlending> */}
                <BuRetailProjects year={props.year} product={props.activeTab}></BuRetailProjects>
            </div>
        </div>
    )
}
export default BuSections