import React, {useState, useEffect} from 'react'
import styles from './SalesKpi.module.scss'
import SalesKpiGraph from './SalesKpiGraph'
import DeltaValuesOrgGrowth from '../../DeltaValuesOrg/DeltaValuesOrgGrowth'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import {numberFormatEnIn} from '../../../utility/utility'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import greenTick from '../../../assets/images/greenTick.png'

const SalesKpi = (props) => {
    useEffect(() => {}, [props.loading])
    return (
        <div className={`${styles.GrowthKpi1Container}`}>
            <div className={`d-flex align-items-center justify-content-between ${styles.GrowthKpi1Header}`}>
                <div className={`${styles.kpiTitle}`}>{props.kpiName}</div>
                <div className={`d-flex flex-column ${styles.kpiOverallDetails}`}>
                    <span className={`${styles.totalValues}`}>Total: {props.totalsalesf.value ? numberFormatEnIn(parseFloat(props.totalsalesf.value).toFixed(2)) : ''} {props.totalsalesf.unit ? props.totalsalesf.unit : ''}</span>
                    {/* <DeltaValuesOrgGrowth quater={props.totalsalesf.quater ? parseFloat(props.totalsalesf.quater).toFixed(2) : ''} year={props.totalsalesf.year ? parseFloat(props.totalsalesf.year).toFixed(2) : ''}></DeltaValuesOrgGrowth> */}
                </div>
                {(props.statusSales === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
            </div>
            <div className={`${styles.GrowthKpi1ContentContainer}`}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={`mt-2 d-flex align-items-right ${styles.headerLabels}`}>
                        <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.HpclDot}`}></span>Domestic</div>
                        <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.IoclDot}`}></span>Export</div>
                        <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.ShellDot}`}></span>Total</div>
                    </div>
                </div>
                <div className={`${styles.graphContainer}`}>
                    {props.loading ? 
                        // <NoDataFound />
                        <QuarterlyDataNotFound />
                        :
                        <SalesKpiGraph kpiName={props.kpiName} salesfdata={props.salesfdata}></SalesKpiGraph>
                    }
                </div>
                
            </div>
        </div>
    )
}
export default SalesKpi