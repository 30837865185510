import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuOperatingCostBarGraph from '../../BuRetailGraphs/BuOperatingCost/BuOperatingCostBarGraph'
import styles from './BuLubesOperatingCost.module.scss'
import { Spinner } from 'react-bootstrap'
import Checkbox from '../../Checkbox.js/Checkbox'
import { fetchLubesOperatingCost, fetchupdatedStatus } from '../../../services/bu'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuLubesOperatingCost = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [staffCostVar, setStaffCostStatus] = useState(true)
    const [rmVar, setRmStatus] = useState(true)
    const [rentalVar, setRentalStatus] = useState(true)
    const [thirdPartyVar, setThirdPartyStatus] = useState(true)
    const [salesProVar, setSalesProStatus] = useState(true)
    const [othersVar, setOthersStatus] = useState(true)
    const [graphData, setGraphData] = useState([])
    const [totaloclastitem, settotaloc] = useState('')
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps


    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "lubes" && res.kpi_name === "Operating Cost" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        const prepareGraphData = () => {
            fetchLubesOperatingCost(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setGraphData(response.data)
                        setIsNoDataAvailable(false)
                        settotaloc(response.data[response.data.length - 1])

                    }
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData()
    }, [props.year])
    const onCheckStateChanged = (event) => {
        if (event.name === 'all') {
            if (event.checkState) {
                setStaffCostStatus(true)
                setRmStatus(true)
                setRentalStatus(true)
                setThirdPartyStatus(true)
                setSalesProStatus(true)
                setOthersStatus(true)
            }
            else {
                setStaffCostStatus(false)
                setRmStatus(false)
                setRentalStatus(false)
                setThirdPartyStatus(false)
                setSalesProStatus(false)
                setOthersStatus(false)
            }
        }
        if (event.name === 'staffCost') {
            setStaffCostStatus(event.checkState)
        }
        if (event.name === 'rm') {
            setRmStatus(event.checkState)
        }
        if (event.name === 'rental') {
            setRentalStatus(event.checkState)
        }
        if (event.name === 'thirdParty') {
            setThirdPartyStatus(event.checkState)
        }
        if (event.name === 'salesPro') {
            setSalesProStatus(event.checkState)
        }
        if (event.name === 'others') {
            setOthersStatus(event.checkState)
        }
    }

    return (
        <div className={`${styles.BuOperatingCostContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-7'}>
                            <span>Operating Cost - Daily</span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                        {
                            isNoDataAvailable ||
                            <div className={`col-5 mt-2 ${styles.headerRight}`}>
                                {/* <span>Total: &#8377; {totaloc} Cr</span> */}
                                <span>Total: {totaloclastitem.totaloc !== null ? Number(totaloclastitem.totaloc).toFixed(2) + ' INR Cr' : 'No Data'} </span>

                                <span className={'ml-4'} style={{ color: '#e13219' }}></span>
                            </div>
                        }
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-9 d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.staffCostDot}`}></span>Staff Cost</div>
                            <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.rmDot}`}></span>R&M</div>
                            <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.rentalDot}`}></span>Rental</div>
                            <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.thirdPartyDot}`}></span>3rd Party</div>
                            <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.salesProDot}`}></span>Sales Pro.</div>
                            <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.othersDot}`}></span>Others</div>
                        </div>
                        {/* <div className={`col-3 ${styles.headerLabelsTarget}`}>
                        <span>---- Target</span>
                    </div> */}
                    </div>
                </div>
                {
                    isNoDataAvailable ?
                        <NoDataFound height={512} />
                        :
                        <React.Fragment>
                            <div className={`d-flex ${styles.graphContainer}`}>
                                {
                                    graphData ?
                                        <BuOperatingCostBarGraph graphData={graphData}
                                            staffCostVar={staffCostVar}
                                            rmVar={rmVar}
                                            rentalVar={rentalVar}
                                            thirdPartyVar={thirdPartyVar}
                                            salesProVar={salesProVar}
                                            othersVar={othersVar}
                                        ></BuOperatingCostBarGraph>
                                        :
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                }
                                <div>
                                    {/* <div className={`${styles.MsHsdContainer}`}>
                                    <div className={`${styles.HsdCircle}`}><span className={`${styles.Hsd}`}>{props.product.toLocaleUpperCase()}</span></div>
                                    <div className={`${styles.MsCircle}`}><span className={`${styles.Ms}`}>MS</span></div>
                                </div> */}
                                    <div className={`d-flex flex-column justify-content-center pt-4 ${styles.graphFilters}`}>
                                        <Checkbox
                                            size="sm"
                                            name="all"
                                            label="All"
                                            checked={staffCostVar && rmVar && rentalVar && thirdPartyVar && salesProVar && othersVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="staffCost"
                                            label="Staff Cost"
                                            checked={staffCostVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="rm"
                                            label="R&M"
                                            checked={rmVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="rental"
                                            label="Rental"
                                            checked={rentalVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="thirdParty"
                                            label="3rd Party"
                                            checked={thirdPartyVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="salesPro"
                                            label="Sales Pro."
                                            checked={salesProVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                        <Checkbox
                                            size="sm"
                                            name="others"
                                            label="Others"
                                            checked={othersVar}
                                            onChange={(e) => onCheckStateChanged(e)}
                                        ></Checkbox>
                                    </div>
                                </div>
                            </div>
                            <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' id="fullOc" data-fs="handleenter" onClick={fullScrn}></img>
                            </div>
                        </React.Fragment>
                }
            </FullScreen>
        </div>
    )
}
export default BuLubesOperatingCost