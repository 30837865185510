// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RefinaryOperatingCost_operatingCostContainer__2vKOt {\n  border: 0.5px #a7a7a7 solid;\n  border-bottom: none;\n  font-family: Titillium Web;\n  width: 644px;\n  height: 653px;\n  background-color: #141923;\n  color: #dee5ef;\n  position: relative;\n  border-left: none; }\n  .RefinaryOperatingCost_operatingCostContainer__2vKOt .RefinaryOperatingCost_graphTitle__36T92 {\n    height: 88px;\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    background-color: black;\n    font-size: 34px;\n    font-weight: bold; }\n  .RefinaryOperatingCost_operatingCostContainer__2vKOt .RefinaryOperatingCost_OperatingCostFooter__3atG7 {\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    height: 64px;\n    background-color: #283040; }\n    .RefinaryOperatingCost_operatingCostContainer__2vKOt .RefinaryOperatingCost_OperatingCostFooter__3atG7 .RefinaryOperatingCost_footerLeft__2Ked_ {\n      cursor: pointer;\n      margin-left: 36px;\n      color: #ffffff;\n      font-size: 22px;\n      text-decoration: underline; }\n    .RefinaryOperatingCost_operatingCostContainer__2vKOt .RefinaryOperatingCost_OperatingCostFooter__3atG7 .RefinaryOperatingCost_footerRight__JWfWA {\n      margin-right: 36px;\n      font-size: 22px;\n      font-style: italic; }\n  .RefinaryOperatingCost_operatingCostContainer__2vKOt .RefinaryOperatingCost_statusUpdatedImg__93hBE {\n    margin-left: 0px;\n    margin-top: -42px; }\n", ""]);
// Exports
exports.locals = {
	"operatingCostContainer": "RefinaryOperatingCost_operatingCostContainer__2vKOt",
	"graphTitle": "RefinaryOperatingCost_graphTitle__36T92",
	"OperatingCostFooter": "RefinaryOperatingCost_OperatingCostFooter__3atG7",
	"footerLeft": "RefinaryOperatingCost_footerLeft__2Ked_",
	"footerRight": "RefinaryOperatingCost_footerRight__JWfWA",
	"statusUpdatedImg": "RefinaryOperatingCost_statusUpdatedImg__93hBE"
};
module.exports = exports;
