import React, { useState, useEffect } from 'react'
import styles from './OrgHr.module.scss'
import EmployeeDataAndRecruitment from './EmployeeDataAndRecruitment/EmployeeDataAndRecruitment'
import EthicalHr from './EthicalHr/EthicalHr'
import LearningDevelopment from './LearningDevelopment/LearningDevelopment'
import PerformanceAndProductivity from './PerformanceAndProductivity/PerformanceAndProductivity'
import BuProjectGraphNumber from '../BuProjectGraphs/BuProjectGraphNumber/BuProjectGraphNumber'
import BuProjectGraphCost from '../BuProjectGraphs/BuProjectGraphCost/BuProjectGraphCost'
import BuProjectGraphProgress from '../BuProjectGraphs/BuProjectGraphProgress/BuProjectGraphProgress'
import BUProjectGraphDelay from '../BuProjectGraphs/BuProjectGraphDelay/BuProjectGraphDelay'
import { Dropdown } from 'react-bootstrap'
import dropdownArrows from '../../assets/images/dropdownArrows.png'


const OrgHr = (props) => {
    const [ohrdropdown] = useState('YTM')
    const [product, setProduct] = useState('ms')
    const [hr, setHR] = useState('HR')
    // const [project, setProject] = useState('')
    useEffect(() => {}, [props.activeTab, props.year])

    return (
        <div className={`${styles.hrContainer}`}>
            <div className={`d-flex align-items-center justify-content-center ${styles.hrHeader}`}>
            {/* <Dropdown className={`${styles.gheaderDropdownYear}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{hr}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setHR('HR')}>HR</Dropdown.Item>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setHR('Projects')}>PROJECTS</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                <div className={`${styles.gheaderDropdownYear}`}>
                    {/* <div variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{hr}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </div> */}
                    <div className={`${styles.gdropdownMenuContainer}`}>
                        <button className={`${styles.gmenuItem}`} onClick={() => setHR('HR')}>HR</button>
                        <button className={`${styles.gmenuItem}`} onClick={() => setHR('Projects')}>PROJECTS</button>
                    </div>
                </div>
                {/* <Dropdown className={`${styles.hrheaderDropdown}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.hrdropdownButton}`}>
                        <span className={`${styles.hrdropdownName}`}>View as {ohrdropdown}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.hrdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.hrdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.hrmenuItem}`} onClick={() => setohrdropdown('YTM')} >YTM</Dropdown.Item>
                        <Dropdown.Item className={`${styles.hrmenuItem}`} onClick={() => setohrdropdown('MTD')}>MTD</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
            { (hr === 'HR') ?
            <div className={`${styles.hrContentContainer}`}>
                <EmployeeDataAndRecruitment dropdown={ohrdropdown} year={props.year}></EmployeeDataAndRecruitment>
                <EthicalHr dropdown={ohrdropdown} year={props.year}></EthicalHr>
                <LearningDevelopment dropdown={ohrdropdown} year={props.year}></LearningDevelopment>
                <PerformanceAndProductivity dropdown={ohrdropdown} year={props.year}></PerformanceAndProductivity>
            </div>
            :
            <div className={`${styles.hrContentContainer}`}>
                <BuProjectGraphNumber year={props.year} mapOf={props.mapOf} product={product}></BuProjectGraphNumber>
                <BuProjectGraphCost year={props.year} mapOf={props.mapOf} product={product}></BuProjectGraphCost>
                <BuProjectGraphProgress year={props.year} mapOf={props.mapOf} product={product}></BuProjectGraphProgress>
                {/* <BUProjectGraphDelay year={props.year} mapOf={props.mapOf} product={product}></BUProjectGraphDelay> */}
            </div>
}
        </div>
    )
}
export default OrgHr