import React, {useState, useEffect, useCallback} from 'react'
import styles from './FinancialRatio.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import OrgListFR from './OrgListFR'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchFinancialRatio, fetchupdatedStatus } from '../../../services/Org'
import {NoDataFound} from '../../DataNotFound/nodatafound'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
import "../../../Common.scss"


const FinancialRatio = (props) => {
    const [loading, setLoading] = useState(true)
    const[de, setde] = useState([])
    const[cr, setcr] = useState([])
    const[icr, seticr] = useState([])
    const[it, setit] = useState([])
    const[dt, setdt] = useState([])
    const [quarter, setquarter] = useState(0)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const [updatedStatus, setUpdatedStatus] = useState('')
    
    const fullScrn = () => {
        if(attr === 'handleenter'){
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        }else if(attr === 'handleexit'){
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try{
            if(state === false){
                document.body.style.zoom = 0
            }
        }catch(exception){
            console.debug(exception)
        }
      }, [handle]);
    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
                let debtRatio = response.findLast((res) => res.kpi_name === "Debt/Equity Ratio");
                let curRatio = response.findLast((res) => res.kpi_name === "Current Ratio")
                let intRatio = response.findLast((res) => res.kpi_name === "Interest Coverage Ratio")
                let invTurnover = response.findLast((res) => res.kpi_name === "Inventory Turnover")
                let debtTurnover = response.findLast((res) => res.kpi_name === "Debtors Turnover")
                if (debtRatio.status && curRatio.status && intRatio.status && invTurnover.status && debtTurnover.status === "Updated") {
                    setUpdatedStatus("Updated")
                } else {
                    setUpdatedStatus("Not Updated")
                }
            } else {
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchFinancialRatio(props.dropdown.toLowerCase(), props.year).then(data => {
            if(data.length === 0){
                setLoading(true)
                setquarter(0)
            }else{
                let dearray = []
                let crarray = []
                let icrarray = []
                let itarray = []
                let dtarray = []
                data.map(getprldata => {
                    if(getprldata.sub_kpi_type === 'Debt/Equity Ratio'){
                        dearray.push(getprldata)
                        setde(dearray[0])
                    }
                    if(getprldata.sub_kpi_type === 'Current Ratio'){
                        crarray.push(getprldata)
                        setcr(crarray[0])
                    }
                    if(getprldata.sub_kpi_type === 'Interest Coverage Ratio'){
                        icrarray.push(getprldata)
                        seticr(icrarray[0])
                    }
                    if(getprldata.sub_kpi_type === 'Inventory Turnover'){
                        itarray.push(getprldata)
                        setit(itarray[0])
                    }
                    if(getprldata.sub_kpi_type === 'Debtors Turnover'){
                        dtarray.push(getprldata)
                        setdt(dtarray[0])
                    }
                })
                let lastQuarter = new Date(data[0].date).getMonth() + 1
                let quarterData
                switch (lastQuarter) {
                    case 6:
                        quarterData = 1
                        break
                    case 9:
                        quarterData = 2
                        break
                    case 12:
                        quarterData = 3
                        break
                    case 3:
                        quarterData = 4
                        break
                    default:
                        break
                }
                setquarter(quarterData)
                setLoading(false)
            }
        }).catch(error => console.debug(error))
    }, [props.dropdown, props.year])
    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.FinancialRatioContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.FinancialRatioHeader}`}>
                    <span>Financial Ratio (Quarter {quarter}) - Quarterly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand FinancialRatio" id="fullSrnfr" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                {loading
                    ?
                    // <NoDataFound />
                    <QuarterlyDataNotFound />
                    :
                    <OrgListFR de={de} cr={cr} icr={icr} it={it} dt={dt}></OrgListFR>
                }
            </div>
        </FullScreen>
    )
}
export default FinancialRatio