import React from 'react'
import styles from '../../OrgListTile/OrgListTile.module.scss'
import DeltaValuesOrg from '../../DeltaValuesOrg/DeltaValuesOrg'
import {numberFormatEnIn} from '../../../utility/utility'

const OrgListPP = (props) => {
    return (
        <div>
            <div className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                <div className={`${styles.kpiTitle}`}>Revenue / Employee</div>
                <div className={`${styles.kpiValue}`}>{props.rpe.value ? <> INR {numberFormatEnIn(parseFloat(props.rpe.value).toFixed(2))} Cr</> : ''}</div>
                <div className={`${styles.deltaComponent}`}>
                    <DeltaValuesOrg quater={props.rpe.quaterly ? parseFloat(props.rpe.quaterly).toFixed(2) : ''} year={props.rpe.yearly ? parseFloat(props.rpe.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                </div>
            </div>
            <div className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                <div className={`${styles.kpiTitle}`}>Opex / Employee</div>
                <div className={`${styles.kpiValue}`}>{props.ope.value ? <> INR {numberFormatEnIn(parseFloat(props.ope.value).toFixed(2))} Cr</> : ''}</div>
                <div className={`${styles.deltaComponent}`}>
                    <DeltaValuesOrg quater={props.ope.quaterly ? parseFloat(props.ope.quaterly).toFixed(2) : ''} year={props.ope.yearly ? parseFloat(props.ope.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                </div>
            </div>
            <div className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                <div className={`${styles.kpiTitle}`}>EBITDA / Employee</div>
                <div className={`${styles.kpiValue}`}>{props.epe.value ? <> INR {numberFormatEnIn(parseFloat(props.epe.value).toFixed(2))} Cr</> : ''}</div>
                <div className={`${styles.deltaComponent}`}>
                    <DeltaValuesOrg quater={props.epe.quaterly ? parseFloat(props.epe.quaterly).toFixed(2) : ''} year={props.epe.yearly ? parseFloat(props.epe.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                </div>
            </div>
        </div>
    )
}
export default OrgListPP