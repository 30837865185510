import React, { useState, useEffect } from 'react'
import mapStyles from './mapStyles'
import styles from './GoogleMapComponent.module.scss'
import {
    GoogleMap,
    Marker,
    Polygon,
    Polyline
} from 'react-google-maps'
import CustomInfoBox from './CustomInfoBox'
import BuRetailMapTable from '../BuRetailMapTable/BuRetailMapTable'
import statesJson from './indiaRegions.json'
import StateMap from './StateMap'
import { getColourFromRegion } from '../../../utility/buMapUtility'
import { Spinner } from 'react-bootstrap'
import depotBlackIcon from '../../../assets/images/depot-alert-02.png'
import depotWhiteIcon from '../../../assets/images/depot-ok-02.png'
import heatmapLegend from '../../../assets/images/HMap.png'
import { fetchRetailGoogleMap, fetchRetailStateGoogleMap } from '../../../services/bu'
import {isEmpty} from 'lodash'

function GoogleMapComponent(props) {
    const [activeKpi, setactiveKpi] = useState('all') 
    const [responseData, setresponseData] = useState([]) 
    const [responseStateData, setresponseStateData] = useState([]) 
    const [loading, setloading] = useState(true)    
    //onRegionClick = onRegionClick.bind(this)
    useEffect(() => {
        // fetchRetailGoogleMap(props.year)
        //     .then(response => {
        //         if(isEmpty(response.data)){
        //             setresponseData([])
        //             setloading(true)
        //         }else{
        //             setresponseData(response.data)
        //             setloading(false)
        //         }
        //     })
        //     .catch(error => console.debug(error))
        setloading(true);
        fetchRetailStateGoogleMap(props.year)
            .then(response => {
                if(isEmpty(response.data)){
                    setresponseStateData([])
                    setloading(true)
                } else {
                    setresponseData(response.data)
                    setresponseStateData(response.data1) //for heatmap
                    setloading(false)
                }
            })
            .catch(error => {
                console.debug(error);
                setloading(false);
            });
    }, [props.mapOf, props.year])

    const onRegionClick = (e, region) => {
        console.debug(e)
        props.handleRegionDropdown(region.name, e.latLng, 6.2)
    }

    const onKpiTabChange = (kpiToShow) => {
        setactiveKpi(kpiToShow)
    }
        return (
            loading
                ?
                <GoogleMap
                className={`${styles.noCloseButton}`}
                center={props.mapCenter}
                // zoom={props.mapZoom}
                zoom={5.4}
                defaultOptions={{
                    styles: mapStyles,
                    mapTypeControl: false,
                    disableDoubleClickZoom: true,
                    zoomControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                }}
                ref={props.onMapMounted}
                onClick={(e) => { console.debug('FROM MAP', e.latLng) }}

            >
                {(activeKpi === 'depot') && <div className={`d-flex align-items-center justify-content-center p-2 ${styles.depotTabLabelContainer}`}>
                    <div className="d-flex align-items-center mr-3"><img src={depotBlackIcon} alt="Below Target" className="mr-2" />Below</div>
                    <div className="d-flex align-items-center ml-3"><img src={depotWhiteIcon} alt="Above Target" className="mr-2" />Above</div>
                </div>}
                {/* <div className={`${activeKpi === 'depot' ? styles.mapTableContainerDepot : styles.mapTableContainer}`}>
                <OrgMapDetailTable year={props.year} mapOf={props.mapOf} activeKpi={activeKpi}></OrgMapDetailTable>
                </div> */}
                {
                    (props.mapOf === 'all regions' && props.activeTab === 'retail_tab')
                    ?
                        statesJson.regions.map((regionItem, index) => {
                            let responseRegion = responseData.find((responseDataItem) => {
                                return responseDataItem.region === regionItem.name
                            })
                            return (
                                <React.Fragment key={index}>
                                    {activeKpi === 'depot' && <Marker position={{ lat: 18, lng: 80 }} icon={depotBlackIcon}></Marker>}
                                    {activeKpi === 'depot' && <Marker position={{ lat: 24.7, lng: 77.4 }} icon={depotWhiteIcon}></Marker>}
                                    {activeKpi === 'depot' && <Marker position={{ lat: 10.4, lng: 78.75 }} icon={depotBlackIcon}></Marker>}
                                    {activeKpi === 'depot' && <Marker position={{ lat: 20.0, lng: 84.5 }} icon={depotWhiteIcon}></Marker>}
                                    {activeKpi === 'depot' && <Marker position={{ lat: 19.9, lng: 74.7 }} icon={depotBlackIcon}></Marker>}
                                    {/* {responseRegion &&
                                        <CustomInfoBox
                                            kpiSelection={activeKpi}
                                            locationData={responseRegion}
                                            anchorPosition={regionItem.data.markers[0].coordinates.slice(-1)[0]}
                                            markerPixelOffset={{ x: 135, y: 120 }}
                                        ></CustomInfoBox>
                                    } */}
                                    {regionItem.data.polygons.map((polygon, index) => {
                                        return (
                                            <Polygon
                                                key={index}
                                                path={polygon.coordinates}
                                                options={{
                                                    // fillColor: `${getColourFromRegion(responseRegion ? responseRegion.region : '')}`,
                                                    fillColor: '#000000',
                                                    fillOpacity: 1,
                                                    strokeColor: '#63656b',
                                                    strokeOpacity: 1,
                                                    strokeWeight: 0.5,
                                                }}
                                            //onClick={(e) => this.onRegionClick(e, regionItem)}
                                            >
                                            </Polygon>)
                                    })}
                                    
                                    
                                </React.Fragment>
                            )
                        })
                        :
                        props.activeTab === 'retail_tab' && <StateMap
                                activeTab={props.activeTab}
                                kpiSelection={activeKpi} 
                                regionName={props.mapOf}
                                year={props.year}
                                statesJsonDb={statesJson}
                                >
                            </StateMap>
                }
            </GoogleMap>
                :
                <GoogleMap
                    className={`${styles.noCloseButton}`}
                    center={props.mapCenter}
                    zoom={props.mapZoom}
                    defaultOptions={{
                        styles: mapStyles,
                        mapTypeControl: false,
                        disableDoubleClickZoom: true,
                        zoomControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false
                    }}
                    ref={props.onMapMounted}
                    onClick={(e) => { console.debug('FROM MAP', e.latLng) }}

                >
                    <div className={`${styles.mapKpiSelectorContainer}`}>
                        <div onClick={() => onKpiTabChange('all')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'all' ? styles.active : ''}`}>ALL</div>
                        {/* <div onClick={() => this.onKpiTabChange('sales')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'sales' ? styles.active : ''}`}>SALES</div>
                        <div onClick={() => this.onKpiTabChange('revenue')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'revenue' ? styles.active : ''}`}>REVENUE</div>
                        <div onClick={() => this.onKpiTabChange('market share')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'market share' ? styles.active : ''}`}>MARKET SHARE</div> */}
                        {/* <div onClick={() => this.onKpiTabChange('hsse')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'hsse' ? styles.active : ''}`}>HSSE</div> */}
                        {/* <div onClick={() => this.onKpiTabChange("depot")} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === "depot" ? styles.active : ""}`}>DEPOT</div> */}
                    </div>
                    {/* <div className={`d-flex align-items-center justify-content-around p-2 ${styles.tabLabelsContainer}`}>
                        <div className="d-flex align-items-center"><span className={`mr-2 ${styles.poorDot}`}></span>North</div>
                        <div className="d-flex align-items-center"><span className={`mr-2 ${styles.fairDot}`}></span>East</div>
                        <div className="d-flex align-items-center"><span className={`mr-2 ${styles.goodDot}`}></span>West</div>
                        <div className="d-flex align-items-center"><span className={`mr-2 ${styles.excellentDot}`}></span>South</div>
                    </div> */}
                    {(activeKpi === 'depot') && <div className={`d-flex align-items-center justify-content-center p-2 ${styles.depotTabLabelContainer}`}>
                        <div className="d-flex align-items-center mr-3"><img src={depotBlackIcon} alt="Below Target" className="mr-2" />Below</div>
                        <div className="d-flex align-items-center ml-3"><img src={depotWhiteIcon} alt="Above Target" className="mr-2" />Above</div>
                    </div>}
                    {
                        (props.mapOf === 'all regions' && props.activeTab === 'retail_tab') &&
                        <div className={styles.heatmapLegend}><img className={styles.heatmapImg} src={heatmapLegend} />
                            <div className={styles.heatmapMax}>{(responseData[0].sales_actual).toFixed()} TKL</div>
                            {/* <div className={styles.heatmapMiddle}>{(responseData[0].sales_actual/2).toFixed()}</div> */}
                            <div className={styles.heatmapMin}>{(responseData[responseData.length - 1].sales_actual).toFixed()} TKL</div>
                        </div>}
                    <div className={`${activeKpi === 'depot' ? styles.mapTableContainerDepot : styles.mapTableContainer}`}>
                        <BuRetailMapTable year={props.year} mapOf={props.mapOf} activeKpi={activeKpi}></BuRetailMapTable>
                    </div>
                    {
                        (props.mapOf === 'all regions' && props.activeTab === 'retail_tab')
                            ?
                            responseStateData.regions.map((regionItem, index) => {
                                // let responseRegion = responseData.find((responseDataItem) => {
                                //     return responseDataItem.region === regionItem.name
                                // })
                                return (
                                    <React.Fragment key={index}>
                                        {activeKpi === 'depot' && <Marker position={{ lat: 18, lng: 80 }} icon={depotBlackIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 24.7, lng: 77.4 }} icon={depotWhiteIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 10.4, lng: 78.75 }} icon={depotBlackIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 20.0, lng: 84.5 }} icon={depotWhiteIcon}></Marker>}
                                        {activeKpi === 'depot' && <Marker position={{ lat: 19.9, lng: 74.7 }} icon={depotBlackIcon}></Marker>}
                                        {/* {responseRegion &&
                                            <CustomInfoBox
                                                kpiSelection={activeKpi}
                                                locationData={responseRegion}
                                                anchorPosition={regionItem.data.markers[0].coordinates.slice(-1)[0]}
                                                markerPixelOffset={{ x: 135, y: 120 }}
                                            ></CustomInfoBox>
                                        } */}
                                        {/* {responseStateData.map((map, index1)=>{ */}
                                         { regionItem.data.polygons.map((polygon, index) => {
                                            // if(polygon.name === map.state){
                                            return (
                                                <Polygon
                                                    key={index}
                                                    path={polygon.coordinates}
                                                    options={{
                                                        fillColor: `${getColourFromRegion(polygon.name, polygon.color)}`,
                                                        fillOpacity: 1,
                                                        strokeColor: '#ffffff',
                                                        strokeOpacity: 0.2,
                                                        strokeWeight: 1,
                                                    }}
                                                //onClick={(e) => this.onRegionClick(e, regionItem)}
                                                >
                                                </Polygon>)
                                            // }
                                            // })
                                        })}
                                        {/* <Polyline
                                            path={
                                                regionItem.data.markers[0].coordinates
                                            }
                                            options={{
                                                strokeColor: '#ffffff',
                                                strokeOpacity: 1,
                                                strokeWeight: 1,
                                            }}
                                        >
                                        </Polyline> */}
                                    </React.Fragment>
                                )
                            })
                            :
                            props.activeTab === 'retail_tab' && <StateMap
                                activeTab={props.activeTab}
                                kpiSelection={activeKpi} 
                                regionName={props.mapOf}
                                year={props.year}
                                statesJsonDb={responseStateData}
                                >
                            </StateMap>
                    }
                </GoogleMap>
        )
}

export default GoogleMapComponent
