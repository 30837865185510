import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuLpgCommercialDiscount.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuLpgCommercialDiscGraph from './BuLpgCommercialDiscGraph'
import { fetchLpgCommercialDiscount, fetchupdatedStatus} from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty, last } from 'lodash'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuLpgCommercialDiscount = (props) => {
    const [graphData, setGraphData] = useState([])
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [lastItem, setLastItem] = useState({})
    const [product, setProduct] = useState('all')
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "lpg" && res.kpi_name === "Commercial Discount" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        function setProductOnTab() {
            if (props.activeTab === 'all_tab') {
                setProduct('all')
            }
            if (props.activeTab === 'bulk_tab') {
                setProduct('bulk')
            }
            if (props.activeTab === 'commercial_tab') {
                setProduct('commercial')

            }
            if (props.activeTab === 'domestic_tab') {
                setProduct('domestic')
            }
        }

        function prepareGraphData(kpi_name) {
            fetchLpgCommercialDiscount(kpi_name, product, props.year)
                .then((response) => {
                    if (!isEmpty(response.data)) {
                        const total = {
                            actual: last(response.data).total,
                            growth: last(response.data).growth,
                            target: last(response.data).target
                        }
                        response.data.forEach((item, index) => {
                            if (item.kpi_name === 'commercial discounts') {
                                setGraphData(response.data)
                                setLastItem(total)
                                // setLastItem(response.data[response.data.length - 1])
                            }
                        })
                        setIsNoDataAvailable(false)

                    }
                    else {
                        setIsNoDataAvailable(true)
                    }
                })
                .catch((error) => console.debug(error))
        }
        prepareGraphData('commercial discounts')
        setProductOnTab()
    }, [product, props.activeTab, props.year])

    return (
        <div className={`${styles.salesRevenueContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>

                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col flex-grow-1'}>
                            <span>Commercial Discount - Daily</span>
                        </div>
                        {
                            isNoDataAvailable ||
                            <div className={`mt-2 mr-3 ${styles.headerRight}`}>
                                {/* <span>Total: 330 INR</span> */}
                                <span>Total: {lastItem.actual !== null ? Number(lastItem.actual).toFixed(2) + ' INR Cr' : 'No Data'}</span>
                                {/* <span className={'ml-4'} style={{ color: '#e13219' }}>
                    -10%
                                </span> */}
                                <span className={'ml-4'} style={{ color: `${Math.sign(lastItem !== undefined && lastItem.growth) === 1 ? '#74ce12' : '#e13219'}` }}>{lastItem.growth !== null && Number(lastItem.growth).toFixed(2) + '%'}</span>
                            </div>
                        }
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    </div>
                    <div className={'row pt-2'}>
                        <div
                            className={`col-8 d-flex align-items-center ${styles.headerLabels}`}
                        >
                            <div className="d-flex mr-2 align-items-center "><span className={`mr-2 ${styles.actualDot}`}></span>Actual</div>
                            {/* <div className={'d-flex mr-2 align-items-center'}>   --- Target:{lastItem.target !== null ? Number(lastItem.target).toFixed(2)  : 'No Data'}</div> */}
                        </div>
                    </div>
                </div>
                {isNoDataAvailable ? (
                    <NoDataFound height={507} />
                ) : (
                    <>

                        <div className={`row ${styles.salesRevenueGraph}`}>
                            {graphData ? (
                                <BuLpgCommercialDiscGraph
                                    graphData={graphData}
                                ></BuLpgCommercialDiscGraph>
                            ) : (
                                <NoDataFound />
                            )}
                        </div>
                        <div
                            className={`d-flex justify-content-end ${styles.expandIconContainer}`}
                        >
                            <img
                                className={`${styles.expandIcon}`}
                                src={expandIcon}
                                alt="Expand Icon"
                                onClick={fullScrn}
                            ></img>
                        </div>
                    </>
                )}
            </FullScreen>
        </div>
    )
}
export default BuLpgCommercialDiscount
