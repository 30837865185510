// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BuDepotInventoryChart_graph__1Ej4u {\n  position: relative;\n  width: 800px;\n  height: 400px;\n  padding-top: 35px; }\n", ""]);
// Exports
exports.locals = {
	"graph": "BuDepotInventoryChart_graph__1Ej4u"
};
module.exports = exports;
