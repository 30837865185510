import React, { useEffect, useState, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuLpgMarketShareGraph from './BuLpgMarketShareGraph'
import styles from './BuLpgMarketShare.module.scss'
import { fetchLpgMarketShare, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { isEmpty } from 'lodash'
import { Spinner } from 'react-bootstrap'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuMarketShare = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [graphData, setGraphData] = useState([])
    const [lastItem, setLastItem] = useState({})
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')    
    const [product, setProduct] = useState('all')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps



    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "lpg" && res.kpi_name === "Market share" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        const setProductOnTab = () => {
            if (props.activeTab === 'all_tab') {
                setProduct('all')
            }
            if (props.activeTab === 'bulk_tab') {
                setProduct('bulk')
            }
            if (props.activeTab === 'commercial_tab') {
                setProduct('commercial')

            }
            if (props.activeTab === 'domestic_tab') {
                setProduct('domestic')
            }
        }
        
        fetchLpgMarketShare(product, props.year)
            .then(response => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                } else {
                    setGraphData(response.data)
                    setLastItem(response.data[response.data.length - 1])
                    setIsNoDataAvailable(false)
                }
            })
            .catch(error => console.debug(error))
            setProductOnTab()
    }, [product, props.activeTab, props.year])

    return (
        <div className={`${styles.BuMarketShareContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>

                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col flex-grow-1'}>
                            <span>Market Share - Monthly</span>
                        </div>
                        {
                            isNoDataAvailable ||
                            <div className={`mt-2 mr-3 ${styles.headerRight} col-auto`} >
                                <span>Total: {lastItem.total !== null ? Number(lastItem.total).toFixed(2) + '%' : 'No Data'}</span>
                                {/* <span className={'ml-4'} style={{ color: `${Math.sign(lastItem !== undefined && lastItem.percentage_growth) === 1 ? '#74ce12' : '#e13219'}` }}>{lastItem.percentage_growth !== null && Number(lastItem.percentage_growth).toFixed(2) + '%'}</span> */}
                            </div>                            
                        }
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-8 d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.BpclDot}`}></span>BPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.HpclDot}`}></span>HPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.IoclDot}`}></span>IOCL</div>
                            {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.ShellDot}`}></span>SHELL</div> */}
                            {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.RilDot}`}></span>RIL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.NelDot}`}></span>NEL</div> */}
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.targetDot}`}></span></div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.targetDot}`}></span></div>
                        </div>
                        {/* <div className={`col-3 ${styles.headerLabelsTarget}`}>
                            <span>---- BPCL Target</span>
                        </div> */}
                    </div>
                </div>
                {
                    isNoDataAvailable ?
                        <NoDataFound height={507} />
                        :
                        (
                            <>
                                <div className={`d-flex  ${styles.graphContainer}`}>
                                    {
                                        graphData ?
                                            <BuLpgMarketShareGraph graphData={graphData}></BuLpgMarketShareGraph>
                                            :
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>

                                    }

                                    {/* <div className={`d-flex flex-column justify-content-center ${styles.graphFilters}`}>
                                <Checkbox
                                    size="sm"
                                    name="public"
                                    label="Public"
                                    checked={publicVar}
                                    onChange={(e) => onCheckStateChanged(e)}
                                ></Checkbox>
                                <Checkbox
                                    size="sm"
                                    name="private"
                                    label="Private"
                                    checked={privateVar}
                                    onChange={(e) => onCheckStateChanged(e)}
                                ></Checkbox>
                                <Checkbox
                                    size="sm"
                                    name="both"
                                    label="Both"
                                    checked={publicVar && privateVar}
                                    onChange={(e) => onCheckStateChanged(e)}
                                ></Checkbox>
                            </div> */}
                                    <div className={`${styles.MsHsdContainer}`}>
                                        {/* <div className={`${styles.HsdCircle}`}><span className={`${styles.Hsd}`}>{props.product.toLocaleUpperCase()}</span></div> */}
                                        {/* <div className={`${styles.MsCircle}`}><span className={`${styles.Ms}`}>MS</span></div> */}
                                    </div>
                                </div>
                                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' onClick={fullScrn}></img>
                                </div>
                            </>
                        )
                }

            </FullScreen>

        </div>
    )
}
export default BuMarketShare