import React from 'react'
import styles from './DeltaValuesOrg.module.scss'

const DeltaValuesOrghsse = (props) => {
    return (
        <div className={`d-flex flex-column ${styles.kpiOverallDetails}`}>
            <span className={`${styles.totalValues}`}>{props.total ? <>Total: <span className={`${styles.totVal}`}>{props.total.value}</span></> : 'No Data'}</span>
            {/* <div className={`d-flex ${styles.deltaValuesContainer}`}>

                <div className={`mr-4 ${styles.container}`}>
                    <span className={`${styles.title}`}>
                        <img className={`${styles.deltaIcon}`} src={deltaTriangle} alt="delta"></img>Q:
                    </span>
                    {
                        props.total ? Math.sign(props.total.quater) === -1 ? <span className={`${styles.value}`} style={{ color: '#ff5b4f' }}>-{props.total.quater}%</span> : <span className={`${styles.value}`} style={{ color: '#7df289' }}>+{props.total.quater}%</span> :  <span className={`${styles.value}`} style={{ color: '#7df289' }}></span>
                    }
                </div>

                <div className={`${styles.container}`}>
                    <span className={`${styles.title}`}>
                        <img className={`${styles.deltaIcon}`} src={deltaTriangle} alt="delta"></img>Y:
                    </span>
                    {
                        props.total ? Math.sign(props.total.year) === -1 ? <span className={`${styles.value}`} style={{ color: '#ff5b4f' }}>-{props.total.year}%</span> : <span className={`${styles.value}`} style={{ color: '#7df289' }}>+{props.total.year}%</span> :  <span className={`${styles.value}`} style={{ color: '#7df289' }}></span>
                    }
                </div>
            </div> */}
        </div>

    )
}
export default DeltaValuesOrghsse