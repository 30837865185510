import React, { useState, useEffect, useContext } from 'react'
import ApplicationContext from '../../ApplicationContext'
import styles from './Backdashboard.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserShield } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from 'react-paginate'
import { useHistory, Redirect } from 'react-router-dom'
import './Backdashboard.css'
import { getAllUsers, rejectUser, acceptUser, deleteUsers, addNewAdmin} from '../../services/auth'
const Backdashboard = () => {
    const context = useContext(ApplicationContext)
    const history = useHistory()
    const [userdetails, setuserdetails] = useState([])
    const [offset, setoffset] = useState(0)
    const [perpage] = useState(10)
    const [currentpage, setcurrentpage] = useState(0)
    const [pagecount, setpagecount] = useState(0)
    const [setuserSelected] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [aduserName, setAduserName] = useState('')
    const [aduser, setadUser] = useState('')
    const [aduserPass, setaduserPass] = useState('')
    const [error, setError] = useState('')
    const [showPopup, setshowPopup] = useState(0)
    const [addText, setaddText] = useState('Add Admin')
    
    const receivedData = async () => {
        getAllUsers().then(data => {
            if (data === 0) {
                return (<tr><td>No User Registered..</td></tr>)
            } else {
                var dividedata = data.slice(offset, offset + perpage)
                var displayUser = dividedata.map((getuser, index) => (
                    <tr key={getuser.id}>
                        <td><input type="checkbox" name={'userselect'} className={'userslect'} value={getuser.id} onChange={(e) => pushUser} /></td>
                        <td>{getuser.bpclusers}</td>
                        <td>{getuser.bpcluseremails}</td>
                        <td>{getuser.bpcluserdate}</td>
                        <td className={`${styles.alignright}`}>
                            {getuser.bpcluserstatus === 'New' ?
                                <div>
                                    <button className={`btn ${styles.acceptbtn}`} onClick={(e) => { userAcpt(e, getuser.id, getuser.bpcluseremails, getuser.bpclusers) }}>APPROVE</button>
                                    <button className={`btn ${styles.rejectbtn}`} onClick={(e) => { userRejct(e, getuser.id) }}>REJECT</button>
                                </div>
                                : (getuser.bpcluserstatus === 'approved' || getuser.bpcluserstatus === 'rejected') &&
                                <div>
                                    <button className={`btn ${getuser.bpcluserstatus === 'approved' ? styles.acceptedbtn : styles.rejectedbtn} `} disabled={true}>{getuser.bpcluserstatus === 'approved' ? 'APPROVED' : 'REJECTED'}</button>
                                </div>
                            }
                        </td>
                    </tr>
                ))
                setpagecount(Math.ceil(data.length / perpage))
                setuserdetails(displayUser)
            }
        })
    }
    const changeData = (e) => {
        const selectedPage = e.selected
        const newoffset = selectedPage * perpage
        setcurrentpage(selectedPage)
        setoffset(newoffset)
        receivedData()
    }
    function userRejct(e, id) {
        e.preventDefault()
        rejectUser(id).then(data => {
            if (data === 1) {
                receivedData()
            }
        }).catch(err => console.debug(err))
    }
    function userAcpt(e, id, acptemailid, acptuser) {
        e.preventDefault()
        acceptUser(id, acptemailid, acptuser).then(data => {
            if (data === 1) {
                receivedData()
            }
        }).catch(err => console.debug(err))
    }
    const pushUser = (e) => {
        setuserSelected({ [e.target.name]: e.target.value })
    }
    const deleteUsrs = async () => {
        if (window.confirm('Are you sure you want to delete this user')) {
            let getusers = document.getElementsByClassName('userslect')
            let userArray = []
            for (var i = 0; i < getusers.length; i++) {
                if (getusers[i].checked === true) {
                    userArray.push(getusers[i].value)
                }
            }
            deleteUsers(userArray).then(data => {
                if (data === 1) {
                    receivedData()
                }
            }).catch(err => console.debug(err))
        } else {
            console.debug('not deleted')
        }
    }
    const adminLogout = () => {
        context.setLoggedInUser(null)
        setTimeout(() => {
            history.push('/adminlogin')
        }, 1000)
    }
    // admin add section
    const handleChangeName = (e) => {
        setshowPopup(0)
        setAduserName(e.target.value)
    }
    const handleChangeEmail = (e) => {
        setshowPopup(0)
        setadUser(e.target.value)
    }
    const handleChangePass = (e) => {
        setshowPopup(0)
        setaduserPass(e.target.value)
    }
    const handleModal = () => {
        setIsOpen(false)
        setshowPopup(0)
    }
    const adminRegisterSubmit = async (e) => {
        e.preventDefault()
        setaddText('Adding..')
        let user_Pattern = /^[a-zA-Z]{3,20}$/
        let emailregex = /^[a-zA-Z0-9._-]+@bharatpetroleum.in$/
        let passwordCheck = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
        if (!aduser && !aduserPass && !aduserName) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('Name, e-mail id and password are required.')
        } else if (!aduser && !aduserPass) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('E-mail id and password are required.')
        } else if (!aduser && !aduserName) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('Name and e-mail id are required.')
        } else if (!aduserName && !aduserPass) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('Name and password are required.')
        } else if (!aduser) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('Enter your E-mail Id.')
        } else if (!aduserPass) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('Enter your Password.')
        } else if (!user_Pattern.test(aduserName)) {
            setaddText('Add Admin')
            setshowPopup(2)
            setError('Username should be : \n 1. alphabetical characters. \n 2. greater than 3 characters and lower than 20 characters')
        } else if (!passwordCheck.test(aduserPass)) {
            setshowPopup(2)
            setaddText('Add Admin')
            setError('Password must contain: \n 1. atleast 1 lowercase alphabetical character \n 2. atleast 1 uppercase alphabetical character \n 3. at least 1 numeric character \n 4. at least 1 special character \n 5 The password must be 8 characters or longer.')
        } else if (!aduserName) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('Enter your Name.')
        }  else if (!emailregex.test(aduser)) {
            setshowPopup(1)
            setaddText('Add Admin')
            setError('Invalid Credentials!')
        } else {
            addNewAdmin(aduserName, aduser, aduserPass).then(data => {
                if (data.success === 1) {
                    setaddText('Add Admin')
                    setError(data.message)
                } else {
                    setshowPopup(1)
                    setaddText('Add Admin')
                    setError(data.message)
                }
            })
        }
    }

    useEffect(() => {
        receivedData()
    }, [offset])// eslint-disable-next-line react-hooks/exhaustive-deps

    if (!context.loggedInUser || context.loggedInUser.role !== 'ADMIN') {
        return (
            <Redirect to="/adminlogin" />
        )
    }
    return (
        <div className={`container ${styles.BackDashSection}`}>
            <div className={'d-flex flex-wrap-wrap justify-content-center'}>
                <div className={`col-md-2 ${styles.pad0} `}>
                    <div className={`${styles.menusection}`}>
                        <div className={`${styles.menudiv}`}>
                            <img src="https://www.bharatpetroleum.com/images/bpcl_logo_1.jpg" alt="logo" />
                        </div>
                    </div>
                </div>
                <div className={`col-md-10 ${styles.pad0}`}>
                    <div className={`${styles.profilesection}`}>
                        <div className={`d-flex justify-content-space-between ${styles.justifybetween}`}>
                            <p className={`${styles.adminname}`}>Welcome, <span>{context.loggedInUser && context.loggedInUser.name}</span></p>
                            <h1><span><FontAwesomeIcon icon={faUserShield} /></span>Dashboard</h1>
                            <div className={`${styles.lgsection}`}>
                                <button className={`btn ${styles.addAdmin}`} onClick={() => setIsOpen(true)}>Add Admin</button>
                                <button className={`btn ${styles.logout}`} onClick={adminLogout}>Log out</button>
                            </div>
                        </div>
                        <div className={`${styles.profilediv}`}>
                            <h4>List of Users</h4>
                            <div className={`${styles.usertable}`}>
                                <table className={`table ${styles.userprofile}`}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Name</th>
                                            <th>E-mail</th>
                                            <th>Date of Request</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userdetails}
                                    </tbody>
                                </table>
                            </div>
                            <div className={`d-flex ${styles.userdeletesec}`}>
                                <button className={`btn ${styles.deluser}`} onClick={deleteUsrs}>Delete</button>
                                <ReactPaginate
                                    previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                                    nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pagecount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={changeData}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen === true && 
            <div className={`${styles.modalAdd}`}>
                <div className={`${styles.modaldialog}`}>
                <div className={`d-flex justify-content-between ${styles.modalheader}`}>
                    <h3>Fill up the form to add new admin</h3>
                    <FontAwesomeIcon icon={faTimesCircle} color={'#000'} size={'lg'} cursor={'pointer'} onClick={handleModal} />
                </div>
                <div className={`d-flex ${styles.modalBody}`}>
                    <div className={`col-9 ${styles.pad0}`}>
                    <form onSubmit={adminRegisterSubmit}>
                        <div className={`form-goup ${styles.admindiv}`}>
                            <input type="text" name="adname" className={`form-control`} placeholder="Name" onChange={(e) => handleChangeName(e)} />
                        </div>   
                        <div className={`form-goup ${styles.admindiv}`}>
                            <input type="email" className={'form-control'} name="ademail" placeholder="Email Id" onChange={(e) => handleChangeEmail(e)} />
                        </div> 
                        <div className={`form-goup ${styles.admindiv}`}>
                            <input type="password" className={'form-control'} name="adpassword" placeholder="Password" onChange={(e) => handleChangePass(e)} />
                        </div>   
                        <div className={styles.adminbtn}>
                            <button type="submit"> {addText} </button>
                        </div>
                        {(showPopup === 1 && error === 'Account Added Successfully!') ?
                            <div className={`${styles.popuperr}`} id="popuperr">
                                <p className={` ${styles.successText} `}>
                                    <span>Admin Added Successfully.</span>
                                </p>
                            </div>
                            : showPopup === 1 ?
                            <div className={`${styles.popuperrmsg}`} id="popuperr">
                                <p className={`${styles.errormsg}`}><FontAwesomeIcon icon={faExclamationCircle} /> {error}</p>
                            </div>
                            : showPopup === 2 &&
                            <div className={`${styles.popuppasserr}`}>
                                <p className={`${styles.errormsg}`}>{error}</p>
                            </div>
                        }
                    
                    </form>
                    </div>
                    <div className={`col-3 ${styles.pad0}`}>
                        <img src="https://www.bharatpetroleum.com/images/bpcl_logo_1.jpg" alt="logo" />
                    </div>
                </div>
                </div>
            </div>
            }
        </div>
    )
}
export default Backdashboard