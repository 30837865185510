// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfitLoss_profitLossContainer__2nVSA {\n  height: 810px;\n  font-family: Titillium Web; }\n  .ProfitLoss_profitLossContainer__2nVSA .ProfitLoss_profitLossHeader__1vtBf {\n    height: 84px;\n    background-color: #181e2a;\n    font-size: 28px;\n    font-weight: bold;\n    color: white;\n    padding-left: 24px;\n    padding-right: 24px; }\n\n.ProfitLoss_treasuryDataContainer__2-r1M {\n  height: 830px;\n  font-family: Titillium Web; }\n  .ProfitLoss_treasuryDataContainer__2-r1M .ProfitLoss_treasuryDataHeader__2Ddzf {\n    height: 84px;\n    background-color: #181e2a;\n    font-size: 28px;\n    font-weight: bold;\n    color: white;\n    padding-left: 24px;\n    padding-right: 24px; }\n\n.ProfitLoss_statusUpdatedImg__1ELb3 {\n  margin-left: 302px;\n  margin-top: -12px; }\n", ""]);
// Exports
exports.locals = {
	"profitLossContainer": "ProfitLoss_profitLossContainer__2nVSA",
	"profitLossHeader": "ProfitLoss_profitLossHeader__1vtBf",
	"treasuryDataContainer": "ProfitLoss_treasuryDataContainer__2-r1M",
	"treasuryDataHeader": "ProfitLoss_treasuryDataHeader__2Ddzf",
	"statusUpdatedImg": "ProfitLoss_statusUpdatedImg__1ELb3"
};
module.exports = exports;
