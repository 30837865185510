import React, { useState, useEffect } from 'react'
import styles from './BuGraphs.module.scss'
import BuProjectGraphNumber from './BuProjectGraphNumber/BuProjectGraphNumber'
import BuProjectGraphProgress from './BuProjectGraphProgress/BuProjectGraphProgress'
import BUProjectGraphDelay from './BuProjectGraphDelay/BuProjectGraphDelay'

// import BuMarketShare from './BuMarketShare/BuMarketShare'
// import BuOperatingCost from './BuOperatingCost/BuOperatingCost'
// import BuCcs from './BuCcs/BuCcs'
// import BuDepotInventory from './BuDepotInventory/BuDepotInventory'
// // import BuRetailProjects from '../BuProjectGraphs/BuProjects/BuRetailProjects'
// import BuHsse from './BuHsse/BuHsse'
// import BuRetailOutlet from './BuRetailOutlet/BuRetailOutlet'
// import BuEthanolBlending from './BuEthanolBlending/BuEthanolBlending'
// import { Dropdown } from 'react-bootstrap'
// import dropdownArrows from '../../assets/images/dropdownArrows.png'

const BuSections = (props) => {
    const [product, setProduct] = useState('ms')
    useEffect(() => {}, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuGraphsContainer}`}>
            <div className={`d-flex ${styles.BuGraphsRow1}`}>
                <BuProjectGraphNumber year={props.year} mapOf={props.mapOf} product={product}></BuProjectGraphNumber>
                {/* <BuProjectGraphProgress year={props.year} mapOf={props.mapOf} product={product}></BuProjectGraphProgress>
                <BUProjectGraphDelay year={props.year} mapOf={props.mapOf} product={product}></BUProjectGraphDelay> */}

            </div>
            <div className={`d-flex ${styles.BuGraphsRow2}`}>
            <BuProjectGraphProgress year={props.year} mapOf={props.mapOf} product={product}></BuProjectGraphProgress>
                {/* <BuRetailOutlet year={props.year}></BuRetailOutlet>
                <BuCcs year={props.year}></BuCcs>
                <BuDepotInventory activeTab={props.activeTab} year={props.year}></BuDepotInventory> */}
            </div>
            <div className={`d-flex ${styles.BuGraphsRow3}`}>
            <BUProjectGraphDelay year={props.year} mapOf={props.mapOf} product={product}></BUProjectGraphDelay>
                {/* <BuHsse year={props.year}></BuHsse>
                <BuEthanolBlending activeTab={props.activeTab} year={props.year} product={product}></BuEthanolBlending>
                <BuRetailProjects year={props.year} product={props.activeTab}></BuRetailProjects> */}
            </div>
        </div>
    )
}
export default BuSections