import React, { useEffect, useState, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuIncMarketShareGraph from './BuIncMarketShareGraph'
import styles from './BuIncMarketShare.module.scss'
import { Spinner } from 'react-bootstrap'
import { fetchBuIncCountry, fetchupdatedStatus } from '../../../services/bu'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { isEmpty } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuIncMarketShare = (props) => {
    const [graphData, setGraphData] = useState([])
    const [lastItem, setLastItem] = useState({})
    const [attr, setattr] = useState('handleEnter')
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }

    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "inc" && res.kpi_name === "Market share" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        const prepareGraphData = () => {
            fetchBuIncCountry(props.year)
                .then(data => {
                    if (data.success) {
                        setGraphData(data.data)
                        setLastItem(data.data[data.data.length - 1])
                    }
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData()
    }, [props.product, props.year])

    return (
        <div className={`${styles.BuMarketShareContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-auto flex-grow-1'}>
                            <span>Market Share - Monthly</span>
                        </div>
                        {
                            lastItem ?
                                <div className={`mt-2 mr-3 ${styles.headerRight}`} style={{ fontSize: '24px' }}>
                                    <span>Total: {lastItem && lastItem.total !== null ? Number(lastItem.total).toFixed(2) + '%' : 'No Data'}</span>
                                    {/* <span className={'ml-3'} style={{ fontSize: '24px', color: `${Math.sign(lastItem !== undefined && lastItem.percentage_growth) === 1 ? '#74ce12' : '#e13219'}` }}>{lastItem && lastItem.percentage_growth !== null && Number(lastItem.percentage_growth).toFixed(2) + '%'}</span> */}
                                </div>
                                : ''
                        }
                         {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-9 d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.BpclDot}`}></span>BPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.HpclDot}`}></span>HPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.IoclDot}`}></span>IOCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.RilDot}`}></span>RBML</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.NelDot}`}></span>NEL</div>
                            {/* <div className="d-flex mr-4 align-items-center "><span>BPCL Target</span></div> */}
                        </div>
                        {/* <div className={`col-3 ${styles.headerLabelsTarget}`}>
                        <span>---- BPCL Target</span>
                    </div> */}
                    </div>
                </div>
                <div className={`d-flex  ${styles.graphContainer}`}>
                    {
                        isEmpty(graphData) ?
                            <NoDataFound />
                            :
                            <BuIncMarketShareGraph graphData={graphData}></BuIncMarketShareGraph>
                    }
                </div>
                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                    <img className={`${styles.expandIcon}`} onClick={fullScrn} src={expandIcon} alt='Expand Icon'></img>
                </div>
            </FullScreen>
        </div>
    )
}
export default BuIncMarketShare