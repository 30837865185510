// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InfographicKpi2_cduContainer__1ZLvd {\n  font-family: Titillium Web;\n  position: absolute;\n  height: 50px;\n  min-width: 193px;\n  background-color: #323b4e;\n  border-radius: 4px;\n  font-size: 27px; }\n  .InfographicKpi2_cduContainer__1ZLvd .InfographicKpi2_cduLeft__1EGLw {\n    padding: 10px;\n    flex: 1 1;\n    height: 50px;\n    font-weight: bold;\n    color: #dee5ef;\n    border-right: 1px solid #283040; }\n  .InfographicKpi2_cduContainer__1ZLvd .InfographicKpi2_cduRight__B9zUF {\n    padding: 10px;\n    flex: 1 1;\n    height: 50px;\n    color: #06bee1; }\n", ""]);
// Exports
exports.locals = {
	"cduContainer": "InfographicKpi2_cduContainer__1ZLvd",
	"cduLeft": "InfographicKpi2_cduLeft__1EGLw",
	"cduRight": "InfographicKpi2_cduRight__B9zUF"
};
module.exports = exports;
