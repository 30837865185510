// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".stateMap_heatmapLegend__1MZGd {\n  position: relative;\n  bottom: 893px;\n  left: 412px; }\n\n.stateMap_heatmapImg__ERYRb {\n  height: 54px; }\n\n.stateMap_heatmapMin__Giuke {\n  margin-top: -38px;\n  margin-left: 18px;\n  font-size: 25px; }\n\n.stateMap_heatmapMax__-mJhC {\n  margin-left: 590px;\n  font-size: 25px; }\n\n.stateMap_heatmapMiddle__26h3A {\n  margin-top: -35px;\n  margin-left: 300px;\n  font-size: 25px; }\n", ""]);
// Exports
exports.locals = {
	"heatmapLegend": "stateMap_heatmapLegend__1MZGd",
	"heatmapImg": "stateMap_heatmapImg__ERYRb",
	"heatmapMin": "stateMap_heatmapMin__Giuke",
	"heatmapMax": "stateMap_heatmapMax__-mJhC",
	"heatmapMiddle": "stateMap_heatmapMiddle__26h3A"
};
module.exports = exports;
