import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const SalesKpiGraph = (props) => {

    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        let xTicks = ['Q1', 'Q2', 'Q3', 'Q4']
        
        if (chartInstance) {
            chartInstance.destroy()
        }
        let domesticdata = []
        let exportdata = []
        let totaldata = []
        let unit = []
        let maxvalue = []
        

        props.salesfdata.forEach(getgrmvalues => {
            if(getgrmvalues.parameter === 'domestic'){
                domesticdata.push(parseFloat(getgrmvalues.value).toFixed(2))
                maxvalue.push(getgrmvalues.value)
            }else if(getgrmvalues.parameter === 'export'){
                exportdata.push(parseFloat(getgrmvalues.value).toFixed(2))
                maxvalue.push(getgrmvalues.value)
            }else if(getgrmvalues.parameter === 'totalsales'){
                totaldata.push(parseFloat(getgrmvalues.value).toFixed(0))
                unit.push(getgrmvalues.unit)
                maxvalue.push(getgrmvalues.value)
            }
        })

        const ctx = chartContainer.current.getContext('2d')
        let gradient = ctx.createLinearGradient(0, 0, 0, 450)
        gradient.addColorStop(1, 'rgba(0, 231, 255,0.4)')
        gradient.addColorStop(0, 'rgba(36, 169, 217,0.4)')

        const chartConfig = {
            type: 'bar',
            data: {
                labels: xTicks,
                datasets: [
                    {
                        label: 'Domestic',
                        data: domesticdata.reverse(),
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: '#04caa6',
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                            anchor: 'center',
                            align: 'center',
                            color: '#fff',
                            font: {
                                size: 14
                            }
                        },
                        order : 2
                    },
                    {
                        label: 'Export',
                        data: exportdata.reverse(),
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: '#1b55c0',
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                            anchor: 'center',
                            align: 'center',
                            color: '#fff',
                            font: {
                                size: 14
                            }
                        },
                        order : 2
                    },
                    {
                        label: 'Total',
                        data : totaldata.reverse(),
                        pointBorderWidth: 2,
                        pointBackgroundColor: gradient,
                        pointBorderColor: gradient,
                        borderColor: gradient,
                        backgroundColor: 'transparent',
                        borderWidth: 2,
                        fill : false,
                        borderDash : [2],
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            color: '#fff',
                            font: {
                                size: 13
                            }
                        },
                        type : 'line',
                        order : 1
                    },
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                annotation: {
                },
                elements : {
                    line : {
                        tension : 0.4
                    }
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                                lineWidth: 3,
                                zeroLineBorderDash: [3]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 5
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                                lineWidth: 3,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: unit[0],
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [props.graphData, props.publicVar, props.privateVar, props.bothVar, props.salesfdata]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <canvas
            ref={chartContainer}
        />
    )
}
export default SalesKpiGraph