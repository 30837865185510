// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA {\n  border: none;\n  border-radius: 0px;\n  background-color: rgba(35, 43, 56, 0); }\n  .OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA .OrgListAccordian_CollapsedListItemContainer__1QW16 {\n    height: 73px;\n    background-color: #232b38;\n    font-size: 30px;\n    border-bottom: 2px solid #35425c; }\n    .OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA .OrgListAccordian_CollapsedListItemContainer__1QW16 .OrgListAccordian_kpiTitle__zlPPq {\n      color: #dee5ef;\n      width: 40%;\n      text-transform: capitalize; }\n    .OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA .OrgListAccordian_CollapsedListItemContainer__1QW16 .OrgListAccordian_kpiValue__Zeyoj {\n      color: #dee5ef;\n      font-weight: bold;\n      width: 30%; }\n    .OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA .OrgListAccordian_CollapsedListItemContainer__1QW16 .OrgListAccordian_kpiValueCredit__39yTz {\n      color: #dee5ef;\n      font-weight: bold;\n      width: 60%;\n      text-align: left;\n      font-size: 25px; }\n    .OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA .OrgListAccordian_CollapsedListItemContainer__1QW16 .OrgListAccordian_deltaComponent__3DKXq {\n      width: 30%; }\n    .OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA .OrgListAccordian_CollapsedListItemContainer__1QW16 .OrgListAccordian_expandButton__3m8nb {\n      color: #67758d;\n      width: 5%; }\n  .OrgListAccordian_listContainer__3iNEL .OrgListAccordian_cardContainer__2r_ZA .OrgListAccordian_ExpandedListItemContainer__QukMo {\n    background-color: #232b38;\n    margin-bottom: 2px; }\n", ""]);
// Exports
exports.locals = {
	"listContainer": "OrgListAccordian_listContainer__3iNEL",
	"cardContainer": "OrgListAccordian_cardContainer__2r_ZA",
	"CollapsedListItemContainer": "OrgListAccordian_CollapsedListItemContainer__1QW16",
	"kpiTitle": "OrgListAccordian_kpiTitle__zlPPq",
	"kpiValue": "OrgListAccordian_kpiValue__Zeyoj",
	"kpiValueCredit": "OrgListAccordian_kpiValueCredit__39yTz",
	"deltaComponent": "OrgListAccordian_deltaComponent__3DKXq",
	"expandButton": "OrgListAccordian_expandButton__3m8nb",
	"ExpandedListItemContainer": "OrgListAccordian_ExpandedListItemContainer__QukMo"
};
module.exports = exports;
