// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RefinaryEnergyIndex_EnergyIndexContainer__2jTS6 {\n  border: 0.5px #a7a7a7 solid;\n  border-bottom: none;\n  font-family: Titillium Web;\n  width: 644px;\n  height: 653px;\n  background-color: #141923;\n  color: #dee5ef;\n  position: relative;\n  border-left: none; }\n  .RefinaryEnergyIndex_EnergyIndexContainer__2jTS6 .RefinaryEnergyIndex_graphTitle__3ukYY {\n    height: 88px;\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    background-color: black;\n    font-size: 35px;\n    padding-left: 15px;\n    font-weight: bold; }\n  .RefinaryEnergyIndex_EnergyIndexContainer__2jTS6 .RefinaryEnergyIndex_refineryEnergyIndexFooter__5yw-n {\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    height: 64px;\n    background-color: #283040; }\n    .RefinaryEnergyIndex_EnergyIndexContainer__2jTS6 .RefinaryEnergyIndex_refineryEnergyIndexFooter__5yw-n .RefinaryEnergyIndex_footerLeft__V456x {\n      margin-left: 36px;\n      color: #ffffff;\n      font-size: 22px;\n      text-decoration: underline; }\n    .RefinaryEnergyIndex_EnergyIndexContainer__2jTS6 .RefinaryEnergyIndex_refineryEnergyIndexFooter__5yw-n .RefinaryEnergyIndex_footerRight__3WDj0 {\n      margin-right: 36px;\n      font-size: 22px;\n      font-style: italic; }\n  .RefinaryEnergyIndex_EnergyIndexContainer__2jTS6 .RefinaryEnergyIndex_statusUpdatedImg__6d1jh {\n    margin-left: 12px;\n    margin-top: -21px; }\n", ""]);
// Exports
exports.locals = {
	"EnergyIndexContainer": "RefinaryEnergyIndex_EnergyIndexContainer__2jTS6",
	"graphTitle": "RefinaryEnergyIndex_graphTitle__3ukYY",
	"refineryEnergyIndexFooter": "RefinaryEnergyIndex_refineryEnergyIndexFooter__5yw-n",
	"footerLeft": "RefinaryEnergyIndex_footerLeft__V456x",
	"footerRight": "RefinaryEnergyIndex_footerRight__3WDj0",
	"statusUpdatedImg": "RefinaryEnergyIndex_statusUpdatedImg__6d1jh"
};
module.exports = exports;
