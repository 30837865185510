// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LubesOthersPieChart_graph__3IiCJ {\n  position: relative;\n  width: 450px;\n  height: 450px;\n  padding-top: 35px; }\n", ""]);
// Exports
exports.locals = {
	"graph": "LubesOthersPieChart_graph__3IiCJ"
};
module.exports = exports;
