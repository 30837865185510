import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './InternationalBond.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function InternationalBondGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    //const xLabels = ["0", "5", "10", "15", "20", "25", "30"]
    //let avgValue = 0;

    // function prepareGraphData(apiGraphData) {
    //     let count = apiGraphData.length;
    //     let total = 0;
    //     apiGraphData.map((item, index) => {
    //         total += item;
    //     })
    //     avgValue = total / count;
    // }

    useEffect(() => {
        // prepareGraphData(props.oceanLossData);
        if (chartInstance) {
            chartInstance.destroy()
        }

        // let fvalue = props.graphData.map(getbs => getbs.sub_kpi_type === 'international' && getbs.value1);
        // let svalue = props.graphData.map(getbs => getbs.sub_kpi_type === 'international' && getbs.value2);
        // let tvalue = props.graphData.map(getbs => getbs.sub_kpi_type === 'international' && getbs.value3); 
        let bondone = []
        let prevyear = props.year
        let prevlabel = `FY ${prevyear.toString().slice(2)}`
        props.graphData.map(getbsdata => {
            if(getbsdata.sub_kpi_type === 'domestic'){
                bondone.push(parseFloat(getbsdata.value).toFixed(2))
            }
        })
        const ctx = chartContainer.current.getContext('2d')

        // let avgColor = "";
        // //SET COLORS ACCORDING TO LOCATION
        // if (props.location === "mumbai") {
        //     avgColor = "#06bee1";
        // }
        // if (props.location === "kochi") {
        //     avgColor = "#b76df1";
        // }

        const chartConfig = {
            type: 'line',
            data: {
                labels: [prevlabel,'Q1','Q2',' Q3','Q4'],
                datasets: [
                    {
                        borderColor: '#04caa6',
                        pointBorderWidth: 4,
                        pointBackgroundColor: '#04caa6',
                        pointBorderColor: '#04caa6',
                        borderWidth: 4,
                        label: 'Domestic Loan',
                        data: bondone.length > 0 ? bondone : [],
                        fill: false,
                        // backgroundColor: "rgb(222, 172, 245)",
                        datalabels: {
                            display: false,
                        },
                    },
                    // {
                    //     borderColor: '#2281e8',
                    //     pointBorderWidth: 4,
                    //     pointBackgroundColor: '#2281e8',
                    //     pointBorderColor: '#2281e8',
                    //     borderWidth: 4,
                    //     label: 'Bond2',
                    //     data: bondtwo.length > 0 ? bondtwo : [],
                    //     fill: false,
                    //     // backgroundColor: "rgb(222, 172, 245)",
                    //     datalabels: {
                    //         display: false,
                    //     },
                    // },
                    // {
                    //     borderColor: '#ffbf45',
                    //     pointBorderWidth: 4,
                    //     pointBackgroundColor: '#ffbf45',
                    //     pointBorderColor: '#ffbf45',
                    //     borderWidth: 4,
                    //     label: 'Bond3',
                    //     data: bondthree.length > 0 ? bondthree : [],
                    //     fill: false,
                    //     // backgroundColor: "rgb(222, 172, 245)",
                    //     datalabels: {
                    //         display: false,
                    //     },
                    // },
                ]
            },
            options: {
                // barRoundness: 1,
                responsive: true,
                maintainAspectRatio: false,
                elements : {
                    line : {
                        tension : 0.4
                    }
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                color: '#67758d',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                zeroLineWidth : 1,
                                zeroLineColor : '#67758d',
                                borderDash : [3]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            beginAtZero: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Cr',
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                // min : 0,
                                // max : Math.round(Math.max(...bondone))+1,
                                // stepSize : Math.round(Math.max(...bondone)/4)
                                maxTicksLimit : 4
                            },
                            gridLines: {
                                color: 'transparent',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}
export default InternationalBondGraph