// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LandedCostGraph_grmGraph__t26rK {\n  position: relative;\n  width: 756px;\n  height: 250px;\n  margin-left: 64px;\n  margin-top: 32px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "LandedCostGraph_grmGraph__t26rK"
};
module.exports = exports;
