import styles from './BuProjects.module.scss'
import React from 'react'
import BuExpenditurePlannedFYChart from './BuExpenditurePlannedFYChart'
import BuProjectsExpenditureQWiseChart from './BuProjectsExpenditureQWiseChart'
import expandIcon from '../../../assets/images/expandIcon.png'

const BuProjectExpenditureTab = ({ graphData, fullScrn }) => (
    <div className={`d-flex flex-column justify-content-between ${styles.graphContainer}`}>
        <div className='row justify-content-center'>
            <BuProjectsExpenditureQWiseChart graphData={graphData} />
        </div>
        <div className={styles.hr} style={{ marginBottom: 22, marginTop: 10}}></div>
        <div className='row justify-content-center'>
            <BuExpenditurePlannedFYChart graphData={graphData} />
        </div>
        <div className='d-flex justify-content-end'
            style={{
                bottom: 70,
                position: 'relative',
                left: 32
            }}
        >
            <img src={expandIcon} alt='Expand Icon' id='fullBup' data-fs='handleenter' onClick={fullScrn}></img>
        </div>
    </div>
)
export default BuProjectExpenditureTab