import React, { useState, useEffect, useCallback } from 'react'
import styles from './TotalCapex.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import OrgListFT from '../FinanceTreasurey/OrgListFT'
import { fetchTreasuryBorrowing, fetchTresuryCRmf, fetchTreasuryroce, fetchTresuryCRCrisil, fetchupdatedStatus } from '../../../services/Org'
import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
import "../../../Common.scss"

const TotalCapex = (props) => {
    const [borrow, setborrow] = useState([])
    const [crdcsl, setcrdcsl] = useState([])
    const [roce, setroce] = useState([])
    const [crd, setcrd] = useState([])
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);
    const getCurrentQuarter = (borrow,crdcsl,roce,crd) => {
        try {
            let getdate = [borrow.date, crdcsl.date, roce.date, crd.date]
            let getlastesdate = getdate.reduce((a,b) => {return a > b ? a : b }, 0)
            let lastQuarter = new Date(getlastesdate).getMonth() + 1
            let quarterData
                switch (lastQuarter) {
                    case 6:
                        quarterData = 1
                        break
                    case 9:
                        quarterData = 2
                        break
                    case 12:
                        quarterData = 3
                        break
                    case 3:
                        quarterData = 4
                        break
                    default:
                        quarterData = 0
                        break
                }
                return quarterData
        } catch (error) {
            console.debug(error)
        }
    }
    useEffect(() => {
        fetchupdatedStatus(props.year)
            .then(response => {
                if (!isEmpty(response)) {
                    let borrowingData = response.findLast((res) => res.kpi_name === "borrowing");
                    let roceData = response.findLast((res) => res.kpi_name === "roce")
                    let crmoodyData = response.findLast((res) => res.kpi_name === "crmoody")
                    let crcrisilData = response.findLast((res) => res.kpi_name === "crcrisil")
                    if (borrowingData.status && roceData.status && crmoodyData.status && crcrisilData.status === "Updated") {
                        setUpdatedStatus("Updated")
                    } else {
                        setUpdatedStatus("Not Updated")
                    }
                } else {
                    setUpdatedStatus("Not Found")
                }
            })
            .catch(error => console.debug(error))
        function getTList() {
            let borrowarray = []
            let crcslarray = []
            let rocearray = []
            let crdarray = []
            fetchTreasuryBorrowing(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length > 0) {
                    data.map(getbsval => {
                        borrowarray.push({
                            value: getbsval.value,
                            quater: getbsval.quaterly,
                            year: getbsval.yearly,
                            date: getbsval.date
                        })
                        setborrow(borrowarray[0])
                    })
                } else {
                    setborrow({
                        value: 0,
                        quater: 0,
                        year: 0
                    })
                }
            }).catch(error => console.debug(error))
            fetchTreasuryroce(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length > 0) {
                    data.map(getbsval => {
                        rocearray.push({
                            value: getbsval.value,
                            quater: getbsval.quaterly,
                            year: getbsval.yearly,
                            date: getbsval.date
                        })
                        setroce(rocearray[0])
                    })
                } else {
                    setroce({
                        value: 0,
                        quater: 0,
                        year: 0
                    })
                }
            }).catch(error => console.debug(error))
            fetchTresuryCRmf(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length > 0) {
                    data.map(getbsval => {
                        crdarray.push({
                            value: getbsval.ratings,
                            date: getbsval.date
                        })
                        setcrd(crdarray[0])
                    })
                } else {
                    setcrd({
                        value: 'NA'
                    })
                }
            }).catch(error => console.debug(error))
            fetchTresuryCRCrisil(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length > 0) {
                    data.map(getbsval => {
                        crcslarray.push({
                            value: getbsval.ratings,
                            date: getbsval.date
                        })
                        setcrdcsl(crcslarray[0])
                    })
                } else {
                    setcrdcsl({
                        value: 'NA'
                    })
                }
            }).catch(error => console.debug(error))
        }
        getTList()
    }, [props.dropdown, props.year])
    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.totalCapexContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.totalCapexHeader}`}>
                    <span>Treasury Data (Quarter {getCurrentQuarter(borrow,crdcsl,roce,crd)}) - Quarterly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand Total Capex" id="fullSrntc" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                <OrgListFT borrow={borrow} crdcsl={crdcsl} roce={roce} crd={crd}></OrgListFT>
            </div>
        </FullScreen>
    )
}
export default TotalCapex