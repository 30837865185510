import React, { useState, useEffect } from 'react'
import styles from './BuIncMapTable.module.scss'
import environment from '../../../environment'
import { fetchIncInventoryMapTable } from '../../../services/bu'
import { numberFormatEnIn } from '../../../utility/utility'

const BuRetailMapTable = (props) => {
    const [allData, setAllData] = useState([])
    const [salesRevData, setSalesRevData] = useState([])
    const [hsseData, setHsseData] = useState([])
    const [mktData, setMktData] = useState([])
    const [northData, setnorthData] = useState([])
    const [eastData, seteastData] = useState([])
    const [westData, setwestData] = useState([])
    const [southData, setsouthData] = useState([])

    const getData = () => {
        setAllData([])
        setSalesRevData([])
        setHsseData([])
        setMktData([])
        setnorthData([])
        seteastData([])
        setwestData([])
        setsouthData([])
        let level = ''
        let appendRegion = ''
        if (props.mapOf.toLocaleLowerCase() === 'north' || props.mapOf.toLocaleLowerCase() === 'south' || props.mapOf.toLocaleLowerCase() === 'east' || props.mapOf.toLocaleLowerCase() === 'west') {
            level = 'region'
            appendRegion = `&region=${props.mapOf}`
        } else {
            level = 'region'
            appendRegion = ''
        }

        fetchIncInventoryMapTable(props.activeKpi, level, appendRegion, props.year)
            .then(response => {
                if (props.activeKpi === 'all') {
                    let east_array = []
                    let west_array = []
                    let south_array = []
                    let north_array = []
                    response.data.forEach(getRegion => {
                        if (getRegion.region === 'east') {
                            east_array.push(getRegion)
                            seteastData(east_array[east_array.length - 1])
                        } else if (getRegion.region === 'west') {
                            west_array.push(getRegion)
                            setwestData(west_array[west_array.length - 1])
                        } else if (getRegion.region === 'north') {
                            north_array.push(getRegion)
                            setnorthData(north_array[north_array.length - 1])
                        } else if (getRegion.region === 'south') {
                            south_array.push(getRegion)
                            setsouthData(south_array[south_array.length - 1])
                        }
                    })
                    setAllData(response.data)
                }
                // else if ((props.activeKpi === 'sales') || (props.activeKpi === 'revenue')) {
                //     setSalesRevData(response.data)
                // }
                // else if (props.activeKpi === 'market share') {
                //     console.debug(response.data)
                //     setMktData(response.data)
                // }
                // else if (props.activeKpi === 'hsse') {
                //     setHsseData(response.data)
                // }
            })
            .catch(error => console.debug(error))
    }

    useEffect(() => {
        getData()
    }, [props.activeKpi, props.mapOf, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${props.mapOf === 'all regions' ? `d-flex flex-column ${styles.BuRetailMapTableContainer}` : `d-flex flex-column ${styles.BuRetailMapTableContainerTable}`}`}>
            <div className={`d-flex flex-column ${styles.tableHeaderRowContainer}`}>
                <div className={`${styles.tableHeaderRow}`}>
                    <div className={`${styles.headerCell}`}>Region</div>
                    <div className={`${styles.headerCell}`}>Sales (TMT)</div>
                    <div className={`${styles.headerCell}`}>Revenue (Rs Cr)</div>
                    <div className={`${styles.headerCell}`}>Contribution (%)</div>
                </div>
                {/* /* <div className={`${styles.headerCell}`}>
              Customer Group
              </div>
              <div className={`${styles.headerCell}`}>
              Sales (TMT)
              </div>
              <div className={`${styles.headerCell}`}>
              Revenue (INR Cr)
              </div>
          </div> */}



                {
                    salesRevData.length > 0 &&
                    <div className={`${styles.tableHeaderRow}`}>
                        <div className={`${styles.headerCell}`}>COM</div>
                        <div className={`${styles.headerCell}`}>BPCL</div>
                        <div className={`${styles.headerCell}`}>IOCL</div>
                        <div className={`${styles.headerCell}`}>HPCL</div>
                        <div className={`${styles.headerCell}`}>SHELL</div>
                        <div className={`${styles.headerCell}`}>RIL</div>
                        <div className={`${styles.headerCell}`}>NEL</div>
                    </div>
                }
                {mktData.length > 0 &&
                    <div className={`${styles.tableHeaderRow}`}>
                        <div className={`${styles.headerCell} `}>Customer Group</div>
                        <div className={`${styles.headerCell}`}>BPCL</div>
                        <div className={`${styles.headerCell}`}>IOCL</div>
                        <div className={`${styles.headerCell}`}>HPCL</div>
                        <div className={`${styles.headerCell}`}>SHELL</div>
                        <div className={`${styles.headerCell}`}>RIL</div>
                    </div>
                }

                {hsseData.length > 0 &&
                    <div className={`${styles.tableHeaderRow}`}>
                        <div className={`${styles.headerCell} `}>Plant Name</div>
                        <div className={`${styles.headerCell}`}>Lost Man Hours</div>
                        <div className={`${styles.headerCell}`}>Incidents Reported</div>
                        <div className={`${styles.headerCell}`}>Accident Free Hours (M)</div>
                        <div className={`${styles.headerCell}`}>CPA</div>
                    </div>
                }
                <div className={` d-flex flex-column ${styles.tableDataRowContainer}`}>
                {(props.mapOf === 'north' || props.mapOf === 'all regions') && (
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>North</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{northData.sales_actual ? numberFormatEnIn(Number(northData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{northData.revenue_actual ? numberFormatEnIn(Number(northData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{northData.bpcl_mkt_share_actual ? numberFormatEnIn(Number(northData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                )}
                {(props.mapOf === 'east' || props.mapOf === 'all regions') && (
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>East</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{eastData.sales_actual && eastData.sales_actual !== null ? numberFormatEnIn(Number(eastData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{eastData.revenue_actual && eastData.revenue_actual !== null ? numberFormatEnIn(Number(eastData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{eastData.bpcl_mkt_share_actual && eastData.bpcl_mkt_share_actual !== null ? numberFormatEnIn(Number(eastData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                    )}
                    {(props.mapOf === 'west' || props.mapOf === 'all regions') && (
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>West</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{westData.sales_actual && westData.sales_actual !== null ? numberFormatEnIn(Number(westData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{westData.revenue_actual && westData.revenue_actual !== null ? numberFormatEnIn(Number(westData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{westData.bpcl_mkt_share_actual && westData.bpcl_mkt_share_actual !== null ? numberFormatEnIn(Number(westData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                    )}
                    {(props.mapOf === 'south' || props.mapOf === 'all regions') && (
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>South</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{southData.sales_actual && southData.sales_actual !== null ? numberFormatEnIn(Number(southData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{southData.revenue_actual && southData.revenue_actual !== null ? numberFormatEnIn(Number(southData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{southData.bpcl_mkt_share_actual && southData.bpcl_mkt_share_actual !== null ? numberFormatEnIn(Number(southData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                    )}
                    {/* {
                        allData &&
            allData.map((el, index) => 
                <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.region}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.sales_actual !== null && numberFormatEnIn(Number(el.sales_actual).toFixed(2))}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.revenue_actual !== null && numberFormatEnIn(Number(el.revenue_actual).toFixed(2))}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.bpcl_mkt_share_actual !== null && numberFormatEnIn(Number(el.bpcl_mkt_share_actual).toFixed(2))}</span>
                    </div>
                    <div className={`${styles.dataCell}`}><span>{el.customer_group}</span></div>
                    <div className={`${styles.dataCell}`}><span>{el.sales_actual}</span></div>
                    <div className={`${styles.dataCell}`}><span>{el.revenue_actual}</span></div>
                </div>
            )
                    } */}


                    {
                        salesRevData &&
                        salesRevData.map((el, index) =>
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `} >
                                    <span>{el.com}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.bpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}  >
                                    <span>{el.iocl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.hpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.shell}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.ril}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span>{el.nel}</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        mktData &&
                        mktData.map((el, index) =>
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `} >
                                    <span>{el.customer_group}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.bpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}  >
                                    <span>{el.iocl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.hpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.shell}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.ril}</span>
                                </div>
                            </div>
                        )
                    }

                    {
                        hsseData &&
                        hsseData.map((el, index) =>
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `} >
                                    <span>{el.plant_name}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.lost_man_hours}</span>
                                </div>
                                <div className={`${styles.dataCell}`}  >
                                    <span>{el.incidents}</span>
                                </div>
                                <div className={`${styles.dataCell}`}  >
                                    <span>{el.accident_free_hrs}</span>
                                </div>
                                <div className={`${styles.dataCell}`}  >
                                    <span>{el.cpa}</span>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}
export default BuRetailMapTable