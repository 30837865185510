import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuIncSalesRevenue.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import SalesRevenueGraph from '../../../charts/SalesRevenueGraph/SalesRevenueGraph'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchIncSalesRevenue, fetchupdatedStatus } from '../../../services/bu'
import { numberFormatEnIn, numberFormatNoSeparator } from '../../../utility/utility'
import { isEmpty, last } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuIncSalesRevenue = (props) => {
    const [salesGraphData, setSalesGraphData] = useState(null)
    const [revenueGraphData, setRevenueGraphData] = useState(null)
    const [totalSales, setTotalSales] = useState({})
    const [totalRevenue, setTotalRevenue] = useState({})

    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [attr, setattr] = useState('handleEnter')
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }

    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "inc" && res.kpi_name === "Sales" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        const prepareGraphData = (kpi_name) => {
            fetchIncSalesRevenue(kpi_name, props.year)
                .then(response => {
                    if (!isEmpty(response.data)) {
                        const total = {
                            actual: last(response.data).total,
                            growth: last(response.data).growth,
                        }
                        if (kpi_name === 'sales') {
                            setSalesGraphData(response.data)
                            setTotalSales(total)
                        } else if (kpi_name === 'revenue') {
                            setRevenueGraphData(response.data)
                            setTotalRevenue(total)
                        }
                        setIsNoDataAvailable(false)
                    } else {
                        setIsNoDataAvailable(true)
                    }

                    // data.data.forEach((item, index) => {
                    //     if (item.kpi_name === 'sales') {
                    //         setSalesGraphData(data.data)
                    //         setSalesLastItem(data.data[data.data.length - 1])
                    //     }
                    //     else if (item.kpi_name === 'revenue') {
                    //         setRevenueGraphData(data.data)
                    //         setRevenueLastItem(data.data[data.data.length - 1])
                    //     }
                    // })
                    // }
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData('sales')
        prepareGraphData('revenue')
    }, [props.year])

    return (
        <div className={`${styles.salesRevenueContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <span>Sales & Revenue - Daily</span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                    </div>
                    <div className={'row pt-2'}>
                        {/* <div className={`col-8 d-flex align-items-center ${styles.headerLabels}`}>
                        <div className="d-flex mr-2 align-items-center "><span className={`mr-2 ${styles.actualDot}`}></span>Actual</div>
                        <div className="d-flex mr-2 align-items-center "><span className={`mr-2 ${styles.targetDot}`}></span>Target</div>
                    </div> */}

                        <div className={`col-6 ${styles.headerRight}`}>
                            {
                                isNoDataAvailable ||
                                <>
                                    <span>Sales: {numberFormatEnIn(totalSales.actual || 0)} TMT</span>
                                    {/* <span className={'ml-4'} style={{ color: `${Math.sign(totalSales.growth ? totalSales.growth : '') === 1 ? '#74ce12' : '#e13219'}` }}>{numberFormatNoSeparator(totalSales.growth)}%</span> */}
                                </>
                            }
                        </div>
                        <div className={`col-6 ${styles.headerLabelsTarget}`}>
                            {
                                isNoDataAvailable ||
                                <>
                                    <span>Revenue: {numberFormatEnIn(totalRevenue.actual || 0)} INR Cr</span>
                                    {/* <span className={'ml-4'} style={{ color: `${Math.sign(totalRevenue.growth) === 1 ? '#74ce12' : '#e13219'}` }} >{numberFormatNoSeparator(totalRevenue.growth)}%</span> */}
                                </>
                            }

                        </div>
                    </div>
                </div>
                {
                    isNoDataAvailable ?
                        <NoDataFound height={507} />
                        : (
                            <React.Fragment>
                                <div className={`row ${styles.salesRevenueGraphHeader}`}>
                                    <div className={'col-5 p-2'}>
                                        <div className='row justify-content-md-center'>
                                            <div className='d-flex mr-2 align-items-center'>Sales:</div>
                                            <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.salesActualDot}`}></span>Actual</div>
                                            {/* <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.salesTargetDot}`}></span>Target</div> */}
                                        </div>
                                    </div>
                                    <div style={{ width: 20 }}></div> {/* Spacer */}
                                    <div className={'col-6 p-2'}>
                                        <div className='row justify-content-md-center'>
                                            <div className='d-flex mr-2 align-items-center'>Revenue:</div>
                                            <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.revenueActualDot}`}></span>Actual</div>
                                            {/* <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.revenueTargetDot}`}></span>Target</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className={`row ${styles.salesRevenueGraph}`}>
                                    <div className={`col-5 p-0 ${styles.leftDiv}`}>
                                        {
                                            salesGraphData ?
                                                <SalesRevenueGraph graphData={salesGraphData}></SalesRevenueGraph>
                                                :
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                        }
                                    </div>
                                    <div className={`col-2 pl-0 pr-1 ${styles.centerDiv}`}>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Apr  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>May  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Jun  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Jul  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Aug  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Sep  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Oct  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Nov  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Dec  {props.year.toString().slice(-2)}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Jan  {(parseInt(props.year.toString().slice(-2), 10) + 1).toString()}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Feb  {(parseInt(props.year.toString().slice(-2), 10) + 1).toString()}</div>
                                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Mar  {(parseInt(props.year.toString().slice(-2), 10) + 1).toString()}</div>
                                    </div>
                                    <div className={`col-5 p-0 ${styles.rightDiv}`}>
                                        {
                                            revenueGraphData ?
                                                <SalesRevenueGraph graphData={revenueGraphData}></SalesRevenueGraph>
                                                :
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                        }
                                    </div>
                                </div>
                                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' id='fullSrv' data-fs='handleenter' onClick={fullScrn}></img>
                                </div>
                            </React.Fragment>
                        )
                }
            </FullScreen>
        </div>
    )
}
export default BuIncSalesRevenue