import React from 'react'
import styles from './BpclMarket.module.scss'
import BpclMarketGraph from './BpclMarketGraph'

const BpclMarket = (props) => {
    return(
        <div className={`${styles.BpclMarketsection}`}>
            <div className={`${styles.BpclGraphSection}`}>
                <BpclMarketGraph dropdown={props.dropdown} deltavalue={props.sensexDelta} senxDate={props.sensexDate} senxClose={props.sensexClose} />
            </div>
            <div className={`${styles.BpclContent}`}>
                <h4 className={`${styles.BpclContenthead}`}>BPCL {props.sensexDelta && props.sensexDelta.includes('-') === true ? <span className={`${styles.BpclContentheadspanred}`}>&#x2B07;</span> : <span className={`${styles.BpclContentheadspan}`}>&#x2B06;</span> }</h4>
                <p className={`${styles.BpclContentp}`}>{props.sensexClose && parseFloat(props.sensexClose[props.sensexClose.length - 1]).toFixed(2)} INR {
                    props.sensexDelta && props.sensexDelta.includes('-') === true ? 
                        <span className={`${styles.BpclContentpspanred}`}> {props.sensexDelta} ({props.sensexPerc}%)</span> 
                        :<span className={`${styles.BpclContentpspan}`}> +{props.sensexDelta} ({props.sensexPerc}%)</span>
                } 
                </p>
            </div>
        </div>
    )
}
export default BpclMarket