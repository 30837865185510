// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Nifty_Niftysection__6RrCa {\n  width: 100%;\n  height: 100%; }\n  .Nifty_Niftysection__6RrCa .Nifty_NiftyGraphSection__1BXcT {\n    width: 100%;\n    height: 60%; }\n    .Nifty_Niftysection__6RrCa .Nifty_NiftyGraphSection__1BXcT .Nifty_NiftyGraph__3uDpV {\n      width: 100%;\n      height: 100%; }\n  .Nifty_Niftysection__6RrCa .Nifty_NiftyContent__28juR {\n    width: 100%;\n    height: 40%;\n    padding: 15px; }\n    .Nifty_Niftysection__6RrCa .Nifty_NiftyContent__28juR .Nifty_NiftyContenthead__Tutyr {\n      color: #8fa9db;\n      margin-bottom: 0; }\n      .Nifty_Niftysection__6RrCa .Nifty_NiftyContent__28juR .Nifty_NiftyContenthead__Tutyr .Nifty_NiftyContentheadspan__xTs5k {\n        color: #74ce12; }\n      .Nifty_Niftysection__6RrCa .Nifty_NiftyContent__28juR .Nifty_NiftyContenthead__Tutyr .Nifty_NiftyContentheadspanred__23KFs {\n        color: #ff5b4f; }\n    .Nifty_Niftysection__6RrCa .Nifty_NiftyContent__28juR .Nifty_NiftyContentp__mMSxi {\n      color: #fff;\n      font-size: 20px; }\n      .Nifty_Niftysection__6RrCa .Nifty_NiftyContent__28juR .Nifty_NiftyContentp__mMSxi .Nifty_NiftyContentpspan__1m4yo {\n        color: #74ce12;\n        margin-left: 15px;\n        font-size: 15px; }\n      .Nifty_Niftysection__6RrCa .Nifty_NiftyContent__28juR .Nifty_NiftyContentp__mMSxi .Nifty_NiftyContentpspanred__2I1Gg {\n        color: #ff5b4f;\n        margin-left: 15px;\n        font-size: 15px; }\n", ""]);
// Exports
exports.locals = {
	"Niftysection": "Nifty_Niftysection__6RrCa",
	"NiftyGraphSection": "Nifty_NiftyGraphSection__1BXcT",
	"NiftyGraph": "Nifty_NiftyGraph__3uDpV",
	"NiftyContent": "Nifty_NiftyContent__28juR",
	"NiftyContenthead": "Nifty_NiftyContenthead__Tutyr",
	"NiftyContentheadspan": "Nifty_NiftyContentheadspan__xTs5k",
	"NiftyContentheadspanred": "Nifty_NiftyContentheadspanred__23KFs",
	"NiftyContentp": "Nifty_NiftyContentp__mMSxi",
	"NiftyContentpspan": "Nifty_NiftyContentpspan__1m4yo",
	"NiftyContentpspanred": "Nifty_NiftyContentpspanred__2I1Gg"
};
module.exports = exports;
