import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import styles from './BuIncInventory.module.scss'
import { fetchIncInventory, fetchupdatedStatus } from '../../../services/bu'
import { isEmpty, isNumber } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import BuIncInventoryGraph from './BuIncInventoryGraph'
import { Spinner } from 'react-bootstrap'
import { numberFormatEnIn } from '../../../utility/utility'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"


const BuIncDepotInventory = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [graphData, setGraphData] = useState([])
    const [attr, setattr] = useState('handleEnter')
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }

    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps


    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "INC" && res.kpi_name === "MOU indicator" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchIncInventory(props.year)
            .then(response => {
                const aggregatedData = response.data
                if (isEmpty(aggregatedData)) {
                    setIsNoDataAvailable(true)
                } else {
                    setGraphData(aggregatedData)
                    setIsNoDataAvailable(false)
                }
            })
            .catch(error => console.debug(error))
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuDepotInventoryContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex justify-content-between ${styles.BuCcsHeader}`}>
                    <div className={`d-flex flex-column justify-content-center ${styles.BuCcsHeaderLeft}`}>
                        <div className={`${styles.HeaderTop}`}>MOU Indicator - Monthly
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                        <div className={`d-flex ${styles.legendsContainer}`}>
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot1}`}></span> Sales
                            </div>
                        </div>
                    </div>
                    <div className={` d-flex flex-column justify-content-center ${styles.BuCcsHeaderRight}`}>
                        {/* <span className={`${styles.BuCcsHeaderResolved}`}>Total Depos: 80</span> */}
                    </div>
                </div>
                {
                    isNoDataAvailable ? <NoDataFound height={507} /> :
                        <>
                            <div className={`d-flex align-items-center ${styles.graphContainer}`}>
                                <div className="d-flex flex-row mr-4">
                                    {
                                        graphData ?
                                            <>
                                            <BuIncInventoryGraph graphData={graphData}></BuIncInventoryGraph>
                                            <div className={`d-flex flex-column ${styles.mouSalesSection}`}>
                                                <span className={`${styles.mouSalesSectionHeader}`}>Sales (MT)</span>
                                                {graphData?.map((data, key) => (
                                                    <p key={key}>{data.sales ? numberFormatEnIn(Number(data.sales).toFixed(2)) : 0}</p>
                                                ))}
                                            </div>
                                            </>
                                            :
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                    }
                                </div>
                            </div>
                            <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' id="fullDt" data-fs="handleenter" onClick={fullScrn}></img>
                            </div>
                        </>
                }
            </FullScreen>
        </div>
    )
}
export default BuIncDepotInventory
