import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
// import 'chartjs-plugin-datalabels';
import styles from './ItrmCrackGraph.module.scss'
// import {inrFormat, usdFormat} from "../../utility/utility"

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function ItrmCrackGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    const xLabels = [
        'M1',
        'M2',
        'M3',
        'M4',
        'M5',
        'M6',
        'M7',
        'M8',
        'M9',
        'M10',
        'M11',
        'M12',
    ]
    useEffect(() => {
    // prepareGraphData(props.graphData);

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let targetColor = ''
        let actualColor = ''
        let napthaColor = ''
        let foColor = ''
        let gasoilColor = ''

        //SET COLORS ACCORDING TO LOCATION
        if (props.location === 'mumbai') {
            targetColor = '#02d345' //green
            actualColor = '#06bee1' //blue
            napthaColor = '#00e0bc'
            foColor = '#fbcd74'
            gasoilColor = '#ff9396'
        }
        if (props.location === 'kochi') {
            targetColor = '#02d345' //pink
            actualColor = '#06bee1' //purple
            napthaColor = '#00e0bc'
            foColor = '#fbcd74'
            gasoilColor = '#ff9396'
        }
        if (props.location === 'bina') {
            targetColor = '#02d345' //pink
            actualColor = '#06bee1' //purple
            napthaColor = '#00e0bc'
            foColor = '#fbcd74'
            gasoilColor = '#ff9396'
        }
        if (props.location === 'all') {
            targetColor = '#02d345' //pink
            actualColor = '#06bee1' //purple
            napthaColor = '#00e0bc'
            foColor = '#fbcd74'
            gasoilColor = '#ff9396'
        }
        let naptha_data = []
        let gasolin_data = []
        let jetkero_data = []
        let gasoil_data = []
        let fo_data = []
        for(let i = 0; i< props.pr_gasoline.length; i++){
            let product = props.pr_gasoline
            gasolin_data.push(Number(product[9].value).toFixed(2),Number(product[10].value).toFixed(2),Number(product[11].value).toFixed(2), Number(product[0].value).toFixed(2), product[1].value, Number(product[2].value).toFixed(2),Number(product[3].value).toFixed(2),Number(product[4].value).toFixed(2),Number(product[5].value).toFixed(2),Number(product[6].value).toFixed(2),Number(product[7].value).toFixed(2),Number(product[8].value).toFixed(2))
        }
        props.pr_naptha && props.pr_naptha.forEach(getdata => {
            if(getdata.value !== null){
                naptha_data.push(Number(getdata.value).toFixed(2))
            }
        })
        props.pr_jetkero && props.pr_jetkero.forEach(getdata => {
            if(getdata.value !== null){
                jetkero_data.push(Number(getdata.value).toFixed(2))
            }
        })
        props.pr_gasoil && props.pr_gasoil.forEach(getdata => {
            if(getdata.value !== null){
                gasoil_data.push(Number(getdata.value).toFixed(2))
            }
        })
        props.pr_fo && props.pr_fo.forEach(getdata => {
            if(getdata.value !== null){
                fo_data.push(Number(getdata.value).toFixed(2))
            }
        })

        const chartConfig = {
            type: 'line',
            data: {
                labels: xLabels,
                datasets: [
                    {
                        label: 'Gasoline',
                        fill: false,
                        //  data: [10, 40, 23, 37, 39, 47, 78, 57, 50, 100, 80, 120],
                        data: gasolin_data,
                        pointBorderWidth: 4,
                        backgroundColor: gasoilColor,
                        pointBorderColor: gasoilColor,
                        borderColor: gasoilColor,
                        borderWidth: 2,
                        borderDash: [2],
                        datalabels: {
                            align: 'bottom',
                            color: 'rgb(161, 173, 187)',
                            font: {
                                size: 15,
                            },
                        },
                    },
                    {
                        label: 'Naphtha',
                        fill: false,
                        //  data: [10, 40, 23, 37, 39, 47, 78, 57, 50, 100, 80, 120],
                        data: naptha_data,
                        pointBorderWidth: 4,
                        backgroundColor: napthaColor,
                        pointBorderColor: napthaColor,
                        borderColor: napthaColor,
                        borderWidth: 2,
                        borderDash: [2],
                        datalabels: {
                            align: 'top',
                            color: 'rgb(161, 173, 187)',
                            font: {
                                size: 15,
                            },
                        },
                    },
                    {
                        label: 'Gas Oil',
                        fill: false,
                        //  data: [10, 40, 23, 37, 39, 47, 78, 57, 50, 100, 80, 120],
                        data: gasoil_data,
                        pointBorderWidth: 4,
                        backgroundColor: targetColor,
                        pointBorderColor: targetColor,
                        borderColor: targetColor,
                        borderWidth: 2,
                        borderDash: [2],
                        datalabels: {
                            align: 'top',
                            color: 'rgb(161, 173, 187)',
                            font: {
                                size: 15,
                            },
                        },
                    },
                    {
                        label: 'Jet/Kero',
                        fill: false,
                        //  data: [10, 40, 23, 37, 39, 47, 78, 57, 50, 100, 80, 120],
                        data: jetkero_data,
                        pointBorderWidth: 4,
                        backgroundColor: actualColor,
                        pointBorderColor: actualColor,
                        borderColor: actualColor,
                        borderWidth: 2,
                        //borderDash: [4],
                        datalabels: {
                            align: 'bottom',
                            color: 'rgb(161, 173, 187)',
                            font: {
                                size: 15,
                            },
                        },
                    },
                    {
                        label: 'FO 180',
                        fill: false,
                        //  data: [10, 40, 70, 50, 90, 40, 60, 80, 50, 57, 70, 60],
                        data: fo_data,
                        pointBorderWidth: 4,
                        pointBackgroundColor: foColor,
                        pointBorderColor: foColor,
                        borderColor: foColor,
                        borderWidth: 2,
                        datalabels: {
                            align: 'top',
                            color: 'rgb(161, 173, 187)',
                            font: {
                                size: 15,
                            },
                        },
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.4,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                color: '#2f3b51',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            beginAtZero: true,
                            ticks: {
                                // min: 42,
                                // max: 60,
                                // stepSize: 2,

                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 4
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineWidth: 1,
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.location, props.pr_gasoline, props.pr_gasoil, props.pr_naptha, props.pr_jetkero, props.pr_fo]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <canvas ref={chartContainer} />
        </div>
    )
}
export default ItrmCrackGraph
