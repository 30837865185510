// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RefinaryProduction_RefinaryProductionContainer__2oXhC {\n  border: 0.5px #a7a7a7 solid;\n  border-left: none;\n  border-bottom: none;\n  font-family: Titillium Web;\n  width: 644px;\n  height: 653px;\n  background-color: #141923;\n  color: #dee5ef;\n  position: relative; }\n  .RefinaryProduction_RefinaryProductionContainer__2oXhC .RefinaryProduction_graphTitle__2BxJU {\n    height: 88px;\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    background-color: black;\n    font-size: 35px;\n    font-weight: bold;\n    padding-left: 36px; }\n  .RefinaryProduction_RefinaryProductionContainer__2oXhC .RefinaryProduction_grmFooter__1zoFB {\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    height: 64px;\n    background-color: #283040; }\n    .RefinaryProduction_RefinaryProductionContainer__2oXhC .RefinaryProduction_grmFooter__1zoFB .RefinaryProduction_footerLeft__2iA0g {\n      cursor: pointer;\n      margin-left: 36px;\n      color: #ffffff;\n      font-size: 22px;\n      text-decoration: underline; }\n    .RefinaryProduction_RefinaryProductionContainer__2oXhC .RefinaryProduction_grmFooter__1zoFB .RefinaryProduction_footerRight__6qPn7 {\n      margin-right: 36px;\n      font-size: 22px;\n      font-style: italic; }\n  .RefinaryProduction_RefinaryProductionContainer__2oXhC .RefinaryProduction_statusUpdatedImg__3OVdm {\n    margin-left: 85px;\n    margin-top: -21px; }\n", ""]);
// Exports
exports.locals = {
	"RefinaryProductionContainer": "RefinaryProduction_RefinaryProductionContainer__2oXhC",
	"graphTitle": "RefinaryProduction_graphTitle__2BxJU",
	"grmFooter": "RefinaryProduction_grmFooter__1zoFB",
	"footerLeft": "RefinaryProduction_footerLeft__2iA0g",
	"footerRight": "RefinaryProduction_footerRight__6qPn7",
	"statusUpdatedImg": "RefinaryProduction_statusUpdatedImg__3OVdm"
};
module.exports = exports;
