// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".stateMap_heatmapLegend___T6TA {\n  position: relative;\n  bottom: 893px;\n  left: 412px; }\n\n.stateMap_heatmapImg__2j2nT {\n  height: 54px; }\n\n.stateMap_heatmapMin__3j2dS {\n  margin-top: -38px;\n  margin-left: 18px;\n  font-size: 25px; }\n\n.stateMap_heatmapMax__22CKO {\n  margin-left: 590px;\n  font-size: 25px; }\n\n.stateMap_heatmapMiddle__1NOSS {\n  margin-top: -35px;\n  margin-left: 300px;\n  font-size: 25px; }\n", ""]);
// Exports
exports.locals = {
	"heatmapLegend": "stateMap_heatmapLegend___T6TA",
	"heatmapImg": "stateMap_heatmapImg__2j2nT",
	"heatmapMin": "stateMap_heatmapMin__3j2dS",
	"heatmapMax": "stateMap_heatmapMax__22CKO",
	"heatmapMiddle": "stateMap_heatmapMiddle__1NOSS"
};
module.exports = exports;
