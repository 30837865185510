// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FinanceMarket_marketContainer__16PK- {\n  background-color: #202c3f;\n  width: 972px;\n  font-family: Titillium Web; }\n  .FinanceMarket_marketContainer__16PK- .FinanceMarket_marketHeader__4oFtn {\n    height: 84px;\n    background-color: #181e2a;\n    font-size: 28px;\n    font-weight: bold;\n    color: white;\n    padding-left: 24px;\n    padding-right: 24px; }\n  .FinanceMarket_marketContainer__16PK- .FinanceMarket_topContainer__1BAoK {\n    padding: 24px 24px 24px 24px; }\n    .FinanceMarket_marketContainer__16PK- .FinanceMarket_topContainer__1BAoK .FinanceMarket_shareContainer__3BJiM {\n      flex: 1 1;\n      height: 192px;\n      background-color: #27364e;\n      margin-right: 12px; }\n    .FinanceMarket_marketContainer__16PK- .FinanceMarket_topContainer__1BAoK .FinanceMarket_detailShareContainer__1qppI {\n      font-size: 29.1px;\n      color: #ffffff; }\n  .FinanceMarket_marketContainer__16PK- .FinanceMarket_bottomContainer__19YMT .FinanceMarket_listItemRow__3UsJg {\n    height: 74px; }\n    .FinanceMarket_marketContainer__16PK- .FinanceMarket_bottomContainer__19YMT .FinanceMarket_listItemRow__3UsJg .FinanceMarket_listItemContainer__3quld {\n      background-color: #273449;\n      flex: 1 1;\n      margin-right: 2px;\n      padding-left: 15px;\n      padding-right: 15px; }\n    .FinanceMarket_marketContainer__16PK- .FinanceMarket_bottomContainer__19YMT .FinanceMarket_listItemRow__3UsJg :last-child {\n      margin-right: 0px; }\n    .FinanceMarket_marketContainer__16PK- .FinanceMarket_bottomContainer__19YMT .FinanceMarket_listItemRow__3UsJg .FinanceMarket_kpiTitle__IqrLk {\n      flex: 1 1;\n      background-color: #273449;\n      color: #8fa9db;\n      font-size: 22px;\n      padding-left: 20px; }\n    .FinanceMarket_marketContainer__16PK- .FinanceMarket_bottomContainer__19YMT .FinanceMarket_listItemRow__3UsJg .FinanceMarket_Kpi__2zlyV {\n      margin-right: 2px;\n      background-color: #273449; }\n", ""]);
// Exports
exports.locals = {
	"marketContainer": "FinanceMarket_marketContainer__16PK-",
	"marketHeader": "FinanceMarket_marketHeader__4oFtn",
	"topContainer": "FinanceMarket_topContainer__1BAoK",
	"shareContainer": "FinanceMarket_shareContainer__3BJiM",
	"detailShareContainer": "FinanceMarket_detailShareContainer__1qppI",
	"bottomContainer": "FinanceMarket_bottomContainer__19YMT",
	"listItemRow": "FinanceMarket_listItemRow__3UsJg",
	"listItemContainer": "FinanceMarket_listItemContainer__3quld",
	"kpiTitle": "FinanceMarket_kpiTitle__IqrLk",
	"Kpi": "FinanceMarket_Kpi__2zlyV"
};
module.exports = exports;
