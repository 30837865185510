import React, { useEffect, useState, useRef } from 'react'
import styles from './OrgNews.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
import { fetchOrgNews } from '../../services/Org'
import { NoDataFound } from '../DataNotFound/nodatafound'

const OrgNews = () => {
    const [newsfeed, setnewsfeed] = useState([])
    const [loading, setloading] = useState(true)
    const [wrapWidth, setWrapWidth] = useState(0);
    const [offset, setOffset] = useState(0);
    const wrapRef = useRef(null);
    const months = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    const [stopped, setStopped] = useState(false);
    let sId;
    const [fid, setFid] = useState(0);
    useEffect(() => {
        fetchOrgNews().then(data => {
            if(data.length > 0){
                setnewsfeed(data)
                setloading(false)
            }else{
                setloading(true)
            }
        })
    }, [])
    const move = () => {
        const wrapWidth = 120 * 180;
        setWrapWidth(wrapWidth);
        setOffset((offset) => {
          if (-offset === Math.floor(wrapWidth / 2)) {
            return 0;
          }
          return offset - 2
        });
       sId=  requestAnimationFrame(move);
       setFid(sId);
    };
    useEffect(() => {
    move();
  },[]);
  const onClickHandler = () => {
    if (!stopped){
      cancelAnimationFrame(fid);
       setFid(sId);
      setStopped(!stopped);
    }
    else{
    requestAnimationFrame(move);
    setStopped(!stopped);
    }
    
  }

  return (
    <div className={`${styles.newsContainer}`}>
      <div className={`d-flex align-items-center justify-content-center ${styles.newsHeader}`}>News - Real-time</div>
      <div className={`${styles.wrap}`}>
        {loading ?
          <div className={`${styles.overflowScroll}`}>
            <NoDataFound />
          </div>
          :
          <div
            className={`${styles.msgwrap}`}
            ref={wrapRef}
            style={{
              height: `${wrapWidth}px`,
              transform: `translateY(${offset}px)`
            }}
          >
            {newsfeed.map((getnews, index) => (
              <div className={`d-flex justify-content-center ${styles.newsBox}`} key={index} onClick={onClickHandler}>
                <div className={`${styles.newsBoximg}`}>
                  <div className={`${styles.newsBoximgdate}`}>
                    <h1>{new Date(getnews.pubDate).getDate()}</h1>
                    <p>{months[new Date(getnews.pubDate).getMonth()]}, {new Date(getnews.pubDate).getFullYear()}</p>
                  </div>
                </div>
                <div className={`${styles.newsBoxcontent}`}>
                  <h4>{getnews.title}</h4>
                  <p className={`${styles.shortcnt}`}>{getnews.contentSnippet}</p>
                  <p className={`${styles.ndate}`}><span><FontAwesomeIcon icon={faCalendarAlt} /></span>{getnews.pubDate}</p>
                </div>
              </div>
            ))}
          </div>
        }

      </div>
    </div>

  )  

}
  export default OrgNews
