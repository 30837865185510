// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ItrmWorlMap_mapContainer__27Dwn {\n  width: 2100px;\n  height: 1219px;\n  border-top: solid 0.5px #959595;\n  border-right: solid 0.5px #959595;\n  border-left: solid 0.5px #959595;\n  border-bottom: solid 0.5px #959595; }\n", ""]);
// Exports
exports.locals = {
	"mapContainer": "ItrmWorlMap_mapContainer__27Dwn"
};
module.exports = exports;
