function getColourFromRating(rating){
    if(rating === 'poor' ){
        return '#ff5b4f'
    }
    if(rating === 'fair' ){
        return '#ff8d38'
    }
    if(rating === 'good' ){
        return '#ffd64e'
    }
    if(rating === 'excellent' ){
        return '#7df289'
    }
  
}
function getColourFromRegion(city, color, business_unit){
//  stateData.map((item, index) => {
    if(city === 'north' && business_unit !== "retail"){
        return '#0051ff'
    }
    if(city === 'east' && business_unit !== "retail" ){
        return '#009fff'
    }
    if(city === 'west' && business_unit !== "retail"){
        return '#00d2ff'
    }
    if(city === 'south'  && business_unit !== "retail"){
        return '#00f9ff'
    }
    if (city === 'Orissa') {
        return color
    }
    if (city === 'West Bengal') {
        return color
    }
    if (city === 'jharkhand') {
        return color
    }
    if(city === "Daman(Daman&Diu)"){
        return color
    }
    if (city === 'Bihar') {
        return color
    }
    if (city === 'meghalaya') {
        return color
    } if (city === 'Tripura') {
        return color
    }
    if (city === 'Assam') {
        return color
    }
    if (city === 'Mizoram') {
        return color
    }
    if (city === 'Manipur') {
        return color
    }
    if (city === 'arunachal pradesh') {
        return color
    } if (city === 'Nagaland') {
        return color
    }
    if (city === 'Sikkim') {
        return color
    }
    if (city === 'Maharashtra') {
        return color
    }
    if (city === 'chhattisgarh') {
        return color
    }
    if (city === 'Goa') {
        return color
    } if (city === 'ut of d&nh and d&d') {
        return color
    }
    if (city === 'Gujarat') {
        return color
    }
    if (city === 'Madhya Pradesh') {
        return color
    }
    if (city === 'uttrakhand') {
        return color
    }
    if (city === 'Rajasthan') {
        return color
    } 
    if (city === 'uttar pradesh') {
        return color
    }
    if (city === 'haryana') {
        return color
    }
    if (city === 'Jammu & Kashmir') {
        return color
    }
    if (city === 'delhi') {
        return color
    }
    if (city === 'Punjab') {
        return color
    } if (city === 'Chandigarh') {
        return color
    }
    if (city === 'Himachal pradesh') {
        return color
    }
    if (city === 'Kerala') {
        return color
    }
    if (city === 'Pondicherry') {
        return color
    }
    if (city === 'Lakshadweep') {
        return color
    } 
    if (city === 'Andaman and Nicobar Is') {
        return color
    }
    if (city === 'Karnataka') {
        return color
    }
    if (city === 'Tamil Nadu') {
        return color
    }
    if (city === 'Andhra Pradesh') {
        return color
    }
    if (city === 'Telangana') {
        return color

        // })
    }
}

export {getColourFromRating, getColourFromRegion}