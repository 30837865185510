import React, { useEffect, useState, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuAtfMarketShareGraph from './BuAtfMarketShareGraph'
import styles from './BuAtfMarketShare.module.scss'
import { fetchAtfMarketShare, fetchupdatedStatus } from '../../../services/bu'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuAtfMarketShare = (props) => {
    const [graphData, setGraphData] = useState([])
    const [lastItem, setLastItem] = useState({})
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [isNoDataAvailable,setIsNoDataAvailable] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "AVIATION" && res.kpi_name === "Market share" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        function prepareGraphData() {
            fetchAtfMarketShare(props.year)
                // .then(response => {
                //     if(isEmpty(response.data)) {
                //         setIsNoDataAvailable(true)
                //     } else {
                //     setGraphData(response.data)
                //     setLastItem(response.data[response.data.length - 1])
                // }})
                .then(response => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setGraphData(response.data)
                        setIsNoDataAvailable(false)
                        setLastItem(response.data[response.data.length - 1])
                    }
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData()
    }, [props.product, props.year])

    return (
        <div className={`${styles.BuMarketShareContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col flex-grow-1'}>
                            <span>Market Share - Monthly</span>

                        </div>
                        {
                            isNoDataAvailable ||
                            <div className={`mt-2 mr-3 ${styles.headerRight}`} style={{ fontSize: '24px' }}>
                                <span>Total: {lastItem && lastItem.total !== null ? Number(lastItem.total).toFixed(2) + '%' : 'No Data'}</span>
                                {/* <span className={'ml-3'} style={{ fontSize: '24px', color: `${Math.sign(lastItem !== undefined && lastItem.percentage_growth) === 1 ? '#74ce12' : '#e13219'}` }}>{lastItem && lastItem.percentage_growth !== null && Number(lastItem.percentage_growth).toFixed(2) + '%'}</span> */}
                            </div>
                        }
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-8 d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.BpclDot}`}></span>BPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.HpclDot}`}></span>HPCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.IoclDot}`}></span>IOCL</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.RilDotNew}`}></span>RIL</div>
                            {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.NelDot}`}></span>NEL</div> */}
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.SmafslDot}`}></span>SMAFSL</div>
                            
                            {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.targetDot}`}></span></div> */}
                        </div>
                        {/* <div className={`col-4 ${styles.headerLabelsTarget}`}>
                        <span>---- BPCL Target</span>
                    </div> */}
                    </div>
                </div>
                {
                    isNoDataAvailable ?
                        <NoDataFound height={450} />
                        :
                        (
                            <div className={`d-flex  ${styles.graphContainer}`}>
                                {
                                    graphData.length > 0 ?
                                        <BuAtfMarketShareGraph graphData={graphData}></BuAtfMarketShareGraph>
                                        :
                                        <NoDataFound />
                                }
                            </div>
                        )
                }
                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' onClick={fullScrn}></img>
                </div>
            </FullScreen>
        </div>
    )
}
export default BuAtfMarketShare