import React from 'react'
import styles from './InternationalBond.module.scss'
//import { Spinner } from "react-bootstrap";
import InternationalBondGraph from './InternationalBondGraph'
import {numberFormatEnIn} from '../../../../utility/utility'

function InternationalBond(props) {
    return (
        <div className={`${styles.itrmOceanLossContainer}`}>
            <div className={`pt-2 ${styles.headerContainer}`}>
                <div className={'row'}>
                    <div className={'col-8'}>
                        <span>Domestic Loans</span>
                    </div>
                    <div className={`col-4 mt-2 d-flex align-items-center ${styles.headerLabels}`}>
                        <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.HpclDot}`}></span>Total: <span style={{color:'#fff',marginLeft : '5px'}}> INR {props.total && numberFormatEnIn(parseFloat(props.total).toFixed(2))} Cr</span></div>
                        {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.IoclDot}`}></span>Bond 2: <span style={{color:'#fff',fontWeight : 'normal',marginLeft : '5px'}}> {props.total.Bond2} Cr</span></div>
                        <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.ShellDot}`}></span>Bond 3: <span style={{color:'#fff',fontWeight : 'normal',marginLeft : '5px'}}> {props.total.Bond3} Cr</span></div> */}
                    </div>
                </div>
            </div>
            <div className={`d-flex ${styles.graphContainer}`}>
                <InternationalBondGraph graphData={props.graphData} year={props.year}></InternationalBondGraph>
            </div>
        </div>
    )
}
export default InternationalBond