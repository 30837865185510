import React, { useState, useEffect } from 'react'
import styles from './OrgMapDetailTable.module.scss'
import { fetchRetailMapTable } from '../../../services/bu'
import { numberFormatEnIn } from '../../../utility/utility'
import bioFuelIcon from '../../../assets/images/mapIcon/Biofuel-48.png'
import gasIcon from '../../../assets/images//mapIcon/Gas02-48.png'
import hrsIcon from '../../../assets/images//mapIcon/HRS-48.png'
import lpgIcon from '../../../assets/images//mapIcon/LPG-48.png'
import lubesIcon from '../../../assets/images//mapIcon/Lubes-48.png'
import pipelineIcon from '../../../assets/images//mapIcon/Pipeline-48.png'
import refineriesIcon from '../../../assets/images//mapIcon/Refineries01-48.png'
import renewableEnergyIcon from '../../../assets/images//mapIcon/RenewableEnergy-48.png'
import retailIcon from '../../../assets/images//mapIcon/Retail01-48.png'
const OrgMapDetailTable = (props) => {
    // const [allData, setAllData] = useState([])
    // const [salesRevMarkData, setSalesRevMarkData] = useState([])
    // const [hsseData, setHsseData] = useState([])
    // const [depotData, setDepotData] = useState([])
    // const [northData, setnorthData] = useState([])
    // const [eastData, seteastData] = useState([])
    // const [westData, setwestData] = useState([])
    // const [southData, setsouthData] = useState([])
    // const getData = () => {
    //     setAllData([])
    //     setSalesRevMarkData([])
    //     setHsseData([])
    //     setDepotData([])
    //     setnorthData([])
    //     seteastData([])
    //     setwestData([])
    //     setsouthData([])
        // let level = ''
        // let appendRegion = ''
        // if (props.mapOf.toLocaleLowerCase() === 'north' || props.mapOf.toLocaleLowerCase() === 'south' || props.mapOf.toLocaleLowerCase() === 'east' || props.mapOf.toLocaleLowerCase() === 'west') {
        //     level = 'region'
        //     appendRegion = `&region=${props.mapOf}`
        // } else {
        //     level = 'region'
        //     appendRegion = ''
        // }

        // fetchRetailMapTable(props.activeKpi,level,appendRegion, props.year)
        //     .then((response) => {
        //         if (props.activeKpi === 'all') {
        //             let east_array = []
        //             let west_array = []
        //             let south_array = []
        //             let north_array = []
        //             response.data.forEach(getRegion => {
        //                 if (getRegion.region === 'east') {
        //                     east_array.push(getRegion)
        //                     seteastData(east_array[east_array.length - 1])
        //                 } else if (getRegion.region === 'west') {
        //                     west_array.push(getRegion)
        //                     setwestData(west_array[west_array.length - 1])
        //                 } else if (getRegion.region === 'north') {
        //                     north_array.push(getRegion)
        //                     setnorthData(north_array[north_array.length - 1])
        //                 } else if (getRegion.region === 'south') {
        //                     south_array.push(getRegion)
        //                     setsouthData(south_array[south_array.length - 1])
        //                 }
        //             })
        //             setAllData(response.data)
        //         }
        //         //         else if (
        //         //             props.activeKpi === 'sales' ||
        //         //   props.activeKpi === 'revenue' ||
        //         //   props.activeKpi === 'market share'
        //         //         ) {
        //         //             setSalesRevMarkData(response.data)
        //         //         } else if (props.activeKpi === 'hsse') {
        //         //             setHsseData(response.data)
        //         //         } else if (props.activeKpi === 'depot') {
        //         //             setDepotData(response.data)
        //         //         }
        //     })
        //     .catch((error) => console.debug(error))
    // }

    // useEffect(() => {
    //     getData()
    // }, [props.activeKpi, props.mapOf, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`d-flex flex-column ${styles.OrgMapDetailTableContainer}`}>
            <div className={`d-flex flex-column ${styles.tableHeaderRowContainer}`}>
                <div className={`${styles.tableHeaderRow}`}>
                    {/* <div className={`${styles.headerCell}`}>Icon</div> */}
                    {/* <div className={`${styles.headerCell}`}>Name</div> */}
                    {/* <div className={`${styles.headerCell}`}>Option</div> */}
                </div>


                <div className={` d-flex flex-column ${styles.tableDataRowContainer}`}>
                    <div className={`d-flex ${styles.tableDataRow}`}>      
                        <div className={`${styles.dataCell}`}>
                            <img src={bioFuelIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>Bio Fuel</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{northData.revenue_actual ? numberFormatEnIn(Number(northData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <img src={refineriesIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>Refineries</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{eastData.revenue_actual && eastData.revenue_actual !== null ? numberFormatEnIn(Number(eastData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <img src={lpgIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>LPG</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{westData.revenue_actual && westData.revenue_actual !== null ? numberFormatEnIn(Number(westData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <img src={pipelineIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>Pipeline</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{southData.revenue_actual && southData.revenue_actual !== null ? numberFormatEnIn(Number(southData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <img src={lubesIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>Lube</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{eastData.revenue_actual && eastData.revenue_actual !== null ? numberFormatEnIn(Number(eastData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <img src={retailIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>Retail</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{westData.revenue_actual && westData.revenue_actual !== null ? numberFormatEnIn(Number(westData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <img src={hrsIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>HRS</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{southData.revenue_actual && southData.revenue_actual !== null ? numberFormatEnIn(Number(southData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <img src={renewableEnergyIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>Renewable Energy</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{eastData.revenue_actual && eastData.revenue_actual !== null ? numberFormatEnIn(Number(eastData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                       <div className={`${styles.dataCell}`}>
                            <img src={gasIcon} alt='Icon'></img>
                        </div>
                        {/* <div className={`${styles.dataCell}`}>
                            <span>Gas</span>
                        </div> */}
                        {/* <div className={`${styles.dataCell}`}>
                            <span>{westData.revenue_actual && westData.revenue_actual !== null ? numberFormatEnIn(Number(westData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div> */}
                    </div>

                    {/* {salesRevMarkData &&
                        salesRevMarkData.map((el, index) => (
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.com}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.bpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.iocl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.hpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.shell}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.ril}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span>{el.nel}</span>
                                </div>
                            </div>
                        ))}
                    {hsseData &&
                        hsseData.map((el, index) => (
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.com}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.incidents}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span>{el.lost_man_hours}</span>
                                </div>
                            </div>
                        ))}

                    {depotData &&
                        depotData.map((el, index) => (
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.depot_name}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.supply_days.target}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span
                                        style={{
                                            color: `${Math.sign(
                                                el.supply_days.actual - el.supply_days.target
                                            ) === 1
                                                    ? '#74ce12'
                                                    : '#e13219'
                                                }`,
                                        }}
                                    >
                                        {el.supply_days.actual}
                                    </span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.supply.target}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span
                                        style={{
                                            color: `${Math.sign(el.supply.actual - el.supply.target) === 1
                                                    ? '#74ce12'
                                                    : '#e13219'
                                                }`,
                                        }}
                                    >
                                        {el.supply.actual}
                                    </span>
                                </div>
                            </div>
                        ))} */}
                </div>
            </div>
        </div>
    )
}
export default OrgMapDetailTable
