export const withAuthorizationHeaders = (headers = {}) => {
    try {
        const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
        if (loggedInUser && loggedInUser.accessToken.length > 0) {
            return { ...headers, 'x-access-token': loggedInUser.accessToken }
        }
    } catch (error) {
        console.debug('Failed to generate auth headers')
    }

    throw new Error('Invalid authorization')
}