import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './ForeignCurrency.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function ForeignCurrencyGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    //const xLabels = ["0", "5", "10", "15", "20", "25", "30"]
    //let avgValue = 0;

    // function prepareGraphData(apiGraphData) {
    //     let count = apiGraphData.length;
    //     let total = 0;
    //     apiGraphData.map((item, index) => {
    //         total += item;
    //     })
    //     avgValue = total / count;
    // }

    useEffect(() => {
        // prepareGraphData(props.oceanLossData);
        if (chartInstance) {
            chartInstance.destroy()
        }
        // let fvalue = props.graphData.map(getbs => getbs.sub_kpi_type === 'foreign' && getbs.value1);
        // let svalue = props.graphData.map(getbs => getbs.sub_kpi_type === 'foreign' && getbs.value2);
        // let tvalue = props.graphData.map(getbs => getbs.sub_kpi_type === 'foreign'&& getbs.value3); 
        let loanone = []
        props.graphData.map(getbsdata => {
            if(getbsdata.sub_kpi_type === 'foreign'){
                loanone.push(parseFloat(getbsdata.value).toFixed(2))
            }
        })
        const ctx = chartContainer.current.getContext('2d')

        // let avgColor = "";
        // //SET COLORS ACCORDING TO LOCATION
        // if (props.location === "mumbai") {
        //     avgColor = "#06bee1";
        // }
        // if (props.location === "kochi") {
        //     avgColor = "#b76df1";
        // }

        let prevyear = props.year
        let prevlabel = `FY ${prevyear.toString().slice(2)}`

        const chartConfig = {
            type: 'line',
            data: {
                //labels: [0, 5, 10, 15, 20, 25, 30],
                labels: [prevlabel, 'Q1', 'Q2', 'Q3', 'Q4'],
                datasets: [
                    {
                        borderColor: '#ffbf45',
                        pointBorderWidth: 4,
                        pointBackgroundColor: '#ffbf45',
                        pointBorderColor: '#ffbf45',
                        borderWidth: 4,
                        label: 'Foreign Loan',
                        //data: [150, 100, 180, 200, 120, 300, 150],
                        data: loanone.length > 0 ? loanone : [],
                        fill: false,
                        // backgroundColor: "rgb(222, 172, 245)",
                        datalabels: {
                            display: false,
                        },
                    },
                    // {
                    //     borderColor: '#2281e8',
                    //     pointBorderWidth: 4,
                    //     pointBackgroundColor: '#2281e8',
                    //     pointBorderColor: '#2281e8',
                    //     borderWidth: 4,
                    //     label: 'Loan 2',
                    //     //data: [210, 210, 260, 320, 300, 380, 300],
                    //     data: loantwo.length > 0 ? loantwo : [],
                    //     fill: false,
                    //     // backgroundColor: "rgb(222, 172, 245)",
                    //     datalabels: {
                    //         display: false,
                    //     },
                    // },
                    // {
                    //     borderColor: '#ffbf45',
                    //     pointBorderWidth: 4,
                    //     pointBackgroundColor: '#ffbf45',
                    //     pointBorderColor: '#ffbf45',
                    //     borderWidth: 4,
                    //     label: 'Loan3',
                    //     //data: [380, 320, 400, 420, 400, 450, 380],
                    //     data: loanthree.length > 0 ? loanthree : [],
                    //     fill: false,
                    //     // backgroundColor: "rgb(222, 172, 245)",
                    //     datalabels: {
                    //         display: false,
                    //     },
                    // },
                ]
            },
            options: {
                // barRoundness: 1,
                responsive: true,
                maintainAspectRatio: false,
                elements : {
                    line : {
                        tension : 0.4
                    }
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                color: '#67758d',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                zeroLineWidth : 1,
                                zeroLineColor : '#67758d',
                                borderDash : [3]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            beginAtZero: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Cr',
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 4
                                // min : 0,
                                // max : Math.round(Math.max(...loanone))+1,
                                // stepSize : Math.round(Math.max(...loanone)/4)
                            },
                            gridLines: {
                                color: 'transparent',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}
export default ForeignCurrencyGraph