import React, { useState, useEffect } from 'react'

import styles from './RefinaryGrm.module.scss'
import GrmGraph from './GrmGraph'
import { fetchMumbaiUsdDataGRM, fetchKochiUsdDataGRM, fetchKochiInrDataGRM, fetchMumbaiInrDataGRM, fetchBinaUsdDataGRM, fetchBinaInrDataGRM, fetchupdatedStatus } from '../../../services/Refinery'
import { isEmpty } from 'lodash'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const RefinaryGrm = (props) => {
    const [grmMumbaiUsdData, setGrmMumbaiUsdData] = useState([])
    const [grmMumbaiInrData, setGrmMumbaiInrData] = useState([])
    const [grmKochiUsdData, setGrmKochiUsdData] = useState([])
    const [grmKochiInrData, setGrmKochiInrData] = useState([])
    const [grmBinaUsdData, setGrmBinaUsdData] = useState([])
    const [grmBinaInrData, setGrmBinaInrData] = useState([])
    const [graphData, setGraphData] = useState([])
    const [showingGraphUnit, setShowingGraphUnit] = useState('USD/Barrel')
    const [targetGraphUnit, setTargetGraphUnit] = useState('INR(Crore)')
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [isNoDataAvailableUSD, setIsNoDataAvailableUSD] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState('')


    useEffect(() => {
        if (props.location === 'kochi') {
            fetchKochiUsdDataGRM(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailableUSD(true)
                    } else {

                        setGrmKochiUsdData(response.data)
                        setGraphData(response.data)
                        setIsNoDataAvailableUSD(false)
                    }
                })
                .catch((error) => console.debug(error))

            fetchKochiInrDataGRM(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setGrmKochiInrData(response.data)
                        setIsNoDataAvailable(false)

                    }
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "KR" && res.kpi_name === "GRM" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
        if (props.location === 'mumbai') {
            fetchMumbaiUsdDataGRM(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailableUSD(true)
                    } else {
                        setGrmMumbaiUsdData(response.data)
                        setGraphData(response.data)
                        setIsNoDataAvailableUSD(false)

                    }
                })
                .catch((error) => console.debug(error))

            fetchMumbaiInrDataGRM(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {

                        setGrmMumbaiInrData(response.data)
                        setIsNoDataAvailable(false)

                    }
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "MR" && res.kpi_name === "GRM" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
        if (props.location === 'bina') {
            fetchBinaUsdDataGRM(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailableUSD(true)
                    } else {
                        setGrmBinaUsdData(response.data)
                        setGraphData(response.data)
                        setIsNoDataAvailableUSD(false)

                    }
                })
                .catch((error) => console.debug(error))

            fetchBinaInrDataGRM(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {

                        setGrmBinaInrData(response.data)
                        setIsNoDataAvailable(false)

                    }
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "BR" && res.kpi_name === "GRM" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }

    }, [props.location, props.year])

    useEffect(() => {
        if (props.location === 'mumbai') {
            setGraphData(grmMumbaiUsdData)
            setShowingGraphUnit('USD/Barrel')
            setTargetGraphUnit('INR(Crore)')
        } 
        if (props.location === 'kochi') {
            setGraphData(grmKochiUsdData)
            setShowingGraphUnit('USD/Barrel')
            setTargetGraphUnit('INR(Crore)')
        }
        if (props.location === 'bina') {
            setGraphData(grmBinaUsdData)
            setShowingGraphUnit('USD/Barrel')
            setTargetGraphUnit('INR(Crore)')
        } 
    }, [props.location, grmMumbaiUsdData, grmKochiUsdData, grmBinaUsdData])

    const switchUnits = () => {
        if (props.location === 'mumbai') {
            if (targetGraphUnit === 'INR(Crore)') {
                //console.debug("Switch USD to INR");
                //console.debug("GRM-Mumbai-INR -- ", grmMumbaiInrData);
                setGraphData(grmMumbaiInrData)
                setShowingGraphUnit('INR(Crore)')
                setTargetGraphUnit('USD/Barrel')
            }
            if (targetGraphUnit === 'USD/Barrel') {
                //console.debug("Switch INR to USD");
                //console.debug("GRM-Mumbai-USD -- ", grmMumbaiUsdData);
                setGraphData(grmMumbaiUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('INR(Crore)')
            }
        }
        if (props.location === 'kochi') {
            if (targetGraphUnit === 'INR(Crore)') {
                //console.debug("Switch USD to INR");
                //console.debug("GRM-Kochi-INR -- ", grmKochiInrData);
                setGraphData(grmKochiInrData)
                setShowingGraphUnit('INR(Crore)')
                setTargetGraphUnit('USD/Barrel')
            }
            if (targetGraphUnit === 'USD/Barrel') {
                //console.debug("Switch INR to USD");
                //console.debug("GRM-Kochi-USD -- ", grmKochiUsdData);
                setGraphData(grmKochiUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('INR(Crore)')
            }
        }
        if (props.location === 'bina') {
            if (targetGraphUnit === 'INR(Crore)') {
                //console.debug("Switch USD to INR");
                //console.debug("GRM-Kochi-INR -- ", grmKochiInrData);
                setGraphData(grmBinaInrData)
                setShowingGraphUnit('INR(Crore)')
                setTargetGraphUnit('USD/Barrel')
            }
            if (targetGraphUnit === 'USD/Barrel') {
                //console.debug("Switch INR to USD");
                //console.debug("GRM-Kochi-USD -- ", grmKochiUsdData);
                setGraphData(grmBinaUsdData)
                setShowingGraphUnit('USD/Barrel')
                setTargetGraphUnit('INR(Crore)')
            }
        }
    }

    return (
        <div
            className={`d-flex flex-column justify-content-between ${styles.GrmContainer}`}
        >
            <div className={`d-flex align-items-center ${styles.graphTitle}`}>
                <span>GRM in {showingGraphUnit} (YTM) - Quarterly
                {/* {props.year === '2022' ?" - LY": ""} */}
                </span>
                {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
            </div>
            {targetGraphUnit === 'USD/Barrel' ?
                isNoDataAvailableUSD ?
                    <QuarterlyDataNotFound height={620} />
                    :
                    (isEmpty(graphData) ? <NoDataFound height={620} /> :
                        <GrmGraph
                            location={props.location}
                            graphData={graphData}
                            kpiName="GRM"
                            refinery="Mumbai"
                            unit="INR Crore"
                            year={props.year}
                        ></GrmGraph>)
                : targetGraphUnit === 'INR(Crore)' ?
                    isNoDataAvailable ?
                        <QuarterlyDataNotFound height={620} />
                        :
                        (isEmpty(graphData) ? <NoDataFound height={620} /> :
                            <GrmGraph
                                location={props.location}
                                graphData={graphData}
                                kpiName="GRM"
                                refinery="Mumbai"
                                unit="INR Crore"
                                year={props.year}
                            ></GrmGraph>
                        )
                    : <NoDataFound height={620} />
            }
            <div className={`d-flex align-items-center justify-content-between ${styles.grmFooter}`}>
                <div onClick={() => switchUnits()} className={`${styles.footerLeft}`}>
                    <span>View in {targetGraphUnit}</span>
                </div>
                <div className={`${styles.footerRight}`}>
                    <span>
                        {graphData != undefined && graphData.length > 0 ? '' : <>No Data Avaliable</>}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default RefinaryGrm
