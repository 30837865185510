import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import styles from './BuDepotInventory.module.scss'
import BuDepotInventoryChart, { prepareInventoryChartData } from './BuDepotInventoryChart'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchRetailDepotInventory, fetchupdatedStatus } from '../../../services/bu'
import { isEmpty } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuDepotInventory = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [graphData, setGraphData] = useState(null)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "retail" && res.kpi_name === "Inventory" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchRetailDepotInventory(props.year)
            .then(response => {
                const aggregatedData = prepareInventoryChartData(response.data)
                if (isEmpty(aggregatedData)) {
                    setIsNoDataAvailable(true)
                } else {
                    setGraphData(aggregatedData)
                    setIsNoDataAvailable(false)
                }
            })
            .catch(error => {
                console.debug(error)
                setIsNoDataAvailable(true)
            })
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuDepotInventoryContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex justify-content-between ${styles.BuCcsHeader}`}>
                    <div className={`d-flex flex-column justify-content-center ${styles.BuCcsHeaderLeft}`}>

                        <div className={`${styles.HeaderTop}`}>Inventory - Daily &nbsp;&nbsp;&nbsp;&nbsp; Total &#40;MS+HSD&#41;</div>
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                        <div className={`d-flex ${styles.legendsContainer}`}>
                            {/* <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot1}`}></span> {'NORM'}
                            </div> */}
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot2}`}></span>Actual
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot4}`}></span>{'Monthly Average Inventory'}
                            </div>
                        </div>
                    </div>
                    <div className={` d-flex flex-column justify-content-center ${styles.BuCcsHeaderRight}`}>
                        {/* <span className={`${styles.BuCcsHeaderResolved}`}>Total Depos: 80</span> */}
                    </div>
                </div>
                {
                    isNoDataAvailable ? <NoDataFound height={507} /> :
                        <>
                            <div className={`d-flex align-items-center justify-content-center ${styles.graphContainer}`}>
                                <div className="d-flex flex-column align-items-center mr-4">
                                    {
                                        graphData ?
                                            <BuDepotInventoryChart activeTab={props.activeTab} graphData={graphData}></BuDepotInventoryChart>
                                            :
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                    }
                                </div>
                            </div>
                            <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' id="fullDt" data-fs="handleenter" onClick={fullScrn}></img>
                            </div>
                        </>
                }
            </FullScreen>
        </div>
    )
}
export default BuDepotInventory