// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InfographicKpiRound_cduContainer__hArvY {\n  position: absolute;\n  height: 140px;\n  min-width: 140px;\n  background-color: #1f2532;\n  border-radius: 50%;\n  border: solid 2.5px #25ceda;\n  font-size: 27px; }\n  .InfographicKpiRound_cduContainer__hArvY .InfographicKpiRound_cduLeft__2s60G {\n    padding-top: 10px;\n    flex: 1 1;\n    height: 50px;\n    font-weight: bold;\n    color: #dee5ef;\n    border-bottom: 3px solid #979797; }\n  .InfographicKpiRound_cduContainer__hArvY .InfographicKpiRound_cduRight__NsqWn {\n    flex: 1 1;\n    padding-bottom: 10px;\n    height: 50px;\n    color: #06bee1; }\n", ""]);
// Exports
exports.locals = {
	"cduContainer": "InfographicKpiRound_cduContainer__hArvY",
	"cduLeft": "InfographicKpiRound_cduLeft__2s60G",
	"cduRight": "InfographicKpiRound_cduRight__NsqWn"
};
module.exports = exports;
