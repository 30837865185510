// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DistillateYieldChart_grmGraph__2bF2w {\n  position: relative;\n  height: 384px; }\n\n.DistillateYieldChart_legendsContainer__3o_gd {\n  font-family: Titillium Web;\n  font-size: 22px;\n  padding: 18px 36px 0px 36px; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_leftLegend__GRKeS .DistillateYieldChart_actualMumbai__1m44Q {\n    font-weight: bold;\n    color: #3873da;\n    height: 20px; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_leftLegend__GRKeS .DistillateYieldChart_actualKochi__2tSop {\n    font-weight: bold;\n    color: #8e56e8;\n    height: 20px; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_leftLegend__GRKeS .DistillateYieldChart_actualBina__2Q8Er {\n    font-weight: bold;\n    color: #99eb6a;\n    height: 20px; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_leftLegend__GRKeS .DistillateYieldChart_target__3xWFn {\n    color: #a1adbb;\n    font-size: 22px;\n    height: 20px;\n    font-style: italic; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_rightLegend__2zcTL .DistillateYieldChart_actualMumbai__1m44Q {\n    font-weight: bold;\n    color: #15bccb;\n    height: 20px; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_rightLegend__2zcTL .DistillateYieldChart_actualKochi__2tSop {\n    font-weight: bold;\n    color: #cd95ec;\n    height: 20px; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_rightLegend__2zcTL .DistillateYieldChart_actualBina__2Q8Er {\n    font-weight: bold;\n    color: #d2f5bf;\n    height: 20px; }\n  .DistillateYieldChart_legendsContainer__3o_gd .DistillateYieldChart_rightLegend__2zcTL .DistillateYieldChart_target__3xWFn {\n    color: #a1adbb;\n    font-size: 22px;\n    height: 20px;\n    font-style: italic; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "DistillateYieldChart_grmGraph__2bF2w",
	"legendsContainer": "DistillateYieldChart_legendsContainer__3o_gd",
	"leftLegend": "DistillateYieldChart_leftLegend__GRKeS",
	"actualMumbai": "DistillateYieldChart_actualMumbai__1m44Q",
	"actualKochi": "DistillateYieldChart_actualKochi__2tSop",
	"actualBina": "DistillateYieldChart_actualBina__2Q8Er",
	"target": "DistillateYieldChart_target__3xWFn",
	"rightLegend": "DistillateYieldChart_rightLegend__2zcTL"
};
module.exports = exports;
