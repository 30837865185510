import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import { getGradient1, numberFormatEnIn } from '../../utility/utility'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const BarGraph = (props) => {

    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
    function getData() {
        // console.debug("MOU............",props.graphData.data);
        return props.graphData.data
    }

    function setBackgroundGradient(ctx) {
        const gradientTempArray = []
        props.kpiDetails.forEach((item, index) => {
            let barGradient = ctx.createLinearGradient(0, 0, 0, 600)
            //barGradient.addColorStop(0, getGradient0(item.rating))
            barGradient.addColorStop(1, getGradient1(item.rating))
            gradientTempArray.push(barGradient)
        })
        // if (props.kpiListData.title.trim() === 'Mandatory Kpis') {
        //   gradientTempArray.shift();
        // }
        return gradientTempArray
    }


    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }
        const ctx = chartContainer.current.getContext('2d')
        const gradientArray = setBackgroundGradient(ctx)
        //console.debug(props.graphData.data)

        const chartConfig = {
            type: 'bar',
            borderSkipped: 'bottom',
            data: {
                barThickness: 100,
                labels: props.graphData.labels,
                datasets: [
                    {
                        barPercentage: 0.6,
                        label: 'Actual Achievements',
                        data: props.graphData.data,
                        // data: getData(),
                        backgroundColor: gradientArray,
                        datalabels: {
                            display: false,
                            fontSize: 18,
                            color: '#fff',
                            anchor: 'center',
                            align: 'center'
                        },
                        order: 2
                    },
                    {
                        label: 'Excellent Target',
                        data: props.graphData.target,
                        borderColor: '#009fff',
                        borderWidth: 2,
                        datalabels: {
                            display: false,
                            fontSize: 18,
                            color: '#fff',
                            anchor: 'center',
                            align: 'end'
                        },
                        type: 'line',
                        fill: false,
                        order: 1,
                    },
                ]
            },
            options: {
                elements: {
                    line: {
                        tension: 0.4,
                    },
                },
                responsive: true,
                legend: {
                    display: true,
                    labels: {
                        fontColor: "#fff"
                    },
                    position: "top",
                    align: "end"
                },
                tooltips: {
                    mode: 'single',
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ':' + numberFormatEnIn(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                        }
                    }
                },
                // annotation: {
                //     annotations: [{
                //         type: 'line',
                //         mode: 'horizontal',
                //         drawTime: 'afterDatasetsDraw',
                //         scaleID: 'y-axis-0',
                //         value: props.latestQuaterData.quaterly_target,
                //         borderColor: '#009fff',
                //         borderWidth: 2,
                //         label: {
                //             enabled: true,
                //             content: ''
                //         }
                //     }]
                // },
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: true,
                            offsetGridLines: true,
                            color: '#67758d',
                            tickMarkLength: 0
                        },
                        ticks: {
                            padding: 20,
                            fontColor: '#dee5ef',
                            fontSize: '22',
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: true,
                            color: '#67758d',
                            tickMarkLength: 0
                        },
                        ticks: {
                            padding: 20,
                            fontColor: '#dee5ef',
                            fontSize: '22',
                            beginAtZero: true,
                            //callback: function (value) { return value.toFixed(1)},
                            callback: function (value) { return value },
                        },
                    }],
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)

            setChartInstance(newChartInstance)
        }
    }, [props.graphData, props.latestQuaterData]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <canvas
            ref={chartContainer}
        />
    )
}
export default BarGraph