import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './BpclMarket.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function BpclMarketGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')

        let bpclclose = props.senxClose.filter(value => {
            if(value !== null){
                return value
            }
        })

        const chartConfig = {
            type: 'line',
            data: {
                labels: props.senxDate,
                datasets: [
                    {
                        borderColor: props.deltavalue.includes('-') ? 'rgb(255, 91, 79)' : '#74ce12',
                        pointBorderWidth: 4,
                        pointBackgroundColor: props.deltavalue.includes('-') ? 'rgb(255, 91, 79)' : '#74ce12',
                        pointBorderColor: props.deltavalue.includes('-') ? 'rgb(255, 91, 79)' : '#74ce12',
                        borderWidth: 4,
                        label: 'Actual',
                        data: bpclclose,
                        pointRadius : 0,
                        //pointStyle : 'triangle',
                        //pointRotation : 175,
                        //pointRadius : 0,
                        fill: false,
                        // backgroundColor: "rgb(222, 172, 245)",
                        datalabels: {
                            display: false,
                        },
                    },
                ]
            },
            options: {
                // barRoundness: 1,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            display: false,
                            ticks: {
                                display: false,
                                beginAtZero : true
                            },
                        },
                    ],

                    yAxes: [
                        {
                            display : false,
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                min : Math.min(...bpclclose),
                                max : Math.max(...bpclclose),
                                //stepSize : 1
                            },
                        },
                    ]
                },
                legend: {
                    display: false
                },
                layout : {
                    padding : {
                        left : 30,
                        top : 30,
                        right : 50,
                        bottom : 20
                    }
                }
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer,props.senxDate,props.senxClose,props.deltavalue]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.BpclGraph}`}>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}
export default BpclMarketGraph