import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import { get, reduce, has } from 'lodash'
import { numberFormatNoSeparator } from '../../../utility/utility'
import classes from './BuProjects.module.scss'
import Checkbox from '../../Checkbox.js/Checkbox'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const getCompletionDataSetArray = (data) => ([
    (get(data, 'completed_lt_50_percent') || 0),
    (get(data, 'completed_50_75_percent') || 0),
    (get(data, 'completed_75_90_percent') || 0),
    (get(data, 'completed_gt_90_percent') || 0),
])
const xLabels = ['< 50', '50-75', '75-90', '>90']

const BuProjectsPlanningGraph = ({graphData}) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
    const [estdCompletionFilters, setEstdCompletionFilters] = useState({
        'O_to_3': true,
        '3_to_6': true,
        'gt_6': true,
        'delayed': true,
    })
    const allEstdCompletionFilter = estdCompletionFilters['O_to_3'] && estdCompletionFilters['3_to_6'] && estdCompletionFilters['gt_6'] && estdCompletionFilters['delayed']

    const toggleFilter = (event) => {
        const filterFor = event.name
        if (filterFor === 'all') {
            setEstdCompletionFilters(prev => {
                const newAllValue = !(prev['O_to_3'] && prev['3_to_6'] && prev['gt_6'] && prev['delayed'])

                return {
                    'O_to_3': newAllValue,
                    '3_to_6': newAllValue,
                    'gt_6': newAllValue,
                    'delayed': newAllValue,
                }
            })
        } else if (has(estdCompletionFilters, filterFor)) {
            setEstdCompletionFilters(prev => ({
                ...prev,
                [filterFor]: !prev[filterFor]
            }))
        }
    }

    const getChartConfig = () => {
        const ongoingGraphdata = graphData.ongoing || {}
        let completion_O_to_3 = Array(4).fill(0)
        let completion_3_to_6 = Array(4).fill(0)
        let completion_gt_6 = Array(4).fill(0)
        let completion_delayed = Array(4).fill(0)

        if (estdCompletionFilters['O_to_3']) {
            completion_O_to_3 = getCompletionDataSetArray(ongoingGraphdata[0])
        }

        if (estdCompletionFilters['3_to_6']) {
            completion_3_to_6 = getCompletionDataSetArray(ongoingGraphdata[1])
        }

        if (estdCompletionFilters['gt_6']) {
            completion_gt_6 = getCompletionDataSetArray(ongoingGraphdata[2])
        }

        if (estdCompletionFilters['delayed']) {
            completion_delayed = getCompletionDataSetArray(ongoingGraphdata[3])
        }
        
        const totals = reduce([completion_O_to_3, completion_3_to_6, completion_gt_6, completion_delayed], (acc, eachDataSet) => {
            return [
                acc[0] + eachDataSet[0],
                acc[1] + eachDataSet[1],
                acc[2] + eachDataSet[2],
                acc[3] + eachDataSet[3]
            ]
        }, Array(4).fill(0))

        const ctx = chartContainer.current.getContext('2d')
        let gradientForO_to_3 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForO_to_3.addColorStop(1, '#00a8ec')//dark blue
        gradientForO_to_3.addColorStop(0, '#00e0bc')

        let gradientFor3_to_6 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientFor3_to_6.addColorStop(0, '#ffbf45')//green
        gradientFor3_to_6.addColorStop(1, '#ff8309')

        let gradientForGt_6 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForGt_6.addColorStop(0, '#ff7bda')//purple
        gradientForGt_6.addColorStop(1, '#e640b7')

        let gradientForDelayed = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForDelayed.addColorStop(0, '#ff588c')//pink
        gradientForDelayed.addColorStop(1, '#fac159')

        const chartConfig = {
            type: 'bar',
            data: {
                labels: xLabels,
                datasets: [
                    {
                        label: '0-3 months',
                        data: completion_O_to_3,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradientForO_to_3,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        },
                    },
                    {
                        label: '3-6 months',
                        data: completion_3_to_6,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradientFor3_to_6,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        },
                    },
                    {
                        label: '>6 months',
                        data: completion_gt_6,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradientForGt_6,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        },
                    },
                    {
                        label: 'Delayed',
                        data: completion_delayed,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradientForDelayed,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        },
                    },
                    {
                        label: totals,
                        data: Array(4).fill(0),
                        backgroundColor: 'transparent',
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            color: '#dee5ef',
                            font: {
                                size: 20,
                            },
                            formatter: function (_, context) {
                                const value = context.dataset.label[context.dataIndex]
                                return numberFormatNoSeparator(value)
                            }
                        },
                    },
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                labelString: 'Percentage Completion',
                                fontColor: '#dee5ef',
                                fontSize: 24,
                            },
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                labelString: 'Ongoing projects',
                                fontColor: '#dee5ef',
                                fontSize: 24,
                            },
                            stacked: true,
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 4
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#2f3b51',
                                zeroLineWidth: 1
                            }
                        },
                        {
                            scaleLabel: {
                                display: false,//currently not required
                                fontColor: '#64779c',
                                fontSize: 24,
                            },
                            stacked: true,
                            display: false,//currently not required
                            position: 'right',
                            // beginAtZero: true,
                            ticks: {
                                maxTicksLimit : 4,
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#2f3b51',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }

        return chartConfig
    }

  
    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = getChartConfig()

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, graphData, estdCompletionFilters]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`d-flex ${classes.BuProjectsPlanningGraph}`}>
            <div className='col'>
                <canvas ref={chartContainer} height={330} width={700} />
            </div>
            <div className={`col ${classes.estdCompletionFilters}`}>
                Estd. Completion
                <Checkbox
                    size='sm'
                    name='all'
                    label='All'
                    checked={allEstdCompletionFilter}
                    onChange={toggleFilter}
                ></Checkbox>
                <Checkbox
                    size='sm'
                    name='O_to_3'
                    label='0-3 months'
                    checked={estdCompletionFilters['O_to_3']}
                    onChange={toggleFilter}
                ></Checkbox>
                <Checkbox
                    size='sm'
                    name='3_to_6'
                    label='3-6 months'
                    checked={estdCompletionFilters['3_to_6']}
                    onChange={toggleFilter}
                ></Checkbox>
                <Checkbox
                    size='sm'
                    name='gt_6'
                    label='>6 months'
                    checked={estdCompletionFilters['gt_6']}
                    onChange={toggleFilter}
                ></Checkbox>
                <Checkbox
                    size='sm'
                    name='delayed'
                    label='Delayed'
                    checked={estdCompletionFilters['delayed']}
                    onChange={toggleFilter}
                ></Checkbox>
            </div>
        </div>
    )
}
export default BuProjectsPlanningGraph