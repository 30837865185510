import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './BuIncCcsPieChart.module.scss'
import {get, isEmpty} from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const BuIncCcsPieChart = (props) => {

    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [topTenComplaintsGraphData, setTopTenCompaintsGraphData] = useState(props.topTenDetails)
    const [showPercentages] = useState(Boolean(props.showPercentages))
    const [customerComplaintsData, setCustomerComplaintData] = useState({status: false})
    const handleModal = () => {
        setCustomerComplaintData({status: false})
    }
    const menuRef = useRef();
    useEffect (() => {
        let handler = (e) => {
            if(menuRef.current && !menuRef.current.contains(e.target)){
                setCustomerComplaintData(false);
            }
        };
        document.addEventListener("mousedown",handler);
        return() => {
            document.removeEventListener("mousedown",handler);
        }
    }, [setCustomerComplaintData]);

    let tooltipLabelCallback = (tooltipItems, data) => {
        return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
    }

    let dataLabelFormatterCallback = (value) => (value != 0) ? value : ''

    if (showPercentages) {
        let total
        if (props.year >= "2023") {
            total = get(props, 'graphData.COMPLAINTS_RECEIVED')
        } else {
            total = get(props, 'graphData.less_than_two_days') + get(props, 'graphData.two_to_five_days') + get(props, 'graphData.six_to_ten_days') + get(props, 'graphData.more_than_ten_days')
        }        
        const getPercentageValue = (value) => {
            value = Number(
                ((value * 100) / total) || 0
            ).toFixed(1)
            if(value === '0.0'){
                return ''
            }else{
                // return isNaN(value) ? '': `${value}%`
                return isNaN(value) || value==100 ? '': `${value}%`
            }
        }
        
        dataLabelFormatterCallback = getPercentageValue
        tooltipLabelCallback = (tooltipItems, data) => {
            return data.datasets[tooltipItems.datasetIndex].label[tooltipItems.index] + ' : ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
        }

    }

    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

  
    useEffect(() => {
        let actualArray = []

        if (props.year >= "2023") {
            if (props.graphData && props.openStatus) {
                // actualArray = [props.graphData.less_than_two_days, props.graphData.two_to_five_days, props.graphData.six_to_ten_days, props.graphData.more_than_ten_days]
                actualArray = [props.graphData.OPENED_SINCE_2DAYS, props.graphData.OPENED_BETWEEN_2_TO_5DAYS,
                props.graphData.OPENED_BETWEEN_6_TO_10DAYS, props.graphData.OPENED_SINCE_10DAYS]
            }
            if (props.graphData && props.resolvedStatus) {
                actualArray = [props.graphData.CLOSED_IN_2DAYS, props.graphData.CLOSED_BETWEEN_2_TO_5DAYS,
                props.graphData.CLOSED_BETWEEN_6_TO_10DAYS, props.graphData.CLOSED_AFTER_10DAYS]
            }
        } else {
            actualArray = [props.graphData.less_than_two_days, props.graphData.two_to_five_days, props.graphData.six_to_ten_days, props.graphData.more_than_ten_days]
        }
  
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')

        let lessThanTwoGrad = ctx.createLinearGradient(0, 0, 0, 500)
        lessThanTwoGrad.addColorStop(1, '#00a8ec')//dark blue
        lessThanTwoGrad.addColorStop(0, '#00e0bc')

        let twoToFiveGrad = ctx.createLinearGradient(0, 0, 0, 500)
        twoToFiveGrad.addColorStop(0, '#ffbf45')//green
        twoToFiveGrad.addColorStop(1, '#ff8309')

        let sixToTenGrad = ctx.createLinearGradient(0, 0, 0, 500)
        sixToTenGrad.addColorStop(0, '#ff7bda')//purple
        sixToTenGrad.addColorStop(1, '#e640b7')

        let moreThanTenGrad = ctx.createLinearGradient(0, 0, 0, 500)
        moreThanTenGrad.addColorStop(0, '#ff588c')//pink
        moreThanTenGrad.addColorStop(1, '#fac159')

        const chartConfig = {
            type: 'pie',
            data: {
                datasets: [
                    // {
                    //   label: ["One", "two", "three"],
                    //   data: [125, 80, 80, 110],
                    //   datalabels: {
                    //     display: false,
                    //     // font: {
                    //     //   size: 25,
                    //     // },
                    //     // color: "white"
                    //   },
                    //   backgroundColor: ["#a241f7", "transparent", "transparent", "transparent"],
                    //   borderWidth: [0, 2, 2, 2],
                    //   borderColor: ["a241f7", "#202c3f", "#202c3f", "#202c3f"],

                    //   // borderColor: "#a241f7",
                    //   hoverBorderWidth: 0,
                    //   hoverBorderColor: ["#a241f7", "transparent", "transparent", "transparent"],

                    //   hoverBackgroundColor: ["#a241f7", "transparent", "transparent", "transparent"],
                    // },
                    {
                        label: ['< 2 days', '2-5 days', '6-10 days', '> 10 days'],
                        data: actualArray,
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            font: {
                                size: 18,
                            },
                            color: 'white'
                        },
                        //backgroundColor: ["#00a8ec", "#ffbf45", "#ff7bda", "#ff588c"],
                        backgroundColor: [lessThanTwoGrad, twoToFiveGrad, sixToTenGrad, moreThanTenGrad],
                        borderWidth: 0,
                        // borderWidth: [0, 2, 2, 2],
                        borderColor: '#202c3f',
                        // borderColor: ["00e0bc", "#202c3f", "#202c3f", "#202c3f"],
                        weight: 4,
                        hoverBorderWidth: 0,
                        hoverBorderColor: [lessThanTwoGrad, twoToFiveGrad, sixToTenGrad, moreThanTenGrad],
                        hoverBackgroundColor: [lessThanTwoGrad, twoToFiveGrad, sixToTenGrad, moreThanTenGrad]
                    },

                ]
            },
            options: {
                plugins: {
                    datalabels: {
                        formatter: dataLabelFormatterCallback
                    }
                },
                layout: {
                    padding: {
                        left: 30,
                        right: 30,
                        top: 20,
                        bottom: 80
                    }
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                rotation: -1,
                responsive: true,
                maintainAspectRatio: false,
                tooltips : {
                    enabled : true,
                    mode : 'label',
                    callbacks: {
                        label: tooltipLabelCallback
                    }
                }
            },
            plugins: [{
                afterUpdate: function (chart) {
                    let maxNo = 0
                    let maxNoIndex = 0
                    for (let i in chart.config.data.datasets) {
                        let dataset = chart.config.data.datasets[i]
                        maxNo = Math.max(...dataset.data)
                        maxNoIndex = dataset.data.indexOf(maxNo)

                        for (let j = chart.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                            // let model = chart.getDatasetMeta(i).data[j]._model;
                            if (j === maxNoIndex) {
                                chart.getDatasetMeta(i).data[maxNoIndex]._model.outerRadius = chart.outerRadius + 30
                            }
                        }
                    }
                },
            }],
            legend: {
                display: false
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
            ctx.canvas.onclick = function (evt) {
                var activePoints = newChartInstance.getElementsAtEvent(evt);
                if (activePoints[0]) {
                    var chartData = activePoints[0]['_chart'].config.data;
                    var idx = activePoints[0]['_index'];
                    var value = chartData.datasets[0].data[idx];
                    var label = chartData.datasets[0].data[idx];

                    setCustomerComplaintData({ status: true })
                }
            }
        }
    }, [chartContainer, props.graphData, showPercentages]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.graph}`}>
            <canvas
                ref={chartContainer}
                height="300"
                width="300"
            />
            {customerComplaintsData.status === true && props.openStatus === true &&
                <div className={`${styles.tableHeaderRow}`} ><div className={`${styles.modalAdd}`} ref={menuRef} >
                <div className={`${styles.modaldialog}`}>
                    <div className={`d-flex justify-content-between ${styles.modalheader}`}>
                    <h5 style={{ fontWeight: 'bold' }}>CUSTOMER COMPLAINTS</h5>                            
                        <FontAwesomeIcon icon={faTimesCircle} color={'#000'} size={'lg'} cursor={'pointer'} onClick={handleModal} />
                    </div>
                    <ul style={{ marginLeft: '-10px', marginBottom: 0 }}>
                            {/* <li>{customerComplaintsData.value1}</li>  */}
                            {
                                topTenComplaintsGraphData.length > 0 ?
                                    topTenComplaintsGraphData.map((todo) => (
                                        <li key={todo.CASE_NUMBER}>
                                            {todo.CASE_DESCRIPTION}
                                        </li>
                                    )) :
                                    <li>
                                        No Data Available
                                    </li>
                            }
                        </ul>
                </div>
            </div></div> 
            }
        </div>
    )
}
export default BuIncCcsPieChart

