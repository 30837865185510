import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import classes from './BuProjectGraph1.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const xLabels = ['Refineries', 'Renewable Energy', 'Retail', 'Pipeline', 'Lube','LPG', 'HRS', 'Gas', 'Bio Fuel']

const BuProjectGraph1 = ({graphData}) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    const getChartConfig = () => {
        let noOfProject = [] 
        let delayProject = []
        if(graphData.length>0){
        graphData.forEach(item => {
                if(item.business_unit === 'refineries'){
                    noOfProject[0] = item.no_of_project
                    delayProject[0] = item.no_of_delayed_project
                }
                if(item.business_unit === 'renewable energy'){
                    noOfProject[1] = item.no_of_project
                    delayProject[1] = item.no_of_delayed_project
                }if(item.business_unit === 'retail'){
                    noOfProject[2] = item.no_of_project
                    delayProject[2] = item.no_of_delayed_project
                }if(item.business_unit === 'pipeline'){
                    noOfProject[3] = item.no_of_project
                    delayProject[3] = item.no_of_delayed_project
                }if(item.business_unit === 'lube'){
                    noOfProject[4] = item.no_of_project
                    delayProject[4] = item.no_of_delayed_project
                }if(item.business_unit === 'lpg'){
                    noOfProject[5] = item.no_of_project
                    delayProject[5] = item.no_of_delayed_project
                }
                if(item.business_unit === 'hrs'){
                    noOfProject[6] = item.no_of_project
                    delayProject[6] = item.no_of_delayed_project
                }if(item.business_unit === 'gas'){
                    noOfProject[7] = item.no_of_project
                    delayProject[7] = item.no_of_delayed_project
                }if(item.business_unit === 'bio fuel'){
                    noOfProject[8] = item.no_of_project
                    delayProject[8] = item.no_of_delayed_project
                }
        })
    }
        const ctx = chartContainer.current.getContext('2d')
        let gradientForO_to_3 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForO_to_3.addColorStop(1, '#0077ff')//dark blue
        gradientForO_to_3.addColorStop(0, '#0d7dfc')

        let gradientFor3_to_6 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientFor3_to_6.addColorStop(0, '#ff7700')//orange
        gradientFor3_to_6.addColorStop(1, '#fc7b0a')

        const chartConfig = {
            type: 'horizontalBar',
            data: {
                labels: xLabels,
                datasets: [
                    {
                        label: 'No. of Projects',
                        backgroundColor: gradientForO_to_3,
                        data: noOfProject,
                        barThickness: 15,
                        datalabels: {
                            formatter: function (value, context) {
                                return value > 0 ? `${noOfProject[context.dataIndex]}` : "" 
                            },
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        }
                    },
                    {
                        label: 'No. of Delayed Projects',
                        backgroundColor: gradientFor3_to_6,
                        data: delayProject,
                        barThickness: 15,
                        datalabels: {
                            formatter: function (value, context) {
                                return value > 0 ? `${delayProject[context.dataIndex]}` : "" 
                            },
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        }
                    }
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: true,
                            },
                            scaleLabel: {
                                display: true,
                                // labelString: 'Cr',
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 0,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 6
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            }

                        }
                    ]
                },
            },
        }

        return chartConfig
    }

  
    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = getChartConfig()

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, graphData]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`d-flex ${classes.BuProjectsDelayGraph}`}>
            <div className='col'>
                <canvas ref={chartContainer} height={499} width={700} />
            </div>
        </div>
    )
}
export default BuProjectGraph1