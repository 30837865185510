import React, { useEffect, useState, useCallback } from "react";
import styles from "./EthicalHr.module.scss";
import ExpandIcon from "../../../assets/images/expandIcon.png";
import EthicalHrGraph from "./EthicalHrBar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { fetchEthicalHr, fetchupdatedStatus } from "../../../services/Org";
import { NoDataFound } from "../../DataNotFound/nodatafound";
import Radio from "../../Itrm/ItrmDemurrage/Radio";
import { last } from "lodash";
import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
import "../../../Common.scss"

const EthicalHr = (props) => {
  const [loading, setLoading] = useState(true);
  const [alldata, setalldata] = useState(true);
  const [retaildata, setretaildata] = useState(false);
  const [atfdata, setatfdata] = useState(false);
  const [lpgdata, setlpgdata] = useState(false);
  const [lubesdata, setlubesdata] = useState(false);
  // const [refinerydata, setrefinerydata] = useState(false);
  const [refinerydatam, setrefinerydatam] = useState(false);
  const [refinerydatak, setrefinerydatak] = useState(false);
  const [refinerydatab, setrefinerydatab] = useState(false);
  const [incdata, setincdata] = useState(false);
  const [graphdata, setgraphdata] = useState([]);
  const [entitiesdata, setentitiesdata] = useState(false);
  const [gasdata, setgasdata] = useState(false);
  // const [depdata, setdepdata] = useState(false);
  // retirement counts
  const [retirecountr, setretirecountr] = useState(0); //retail
  const [retirecountall, setretirecountall] = useState(0); // all
  const [retirecounta, setretirecounta] = useState(0); //avi
  const [retirecountl, setretirecountl] = useState(0); //lpg
  const [retirecountlu, setretirecountlu] = useState(0); //lubes
  // const [retirecountref, setretirecountref] = useState(0); //refi
  const [retirecountrefm, setretirecountrefm] = useState(0); //Mrefi
  const [retirecountrefk, setretirecountrefk] = useState(0); //Krefi
  const [retirecountrefb, setretirecountrefb] = useState(0); //Brefi
  const [retirecountinc, setretirecountinc] = useState(0); //i&c
  const [retirecountent, setretirecountent] = useState(0); //enti
  const [retirecountgas, setretirecountgas] = useState(0); //gas
  // const [retirecountdep, setretirecountdep] = useState(0); //depu
  //retired countes
  const [retiredcountr, setretiredcountr] = useState(0); //retail
  const [retiredcountall, setretiredcountall] = useState(0); // all
  const [retiredcounta, setretiredcounta] = useState(0); //avi
  const [retiredcountl, setretiredcountl] = useState(0); //lpg
  const [retiredcountlu, setretiredcountlu] = useState(0); //lubes
  // const [retiredcountref, setretiredcountref] = useState(0); //refi
  const [retiredcountrefm, setretiredcountrefm] = useState(0); //Mrefi
  const [retiredcountrefk, setretiredcountrefk] = useState(0); //Krefi
  const [retiredcountrefb, setretiredcountrefb] = useState(0); //Brefi
  const [retiredcountinc, setretiredcountinc] = useState(0); //i&c
  const [retiredcountent, setretiredcountent] = useState(0); //enti
  const [retiredcountgas, setretiredcountgas] = useState(0); //gas
  const [updatedStatus, setUpdatedStatus] = useState('')

  // const [retiredcountdep, setretiredcountdep] = useState(0); //depu
  //const colors = ['#ff6271', '#b5424d', '#ab202d', '#7b0712', '#f097a0', '#aa696e']
  const handle = useFullScreenHandle();
  const [attr, setattr] = useState("handleenter");
  const fullScrn = () => {
    if (attr === "handleenter") {
      setattr("handleexit");
      handle.enter();
      document.body.style.zoom = window.innerWidth / window.outerWidth;
    } else if (attr === "handleexit") {
      setattr("handleenter");
      handle.exit();
      document.body.style.zoom = 0;
    }
  };
  const reportChange = useCallback(
    (state) => {
      try {
        if (state === false) {
          document.body.style.zoom = 0;
        }
      } catch (exception) {
        console.debug(exception);
      }
    },
    [handle]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  const onCheckStateChangedehr = (event) => {
    if (event.name === "allBox") {
      setalldata(true);
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "retBox") {
      setretaildata(true);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "lpgBox") {
      setretaildata(false);
      setlpgdata(true);
      setatfdata(false);
      setlubesdata(false);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "atfBox") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(true);
      setlubesdata(false);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "lubBox") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(true);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    // if (event.name === "refBox") {
    //   setretaildata(false);
    //   setlpgdata(false);
    //   setatfdata(false);
    //   setlubesdata(false);
    //   setrefinerydata(true);
    //   setincdata(false);
    //   setalldata(false);
    //   setentitiesdata(false);
    //   setgasdata(false);
    //   setdepdata(false);
    // }
    if (event.name === "refBoxm") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      setrefinerydatam(true);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "refBoxk") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      setrefinerydatam(false);
      setrefinerydatak(true);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "refBoxb") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(true);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "incBox") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(true);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "entBox") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(true);
      setgasdata(false);
      // setdepdata(false);
    }
    if (event.name === "gasBox") {
      setretaildata(false);
      setlpgdata(false);
      setatfdata(false);
      setlubesdata(false);
      // setrefinerydata(false);
      setrefinerydatam(false);
      setrefinerydatak(false);
      setrefinerydatab(false);
      setincdata(false);
      setalldata(false);
      setentitiesdata(false);
      setgasdata(true);
      // setdepdata(false);
    }
    // if (event.name === "depBox") {
    //   setretaildata(false);
    //   setlpgdata(false);
    //   setatfdata(false);
    //   setlubesdata(false);
    //   setrefinerydata(false);
    //   setincdata(false);
    //   setalldata(false);
    //   setentitiesdata(false);
    //   setgasdata(false);
    //   setdepdata(true);
    // }
  };

  useEffect(() => {
    fetchupdatedStatus(props.year)
      .then(response => {
        if (!isEmpty(response)) {
          response.filter((res) => res.entity === "HR" && res.kpi_name === "Employee Distribution" ? setUpdatedStatus(res.status) : false);
        } else {
          setUpdatedStatus("Not Found")
        }
      })
      .catch(error => console.debug(error))
    function getEhr() {
      let allkpi = [];
      let retail = [];
      let atf = [];
      let lpg = [];
      let lubes = [];
      // let refinery = [];
      let refinerym = [];
      let refineryk = [];
      let refineryb = [];
      let inc = [];
      let ent = [];
      let gas = [];
      // let dep = [];
      fetchEthicalHr(props.year)
        .then((data) => {
          if (data.length === 0) {
            setLoading(true);
          } else {
            setgraphdata(data);
            data.forEach((gethrdata) => {
              if (gethrdata.age_range === ">55") {
                if (gethrdata.business_unit === "all") {
                  allkpi.push(gethrdata.retirement_count);
                  setretirecountall(last(allkpi));
                  allkpi.push(gethrdata.retiree_count);
                  setretiredcountall(last(allkpi));
                } else if (gethrdata.business_unit === "retail") {
                  retail.push(gethrdata.retirement_count);
                  setretirecountr(last(retail));
                  retail.push(gethrdata.retiree_count);
                  setretiredcountr(last(retail));
                } else if (gethrdata.business_unit === "atf") {
                  atf.push(gethrdata.retirement_count);
                  setretirecounta(last(atf));
                  atf.push(gethrdata.retiree_count);
                  setretiredcounta(last(atf));
                } else if (gethrdata.business_unit === "lpg") {
                  lpg.push(gethrdata.retirement_count);
                  setretirecountl(last(lpg));
                  lpg.push(gethrdata.retiree_count);
                  setretiredcountl(last(lpg));
                } else if (gethrdata.business_unit === "lubes") {
                  lubes.push(gethrdata.retirement_count);
                  setretirecountlu(last(lubes));
                  lubes.push(gethrdata.retiree_count);
                  setretiredcountlu(last(lubes));
                // else if (gethrdata.business_unit === "refinery") {
                //   refinery.push(gethrdata.retirement_count);
                //   setretirecountref(last(refinery));
                //   refinery.push(gethrdata.retiree_count);
                //   setretiredcountref(last(refinery));
                } else if (gethrdata.business_unit === "Mumbai") {
                  refinerym.push(gethrdata.retirement_count);
                  setretirecountrefm(last(refinerym));
                  refinerym.push(gethrdata.retiree_count);
                  setretiredcountrefm(last(refinerym));
                } else if (gethrdata.business_unit === "Kochi") {
                  refineryk.push(gethrdata.retirement_count);
                  setretirecountrefk(last(refineryk));
                  refineryk.push(gethrdata.retiree_count);
                  setretiredcountrefk(last(refineryk));
                } else if (gethrdata.business_unit === "Bina") {
                  refineryb.push(gethrdata.retirement_count);
                  setretirecountrefb(last(refineryb));
                  refineryb.push(gethrdata.retiree_count);
                  setretiredcountrefb(last(refineryb));
                } else if (gethrdata.business_unit === "inc") {
                  inc.push(gethrdata.retirement_count);
                  setretirecountinc(last(inc));
                  inc.push(gethrdata.retiree_count);
                  setretiredcountinc(last(inc));
                } else if (gethrdata.business_unit === "others") {
                  ent.push(gethrdata.retirement_count);
                  setretirecountent(last(ent));
                  ent.push(gethrdata.retiree_count);
                  setretiredcountent(last(ent));
                } else if (gethrdata.business_unit === "gas") {
                  gas.push(gethrdata.retirement_count);
                  setretirecountgas(last(gas));
                  gas.push(gethrdata.retiree_count);
                  setretiredcountgas(last(gas));
                }
                //  else if (gethrdata.business_unit === "dep") {
                //   dep.push(gethrdata.retirement_count);
                //   setretirecountdep(last(dep));
                //   dep.push(gethrdata.retiree_count);
                //   setretiredcountdep(last(dep));
                // }
              }
            });
            setLoading(false);
          }
        })
        .catch((error) => console.debug(error));
    }
    getEhr();
  }, [props.year]);
  return (
    <FullScreen handle={handle} onChange={reportChange}>
      <div className={`${styles.LearningDevelopmentContainer}`}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.LearningDevelopmentHeader}`}
        >
          <span>Employees Distribution (Age Wise) - Daily</span>
          {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
          </div>}
          <img
            src={ExpandIcon}
            alt="Expand Learning & Development"
            id="fullSrnehr"
            data-fs="handleenter"
            onClick={fullScrn}
          ></img>
        </div>
        {loading ? (
          <NoDataFound />
        ) : (
          <div className={`d-flex ${styles.graphContainer}`}>
            <div className={`${styles.gContainer}`}>
              <div className={`d-flex ${styles.legendehr}`}>
                {/* <p className={`d-flex mr-3 align-items-center`}><span className={`mr-2 ${styles.staffCostDot}`}></span> Withdrawn</p> */}
                <p className={`d-flex mr-3 align-items-center`}>
                  <span className={`mr-2 ${styles.rmDot}`}></span> Active
                </p>
                {/* <p className={`d-flex mr-3 align-items-center`}><span className={`mr-2 ${styles.thirdPartyDot}`}></span>Retiree</p>
                                <p className={`d-flex mr-3 align-items-center`}><span className={`mr-2 ${styles.salesProDot}`}></span>InActive</p> */}
              </div>
              <EthicalHrGraph
                graphdata={graphdata}
                allGraph={alldata}
                retailGraph={retaildata}
                lpgGraph={lpgdata}
                atfGraph={atfdata}
                lubesGraph={lubesdata}
                // refineryGraph={refinerydata}
                refineryGraphm={refinerydatam}
                refineryGraphk={refinerydatak}
                refineryGraphb={refinerydatab}
                incGraph={incdata}
                entGraph={entitiesdata}
                gasGraph={gasdata}
                // depGraph={depdata}
              ></EthicalHrGraph>
            </div>
            <div
              className={`d-flex flex-column justify-content-center ${styles.graphFilters}`}
            >
              <h3>
                Employees Retired in CFY :{" "}
                <b>
                  {alldata
                    ? retiredcountall
                    : retaildata
                    ? retiredcountr
                    : lpgdata
                    ? retiredcountl
                    : atfdata
                    ? retiredcounta
                    : lubesdata
                    ? retiredcountlu
                    : refinerydatam
                    ? retiredcountrefm
                    : refinerydatak
                    ? retiredcountrefk
                    : refinerydatab
                    ? retiredcountrefb
                    : incdata
                    ? retiredcountinc
                    : entitiesdata
                    ? retiredcountent
                    : gasdata
                    ? retiredcountgas
                    : // : depdata
                      // ? retiredcountdep
                      0}
                </b>
              </h3>
              <h4>
                Employees would retire in CFY :{" "}
                <b>
                  {alldata
                    ? retirecountall
                    : retaildata
                    ? retirecountr
                    : lpgdata
                    ? retirecountl
                    : atfdata
                    ? retirecounta
                    : lubesdata
                    ? retirecountlu
                    : refinerydatam
                    ? retirecountrefm
                    : refinerydatak
                    ? retirecountrefk
                    : refinerydatab
                    ? retirecountrefb
                    : incdata
                    ? retirecountinc
                    : entitiesdata
                    ? retirecountent
                    : gasdata
                    ? retirecountgas
                    : // : depdata
                      // ? retirecountdep
                      0}
                </b>
              </h4>
              <Radio
                size="sm"
                name="allBox"
                label="All"
                checked={alldata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="retBox"
                label="Retail"
                checked={retaildata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="lpgBox"
                label="LPG"
                checked={lpgdata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="atfBox"
                label="Aviation"
                checked={atfdata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="lubBox"
                label="Lubes"
                checked={lubesdata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="gasBox"
                label="Gas"
                checked={gasdata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              {/* <Radio
                size="sm"
                name="refBox"
                label="Refineries"
                checked={refinerydata}
                onClick={(e) => onCheckStateChangedehr(e)}
              /> */}
              <Radio
                size="sm"
                name="refBoxm"
                label="Mumbai Refinery"
                checked={refinerydatam}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="refBoxk"
                label="Kochi Refinery"
                checked={refinerydatak}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="refBoxb"
                label="Bina Refinery"
                checked={refinerydatab}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="incBox"
                label="I&C"
                checked={incdata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              <Radio
                size="sm"
                name="entBox"
                label="Other Units / Entities"
                checked={entitiesdata}
                onClick={(e) => onCheckStateChangedehr(e)}
              />
              {/* <Radio
                size="sm"
                name="depBox"
                label="Deputation"
                checked={depdata}
                onClick={(e) => onCheckStateChangedehr(e)}
              /> */}
            </div>
          </div>
        )}
      </div>
    </FullScreen>
  );
};
export default EthicalHr;
