import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import { numberFormatNoSeparator } from '../../utility/utility';
import styles from './topCountry.module.scss';

Chart.defaults.global.defaultFontFamily = 'Titillium Web';

const TopCountry = (props) => {
    const chartContainer = useRef('barGraph');
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        const percentages = props.data.map(obj => obj.percentage);
        let xTicks =  props.data.map(obj => {
            // Check if the country is "United States of America" and replace it with "USA"
            if (obj.country === "United States of America") {
                return "USA";
            }
            if (obj.country === "United Arab Emirates") {
                return "UAE";
            }
            if (obj.country === "Russian Federation") {
                return "Russia";
            }
            return obj.country;  // Otherwise, keep the original country name
        });

       
        if (chartInstance) {
            chartInstance.destroy();
        }

       
        // incidentData = incidentData.reduce((acc, val, index) => {
        //     acc[xTicks[index]] = val; 
        //     return acc;
        // }, {});

        const ctx = chartContainer.current.getContext('2d');
        let gradient = ctx.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, '#0340bd')
        gradient.addColorStop(1, '#007aff')

        const footer = (tooltipItems) => {
            let sum = 0;
          
            tooltipItems.forEach(function(tooltipItem) {
              sum += tooltipItem.parsed.y;
            });
            return 'Sum: ' + sum;
          };
        const chartConfig = {
            type: 'bar',
            data: {
                labels: xTicks,
                datasets: [
                    {
                        // label: 'Incident reported',
                        data: percentages,
                        categoryPercentage: 0.8,
                        // barPercentage: 0.5,
                        backgroundColor: gradient,
                        borderWidth: 0,
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            color: '#ffffff',
                            font: { size: 14 },
                            formatter: function (value) {
                                return value !== 0 ? `${numberFormatNoSeparator(value)} %` : '';
                            }
                        },
                        order: 2
                    }
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            barPercentage: 0.4,
                            gridLines: {
                                color: 'transparent',
                                offsetGridLines: true,
                                tickMarkLength: 0,
                                // borderDash: [3],
                                // lineWidth: 3,
                                // zeroLineBorderDash: [3]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '15',
                            },
                        },
                    ],

                    // yAxes: [
                    //     {
                    //         stacked: true,
                    //         ticks: {
                    //             display: true,
                    //             padding: 20,
                    //             fontColor: '#dee5ef',
                    //             fontSize: '20',
                    //             maxTicksLimit: 5
                    //         },
                    //         gridLines: {
                    //             color: '#2f3b51',
                    //             // tickMarkLength: 0,
                    //             zeroLineColor: '#67758d',
                    //             zeroLineWidth: 1,
                    //             lineWidth: 3,
                    //         },
                    //         scaleLabel: {
                    //             display: true,
                    //             fontColor: '#dee5ef',
                    //             fontSize: '20',
                    //         },
                    //     },
                    // ]
                },
                legend: {
                    display: false
                },
                plugins: {
                    tooltip: {
                      callbacks: {
                        footer: footer,
                      }
                    }
                  }
            }
        };

        // Create the chart instance
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [props.data, props.count, props.totalMMT]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`flex-grow-1 ${styles.graphContainer}`}>
            <div className={`d-flex justify-content-between align-items-center ${styles.legendsContainer}`}>
                <div className={`d-flex align-items-center justify-content-around p-2 ${styles.tabLabelsContainer}`}>
                    <div className="d-flex align-items-center">
                    Top 5 Crude Suppliers to BPCL ({props.totalMMT} MMT)
                    </div>
                </div>
            </div>
            <div className={`my-4 ${styles.grmGraph}`}>
                <canvas ref={chartContainer} />
            </div>
            <div className={`d-flex align-items-start ${styles.cduContainer}`}>
                <div className={`${styles.cduLeft}`}>&nbsp;&nbsp;Total Crude Suppliers &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className={`d-flex justify-content-center align-items-start ${styles.cduRight}`} style={{ color: `${props.textColor}` }} >{props.count}</div>
            </div>
        </div>
    );
};

export default TopCountry;
