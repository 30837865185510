// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AtfCustomerGrowthGraph_grmGraph__1NKCC {\n  position: relative;\n  width: 715px;\n  margin-top: 36px; }\n  .AtfCustomerGrowthGraph_grmGraph__1NKCC .AtfCustomerGrowthGraph_graphHeader__MSwyx {\n    font-weight: 600;\n    font-size: 24px;\n    color: #fafafa;\n    margin-left: 7px; }\n\n.AtfCustomerGrowthGraph_grmGraph1__2vTp0 {\n  position: relative;\n  width: 715px;\n  margin-top: 34px;\n  margin-left: -18px; }\n  .AtfCustomerGrowthGraph_grmGraph1__2vTp0 .AtfCustomerGrowthGraph_graphHeader__MSwyx {\n    font-weight: 600;\n    font-size: 24px;\n    color: #fafafa;\n    margin-left: 22px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "AtfCustomerGrowthGraph_grmGraph__1NKCC",
	"graphHeader": "AtfCustomerGrowthGraph_graphHeader__MSwyx",
	"grmGraph1": "AtfCustomerGrowthGraph_grmGraph1__2vTp0"
};
module.exports = exports;
