// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FinancialRatio_FinancialRatioContainer__AJI88 {\n  height: 450px;\n  font-family: Titillium Web; }\n  .FinancialRatio_FinancialRatioContainer__AJI88 .FinancialRatio_FinancialRatioHeader__29H_B {\n    height: 84px;\n    background-color: #181e2a;\n    font-size: 28px;\n    font-weight: bold;\n    color: white;\n    padding-left: 24px;\n    padding-right: 24px; }\n\n.FinancialRatio_statusUpdatedImg__3k6U5 {\n  margin-left: 234px;\n  margin-top: -18px; }\n", ""]);
// Exports
exports.locals = {
	"FinancialRatioContainer": "FinancialRatio_FinancialRatioContainer__AJI88",
	"FinancialRatioHeader": "FinancialRatio_FinancialRatioHeader__29H_B",
	"statusUpdatedImg": "FinancialRatio_statusUpdatedImg__3k6U5"
};
module.exports = exports;
