import React, { useEffect, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import styles from './KpisList.module.scss'
import SmallPercentageDoughnut from '../../../charts/SmallPercentageDoughnut'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ExpandedKpi from '../ExpandedKpi/ExpandedKpi'
import { getColor, toTitleCase } from '../../../utility/utility'
import { fetchMouMandatoryKpi, fetchMouOtherKpi } from '../../../services/Mou'
import { NoDataFound } from '../../DataNotFound/nodatafound'

function KpisList(props) {
    const [fetchedKpiData, setFetchedKpiData] = useState([])
    const [loading, setLoading] = useState(true)
    const [lastUpdated, setLastUpdated] = useState([])

    useEffect(() => {
        function fetchKpis() {
            let fetchPromise
            if (props.kpiListData.title === 'Mandatory Kpis') {
                fetchPromise = fetchMouMandatoryKpi(props.year)
            }
            if (props.kpiListData.title === 'Others') {
                fetchPromise = fetchMouOtherKpi()
            }

            fetchPromise && fetchPromise.then(response => {
                if (response.length > 0) {
                    setFetchedKpiData(response)
                    setLastUpdated(response[0].date)
                    // console.debug(response[response.length - 1].date);
                    setLoading(false)
                } else {
                    setLoading(true)
                }
            })
                .catch(error => console.debug(error))
        }
        fetchKpis()
    }, [props.kpiListData.title, props.year])

    return (
        <div className={styles.kpis_container}>
            {/* <div className={`d-flex align-items-center pl-4 ${styles.kpis_header}`}><span>{props.kpiListData.title.toUpperCase()}</span></div> */}
            <Accordion className={`bg-dark ${styles.kpis_list_container}`}>
                {loading
                    ?
                    <div className={`${styles.notFound}`}>
                        <NoDataFound />
                    </div>
                    :
                    props.kpiListData.kpiList.map((kpiTitle, index) => {
                        let currentItem = fetchedKpiData.find((item) => {
                            return item.kpi_name.toLowerCase() === kpiTitle.kpi_name.toLowerCase()
                        })
                        return (currentItem &&
                            <Card key={index} className={`${styles.card_container}`}>
                                <Accordion.Toggle as={Card.Header} eventKey={index + 1} className={`d-flex flex-row justify-content-between align-items-stretch ${styles.kpis_list_item}`}>
                                    <div className={`d-flex align-items-center ${styles.kpi_item_progress_circle}`}>
                                        <SmallPercentageDoughnut percentage={parseFloat((((Number(currentItem.score * 10)) / 10) / currentItem.total_marks) * 100).toFixed(1)} />
                                    </div>
                                    <div className={` d-flex flex-column justify-content-center flex-grow-1 ${styles.kpi_item_title}`}>
                                        <div className={`d-flex align-items-center justify-content-between pr-5 ${styles.kpi_item_score}`}>
                                            <span>{kpiTitle.accordian_name}</span>
                                            {`${Number(currentItem.score).toFixed(2)}/${currentItem.total_marks}`}
                                        </div>
                                        <span className={styles.kpi_value} style={{ color: getColor(currentItem.rating) }}>{toTitleCase(currentItem.rating)}</span>
                                    </div>
                                    <span className="d-flex align-items-center pr-5 mb-4 "><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={index + 1} className={styles.collapse_chart}>
                                    <ExpandedKpi year={props.year} kpiTitle={kpiTitle} latestQuaterData={currentItem} kpiListData={props.kpiListData} />
                                </Accordion.Collapse>
                            </Card>
                        )
                    })
                }
            </Accordion>
            <div className={`d-flex flex-row justify-content-between align-items-center pl-4 pr-4 ${styles.kpis_footer}`}>
                <div className={styles.kpis_footer_left}>Evaluation based on audited results as on <b><i>{new Date(lastUpdated).toDateString()}</i></b></div>
                {/* <div className={styles.kpis_footer_right}>Showing Data for : April 20 - Sept 20</div> */}
            </div>
        </div>

    )
}
export default KpisList