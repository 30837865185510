// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ExpandedKpi_turnover__2Zixs {\n  background-color: #283040;\n  max-width: 1920px;\n  font-family: Titillium Web;\n  color: #dee5ef;\n  border-top: 1px solid #424b5f; }\n  .ExpandedKpi_turnover__2Zixs .ExpandedKpi_kpi_section_header_container__1Xo_- {\n    font-size: 40px;\n    margin-left: 150px; }\n    .ExpandedKpi_turnover__2Zixs .ExpandedKpi_kpi_section_header_container__1Xo_- .ExpandedKpi_span1__3vlbe {\n      font-size: 32px; }\n    .ExpandedKpi_turnover__2Zixs .ExpandedKpi_kpi_section_header_container__1Xo_- .ExpandedKpi_span2__15Vas {\n      font-size: 22px; }\n  .ExpandedKpi_turnover__2Zixs .ExpandedKpi_lineGraph__1EODd {\n    padding: 20px 20px 20px 64px; }\n", ""]);
// Exports
exports.locals = {
	"turnover": "ExpandedKpi_turnover__2Zixs",
	"kpi_section_header_container": "ExpandedKpi_kpi_section_header_container__1Xo_-",
	"span1": "ExpandedKpi_span1__3vlbe",
	"span2": "ExpandedKpi_span2__15Vas",
	"lineGraph": "ExpandedKpi_lineGraph__1EODd"
};
module.exports = exports;
