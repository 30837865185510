import React from 'react'
import styles from './Sensex.module.scss'
import SensexGraph from './SensexGraph'

const Sensex = (props) => {
    return(
        <div className={`${styles.Sensexsection}`}>
            <div className={`${styles.SensexGraphSection}`}>
                <SensexGraph dropdown={props.dropdown} deltavalue={props.sensxDelta} senxDate={props.sensxDate} senxClose={props.sensxClose} />
            </div>
            <div className={`${styles.SensexContent}`}>
                <h4 className={`${styles.SensexContenthead}`}>Sensex {props.sensxDelta && props.sensxDelta.includes('-') === true ? <span className={`${styles.SensexContentheadspanred}`}>&#x2B07;</span> : <span className={`${styles.SensexContentheadspan}`}>&#x2B06;</span> }</h4>
                <p className={`${styles.SensexContentp}`}>{props.sensxClose && parseFloat(props.sensxClose[props.sensxClose.length - 1]).toFixed(2)} INR {
                    props.sensxDelta && props.sensxDelta.includes('-') === true ? 
                        <span className={`${styles.SensexContentpspanred}`}> {props.sensxDelta} ({props.sensxPerc}%)</span> 
                        :<span className={`${styles.SensexContentpspan}`}> +{props.sensxDelta} ({props.sensxPerc}%)</span>
                } 
                </p>
            </div>
        </div>
    )
}
export default Sensex