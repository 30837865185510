import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import styles from './EthicalHrBar.module.scss'
import {last} from 'lodash'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const EthicalHrBar = (props) => {

    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        // Graph Data X axis
        let smaller_than_25 = {active_count : 0}
        let smaller_than_30 = {active_count : 0}
        let smaller_than_35 = {active_count : 0}
        let smaller_than_40 = {active_count : 0}
        let smaller_than_45 = {active_count : 0}
        let smaller_than_50 = {active_count : 0}
        let smaller_than_55 = {active_count : 0}
        let greater_than_55 = {active_count : 0}

        const xLabels = ['<25', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '>55']
        const getAgeData = (data) => {
            switch (data.age_range){
                case '<25': 
                    let d1 = [data]
                    smaller_than_25 = last(d1)
                    break
                case '25-29': 
                    let d2 = [data]
                    smaller_than_30 = last(d2)
                    break
                case '30-34': 
                    let d3 = [data]
                    smaller_than_35 = last(d3)
                    break
                case '35-39': 
                    let d4 = [data]
                    smaller_than_40 = last(d4)
                    break
                case '40-44': 
                    let d5 = [data]
                    smaller_than_45 = last(d5)
                    break
                case '45-49': 
                    let d6 = [data]
                    smaller_than_50 = last(d6)
                    break
                case '50-54': 
                    let d7 = [data]
                    smaller_than_55 = last(d7)
                    break
                case '>55': 
                    let d8 = [data]
                    greater_than_55 = last(d8)
                    break
                default : 
                    return data.age_range
            }
        }
        // graphdata mapping
            props.graphdata.forEach(item => {
                if (props.allGraph) {
                    if(item.business_unit === 'all'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.retailGraph) {
                    if(item.business_unit === 'retail'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.lpgGraph) {
                    if(item.business_unit === 'lpg'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.atfGraph) {
                    if(item.business_unit === 'atf'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.lubesGraph) {
                    if(item.business_unit === 'lubes'){
                        getAgeData(item)
                        return
                    }
                }
                // if (props.refineryGraph) {
                //     if(item.business_unit === 'refinery'){
                //         getAgeData(item)
                //         return
                //     }
                // }
                if (props.refineryGraphm) {
                    if(item.business_unit === 'Mumbai'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.refineryGraphk) {
                    if(item.business_unit === 'Kochi'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.refineryGraphb) {
                    if(item.business_unit === 'Bina'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.incGraph) {
                    if(item.business_unit === 'inc'){
                        getAgeData(item)
                        return
                    }
                }
                
                if (props.entGraph) {
                    if(item.business_unit === 'others'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.gasGraph) {
                    if(item.business_unit === 'gas'){
                        getAgeData(item)
                        return
                    }
                }
                if (props.depGraph) {
                    if(item.business_unit === 'dep'){
                        getAgeData(item)
                        return
                    }
                }
            })

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let lpGradientMumbai = ctx.createLinearGradient(0, 0, 0, 500)
        lpGradientMumbai.addColorStop(0.5, 'rgb(0, 168, 236)')//dark blue
        lpGradientMumbai.addColorStop(0.5, 'rgb(0, 224, 188)')

        let dpGradientMumbai = ctx.createLinearGradient(0, 0, 0, 500)
        dpGradientMumbai.addColorStop(1, '#02d345')//green
        dpGradientMumbai.addColorStop(0, '#5eff91')

        let lpGradientKochi = ctx.createLinearGradient(0, 0, 0, 500)
        lpGradientKochi.addColorStop(1, 'rgb(253, 224, 147)')//purple
        lpGradientKochi.addColorStop(0, 'rgb(250, 189, 90)')

        let dpGradientKochi = ctx.createLinearGradient(0, 0, 0, 500)
        dpGradientKochi.addColorStop(1, 'rgb(255, 177, 179)')//pink
        dpGradientKochi.addColorStop(0, 'rgb(253, 121, 124)')

        let dpGradient = ''
        let lpGradient = ''
        //SET COLORS ACCORDING TO LOCATION
        lpGradient = lpGradientMumbai
        dpGradient = dpGradientMumbai
        const chartConfig = {
            type: 'bar',
            data: {
                labels: xLabels,
                datasets: [
                    // {
                    //     label: 'Withdraw',
                    //     data: [smaller_than_25.withdrawn_count, smaller_than_30.withdrawn_count, smaller_than_35.withdrawn_count, smaller_than_40.withdrawn_count, smaller_than_45.withdrawn_count, smaller_than_50.withdrawn_count, smaller_than_55.withdrawn_count, greater_than_55.withdrawn_count],
                    //     categoryPercentage: 0.8,
                    //     barPercentage: 0.5,
                    //     backgroundColor: lpGradient,
                    //     borderWidth: 0,
                    //     datalabels: {
                    //         display: false,
                    //         anchor: 'end',
                    //         align: 'start',
                    //         color: '#dee5ef',
                    //         font: {
                    //             size: 14
                    //         }
                    //     },
                    // },
                    {
                        label: 'Active',
                        data: [smaller_than_25.active_count, smaller_than_30.active_count, smaller_than_35.active_count, smaller_than_40.active_count, smaller_than_45.active_count, smaller_than_50.active_count, smaller_than_55.active_count, greater_than_55.active_count],
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: dpGradient,
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 14
                            }
                        },
                    },
                    // {
                    //     label: 'Retiree',
                    //     data: [smaller_than_25.retiree_count, smaller_than_30.retiree_count, smaller_than_35.retiree_count, smaller_than_40.retiree_count, smaller_than_45.retiree_count, smaller_than_50.retiree_count, smaller_than_55.retiree_count, greater_than_55.retiree_count],
                    //     categoryPercentage: 0.8,
                    //     barPercentage: 0.5,
                    //     backgroundColor: lpGradientKochi,
                    //     borderWidth: 0,
                    //     datalabels: {
                    //         display: false,
                    //         anchor: 'end',
                    //         align: 'start',
                    //         color: '#dee5ef',
                    //         font: {
                    //             size: 14
                    //         }
                    //     },
                    // },
                    // {
                    //     label: 'InActive',
                    //     data: [smaller_than_25.inactive_count, smaller_than_30.inactive_count, smaller_than_35.inactive_count, smaller_than_40.inactive_count, smaller_than_45.inactive_count, smaller_than_50.inactive_count, smaller_than_55.inactive_count, greater_than_55.inactive_count],
                    //     categoryPercentage: 0.8,
                    //     barPercentage: 0.5,
                    //     backgroundColor: dpGradientKochi,
                    //     borderWidth: 0,
                    //     datalabels: {
                    //         display: false,
                    //         anchor: 'end',
                    //         align: 'start',
                    //         color: '#dee5ef',
                    //         font: {
                    //             size: 14
                    //         }
                    //     },
                    // }
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips : {
                    enabled : true,
                    mode : 'label',
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label + ': ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Age Range',
                                fontColor: '#64779c',
                                fontSize: 18,
                            },
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                labelString: 'Nos',
                                fontColor: '#64779c',
                                fontSize: 18,
                            },
                            stacked: true,
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                // min: 0,
                                // max: Math.floor(Math.max(...targetArrayLabels))+1,
                                // stepSize: Math.floor(Math.max(...targetArrayLabels)/4),
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit: 4
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#2f3b51',
                                zeroLineWidth: 1
                            }
                        },
                        {
                            scaleLabel: {
                                display: false,//currently not required
                                labelString: 'Age Range',
                                fontColor: '#64779c',
                                fontSize: 24,
                            },
                            stacked: true,
                            display: false,//currently not required
                            position: 'right',
                            // beginAtZero: true,
                            ticks: {
                                //Configure these options depending on the conversion factor btwn Hours and Cr.
                                // max: 1200,
                                // min: 0,
                                // stepSize: 200,
                                // min: 0,
                                // max: Math.floor(Math.max(...targetArrayLabels))+1,
                                // stepSize: Math.floor(Math.max(...targetArrayLabels)/4),
                                maxTicksLimit: 4,
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#2f3b51',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphdata, props.allGraph, props.retailGraph, props.lpgGraph, props.lubesGraph, props.atfGraph, props.refineryGraphm, props.refineryGraphk, props.refineryGraphb, props.incGraph, props.entGraph, props.gasGraph, props.depGraph]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.ehrGr}`}>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}
export default EthicalHrBar