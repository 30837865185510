import React, { useState, useEffect } from 'react'
import { fetchAtfProjects } from '../../../services/bu'
import BuProjects from '../../BuRetailGraphs/BuProjects/BuProjects'
import BuProjectsCount from '../../BuRetailGraphs/BuProjects/BuProjectsCount'

const BuAtfProjects = (props) => {
    const [graphData, setGraphData] = useState({})
    const [loading, setloading] = useState(true)

    useEffect(() => {
        setloading(false)
        fetchAtfProjects(props.year).then(data => {
            const projectData = data
            setGraphData(projectData)
        }).catch(console.debug).then(() => setloading(false))
    }, [props.product, props.year])
    return (
        // <div>{  props.year >= '2023'?
        //  <BuProjectsCount graphData={graphData} product={props.product} year={props.year} isLoading={loading} />
        //  :
         <BuProjects graphData={graphData} product={props.product} isLoading={loading} />
            // }
        // </div>   
         )
}
export default BuAtfProjects