import React from 'react'
import styles from '../../OrgListAccordian/OrgListAccordian.module.scss'

import { Accordion, Card } from 'react-bootstrap'
import DeltaValuesOrg from '../../DeltaValuesOrg/DeltaValuesOrg'
import { numberFormatEnIn } from '../../../utility/utility'

const OrgListFT = (props) => {
    return (
        <Accordion className={`${styles.listContainer}`}>
            <Card className={`${styles.cardContainer}`}>
                <Accordion.Toggle as={Card.Header} eventKey="1" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Total Borrowing</div>
                    <div className={`${styles.kpiValue}`}>INR {props.borrow.value && numberFormatEnIn(parseFloat(props.borrow.value).toFixed(2))} Cr</div>
                    {/* <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.borrow.quater} year={props.borrow.year}></DeltaValuesOrg>
                    </div> */}
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse>
                {/* <Accordion.Toggle as={Card.Header} eventKey="2" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Total Investment</div>
                    <div className={`${styles.kpiValue}`}>{props.invest.value && parseFloat(props.invest.value).toFixed(2)} Cr INR</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.invest.quater} year={props.invest.year}></DeltaValuesOrg>
                    </div>
                    <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                {(props.roce.value > 0) &&
                    <Accordion.Toggle as={Card.Header} eventKey="3" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                        <div className={`${styles.kpiTitle}`}>ROCE</div>
                        <div className={`${styles.kpiValue}`}> {props.roce.value && (Math.sign(props.roce.value) === -1 ? `(${numberFormatEnIn(Math.abs(props.roce.value).toFixed(2))})` : numberFormatEnIn(Number(props.roce.value).toFixed(2)))}%</div>
                        {/* <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.roce.quater} year={props.roce.year}></DeltaValuesOrg>
                    </div> */}
                        {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                    </Accordion.Toggle>
                }
                <Accordion.Collapse eventKey="3" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} eventKey="4" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Credit Rating - Moody's/Fitch</div>
                    <div className={`${styles.kpiValueCredit}`}>{props.crd.value && props.crd.value}</div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse>
                <Accordion.Toggle as={Card.Header} eventKey="5" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Credit Rating - CRISIL</div>
                    <div className={`${styles.kpiValueCredit}`}>{props.crdcsl.value && props.crdcsl.value}</div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
export default OrgListFT
