import React, {useState, useEffect} from 'react'
import styles from './FinanceGrowth.module.scss'
// import ExpandIcon from "../../../assets/images/expandIcon.png"
import GrowthKpi1 from '../GrowthKpi1/GrowthKpi1'
import SalesKpi from '../SalesKpi/SalesKpi'
import { fetchFinanceGrowthCapex, fetchFinanceGrowthGrm, fetchFinanceGrowthSales, fetchFinanceGrowthThroughput, fetchupdatedStatus } from '../../../services/Org'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const FinanceGrowth = (props) => {
    const [grmdata, setgrmdata] = useState([])
    const [throughputdata, setthroughputdata] = useState([])
    const [cagrdata, setcagrdata] = useState([])
    const [salesfdata, setsalesfdata] = useState([])
    const [totalGRM, setTotalGRM] = useState([])
    const [totalThrough, setTotalThrough] = useState([])
    const [totalCAGR, settotalCAGR] = useState([])
    const [totalsalesf, settotalsalesf] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingg, setLoadingg] = useState(true)
    const [loadingc, setLoadingc] = useState(true)
    const [loadingt, setLoadingt] = useState(true)
    const [updatedStatusThroughput, setUpdatedStatusThroughput] = useState('')
    const [updatedStatusCapex, setUpdatedStatusCapex] = useState('')
    const [updatedStatusGrm, setUpdatedStatusGrm] = useState('')
    const [updatedStatusSales, setUpdatedStatusSales] = useState('')


    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
                response.filter((res) => res.entity === "Finance" && res.kpi_name === "throughput" ? setUpdatedStatusThroughput(res.status) : false);
                response.filter((res) => res.entity === "Finance" && res.kpi_name === "capex" ? setUpdatedStatusCapex(res.status) : false);
                response.filter((res) => res.entity === "Finance" && res.kpi_name === "GRM" ? setUpdatedStatusGrm(res.status) : false);
                response.filter((res) => res.entity === "Finance" && res.kpi_name === "sales" ? setUpdatedStatusSales(res.status) : false);

            } else {
                setUpdatedStatusThroughput("Not Found")
                setUpdatedStatusCapex("Not Found")
                setUpdatedStatusGrm("Not Found")
                setUpdatedStatusSales("Not Found")
            }
        })
        .catch(error => console.debug(error))
        function growthData(){
            fetchFinanceGrowthGrm(props.dropdown.toLowerCase(), props.year).then(data => {
                if(data.length === 0){
                    setLoadingg(true)
                    setTotalGRM({value : 0,quater : 0,year : 0,unit : 0})
                }else{
                    setgrmdata(data)
                    let lastElement = data[0]
                    let objectdata = {
                        value : lastElement.value,
                        quater : lastElement.quaterly,
                        year : lastElement.yearly,
                        unit : lastElement.unit
                    }
                    setTotalGRM(objectdata)
                    setLoadingg(false)
                }
            }).catch(error => console.debug(error))
            fetchFinanceGrowthThroughput(props.dropdown.toLowerCase(), props.year).then(data => {
                if(data.length === 0){
                    setLoadingt(true)
                    setTotalThrough({value : 0,quater : 0,year : 0,unit : 0})
                }else{
                    setthroughputdata(data)
                    let filterThrough = data.filter(getValue => {if(getValue.parameter !== 'Total' && getValue.parameter === props.year.toString()){return getValue}})
                    let filTh = filterThrough.map(getval => {return getval.value})
                    let filThrough = filTh.reduce((a,b) => a + b, 0)
                    let lastElement = filterThrough[0]
                    let objectdatat = {
                        value : filThrough,
                        quater : lastElement.quaterly,
                        year : lastElement.yearly,
                        unit : lastElement.unit
                    }
                    setTotalThrough(objectdatat)
                    setLoadingt(false)
                }
            }).catch(error => console.debug(error))
            fetchFinanceGrowthCapex(props.dropdown.toLowerCase(), props.year).then(data => {
                if(data.length === 0){
                    setLoadingc(true)
                    settotalCAGR({value : 0,quater : 0,year : 0,unit : 0})
                }else{
                    setcagrdata(data)
                    let filterThrough = data.filter(getValue => {if(getValue.parameter !== 'Total' && getValue.parameter === props.year.toString()){return getValue}})
                    let filTh = filterThrough.map(getval => {return getval.value})
                    let filThrough = filTh.reduce((a,b) => a + b, 0)
                    let lastElement = filterThrough[0]
                    let objectdatac = {
                        value : filThrough,
                        quater : lastElement.quaterly,
                        year : lastElement.yearly,
                        unit : lastElement.unit
                    }
                    settotalCAGR(objectdatac)
                    setLoadingc(false)
                }
            }).catch(error => console.debug(error))
            fetchFinanceGrowthSales(props.dropdown.toLowerCase(), props.year).then(data => {
                if(data.length === 0){
                    setLoading(true)
                    settotalsalesf({value : 0,quater : 0,year : 0,unit : 0})
                }else{
                    setsalesfdata(data)
                    let filterThrough = data.filter(getValue => {if(getValue.parameter !== 'Total' && getValue.parameter === 'totalsales'){return getValue}})
                    let filTh = filterThrough.map(getval => {return getval.value})
                    let filThrough = filTh.reduce((a,b) => a + b, 0)
                    let lastElement = filterThrough[0]
                    let objectdatasl = {
                        value : filThrough,
                        quater : lastElement.quaterly,
                        year : lastElement.yearly,
                        unit : lastElement.unit
                    }
                    settotalsalesf(objectdatasl)
                    setLoading(false)
                }
            }).catch(error => console.debug(error))
        }
        growthData()
    }, [props.dropdown, props.year])
    return (
        <div className={`${styles.growthContainer}`}>
            <div className={`d-flex align-items-center justify-content-between ${styles.growthHeader}`}>
                <span>Growth - Quarterly</span>
            </div>
            <div className={`d-flex ${styles.growthContentContainer}`}>
                <GrowthKpi1 kpiName="Throughput" graphData={throughputdata} loading={loadingt} totalThrough={totalThrough} year={props.year} statusThroughput={updatedStatusThroughput}></GrowthKpi1>
                <GrowthKpi1 kpiName="Capex" graphData={cagrdata} loading={loadingc} totalCAGR={totalCAGR} year={props.year} statusCapex={updatedStatusCapex}></GrowthKpi1>
                <GrowthKpi1 kpiName="GRM" graphData={grmdata} loading={loadingg} totalGRM={totalGRM} year={props.year} statusGrm={updatedStatusGrm}></GrowthKpi1>
                <SalesKpi kpiName="Sales" salesfdata={salesfdata} loading={loading} totalsalesf={totalsalesf} year={props.year} statusSales={updatedStatusSales}></SalesKpi>
            </div>
        </div>
    )
}
export default FinanceGrowth