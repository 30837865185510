// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LubesLossGainAnalysisGraph_grmGraph__2yf6m {\n  position: relative;\n  width: 1000px;\n  height: 450px;\n  padding: 30px 0px 10px 25px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "LubesLossGainAnalysisGraph_grmGraph__2yf6m"
};
module.exports = exports;
