import React, { useState, useEffect } from 'react'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import Checkbox from '../../Checkbox.js/Checkbox'
import styles from './ItrmLandedCost.module.scss'
import LandedCostGraph from './LandedCostGraph'
import landedCostLegend from '../../../assets/images/landedCostLegend.png'
import mumbaiLandedCost from '../../../assets/images/mumbaiLandedCost.png'
import {fetchupdatedStatus } from '../../../services/Itrm'
import alllandedCostLegend from '../../../assets/images/alllandedCostLegend.png'
import {NoDataFound} from '../../DataNotFound/nodatafound'
import {last} from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
import "../../../Common.scss"


library.add(faCheckSquare, faSquare)

function ItrmLandedCost(props) {

    const [crudeBox, setCrudeBox] = useState(true)
    const [freightBox, setFreightBox] = useState(true)
    const [taxesBox, setTaxesBox] = useState(true)
    const [totalYtm, setYtmtotal] = useState(0)
    const [updatedStatus, setUpdatedStatus] = useState('')
    const [unit, setUnit] = useState("")
    const [unit1, setUnit1] = useState("")

    const onCheckStateChanged = (event) => {
        if (event.name === 'crudeBox') {
            setCrudeBox(event.checkState)
        }
        if (event.name === 'freightBox') {
            setFreightBox(event.checkState)
        }
        if (event.name === 'taxesBox') {
            setTaxesBox(event.checkState)
        }
    }

    useEffect(() => {
        const staticLocation = props.location === "mumbai" ? "Mumbai" : props.location === "kochi" ? "Kochi" : props.location === "Bina" ? "Bina" : "All"
        let unit = props.location === "all" ? "$/3 Barrel" : "$/Barrel"
        setUnit(unit)
        let unit1 = props.location === "all" ? "/3 bbl" : "/bbl"
        setUnit1(unit1)
        fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === staticLocation && res.kpi_name === "Landed Cost" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        if(props.lastData.length !== 0){
            let filternull = props.lastData.filter(data => {
                if(data !== null){
                    return data
                }
            })
            let lastElement = last(filternull)
            let lastElemtotal = Number(lastElement)
            setYtmtotal(lastElemtotal.toFixed(2))
        }else{
            setYtmtotal(0)
        }
    }, [props.landedCostData, props.lastData])

    return (
        <div className={`${styles.itrmLandedCostContainer}`}>
            <div className={`d-flex justify-content-between align-items-center ${styles.headerContainer}`}>
                <div className={`d-flex flex-column justify-content-between ${styles.headerLeft}`}>
                    <div className={`${styles.heading}`}>
                        <span>Landed Cost ({unit}) - Monthly</span>
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    </div>
                    <div className={`d-flex ${styles.labels}`}>
                        <div className="d-flex align-items-center mr-3">
                            <span className={`mr-2 ${props.location === 'mumbai' ? styles.mumbaiCrudeDot : props.location === 'kochi' ? styles.kochiCrudeDot: props.location === 'all'? styles.allCrudeDot :styles.kochiCrudeDot}`}></span>Crude
                        </div>
                        <div className="d-flex align-items-center mx-3">
                            <span className={`mr-2 ${props.location === 'mumbai' ? styles.mumbaiFreightDot :  props.location === 'kochi' ? styles.kochiFreightDot : props.location === 'all'? styles.allFreightDot :styles.kochiFreightDot} `}></span>Freight
                        </div>
                        <div className="d-flex align-items-center mx-3">
                            <span className={`mr-2 ${props.location === 'mumbai' ? styles.mumbaiTaxesDot : props.location === 'kochi' ? styles.kochiTaxesDot : props.location === 'all'? styles.allTaxesDot : styles.kochiTaxesDot}`}></span>Taxes
                        </div>
                        <div className="d-flex align-items-center mx-3">
                            {
                                props.location === 'mumbai' ? <img className={'mr-2 '} src={mumbaiLandedCost} alt='Legend for Landed Cost'></img> : props.location === 'kochi' ? <img className={'mr-2 '} src={landedCostLegend} alt='Legend for Landed Cost'></img>: 
                                props.location === 'all'? <img className={'mr-2 '} src={alllandedCostLegend} alt='Legend for Landed Cost'></img> : <img className={'mr-2 '} src={landedCostLegend} alt='Legend for Landed Cost'></img>
                            }
              Landed Cost
                        </div>
                    </div>
                </div>
                <div className={`${styles.headerRight}`}>
                    {
                        props.location === 'mumbai' ? <span className={`${styles.mumbaiTheme}`}>YTM : ${totalYtm && totalYtm}{unit1}</span> : <span className={`${styles.kochiTheme}`}>YTM : ${totalYtm && totalYtm}{unit1}</span>
                    }
                    {/* <span className={`${props.location === "mumbai" ? styles.mumbaiTheme : styles.kochiTheme}`}>--- YTM : $39.05/bbl</span> */}
                </div>
            </div>
            {
            props.landedCostData.length === 0 ?
            <div className={`d-flex ${styles.graphContainer}`}><NoDataFound /></div> :
            <div className={`d-flex ${styles.graphContainer}`}>
                    <LandedCostGraph landedCostData={props.landedCostData} location={props.location} crudeBox={crudeBox} freightBox={freightBox} taxesBox={taxesBox} ></LandedCostGraph>
                <div className={`d-flex flex-column justify-content-center ${styles.graphFilters}`}>
                    <Checkbox
                        size="sm"
                        name="crudeBox"
                        label="Crude"
                        checked={crudeBox}
                        onChange={(e) => onCheckStateChanged(e)}
                    ></Checkbox>
                    <Checkbox
                        size="sm"
                        name="freightBox"
                        label="Freight"
                        checked={freightBox}
                        onChange={(e) => onCheckStateChanged(e)}
                    ></Checkbox>
                    <Checkbox
                        size="sm"
                        name="taxesBox"
                        label="Taxes"
                        checked={taxesBox}
                        onChange={(e) => onCheckStateChanged(e)}
                    ></Checkbox>
                </div>
            </div>
        }
        </div>
    )
}
export default ItrmLandedCost