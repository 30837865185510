import React, {useContext, useState} from 'react'
import styles from './Adminlogin.module.scss'
import bpclogo from '../../components/Authentication/assets/bpcl-logo.png'
import {useHistory, Redirect} from 'react-router-dom'
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loginAdminUser } from '../../services/auth'
import ApplicationContext from '../../ApplicationContext'
const Adminlogin = () => {
    let history = useHistory()
    const context = useContext(ApplicationContext)
    const [adminuser, setadminuser] = useState('')
    const [adminPass, setadminPass] = useState('')
    const [erroradmin, setadminerror] = useState('')
    const [adsignInttext, setadSignintext] = useState('ADMIN SIGN IN')
    const [showPopup, setshowPopup] = useState(0)
    const handleChangeEmail = (e) => {
        setshowPopup(0)
        setadminuser(e.target.value)
    }
    const handleChangePass = (e) => {
        setshowPopup(0)
        setadminPass(e.target.value)
    }
    const loginAdmn = async (e) => {
        e.preventDefault()
        setadSignintext('SIGNING IN..')
        let emailregex = /^[a-zA-Z0-9._-]+@bharatpetroleum.in$/
        if(!adminuser && !adminPass){
            setadSignintext('ADMIN SIGN IN')
            setshowPopup(1)
            setadminerror('Enter Email Id and Password.')
        }else if(!adminuser){
            setadSignintext('ADMIN SIGN IN')
            setshowPopup(1)
            setadminerror('Enter your Email Id.')
        }else if(!adminPass){
            setadSignintext('ADMIN SIGN IN')
            setshowPopup(1)
            setadminerror('Enter your Password.')
        }else if (!emailregex.test(adminuser)) {
            setadSignintext('ADMIN SIGN IN')
            setshowPopup(1)
            setadminerror('Invalid Credentials!')
        } else{
            loginAdminUser(adminuser, adminPass).then(data => {
                if (data.success === 1) {
                    context.setLoggedInUser(data)
                    setTimeout(() => {
                        history.push('/bpcladmin')
                    }, 500)
                } else {
                    context.setLoggedInUser(null)
                    setadSignintext('ADMIN SIGN IN')
                    setshowPopup(1)
                    setadminerror(data.message)
                }
            }).catch(error => {
                    console.debug(error)
            })
        }
    }
    if(context.loggedInUser && context.loggedInUser.role === 'ADMIN'){
        return (
            <Redirect to="/bpcladmin" />
        )
    }
    return(
        <div className={`container d-flex justify-content-center ${styles.adminLogin}`}>
            <div className={`${styles.adminLboc}`}>
                <div>
                    <img src={bpclogo} alt="Logo" />
                </div>
                <div className={`${styles.bpclbox}`}>
                    <h1>ADMIN SIGN IN</h1>
                    <hr className={`${styles.linebrk}`}></hr>
                    <form className={`${styles.adminfrm}`} onSubmit={loginAdmn}>
                        <div className={`form-goup ${styles.admindiv}`}>
                            <label>EMAIL ID</label>
                            <input typw="email" className={'form-control'} name="adminuemail" onChange={(e) => handleChangeEmail(e)} />
                        </div>
                        <div className={`form-goup ${styles.admindiv}`}>
                            <label>PASSWORD</label>
                            <input type="password" className={'form-control'} name="adminupass" onChange={(e) => handleChangePass(e)} />
                        </div>
                        <div className={`button ${styles.adminbtn}`}>
                            <button type="submit" className={`btn${styles.adminloginbtn}`}>{adsignInttext}</button>
                        </div>
                        {showPopup === 1 && 
                        <div className={`${styles.popuperr}`}>
                            <p className={`${styles.errorad}`}><span><FontAwesomeIcon icon={faExclamationCircle} /></span>{erroradmin}</p>
                        </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )

}
export default Adminlogin