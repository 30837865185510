// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".stateMap_heatmapLegend__3WKKR {\n  position: relative;\n  bottom: 893px;\n  left: 412px; }\n\n.stateMap_heatmapImg__2NCUj {\n  height: 54px; }\n\n.stateMap_heatmapMin__21uGJ {\n  margin-top: -38px;\n  margin-left: 18px;\n  font-size: 25px; }\n\n.stateMap_heatmapMax__che1U {\n  margin-left: 590px;\n  font-size: 25px; }\n\n.stateMap_heatmapMiddle__1YxOG {\n  margin-top: -35px;\n  margin-left: 300px;\n  font-size: 25px; }\n", ""]);
// Exports
exports.locals = {
	"heatmapLegend": "stateMap_heatmapLegend__3WKKR",
	"heatmapImg": "stateMap_heatmapImg__2NCUj",
	"heatmapMin": "stateMap_heatmapMin__21uGJ",
	"heatmapMax": "stateMap_heatmapMax__che1U",
	"heatmapMiddle": "stateMap_heatmapMiddle__1YxOG"
};
module.exports = exports;
