import classes from './NotFound.module.scss'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const NotFound = () => {
    const history = useHistory()
    return (
        <div className={classes.background}>
            <div className={classes.container}>
                <div className={classes.heading}>404</div>
                <div className={classes.body}>
                    The page you requested doesn't exist.
                </div>
                <button className={`btn btn-primary ${classes.button}`} onClick={() => history.push('/')}>Go home</button>
            </div>
        </div>
    )
}