// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RefinaryDistillateYield_DistillateYieldContainer__3aKo_ {\n  border: 0.5px #a7a7a7 solid;\n  border-bottom: none;\n  border-left: none;\n  font-family: Titillium Web;\n  width: 644px;\n  height: 653px;\n  background-color: #141923;\n  color: #dee5ef;\n  position: relative; }\n  .RefinaryDistillateYield_DistillateYieldContainer__3aKo_ .RefinaryDistillateYield_graphTitle__KxF14 {\n    height: 88px;\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    background-color: black;\n    font-size: 35px;\n    font-weight: bold;\n    padding-left: 36px; }\n  .RefinaryDistillateYield_DistillateYieldContainer__3aKo_ .RefinaryDistillateYield_grmFooter__l-Qj2 {\n    border: 0.5px #a7a7a7 solid;\n    border-left: none;\n    height: 64px;\n    background-color: #283040; }\n    .RefinaryDistillateYield_DistillateYieldContainer__3aKo_ .RefinaryDistillateYield_grmFooter__l-Qj2 .RefinaryDistillateYield_footerLeft__3KmEi {\n      margin-left: 36px;\n      color: #ffffff;\n      font-size: 22px;\n      text-decoration: underline; }\n    .RefinaryDistillateYield_DistillateYieldContainer__3aKo_ .RefinaryDistillateYield_grmFooter__l-Qj2 .RefinaryDistillateYield_footerRight__16y9O {\n      margin-right: 36px;\n      font-size: 22px;\n      font-style: italic; }\n  .RefinaryDistillateYield_DistillateYieldContainer__3aKo_ .RefinaryDistillateYield_statusUpdatedImg__3c2jD {\n    margin-left: 172px;\n    margin-top: -21px; }\n", ""]);
// Exports
exports.locals = {
	"DistillateYieldContainer": "RefinaryDistillateYield_DistillateYieldContainer__3aKo_",
	"graphTitle": "RefinaryDistillateYield_graphTitle__KxF14",
	"grmFooter": "RefinaryDistillateYield_grmFooter__l-Qj2",
	"footerLeft": "RefinaryDistillateYield_footerLeft__3KmEi",
	"footerRight": "RefinaryDistillateYield_footerRight__16y9O",
	"statusUpdatedImg": "RefinaryDistillateYield_statusUpdatedImg__3c2jD"
};
module.exports = exports;
