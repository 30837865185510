import React, { useEffect, useState } from 'react'
import styles from './ItrmCracksTable.module.scss'
import ItrmCrackGraph from './ItrmCrackGraph'
function ItrmCracksTable(props) {
    const [textColor, setTextColor] = useState('#06bee1')
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    let nextMonth = currentMonth + 1;
    let nextYear = currentYear;
    if (nextMonth > 11) {
        nextMonth = 0;
        nextYear += 1;
      }
    const nextMonthDate = new Date(nextYear, nextMonth, 1);
    const name = nextMonthDate.toLocaleString('default', { month: 'short' });
    useEffect(() => {
        if (props.location === 'mumbai') {
            setTextColor('#06bee1')
        } else if (props.location === 'kochi') {
            setTextColor('#b76df1')
        }
    }, [props.location, props.pr_gasoline, props.pr_gasoil, props.pr_jetkero, props.pr_naptha, props.pr_fo])

    return (
        <div className={`${styles.itrmCracksTableContainer}`}>
            <div className={`${styles.curvesHeader}`}>
                <h1>Forward Product Cracks - Singapore ($/Barrel)</h1>
            </div>
            <div className={`${styles.curvesTableContainer}`}>
                <div className={`row pt-2 ${styles.legendCrack}`}>
                    <div className={`col-12 d-flex align-items-center ${styles.headerLabels}`}>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.rentalDot}`}></span>Naphtha</div>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.salesProDot}`}></span>Gasoline</div>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.staffCostDot}`}></span>Jet/Kero</div>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.rmDot}`}></span>Gas Oil</div>
                        <div className="d-flex mr-3 align-items-center "><span className={`mr-2 ${styles.thirdPartyDot}`}></span>FO 180</div>
                        <div className="d-flex mr-3 align-items-center ">M1 = {name}</div>
                    </div>
                </div>
                <div className={'row'} style={{ margin: '40px 0px 10px 0px' }}>
                    <ItrmCrackGraph 
                        pr_gasoline={props.pr_gasoline !== undefined && props.pr_gasoline} 
                        pr_gasoil={props.pr_gasoil !== undefined && props.pr_gasoil} 
                        pr_naptha={props.pr_naptha !== undefined && props.pr_naptha}
                        pr_jetkero={props.pr_jetkero !== undefined && props.pr_jetkero}
                        pr_fo={props.pr_fo !== undefined && props.pr_fo}
                        location={props.location} />
                </div>
            </div>
        </div>
    )
}
export default ItrmCracksTable
