// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DemurrageGraph_grmGraph__euyCd {\n  position: relative;\n  width: 820px;\n  height: 240px;\n  margin-left: 64px;\n  margin-top: 52px; }\n", ""]);
// Exports
exports.locals = {
	"grmGraph": "DemurrageGraph_grmGraph__euyCd"
};
module.exports = exports;
