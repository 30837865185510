import React, { useState, useEffect, useMemo } from 'react'
import styles from './OrgLanding.module.scss'
import OrgFinance from '../../components/OrgFinance/OrgFinance'
import OrgHr from '../../components/OrgHr/OrgHr'
import OrgHsse from '../../components/OrgHsse/OrgHsse'
import OrgNews from '../../components/OrgNews/OrgNews'
import { Dropdown } from 'react-bootstrap'
import dropdownArrows from '../../assets/images/dropdownArrows.png'
import { fetchOrgLanding, fetchOrgHsseData } from '../../services/Org'
import OrgMapContainer from "../../components/OrgProjects/OrgMapContainer";

const OrgLanding = () => {
    const [year, setyear] = useState('2020')
    const [yearPrev, setyearPrev] = useState('2019')
    const [yearNext, setyearNext] = useState('2021')
    const [yearCurrent, setyearCurrent] = useState('2020')
    const [lastUpdated, setLastUpdated] = useState([])
    const [hsse, setHsse] = useState('HSSE')
    const [mapOf, setMapOf] = useState("all regions");
    const [mapCenter, setMapCenter] = useState({ lat: 18, lng: 80 });
    const [mapZoom, setMapZoom] = useState(5.65);
    const [activeTab, setActiveTab] = useState("retail_tab");

    function handleRegionDropdown(mapRegion, mapCenter, mapZoom) {
        setMapOf(mapRegion);
        setMapCenter(mapCenter);
        setMapZoom(mapZoom);
      }
    useEffect(() => {
        fetchOrgLanding().then(data => {
            if (data.length === 0) {
                setyear('2020')
            }else{
                setyear(data.year.toString())
                setyearPrev((data.year - 1).toString())
                setyearNext((data.year + 1).toString())
                setyearCurrent(data.year.toString())
            }
        }).catch(error => console.debug(error))
    }, [])
    useEffect(() => {
        fetchOrgHsseData('ytm', year).then(data => {
            if(data.length === 0){
                setLastUpdated([])
            }else{
                setLastUpdated(data[0])
            }
        })
    }, [year])
    document.title = 'Organization KPIs | BPCL'
    const memoizedOrgHr = useMemo(() => <OrgHr year={year} />, [year]);
    const memoizedOrgFinance = useMemo(() => <OrgFinance year={year} />, [year]);
    return (
        <div className={`${styles.orgLandingContainer}`}>
            <div className={`d-flex align-items-center justify-content-center ${styles.orgLandingHeader}`}>
            <a href="/">Organization KPIs</a>
                <Dropdown className={`${styles.gheaderDropdownYear}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{year} - {Number(year.slice(2)) + 1}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearPrev)}>{yearPrev} - {yearCurrent.slice(2)}</Dropdown.Item>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearCurrent)}>{yearCurrent} - {yearNext.slice(2)}</Dropdown.Item>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearNext)}>{yearNext} - {Number(yearNext.slice(2)) + 1}</Dropdown.Item>
                        {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2022')}>2022</Dropdown.Item>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2023')}>2023</Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
                {/* <p className={`${styles.lastUpdated}`}>Last Updated on {new Date(lastUpdated.date).toDateString()}</p> */}
            </div>
            <div className={`d-flex ${styles.orgLandingContentContainer}`}>
            {memoizedOrgFinance}
            {memoizedOrgHr}
                <div className={`d-flex flex-column ${styles.hsseNewsContainer}`}>
                    <div className={`${styles.tHeader}`}>
                    <button className={`${styles.togglebtn2}`} onClick={() => setHsse('Map')}>PROJECT MAP </button>
                    <button className={`${styles.togglebtn1}`} onClick={() => setHsse('HSSE')}>HSSE</button>
                    </div>
                    {(hsse === 'HSSE') ?
                    <div>
                    <OrgHsse year={year}></OrgHsse>
                    <OrgNews year={year}></OrgNews>
                    </div>
                        :
                        <div>
                            <OrgMapContainer
                                handleRegionDropdown={handleRegionDropdown}
                                mapOf={mapOf}
                                mapCenter={mapCenter}
                                mapZoom={mapZoom}
                                activeTab={activeTab}
                                year={year}
                            ></OrgMapContainer>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default OrgLanding