import React, { useEffect, useState, useRef } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'

const EmployeeDemographicGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
    // prepareGraphData(props.graphData);
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let labelsX = ['Retail', 'Aviation', 'I&C', 'LPG', 'Lubes', 'Gas', 'Mumbai Refinery', 'Kochi Refinery', 'Bina Refinery', 'Other Units / Entities']
        let mangment = [props.mangret, props.mangatf, props.manginc, props.manglpg, props.manglubes, props.manggas, props.mangrefm, props.mangrefk, props.mangrefb, props.mangspo]
        
        //let contract = [props.clerret, props.cleratf, props.clerinc, props.clerlpg, props.clerlubes, props.clerref, props.clerspo]
        let other = [props.labret, props.labatf, props.labinc, props.lablpg, props.lablubes, props.labgas, props.labrefm, props.labrefk, props.labrefb, props.labspo]
        let chartConfig = {
            type: 'horizontalBar',
            data: {
                // labels: productNames,
                labels: labelsX,
                datasets: [
                    {
                        label: 'Management',
                        backgroundColor: '#04caa6',
                        data: mangment,
                        // data: aboveTargetArray,
                        categoryPercentage: 1,
                        barPercentage: 0.5,
                        datalabels: {
                            display: false
                        }
                    },
                    // {
                    //     label: 'Contract',
                    //     backgroundColor: '#00ebff',
                    //     data: contract,
                    //     // data: belowTargetArray,
                    //     categoryPercentage: 1,
                    //     barPercentage: 0.2,
                    //     datalabels: {
                    //         display: false
                    //     }
                    // },
                    {
                        label: 'Non-Management',
                        backgroundColor: '#2281e8',
                        data: other,
                        // data: belowTargetArray,
                        categoryPercentage: 1,
                        barPercentage: 0.5,
                        datalabels: {
                            display: false
                        }
                    }

                ],
            },
            options: {
                legend: {
                    display: false,
                    // align: "start",
                    // labels: {
                    //   fontSize: 22,
                    //   fontColor: "#8fa9db",
                    //   boxWidth: 24
                    // }
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                fontColor: '#dee5ef',
                                fontSize: '18',
                                maxTicksLimit : 5
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 5,
                                fontColor: '#dee5ef',
                                fontSize: '18',
                            }

                        }
                    ]
                },
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.mangatf, props.mangret, props.mangrefm, props.mangrefk, props.mangrefb, props.manginc, props.manglpg, props.manglubes,props.manggas, props.mangspo, props.cleratf, props.clerret, props.clerref, props.clerinc, props.clerlpg, props.clerlubes, props.clerspo, props.labatf, props.labret, props.labrefm, props.labrefk, props.labrefb, props.labinc, props.lablpg, props.lablubes, props.labgas, props.labspo]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <canvas
            ref={chartContainer}
        />
    )
}

export default EmployeeDemographicGraph
