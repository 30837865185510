import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuIncOutstandingTerritory.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuIncOutstandingTerritoryGraph from './BuIncOutstandingTerritoryGraph'
import { fetchBuIncOutstandingPaymentTerritory, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { isEmpty, over, last } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

function BuIncOutstandingTerritory(props) {
    const [graphData, setGraphData] = useState([])
    const [lastItem, setLastItem] = useState([])
    const [attr, setattr] = useState('handleEnter')
    const handle = useFullScreenHandle()
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }

    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    const prepareGraphData = () => {
        fetchBuIncOutstandingPaymentTerritory(props.year)
            .then(data => {
                if (!isEmpty(data.data)){
                    let dataArray = []
                    let total = 0
                    let growth = 0
                    let northArray = []
                    let southArray = []
                    let eastArray = []
                    let westArray = []
                    data.data.forEach((item, index) => {
                        // total += item.not_due
                        if(item.product === 'North'){
                            northArray.push(item)
                        }else if(item.product === 'South'){
                            southArray.push(item)
                        }else if(item.product === 'East'){
                            eastArray.push(item)
                        }else if(item.product === 'West'){
                            westArray.push(item)
                        }
                    })
                    let overallArray = []
                    if(northArray.length > 0 || southArray.length > 0 || eastArray.length > 0 || westArray.length > 0){
                        overallArray = [last(northArray), last(southArray), last(eastArray), last(westArray)]
                    }else{
                        overallArray = []
                    }
                    setGraphData(overallArray)
                    overallArray.forEach(lastData => {
                        total += lastData.zero_to_three_months
                        total += lastData.three_to_six_months
                        total += lastData.six_to_twelve_months
                        total += lastData.twelve_to_thirty_six_months
                        total += lastData.greater_than_thirty_six_months
                        growth = lastData.overall_growth
                    })
                    dataArray.push(total)
                    dataArray.push(growth)
                    setLastItem(dataArray)
                }else{
                    setGraphData([])
                    setLastItem([])
                }
            })
            .catch(error => console.debug(error))
    }

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "inc" && res.kpi_name === "Outstanding" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        prepareGraphData()
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuLubesLossGainAnalysisContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-auto flex-grow-1'}>
                            <span>Outstanding Region Wise - Monthly</span>
                        </div>
                        {
                            isEmpty(lastItem) ||
                                <div className={`col-auto mr-4 mt-2 ${styles.headerRight}`}>
                                    <span>Outstanding : {lastItem ? Number(lastItem[0]).toFixed(2) + 'INR Cr' : 'No Data'}</span>
                                    <span className={'ml-4'} style={{ color: `${Math.sign(lastItem[1]) === 1 ? '#74ce12' : '#e13219'}` }} >{lastItem[1]}</span>
                                </div> 
                        }
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    </div>
                    <div className={'row'}>
                        <div className={`col-9 mt-2 d-flex align-items-center ${styles.headerLabels}`}>
                            {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot1}`}></span>Not Due</div> */}
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot2}`}></span>0-3 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot3}`}></span>3-6 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot4}`}></span>6-12 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot5}`}></span>12-36 m</div>
                            <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.dot6}`}></span>36 m</div>
                        </div>
                    </div>
                </div>
                {
                    graphData.length > 0 ?
                        <div className={`d-flex ${styles.graphContainer}`}>
                            <BuIncOutstandingTerritoryGraph graphData={graphData}></BuIncOutstandingTerritoryGraph>
                            <div className={`d-flex flex-column ${styles.revenueGrowthTableContainer}`}>
                                <div className={`d-flex flex-column ${styles.tableHeaderRowContainer}`}>
                                    <div className={`${styles.tableHeaderRow}`}>
                                        <div className={`${styles.headerCell}`}>
                                            Outstanding
                                    </div>
                                    </div>
                                    <div className={` d-flex flex-column ${styles.tableDataRowContainer}`}>
                                        {
                                            graphData.map((item, index) => {
                                                return (
                                                    <div key={index} className={`d-flex ${styles.tableDataRow}`}>
                                                        <div className={`${styles.dataCell}`}><span>{item ? parseFloat(item.zero_to_three_months + item.three_to_six_months + item.six_to_twelve_months + item.twelve_to_thirty_six_months + item.greater_than_thirty_six_months).toFixed(2) : ''} INR Cr</span></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ height: '480px', width: '100%' }}>
                            <NoDataFound />
                        </div>
                }
                <div className={'d-flex justify-content-end'} style={{ position: 'relative', bottom: '28px' }}>
                    <img className={`${styles.expandIcon}`} onClick={fullScrn} src={expandIcon} alt='Expand Icon'></img>
                </div>
            </FullScreen>
        </div >
    )
}
export default BuIncOutstandingTerritory