import React, { useEffect, useState } from 'react'
import styles from './ItrmDemurrage.module.scss'
import DemurrageGraph from './DemurrageGraph'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import Radio from './Radio'
import greenTick from '../../../assets/images/greenTick.png'
import {fetchupdatedStatus } from '../../../services/Itrm'
import {isEmpty} from 'lodash'
import "../../../Common.scss"

function ItrmDemurrage(props) {
    const [hrsBox, sethrsBox] = useState(true)
    const [rsBox, setrsBox] = useState(false)
    const [YTMHrs, setYTMHrs] = useState(0)
    const [YTMRs, setYTMRs] = useState(0)
    const [updatedStatus, setUpdatedStatus] = useState('')

    const onCheckStateChangedDe = (event) => {
        if (event.name === 'hrsBox') {
            sethrsBox(true)
            setrsBox(false)
        }
        if (event.name === 'rsBox') {
            setrsBox(true)
            sethrsBox(false)
        }
    }

    useEffect(() => {
        const staticLocation = props.location === "mumbai" ? "Mumbai" : props.location === "kochi" ? "Kochi" : props.location === "bina" ? "Bina" : "All"
        fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === staticLocation && res.kpi_name === "Demurrage" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        let count = props.demurrageData.length
        let totalDemurrageArrayRs = []
        let totalDemurrageArrayHrs = []
        if (props.demurrageData.length > 0) {
            props.demurrageData.forEach(getld => {
                totalDemurrageArrayHrs.push(getld.lp, getld.dp)
                totalDemurrageArrayRs.push(getld.lp_rs, getld.dp_rs)
            })
            let totalHrs = totalDemurrageArrayHrs.reduce((a, b) => a + b, 0)
            let totalRs = totalDemurrageArrayRs.reduce((a, b) => a + b, 0)
            // let avgYTMHrs = totalHrs/count
            // let avgYTMRs = totalRs/count
            setYTMHrs(totalHrs.toFixed(2))
            setYTMRs(totalRs.toFixed(2))
        }else{
            setYTMHrs(0)
            setYTMRs(0)
        }
    }, [props.demurrageData])

    return (
        <div className={`${styles.itrmDemurrageContainer}`}>
            <div className={`d-flex justify-content-between align-items-center ${styles.headerContainer}`}>
                <div className={`d-flex flex-column justify-content-between ${styles.headerLeft}`}>
                    <div className={`${styles.heading}`}>
                        <span>Demurrage (Hours, &#8377; Cr) - Monthly</span>
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    </div>
                    <div className={`d-flex ${styles.labels}`}>
                        <div className="d-flex align-items-center mr-3">
                            <span className={`mr-2 ${props.location === 'mumbai' ? styles.lpMumbaiDot : styles.lpKochiDot}`}></span>LP
                        </div>
                        <div className="d-flex align-items-center mx-3">
                            <span className={`mr-2 ${props.location === 'mumbai' ? styles.dpMumbaiDot : props.location === 'kochi' ? styles.dpKochiDot : props.location === 'all' ? styles.dpAllDot : styles.dpKochiDot}`}></span>DP
                        </div>
                    </div>
                </div>
                <div className={`${styles.headerRight}`}>
                    {
                        props.location === 'mumbai' ? <span className={`${styles.mumbaiColor}`}>YTM : {YTMHrs && YTMHrs} Hrs, &#8377; {YTMRs && YTMRs} Cr</span> : <span className={`${styles.kochiColor}`}>YTM : {YTMHrs && YTMHrs} Hrs, &#8377; {YTMRs && YTMRs} Cr</span>
                    }
                    {/* <span className={`${props.location === "mumbai" ? styles.mumbaiColor : styles.kochiColor}`}>     --- YTM : 33 Hr,R 1.7 Cr </span> */}
                </div>
            </div>
            {
                props.demurrageData.length === 0 ?
                    <div className={`d-flex ${styles.graphContainer}`}><NoDataFound /></div> :
                    <div className={`d-flex ${styles.graphContainer}`}>
                        <DemurrageGraph demurrageData={props.demurrageData} location={props.location} hrsBox={hrsBox} rsBox={rsBox}></DemurrageGraph>
                        <div className={`d-flex flex-column justify-content-center ${styles.graphFilters}`}>
                            <Radio
                                size="sm"
                                name="hrsBox"
                                label="Hours"
                                checked={hrsBox}
                                onClick={(e) => onCheckStateChangedDe(e)}
                            />
                            <Radio
                                size="sm"
                                name="rsBox"
                                label="&#8377; Cr"
                                checked={rsBox}
                                onClick={(e) => onCheckStateChangedDe(e)}
                            />
                        </div>
                    </div>
            }
        </div>
    )
}
export default ItrmDemurrage