import React, { useEffect, useState } from 'react'
import styles from './ItrmOceanLoss.module.scss'
import OceanLossGraph from './OceanLossGraph'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import {last, isEmpty} from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import {fetchupdatedStatus } from '../../../services/Itrm'
import "../../../Common.scss"

function ItrmOceanLoss(props) {
    const [totalYtm, setYtmtotal] = useState(0)
    const [updatedStatus, setUpdatedStatus] = useState('')

    useEffect(() => {
        const staticLocation = props.location === "mumbai" ? "Mumbai" : props.location === "kochi" ? "Kochi" : props.location === "Bina" ? "Bina" : "All"
        fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === staticLocation && res.kpi_name === "Ocean Loss" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        if(props.lastData.length !== 0){
            let filternull = props.lastData.filter(data => {
                if(data !== null){
                    return data
                }
            })
            let lastElement = last(filternull)
            let lastElemtotal = Number(lastElement)
            setYtmtotal(lastElemtotal.toFixed(2))
        }else{
            setYtmtotal(0)
        }
    }, [props.oceanLossData, props.lastData])

    return (
        <div className={`${styles.itrmOceanLossContainer}`}>
            <div className={`d-flex justify-content-between align-items-center ${styles.headerContainer}`}>
                <div className={`d-flex flex-column justify-content-between align-items-center ${styles.headerLeft}`}>
                    <span className={`${styles.heading}`}>Ocean Loss (%) - Monthly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                </div>
                <div className={`${styles.headerRight}`}>
                    {
                        props.location === 'mumbai' ? <span className={`${styles.mumbaiTheme}`}>YTM : {totalYtm && totalYtm}%</span> : <span className={`${styles.kochiTheme}`}>YTM : {totalYtm && totalYtm}%</span>
                    }
                    {/* <span className={`${props.location === "mumbai" ? styles.mumbaiTheme : styles.kochiTheme}`}>--- YTM : 0.5%</span> */}
                </div>
            </div>
            <div className={`d-flex ${styles.graphContainer}`}>
                {
                    props.oceanLossData.length === 0 ?
                        <NoDataFound /> :
                        <OceanLossGraph oceanLossData={props.oceanLossData} location={props.location}></OceanLossGraph>
                }

            </div>
        </div>
    )
}
export default ItrmOceanLoss