// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AtfOthersPieChart_graph__oZw5X {\n  position: relative;\n  width: 450px;\n  height: 450px;\n  padding-top: 35px; }\n", ""]);
// Exports
exports.locals = {
	"graph": "AtfOthersPieChart_graph__oZw5X"
};
module.exports = exports;
