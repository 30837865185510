// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrgListTile_CollapsedListItemContainer__BkFEc {\n  height: 76px;\n  margin-bottom: 2px;\n  background-color: #232b38;\n  font-size: 30px;\n  padding: 20px; }\n  .OrgListTile_CollapsedListItemContainer__BkFEc .OrgListTile_kpiTitle__ZLTS- {\n    color: #dee5ef;\n    width: 40%; }\n  .OrgListTile_CollapsedListItemContainer__BkFEc .OrgListTile_kpiValue__3FGWc {\n    color: #dee5ef;\n    font-weight: bold;\n    width: 30%; }\n  .OrgListTile_CollapsedListItemContainer__BkFEc .OrgListTile_deltaComponent__17JaZ {\n    width: 30%; }\n", ""]);
// Exports
exports.locals = {
	"CollapsedListItemContainer": "OrgListTile_CollapsedListItemContainer__BkFEc",
	"kpiTitle": "OrgListTile_kpiTitle__ZLTS-",
	"kpiValue": "OrgListTile_kpiValue__3FGWc",
	"deltaComponent": "OrgListTile_deltaComponent__17JaZ"
};
module.exports = exports;
