import React, { useEffect } from 'react'
import styles from './BuGraphs.module.scss'
import BuLubesSalesRevenue from './BuLubesSalesRevenue/BuLubesSalesRevenue'
import BuLubesMarketShare from './BuLubesMarketShare/BuLubesMarketShare'
import BuLubesOperatingCost from './BuLubesOperatingCost/BuLubesOperatingCost'
import BuLubesOthers from './BuLubesOthers/BuLubesOthers'
import BuLubesHsse from './BuLubesHsse/BuLubesHsse'
import BuLubesLossGainAnalysis from './BuLubesLossGainAnalysis/BuLubesLossGainAnalysis'
import BuLubesInventoryByProduct from './BuLubesInventoryByProduct/BuLubesInventoryByProduct'
import BuLubesDiscountVsSalesGrowth from './BuLubesDiscountVsSalesGrowth/BuLubesDiscountVsSalesGrowth'
import { Dropdown } from 'react-bootstrap'
import BuLubesCcs from '../BuLubesGraphs/BuLubesCcs/BuLubesCcs'
const BuSections = (props) => {
    useEffect(() => { }, [props.mapOf, props.year])

    return (
        <div className={`${styles.BuGraphsContainer}`}>
            <div className={`d-flex justify-content-end ${styles.BuGraphsHeader}`}>
                <div className={`d-flex ${styles.mapHeader}`}>
                    <Dropdown className={`${styles.headerDropdown}`}>
                        <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            className={`d-flex align-items-center ${styles.dropdownButton}`}
                        >
                            <span className={`${styles.dropdownName}`}>YTM</span>
                            {/* <div
                                className={`d-flex align-items-center justify-content-center ${styles.dropdownIconContainer}`}
                            >
                                <img src={dropdownArrows} alt="All region dropdown"></img>
                            </div> */}
                        </Dropdown.Toggle>
                        {/* <Dropdown.Menu className={`${styles.dropdownMenuContainer}`}>
                            <Dropdown.Item className={`${styles.menuItem}`}>
                YTM
                            </Dropdown.Item>
                            <Dropdown.Item className={`${styles.menuItem}`}>
                MTD
                            </Dropdown.Item>
                        </Dropdown.Menu> */}
                    </Dropdown>
                </div>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow1}`}>
                <BuLubesSalesRevenue year={props.year} mapOf={props.mapOf}></BuLubesSalesRevenue>
                <BuLubesInventoryByProduct activeTab={props.activeTab} year={props.year}></BuLubesInventoryByProduct>
                {/* <BuLubesMarketShare year={props.year}></BuLubesMarketShare> */}
                <BuLubesOperatingCost year={props.year}></BuLubesOperatingCost>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow2}`}>
                {/* <BuLubesDemandIndex></BuLubesDemandIndex>*/}
                <BuLubesDiscountVsSalesGrowth year={props.year}
                    activeTab={props.activeTab}
                ></BuLubesDiscountVsSalesGrowth>
                <BuLubesHsse year={props.year} activeTab={props.activeTab}></BuLubesHsse>
                <BuLubesCcs year={props.year}></BuLubesCcs>
                {/* <BuLubesDemandIndex activeTab={props.activeTab}></BuLubesDemandIndex>
         */}
                {/* <BuLubesInventoryByProduct activeTab={props.activeTab} year={props.year}></BuLubesInventoryByProduct> */}
            </div>
            <div className={`d-flex ${styles.BuGraphsRow3}`}>
                <BuLubesMarketShare year={props.year}></BuLubesMarketShare>
                {/* <BuLubesHsse year={props.year} activeTab={props.activeTab}></BuLubesHsse> */}
                <BuLubesOthers year={props.year} activeTab={props.activeTab}></BuLubesOthers>
                {/* <BuLubesProjects activeTab={props.activeTab} /> */}
                <BuLubesLossGainAnalysis year={props.year}
                    activeTab={props.activeTab}
                ></BuLubesLossGainAnalysis>
            </div>
        </div>
    )
}
export default BuSections
