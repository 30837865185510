import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './LearningDevelopmentLineGraph.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const LearningDevelopmentLineGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        // prepareGraphData(props.oceanLossData);
        if (chartInstance) {
            chartInstance.destroy()
        }

        let func_array = []
        let beh_array = []
        const getLDdata = (data) => {
            switch(data.training_type){
                case 'Functional':
                        func_array.push(Number(data.officers))
                        break
                case 'Behavioral':
                        beh_array.push(Number(data.officers))
                        break
                default:
                    return data.training_type
            }
        }
        props.graphdata.forEach(item => {
            if (props.allGraph) {
                if(item.business_unit === 'all'){
                    getLDdata(item)
                    return
                }
            }
            if (props.retailGraph) {
                if(item.business_unit === 'retail'){
                    getLDdata(item)
                    return
                }
            }
            if (props.lpgGraph) {
                if(item.business_unit === 'lpg'){
                    getLDdata(item)
                    return
                }
            }
            if (props.atfGraph) {
                if(item.business_unit === 'atf'){
                    getLDdata(item)
                    return
                }
            }
            if (props.lubesGraph) {
                if(item.business_unit === 'lubes'){
                    getLDdata(item)
                    return
                }
            }
            if (props.refineryGraph) {
                if(item.business_unit === 'refinery'){
                    getLDdata(item)
                    return
                }
            }
            if (props.incGraph) {
                if(item.business_unit === 'inc'){
                    getLDdata(item)
                    return
                }
            }
            if (props.entiGraph) {
                if(item.business_unit === 'entities'){
                    getLDdata(item)
                    return
                }
            }
        })
        
        let func_data = [func_array[0]]
        let beh_data = [beh_array[0]]

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = {
            type: 'bar',
            data: {
                datasets: [
                    {
                        label: 'Functional',
                        data: func_data,
                        backgroundColor: "#ffbf45",
                        datalabels: {
                            display: true,
                            align: 'end',
                            anchor : 'middle',
                            color: '#fff',
                            font: {
                                size: 16,
                            },
                        },
                    },
                    {
                        label: 'Behavioral',
                        data: beh_data,
                        backgroundColor: "#2281e8",
                        datalabels: {
                            display: true,
                            align: 'end',
                            anchor : 'middle',
                            color: '#fff',
                            font: {
                                size: 16,
                            },
                        },
                    }
                ]
            },
            options: {
                // barRoundness: 1,
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text:'Officers Covered (Inclusive of repetitions)', 
                    fontColor: 'white',
                    fontSize: 18,
                    position: 'bottom'
                },
                tooltips : {
                    enabled : false,
                    mode : 'single',
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].label + ': ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            barPercentage: 0.4,
                            display: true,
                            gridLines: {
                                color: '#67758d',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                zeroLineWidth : 1,
                                zeroLineColor : '#67758d',
                                borderDash : [1]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {   
                            display: false,
                            beginAtZero: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'Nos.',
                                fontColor: '#64779c',
                                fontSize: '20',
                            },
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 4
                            },
                            gridLines: {
                                color: 'transparent',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphdata, props.allGraph, props.retailGraph, props.lpgGraph, props.lubesGraph, props.atfGraph, props.refineryGraph, props.incGraph]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}
export default LearningDevelopmentLineGraph