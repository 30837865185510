import React from 'react'
import styles from './ItrmDemurrage.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircle,faCheckCircle} from '@fortawesome/free-solid-svg-icons'
const Radio = props => {
    const getMode = e => {
        props.onClick({name : props.name})
    }
    const size = props.size
    const icon = props.checked ? faCheckCircle : faCircle
    return (
        <div className={`${styles.radioSection}`}>
            <FontAwesomeIcon
                icon={icon}
                size={size}
                onClick={getMode}
                cursor='pointer'
            />
            <input type="radio" name="demurragefield" value={props.name} checked={props.checked} readOnly />
            <p>{props.label}</p>
        </div>
    )
}

export default Radio
