import React, { useState, useEffect } from 'react'
import styles from './FinanceMarket.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import DeltaValuesWithTotalOrg from '../../DeltaValuesWithTotalOrg/DeltaValuesWithTotalOrg'
import BpclMarket from './BpclMarket/BpclMarket'
import Sensex from './Sensex/Sensex'
import Nifty from './Nifty/Nifty'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchNiftyDataMonth, fetchSensexDataMonth, fetchStmarketDataMonth, fetchStMarketInfo } from '../../../services/Org'

const FinanceMarket = (props) => {
    const [sensexDate, setsensexDate] = useState([])
    const [sensexClose, setsensexClose] = useState([])
    const [sensexDelta, setsensexDelta] = useState([])
    const [sensexPerc, setsensexPerc] = useState([])

    const [sensxDate, setsensxDate] = useState([])
    const [sensxClose, setsensxClose] = useState([])
    const [sensxDelta, setsensxDelta] = useState([])
    const [sensxPerc, setsensxPerc] = useState([])

    const [niftyDate, setniftyDate] = useState([])
    const [niftyClose, setniftyClose] = useState([])
    const [niftyDelta, setniftyDelta] = useState([])
    const [niftyPerc, setniftyPerc] = useState([])

    const [Markinfo, setMarkinfo] = useState([])
    const [MarketCap, setMarketCap] = useState(0)
    const [Volume, setVolume] = useState(0)

    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
        }
    }
    useEffect(() => {
        var getDate = new Date()
        let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        let getcurrentMonth = months[getDate.getMonth()]
        let getcurrentYear = getDate.getUTCFullYear()
        // if(props.dropdown === 'YTM'){
        //     fetchStmarketDataYear(getcurrentYear)
        //         .then(response => {
        //             setsensexDate(response.sucdate)
        //             setsensexClose(response.succlose)
        //             setsensexDelta(response.sucdelta)
        //             setsensexPerc(response.sucpercentage)
        //         })
        //         .catch(error => console.debug(error))

        //     fetchSensexDataYear(getcurrentYear)
        //         .then(response => {
        //             setsensxDate(response.sucdate)
        //             setsensxClose(response.succlose)
        //             setsensxDelta(response.sucdelta)
        //             setsensxPerc(response.sucpercentage)
        //         })
        //         .catch(error => console.debug(error))

        //     fetchNiftyDataYear(getcurrentYear)
        //         .then(response => {
        //             setniftyDate(response.sucdate)
        //             setniftyClose(response.succlose)
        //             setniftyDelta(response.sucdelta)
        //             setniftyPerc(response.sucpercentage)
        //         })
        //         .catch(error => console.debug(error))
        // }else 
        fetchStmarketDataMonth(getcurrentMonth, getcurrentYear)
            .then(response => {
                setsensexDate(response.sucdate)
                setsensexClose(response.succlose)
                setsensexDelta(response.sucdelta)
                setsensexPerc(response.sucpercentage)
            })
            .catch(error => console.debug(error))

        fetchSensexDataMonth(getcurrentMonth, getcurrentYear)
            .then(response => {
                setsensxDate(response.sucdate)
                setsensxClose(response.succlose)
                setsensxDelta(response.sucdelta)
                setsensxPerc(response.sucpercentage)
            })
            .catch(error => console.debug(error))

        fetchNiftyDataMonth(getcurrentMonth, getcurrentYear)
            .then(response => {
                setniftyDate(response.sucdate)
                setniftyClose(response.succlose)
                setniftyDelta(response.sucdelta)
                setniftyPerc(response.sucpercentage)
            })
            .catch(error => console.debug(error))
        fetchStMarketInfo()
            .then(response => {
                if(response){
                setMarkinfo(response.summaryDetail)
                var s = ['', 'K', 'M', 'B', 'T']
                var sNum = Math.round(parseFloat(('' + response.summaryDetail.marketCap).length / 3))
                var sVol = Math.round(parseFloat(('' + response.summaryDetail.volume).length / 3))
                var sAVol = Math.round(parseFloat(('' + response.summaryDetail.averageVolume).length / 3))
                var sVal = parseFloat((
                    sNum !== 0 ? (response.summaryDetail.marketCap / Math.pow(1000, sNum)) : response.summaryDetail.marketCap).toPrecision(3))
                if (sVal % 1 !== 0) {
                    sVal = sVal.toFixed(3)
                }
                var sVolume = parseFloat((
                    sVol !== 0 ? (response.summaryDetail.volume / Math.pow(1000, sVol)) : response.summaryDetail.volume).toPrecision(3))
                if (sVolume % 1 !== 0) {
                    sVolume = sVolume.toFixed(3)
                }
                var sVolumeavg = parseFloat((
                    sAVol !== 0 ? (response.summaryDetail.averageVolume / Math.pow(1000, sAVol)) : response.summaryDetail.averageVolume).toPrecision(3))
                if (sVolumeavg % 1 !== 0) {
                    sVolumeavg = sVolumeavg.toFixed(3)
                }
                setMarketCap(sVal + s[sNum])
                setVolume({
                    volume: sVolume + s[sVol],
                    avgvolume: sVolumeavg + s[sAVol]
                })
            }
            })
            .catch(error => console.debug(error))
    }, [props.dropdown])
    return (
        <FullScreen handle={handle}>
            <div className={`${styles.marketContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.marketHeader}`}>
                    <span>Market - @Login</span>
                    <img src={ExpandIcon} alt="Expand Growth KPIs" id="fullSrnfm" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                <div className={`d-flex ${styles.topContainer}`}>
                    <div className={`${styles.shareContainer}`}>
                        <BpclMarket dropdown={props.dropdown} sensexDate={sensexDate} sensexClose={sensexClose} sensexDelta={sensexDelta} sensexPerc={sensexPerc} />
                    </div>
                    <div className={`${styles.shareContainer}`}>
                        <Sensex dropdown={props.dropdown} sensxDate={sensxDate} sensxClose={sensxClose} sensxDelta={sensxDelta} sensxPerc={sensxPerc} />
                    </div>
                    <div className={`${styles.shareContainer}`}>
                        <Nifty dropdown={props.dropdown} niftyDate={niftyDate} niftyClose={niftyClose} niftyDelta={niftyDelta} niftyPerc={niftyPerc} />
                    </div>
                </div>
                <div className={`${styles.bottomContainer}`}>

                    <div className={`d-flex ${styles.listItemRow}`}>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Open</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.open).toFixed(2)} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Mkt Cap</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={MarketCap} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>52W H</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.fiftyTwoWeekHigh).toFixed(2)} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>

                    </div>

                    <div className={`d-flex ${styles.listItemRow}`}>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>High</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.dayHigh).toFixed(2)} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Volume</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={Volume.volume} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>52W L</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.fiftyTwoWeekLow).toFixed(2)} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                    </div>

                    <div className={`d-flex ${styles.listItemRow}`}>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Low</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.dayLow).toFixed(2)} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Avg Volume</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={Volume.avgvolume} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>52W Change</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.beta).toFixed(2)} curfromat="%"></DeltaValuesWithTotalOrg>
                            </div>
                        </div>

                    </div>

                    <div className={`d-flex ${styles.listItemRow}`}>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>P/E</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.trailingPE).toFixed(2)} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Div Yield</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.dividendYield * 100).toFixed(2)} curfromat="%"></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                        <div className={`d-flex ${styles.listItemContainer}`}>
                            <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Div Rate</div>
                            <div className={`d-flex align-items-center justify-content-center ${styles.Kpi}`}>
                                <DeltaValuesWithTotalOrg totalData={parseFloat(Markinfo.payoutRatio).toFixed(2)} curfromat=""></DeltaValuesWithTotalOrg>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </FullScreen>
    )
}
export default FinanceMarket