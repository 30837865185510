import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import { get, reduce, has } from 'lodash'
import { numberFormatNoSeparator } from '../../../utility/utility'
import classes from './BuProjectGraph1.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const xLabels = ['Refineries', 'Renewable', 'Retail', 'Pipeline', 'Lubes','LPG', 'HRS', 'Gas', 'Bio Fuel']

const BuProjectGraph1 = ({graphData}) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    const getChartConfig = () => {
        let projectGraph0_25 = [] 
        let projectGraph26_50 = []
        let projectGraph51_75 = []
        let projectGraph76_100 = []
        let delayedProject = []
        if(graphData.length>0){
        graphData.forEach(item => {
                if(item.business_unit === 'refineries'){
                    projectGraph0_25[0] = item.completed_0_25_percent
                    projectGraph26_50[0] = item.completed_26_50_percent
                    projectGraph51_75[0] = item.completed_51_75_percent
                    projectGraph76_100[0] = item.completed_76_100_percent
                    delayedProject[0] = item.no_of_delayed_project
                }
                if(item.business_unit === 'renewable energy'){
                    projectGraph0_25[1] = item.completed_0_25_percent
                    projectGraph26_50[1] = item.completed_26_50_percent
                    projectGraph51_75[1] = item.completed_51_75_percent
                    projectGraph76_100[1] = item.completed_76_100_percent
                    delayedProject[1] = item.no_of_delayed_project

                }if(item.business_unit === 'retail'){
                    projectGraph0_25[2] = item.completed_0_25_percent
                    projectGraph26_50[2] = item.completed_26_50_percent
                    projectGraph51_75[2] = item.completed_51_75_percent
                    projectGraph76_100[2] = item.completed_76_100_percent
                    delayedProject[2] = item.no_of_delayed_project

                }if(item.business_unit === 'pipeline'){
                    projectGraph0_25[3] = item.completed_0_25_percent
                    projectGraph26_50[3] = item.completed_26_50_percent
                    projectGraph51_75[3] = item.completed_51_75_percent
                    projectGraph76_100[3] = item.completed_76_100_percent
                    delayedProject[3] = item.no_of_delayed_project

                }if(item.business_unit === 'lube'){
                    projectGraph0_25[4] = item.completed_0_25_percent
                    projectGraph26_50[4] = item.completed_26_50_percent
                    projectGraph51_75[4] = item.completed_51_75_percent
                    projectGraph76_100[4] = item.completed_76_100_percent
                    delayedProject[4] = item.no_of_delayed_project

                }if(item.business_unit === 'lpg'){
                    projectGraph0_25[5] = item.completed_0_25_percent
                    projectGraph26_50[5] = item.completed_26_50_percent
                    projectGraph51_75[5] = item.completed_51_75_percent
                    projectGraph76_100[5] = item.completed_76_100_percent
                    delayedProject[5] = item.no_of_delayed_project

                }
                if(item.business_unit === 'hrs'){
                    projectGraph0_25[6] = item.completed_0_25_percent
                    projectGraph26_50[6] = item.completed_26_50_percent
                    projectGraph51_75[6] = item.completed_51_75_percent
                    projectGraph76_100[6] = item.completed_76_100_percent
                    delayedProject[6] = item.no_of_delayed_project

                }if(item.business_unit === 'gas'){
                    projectGraph0_25[7] = item.completed_0_25_percent
                    projectGraph26_50[7] = item.completed_26_50_percent
                    projectGraph51_75[7] = item.completed_51_75_percent
                    projectGraph76_100[7] = item.completed_76_100_percent
                    delayedProject[7] = item.no_of_delayed_project

                }if(item.business_unit === 'bio fuel'){
                    projectGraph0_25[8] = item.completed_0_25_percent
                    projectGraph26_50[8] = item.completed_26_50_percent
                    projectGraph51_75[8] = item.completed_51_75_percent
                    projectGraph76_100[8] = item.completed_76_100_percent
                    delayedProject[8] = item.no_of_delayed_project

                }
        })
    }
        const ctx = chartContainer.current.getContext('2d')
        let gradientForDelayedProject = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForDelayedProject.addColorStop(1, '#ff588c')//dark pink

        let gradientForO_to_3 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForO_to_3.addColorStop(1, '#7248bd')//voilet

        let gradientFor3_to_6 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientFor3_to_6.addColorStop(0, '#1f75d1')//blue

        let gradientForGt_6 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForGt_6.addColorStop(0, '#c79638')//yellow

        let gradientFor_100 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientFor_100.addColorStop(0, '#059e92')//teal

        let chartConfig = {
            type: 'horizontalBar',
            data: {
                labels: xLabels,
                datasets: [
                    {
                        label: 'Delayed Projects',
                        backgroundColor: gradientForDelayedProject,
                        data: delayedProject,
                        barThickness: 30,
                        datalabels: {
                            display: true,
                            formatter: function (value, context) {
                                return value > 0 ? `${delayedProject[context.dataIndex]}` : "" 
                            },
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        }
                    },
                    {
                        label: '0-25',
                        backgroundColor: gradientForO_to_3,
                        data: projectGraph0_25,
                        barThickness: 30,
                        datalabels: {
                            display: true,
                            formatter: function (value, context) {
                                return value > 0 ? `${projectGraph0_25[context.dataIndex]}` : "" 
                            },
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        }
                    },
                    {
                        label: '>25-50',
                        backgroundColor: gradientFor3_to_6,
                        data: projectGraph26_50,
                        barThickness: 30,                      
                        datalabels: {
                            formatter: function (value, context) {
                                return value > 0 ? `${projectGraph26_50[context.dataIndex]}` : "" 
                            },
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        }
                    },
                    {
                        label: '>50-75',
                        backgroundColor: gradientForGt_6,
                        data: projectGraph51_75,
                        barThickness: 30,                      
                        datalabels: {
                            formatter: function (value, context) {
                                return value > 0 ? `${projectGraph51_75[context.dataIndex]}` : "" 
                            },
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        }
                    },
                    {
                        label: '>75-100',
                        backgroundColor: gradientFor_100,
                        data: projectGraph76_100,
                        barThickness: 30,                      
                        datalabels: {
                            formatter: function (value, context) {
                                return value > 0 ? `${projectGraph76_100[context.dataIndex]}` : "" 
                            },
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        }
                    }
                ],
            },
            options: {
                legend: {
                    display: false,
                    // align: "start",
                    // labels: {
                    //   fontSize: 22,
                    //   fontColor: "#8fa9db",
                    //   boxWidth: 24
                    // }
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                fontColor: '#dee5ef',
                                fontSize: '18',
                                maxTicksLimit : 5
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 5,
                                fontColor: '#dee5ef',
                                fontSize: '18',
                            }

                        }
                    ]
                },
            },
        }

        return chartConfig
    }

  
    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = getChartConfig()

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, graphData]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`d-flex ${classes.BuProjectsPlanningGraph}`}>
            <div className='col'>
                <canvas ref={chartContainer} height={500} width={700} />
            </div>
        </div>
    )
}
export default BuProjectGraph1