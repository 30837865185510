import React, { useState, useEffect, useCallback } from 'react'
import styles from '../../components/OrgFinance/OrgGAs.module.scss'
import ExpandIcon from '../../assets/images/expandIcon.png'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import IndiaMap from '../../assets/images/gas/IndiaMap.png'
// import { NoDataFound } from '../../DataNotFound/nodatafound'
// import greenTick from '../../../assets/images/greenTick.png'
// import { isEmpty } from 'lodash'
// import "../../../Common.scss"

const OrgMap1 = () => {
   

    // const [loading, setloading] = useState(true)

    

    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps

    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.EmployeeDataAndRecruitmentContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.EmployeeDataAndRecruitmentHeader2}`}>
                    <span>BPCL GAs on India Map</span>
                    
                    {/* {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>} */}
                    {/* <img src={ExpandIcon} alt="Expand Employee Data And Recruitment" id="fullSrnedr" data-fs="handleenter" onClick={FullScreen}></img> */}
                </div>
                <img className={`${styles.mapimg}`} src={IndiaMap}  alt=''/>
            </div>
        </FullScreen>
        
    )

}
export default OrgMap1