import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'

import styles from './RefinaryProduction.module.scss'
import RefinaryProductionChart from './RefinaryProductionChart'
import { fetchKochiMtdDataProduction, fetchKochiYtmDataProduction, fetchMumbaiMtdDataProduction, fetchMumbaiYtmDataProduction , fetchBinaMtdDataProduction, fetchBinaYtmDataProduction, fetchupdatedStatus} from '../../../services/Refinery'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"


const RefinaryProduction = (props) => {
    const [productionMumbaiYtmData, setProductionMumbaiYtmData] = useState([])
    const [productionMumbaiMtdData, setProductionMumbaiMtdData] = useState([])
    const [productionKochiYtmData, setProductionKochiYtmData] = useState([])
    const [productionKochiMtdData, setProductionKochiMtdData] = useState([])    
    const [productionBinaYtmData, setProductionBinaYtmData] = useState([])
    const [productionBinaMtdData, setProductionBinaMtdData] = useState([])
    const [graphData, setGraphData] = useState(null)
    const [showingGraphUnit, setShowingGraphUnit] = useState('MTD')
    const [targetGraphUnit, setTargetGraphUnit] = useState('YTM')
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState('')


    useEffect(() => {
        if(props.location === 'kochi') {
            fetchKochiYtmDataProduction(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setProductionKochiYtmData(response.data)
                        setIsNoDataAvailable(false)

                    }
                })
                .catch((error) => console.debug(error))
            fetchKochiMtdDataProduction(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setProductionKochiMtdData(response.data)
                        if (props.location === 'kochi') {
                            setGraphData(response.data)
                        }
                        setIsNoDataAvailable(false)

                    }

                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "KR" && res.kpi_name === "Production" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        } 
        if (props.location === 'mumbai') {
            fetchMumbaiYtmDataProduction(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                    } else {
                        setProductionMumbaiYtmData(response.data)
                        setIsNoDataAvailable(false)
                    }
    
                })
                .catch((error) => console.debug(error))
    
            fetchMumbaiMtdDataProduction(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                        

                    } else {
                        setProductionMumbaiMtdData(response.data)
                        if (props.location === 'mumbai') {
                            setGraphData(response.data)
                        }
                        setIsNoDataAvailable(false)
                

    
                    }
    
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "MR" && res.kpi_name === "Production" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
        if (props.location === 'bina') {
            fetchBinaYtmDataProduction(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                        

                    } else {
                        setProductionBinaYtmData(response.data)
                        setIsNoDataAvailable(false)
                

                    }
    
                })
                .catch((error) => console.debug(error))
    
            fetchBinaMtdDataProduction(props.year)
                .then((response) => {
                    if (isEmpty(response.data)) {
                        setIsNoDataAvailable(true)
                        

                    } else {
                        setProductionBinaMtdData(response.data)
                        if (props.location === 'bina') {
                            setGraphData(response.data)
                        }
                        setIsNoDataAvailable(false)
                

    
                    }
    
                })
                .catch((error) => console.debug(error))
            fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "BR" && res.kpi_name === "Production" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        }
        
    }, [props.location, props.year])

    useEffect(() => {
        function switchForLocationButton() {
            if (props.location === 'mumbai') {
                setGraphData(productionMumbaiMtdData)
                setShowingGraphUnit('MTD')
                setTargetGraphUnit('YTM')
            }
            if (props.location === 'kochi') {
                setGraphData(productionKochiMtdData)
                setShowingGraphUnit('MTD')
                setTargetGraphUnit('YTM')
            }
            if (props.location === 'bina') {
                setGraphData(productionBinaMtdData)
                setShowingGraphUnit('MTD')
                setTargetGraphUnit('YTM')
            }
        }
        switchForLocationButton()
    }, [props.location, productionMumbaiMtdData, productionKochiMtdData, productionBinaMtdData])

    function switchUnits(location) {
        if (location === 'mumbai') {
            if (targetGraphUnit === 'MTD') {
                // console.debug("Switch YTM to MTD");
                // console.debug("Production-Mumbai-MTD -- ", productionMumbaiMtdData);
                setGraphData(productionMumbaiMtdData)
                setShowingGraphUnit('MTD')
                setTargetGraphUnit('YTM')
            }
            if (targetGraphUnit === 'YTM') {
                // console.debug("Switch MTD to YTM");
                // console.debug("Production-Mumbai-YTM -- ", productionMumbaiYtmData);
                setGraphData(productionMumbaiYtmData)
                setShowingGraphUnit('YTM')
                setTargetGraphUnit('MTD')
            }
        }
        if (location === 'kochi') {
            if (targetGraphUnit === 'MTD') {
                // console.debug("Switch YTM to MTD");
                // console.debug("Production-Kochi-MTD -- ", productionKochiMtdData);
                setGraphData(productionKochiMtdData)
                setShowingGraphUnit('MTD')
                setTargetGraphUnit('YTM')
            }
            if (targetGraphUnit === 'YTM') {
                // console.debug("Switch MTD to YTM");
                // console.debug("Production-Kochi-YTM -- ", productionKochiYtmData);
                setGraphData(productionKochiYtmData)
                setShowingGraphUnit('YTM')
                setTargetGraphUnit('MTD')
            }
        }
        if (location === 'bina') {
            if (targetGraphUnit === 'MTD') {
                // console.debug("Switch YTM to MTD");
                // console.debug("Production-Kochi-MTD -- ", productionKochiMtdData);
                setGraphData(productionBinaMtdData)
                setShowingGraphUnit('MTD')
                setTargetGraphUnit('YTM')
            }
            if (targetGraphUnit === 'YTM') {
                // console.debug("Switch MTD to YTM");
                // console.debug("Production-Kochi-YTM -- ", productionKochiYtmData);
                setGraphData(productionBinaYtmData)
                setShowingGraphUnit('YTM')
                setTargetGraphUnit('MTD')
            }
        }
    }
    return (
        <div
            className={`d-flex flex-column justify-content-between ${styles.RefinaryProductionContainer}`}
        >
            <div className={`d-flex align-items-center ${styles.graphTitle}`}>
                <span>
                    Production ({showingGraphUnit}) - Twice Daily
                    <span style={{ display: 'none' }}>{showingGraphUnit}</span>
                </span>
                {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}

            </div>
            {isNoDataAvailable ? <NoDataFound height={620} />
                :
                (
                    <>
                        {
                            graphData ? <RefinaryProductionChart location={props.location} graphData={graphData}></RefinaryProductionChart> :
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>

                        }
                    </>
                )}
            <div className={`d-flex align-items-center justify-content-between ${styles.grmFooter}`}>
                <div onClick={() => switchUnits(props.location)} className={`${styles.footerLeft}`}><span>View data for {targetGraphUnit}</span></div>
                <div className={`${styles.footerRight}`}>
                    <span>
                        {graphData != undefined && graphData.length > 0 ? '' : <>No Data Avaliable</>}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default RefinaryProduction
