import React, { useEffect, useState, useRef } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './AtfCustomerGrowthGraphLastYr.module.scss'
import { isEmpty } from 'lodash'
import { numberFormatEnIn } from '../../../utility/utility'

const BuLubesInventoryGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
  
  
    useEffect(() => {
        const prevYearArray = []
        const currYearArray = []
        const companyNames = []
        const growthArray = []
        const growthColor = []
        const actualGrowths = []

        const { graphData } = props
        
        if (!isEmpty(graphData)) {
            graphData.forEach((item) => {
                companyNames.push(item.company_name)
                
                const growth = item.curr_year - item.prev_year
                actualGrowths.push(growth)

                const currentWithoutGrowth = (growth > 0) ? item.curr_year - Math.abs(growth) : item.curr_year
                prevYearArray.push(item.prev_year)
                currYearArray.push(currentWithoutGrowth)
                
                growthArray.push(Math.abs(growth))
                growthColor.push(growth >= 0 ? '#74ce12' :  '#e13219')
            })
        }

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let chartConfig = {
            type: 'horizontalBar',
            data: {
                // labels: ["Air India", "Spice Jet", "Vistara", "Indigo", "Indigo", "Indigo", "Lufthansa", "British Airways", "Indigo", "Indigo"],
                labels: companyNames,
                datasets: [
                    {
                        label: 'Last Year',
                        stack: 'Stack 1',
                        backgroundColor: 'rgba(0, 168, 236, 0.3)',
                        // data: [5500, 5200, 5100, 5000, 4692, 4312, 4116, 4182, 3920, 2940],
                        data:prevYearArray,
                        barThickness: 15,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Current Year',
                        stack: 'Stack 2',
                        backgroundColor: 'rgba(0, 168, 236, 0.65)',
                        data:currYearArray,
                        // data: [5390, 5096, 5000, 4900, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 15,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Gain/Loss',
                        stack: 'Stack 2',
                        backgroundColor: growthColor, 
                        data: growthArray,
                        // backgroundColor: ["#74ce12","#74ce12","#e13219", "#74ce12","#e13219","#74ce12","#74ce12","#e13219","#74ce12","#74ce12"],
                        // data: [110, 104, 100, 100, 92, 88, 84, 82, 80, 60],
                        // categoryPercentage: 1,
                        // barPercentage: 1,
                        barThickness: 15,
                        datalabels: {
                            display: false,
                        }
                    }
                ],
            },
            options: {
                tooltips: {
                    mode: 'label',
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label
                            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                            const actualGrowth = actualGrowths[tooltipItem.index]
                            if (label === 'Current Year') {
                                value = (actualGrowth > 0) ? value + Math.abs(actualGrowth) : value
                            } else if (label === 'Gain/Loss') {
                                label = (actualGrowth > 0) ? 'Gain' : 'Loss'
                            }

                            return  `${label} : ${numberFormatEnIn(value)}`
                        }
                    }
                },
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 9,
                                fontColor: 'white',
                                fontSize: '20',
                            }

                        }
                    ]
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <span className={`${styles.graphHeader}`}>Key Customers</span>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}

export default BuLubesInventoryGraph
