import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuAtfSalesRevenue.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import SalesRevenueGraph from '../../../charts/SalesRevenueGraph/SalesRevenueGraph'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchAtfSalesRevenue, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { numberFormatEnIn } from '../../../utility/utility'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

function BuAtfSalesRevenue(props) {
    const [salesGraphData, setSalesGraphData] = useState(null)
    const [revenueGraphData, setRevenueGraphData] = useState(null)
    const [salesLastItem, setSalesLastItem] = useState({})
    const [revenueLastItem, setRevenueLastItem] = useState({})
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState('')

    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "aviation" && res.kpi_name === "Sales" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        function prepareGraphData(kpi_name) {
            fetchAtfSalesRevenue(kpi_name, props.year)
                .then(response => {
                    if (!isEmpty(response.data)) {
                    response.data.forEach((item, index) => {
                        if (item.kpi_name === 'sales') {
                            setSalesGraphData(response.data)
                            setSalesLastItem(response.data[response.data.length - 1])
                        }
                        else if (item.kpi_name === 'revenue') {
                            setRevenueGraphData(response.data)
                            setRevenueLastItem(response.data[response.data.length - 1])
                        }
                    })
                        setIsNoDataAvailable(false)
                    } else {
                        setIsNoDataAvailable(true)
                    }
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData('sales')
        prepareGraphData('revenue')
    }, [props.year])

    return (
        <div className={`${styles.salesRevenueContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <span>Sales & Revenue - Daily</span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                        {isNoDataAvailable || 
                        <div className={`col-6 mt-2 ${styles.headerRight}`}>
                            <span>Sales: {salesLastItem.total !== null ? numberFormatEnIn(Number(salesLastItem.total).toFixed(2)) + ' TKL' : 'No Data'}</span>
                            {/* <span className={'ml-4'} style={{ color: `${Math.sign(salesLastItem.growth) === 1 ? '#74ce12' : '#e13219'}` }}>{salesLastItem.growth && Number(salesLastItem.growth).toFixed(2) + '%'}</span> */}
                        </div>
                        }
                        {isNoDataAvailable || 
                        <div className={`col-6 ${styles.headerLabelsTarget}`}>
                            <span>Revenue: &#8377; {revenueLastItem.total !== null ? numberFormatEnIn(Number(revenueLastItem.total).toFixed(2)) + ' Cr' : 'No Data'}</span>
                            {/* <span className={'ml-4'} style={{ color: `${Math.sign(revenueLastItem.growth) === 1 ? '#74ce12' : '#e13219'}` }} >{revenueLastItem.growth !== null ? Number(revenueLastItem.growth).toFixed(2) + '%' : 'No Data'}</span> */}
                        </div>
                        }
                        
                    </div>
                    {/* <div className={'row pt-2'}>
                        <div className={`col-7 d-flex align-items-center ${styles.headerLabels}`}>
                        </div>
                        <div className={`col-5 ${styles.headerLabelsTarget}`}>
                            <span>Revenue: &#8377; {revenueLastItem.actual !== null ? Number(revenueLastItem.actual).toFixed(2) + ' Cr' : 'No Data'}</span>
                            <span className={'ml-4'} style={{ color: `${Math.sign(revenueLastItem.growth) === 1 ? '#74ce12' : '#e13219'}` }} >{revenueLastItem.growth !== null ? Number(revenueLastItem.growth).toFixed(2) + '%' : 'No Data'}</span>
                        </div>
                    </div> */}
                </div>
                {isNoDataAvailable ? 
                    <NoDataFound height={507} />
                    : 
                    <>
                <div className={`row ${styles.salesRevenueGraphHeader}`}>
                    <div className={'col-5 p-2'}>
                        <div className='row justify-content-md-center'>
                            <div className='d-flex mr-2 align-items-center'>Sales:</div>
                            <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.salesActualDot}`}></span>Actual</div>
                            {/* <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.salesTargetDot}`}></span>Target</div> */}
                        </div>
                    </div>
                    <div style={{ width: 20 }}></div> {/* Spacer */}
                    <div className={'col-6 p-2'}>
                        <div className='row justify-content-md-center'>
                            <div className='d-flex mr-2 align-items-center'>Revenue:</div>
                            <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.revenueActualDot}`}></span>Actual</div>
                            {/* <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.revenueTargetDot}`}></span>Target</div> */}
                        </div>
                    </div>
                </div>
                    <div className={`row ${styles.salesRevenueGraph}`}>
                    <div className={`col-5 p-0 ${styles.leftDiv}`}>
                        {
                            salesGraphData ?
                                <SalesRevenueGraph graphData={salesGraphData}></SalesRevenueGraph>
                                :
                                <NoDataFound />
                        }
                    </div>
                    <div className={`col-2 pl-0 pr-1 ${styles.centerDiv}`}>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Apr  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>May  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Jun  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Jul  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Aug  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Sep  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Oct  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Nov  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Dec  {props.year.toString().slice(-2)}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Jan  {(parseInt(props.year.toString().slice(-2), 10) + 1).toString()}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Feb  {(parseInt(props.year.toString().slice(-2), 10) + 1).toString()}</div>
                        <div className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>Mar  {(parseInt(props.year.toString().slice(-2), 10) + 1).toString()}</div>
                    </div>
                    <div className={`col-5 p-0 ${styles.rightDiv}`}>
                        {
                            revenueGraphData ?
                                <SalesRevenueGraph graphData={revenueGraphData}></SalesRevenueGraph>
                                :
                                <NoDataFound />
                        }
                    </div>
                </div>
                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' onClick={fullScrn}></img>
                </div>
                </>
                }
            </FullScreen>
        </div>
    )
}
export default BuAtfSalesRevenue