// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FinanceGrowth_growthContainer__1XM-y {\n  background-color: #202c3f;\n  width: 2117px;\n  margin-right: 24px;\n  font-family: Titillium Web; }\n  .FinanceGrowth_growthContainer__1XM-y .FinanceGrowth_growthHeader__1WJ2T {\n    height: 84px;\n    background-color: #181e2a;\n    font-size: 28px;\n    font-weight: bold;\n    color: white;\n    padding-left: 24px;\n    padding-right: 24px; }\n  .FinanceGrowth_growthContainer__1XM-y .FinanceGrowth_growthContentContainer__3M9Ls {\n    margin: 24px 0px 24px 24px;\n    height: 490px; }\n\n.FinanceGrowth_statusUpdatedImg__MAlen {\n  margin-left: 900px;\n  margin-top: -14px; }\n", ""]);
// Exports
exports.locals = {
	"growthContainer": "FinanceGrowth_growthContainer__1XM-y",
	"growthHeader": "FinanceGrowth_growthHeader__1WJ2T",
	"growthContentContainer": "FinanceGrowth_growthContentContainer__3M9Ls",
	"statusUpdatedImg": "FinanceGrowth_statusUpdatedImg__MAlen"
};
module.exports = exports;
