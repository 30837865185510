import React, { useState, useEffect, useCallback } from 'react'
import styles from './EmployeeDataAndRecruitment.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import maleIcon from '../../../assets/images/male_icon.png'
import femaleIcon from '../../../assets/images/female_icon.png'
import EmployeeDemographicGraph from './EmployeeDemographicGraph'
//import CostCompositionGraph from "./CostCompositionGraph";
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchEmployeeDetailsData, fetchEmployeeDetailsDemographic, fetchupdatedStatus } from '../../../services/Org'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import EmployeeDemographicPieChart from './EmployeeDemographicPieChart'
import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
import "../../../Common.scss"

const EmployeeDataAndRecruitment = (props) => {
    const [edrdataTotal, setedrdataTotal] = useState([])
    const [edrdataMale, setedrdataMale] = useState([])
    const [edrdataFemale, setedrdataFemale] = useState([])
    const [edrRefineriesdata, setedrRefineriesdata] = useState([])
    const [edrBudata, setedrBudata] = useState([])
    const [edrEntitiesdata, setedrEntitiesdata] = useState([])
    const [edrRefineriesdataFemale, setedrRefineriesdataFemale] = useState([])
    const [edrBudataFemale, setedrBudataFemale] = useState([])
    const [edrEntitiedataFemale, setedrEntitiesdataFemale] = useState([])
    //  mf ratio
    // const [ratiomfref, setratiomfref] = useState({})
    const [ratiomfrefm, setratiomfrefm] = useState({})
    const [ratiomfrefk, setratiomfrefk] = useState({})
    const [ratiomfrefb, setratiomfrefb] = useState({})
    const [ratiomfret, setratiomfret] = useState({})
    const [ratiomfatf, setratiomfatf] = useState({})
    const [ratiomfinc, setratiomfinc] = useState({})
    const [ratiomflpg, setratiomflpg] = useState({})
    const [ratiomflubes, setratiomflubes] = useState({})
    const [ratiomfspo, setratiomfspo] = useState({})
    const [ratiomfdep, setratiomfdep] = useState({})
    const [ratiomfgas, setratiomfgas] = useState({})
    // management 
    // const [mangref, setmangref] = useState([])
    const [mangrefm, setmangrefm] = useState([])
    const [mangrefk, setmangrefk] = useState([])
    const [mangrefb, setmangrefb] = useState([])
    const [mangatf, setmangatf] = useState([])
    const [manginc, setmanginc] = useState([])
    const [manglpg, setmanglpg] = useState([])
    const [manglubes, setmanglubes] = useState([])
    const [mangret, setmangret] = useState([])
    const [mangspo, setmangspo] = useState([])
    const [mangdep, setmangdep] = useState([])
    const [manggas, setmanggas] = useState([])
    const mangTotal = [mangrefm + mangrefk + mangrefb + mangatf + manginc + manglpg + manglubes + mangret + mangspo  + manggas]

    // contract
    const [clerref, setclerref] = useState([])
    const [cleratf, setcleratf] = useState([])
    const [clerinc, setclerinc] = useState([])
    const [clerlpg, setclerlpg] = useState([])
    const [clerlubes, setclerlubes] = useState([])
    const [clerret, setclerret] = useState([])
    const [clerspo, setclerspo] = useState([])
    // others
    // const [labref, setlabref] = useState([])
    const [labrefm, setlabrefm] = useState([])
    const [labrefk, setlabrefk] = useState([])
    const [labrefb, setlabrefb] = useState([])
    const [labatf, setlabatf] = useState([])
    const [labinc, setlabinc] = useState([])
    const [lablpg, setlablpg] = useState([])
    const [lablubes, setlablubes] = useState([])
    const [labret, setlabret] = useState([])
    const [labspo, setlabspo] = useState([])
    const [labdep, setlabdep] = useState([])
    const [labgas, setlabgas] = useState([])
    const OthTotal = [labrefm + labrefk + labrefb + labatf + labinc + lablpg + lablubes + labret + labspo + labgas]
    const [updatedStatus, setUpdatedStatus] = useState('')

    const [loading, setloading] = useState(true)

    useEffect(() => {
        fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === "HR" && res.kpi_name === "employeedemographic" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        fetchEmployeeDetailsDemographic(props.year).then(data => {
            const mumbaiData = data.findLast((element) => element.sbu === 'Mumbai');
            const kochiData = data.findLast((element) => element.sbu === 'Kochi');
            const binaData = data.findLast((element) => element.sbu === 'Bina');
            setedrRefineriesdata({
                male: mumbaiData.male_ratio + kochiData.male_ratio + binaData.male_ratio,
                female: mumbaiData.female_ratio + kochiData.female_ratio + binaData.female_ratio
            })
            const retailData = data.findLast((element) => element.sbu === 'Retail');
            const aviationData = data.findLast((element) => element.sbu === 'Aviation');
            const incData = data.findLast((element) => element.sbu === 'INC');
            const lpgData = data.findLast((element) => element.sbu === 'LPG');
            const lubesData = data.findLast((element) => element.sbu === 'Lubes');
            const gasData = data.findLast((element) => element.sbu === 'Gas');
            setedrBudata({
                male: retailData.male_ratio + aviationData.male_ratio + incData.male_ratio + lpgData.male_ratio + lubesData.male_ratio + gasData.male_ratio,
                female: retailData.female_ratio + aviationData.female_ratio + incData.female_ratio + lpgData.female_ratio + lubesData.female_ratio + gasData.female_ratio
            })
            const entitiesData = data.findLast((element) => element.sbu === 'Entities');
            setedrEntitiesdata({
                male: entitiesData.male_ratio,
                female: entitiesData.female_ratio
            }) 
            if (data.length === 0) {
                setloading(true)
            } else {
                data.forEach((getedr, index) => {
                    if (getedr.sbu === 'Retail') {
                        setmangret(getedr.management)
                        setclerret(getedr.contract)
                        setlabret(getedr.others)
                        setratiomfret({male : getedr.male_ratio, female : getedr.female_ratio})
                    } else if (getedr.sbu === 'Aviation') {
                        setmangatf(getedr.management)
                        setcleratf(getedr.contract)
                        setlabatf(getedr.others)
                        setratiomfatf({male : getedr.male_ratio, female : getedr.female_ratio})
                    } else if (getedr.sbu === 'INC') {
                        setmanginc(getedr.management)
                        setclerinc(getedr.contract)
                        setlabinc(getedr.others)
                        setratiomfinc({male : getedr.male_ratio, female : getedr.female_ratio})
                    } else if (getedr.sbu === 'LPG') {
                        setmanglpg(getedr.management)
                        setclerlpg(getedr.contract)
                        setlablpg(getedr.others)
                        setratiomflpg({male : getedr.male_ratio, female : getedr.female_ratio})
                    } else if (getedr.sbu === 'Lubes') {
                        setmanglubes(getedr.management)
                        setclerlubes(getedr.contract)
                        setlablubes(getedr.others)
                        setratiomflubes({male : getedr.male_ratio, female : getedr.female_ratio})
                    } else if (getedr.sbu === 'Entities') {
                        setmangspo(getedr.management)
                        setclerspo(getedr.contract)
                        setlabspo(getedr.others)
                        setratiomfspo({male : getedr.male_ratio, female : getedr.female_ratio})
                    }
                    //  else if (getedr.sbu === 'Refinery') {
                    //     setmangref(getedr.management)
                    //     setclerref(getedr.contract)
                    //     setlabref(getedr.others)
                    //     setratiomfref({male : getedr.male_ratio, female : getedr.female_ratio})
                    // }
                    else if (getedr.sbu === 'Mumbai') {
                        setmangrefm(getedr.management)
                        setlabrefm(getedr.others)
                        setratiomfrefm({male : getedr.male_ratio, female : getedr.female_ratio})
                    }
                    else if (getedr.sbu === 'Kochi') {
                        setmangrefk(getedr.management)
                        setlabrefk(getedr.others)
                        setratiomfrefk({male : getedr.male_ratio, female : getedr.female_ratio})
                    }
                    else if (getedr.sbu === 'Bina') {
                        setmangrefb(getedr.management)
                        setclerref(getedr.contract)
                        setlabrefb(getedr.others)
                        setratiomfrefb({male : getedr.male_ratio, female : getedr.female_ratio})
                    }
                    else if (getedr.sbu === 'Deputation') {
                        setmangdep(getedr.management)
                        setlabdep(getedr.others)
                        setratiomfdep({male : getedr.male_ratio, female : getedr.female_ratio})
                    }
                    else if (getedr.sbu === 'Gas') {
                        setmanggas(getedr.management)
                        setlabgas(getedr.others)
                        setratiomfgas({male : getedr.male_ratio, female : getedr.female_ratio})
                    }
                })
                setloading(false)
            }
        }).catch(error => console.debug(error))

        fetchEmployeeDetailsData(props.year).then(data => {
            if (data.length === 0) {
                setloading(true)
            } else {
                data.forEach(getedr => {
                    setedrdataMale(getedr.male)
                    setedrdataFemale(getedr.female)
                    setedrdataTotal(getedr.total)
                })
                setloading(false)
            }
        }).catch(error => console.debug(error))
    }, [props.dropdown, props.year])

    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps

    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.EmployeeDataAndRecruitmentContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.EmployeeDataAndRecruitmentHeader}`}>
                    <span>Employee Data</span>
                    {/* <div className={`d-flex ${styles.graphLegendsContainer}`}> */}
                                    <div className="mr-4 d-flex align-items-center"><span className={`mr-2 ${styles.dot4}`}></span>BUs</div>
                                    <div className="mr-4 d-flex align-items-center"><span className={`mr-2 ${styles.dot5}`}></span>Entities</div>
                                    <div className="mr-4 d-flex align-items-center"><span className={`mr-2 ${styles.dot6}`}></span>Refineries</div>
                    {/* </div> */}
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand Employee Data And Recruitment" id="fullSrnedr" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                {loading
                    ?
                    <NoDataFound />
                    :
                    <div className={`d-flex flex-column justify-content-between ${styles.ContentContainer}`}>
                        <div className={`d-flex justify-content-between ${styles.Content}`}>
                            <div className={`${styles.demographicsContainer}`}>
                                <div className={`${styles.demographicsHeader}`}>Employee Demographics - Daily</div>
                                <div className={`d-flex ${styles.graphLegendsContainer}`}>
                                    <div className="mr-4 d-flex align-items-center">
                                        <span className={`mr-2 ${styles.dot1}`}></span> Management : {[mangTotal]}
                                    </div>
                                    {/* <div className="mr-4 d-flex align-items-center">
                                        <span className={`mr-2 ${styles.dot2}`}></span>Contract
                                    </div> */}
                                    <div className="mr-4 d-flex align-items-center">
                                        <span className={`mr-2 ${styles.dot3}`}></span>Non-Management : {[OthTotal]}
                                    </div>
                                </div>
                                {/* <div className={`${styles.demographicsTotals}`}>
                                    Total Employees:
                                    <span className={`${styles.demographicsTotalsValue}`}> {edrdataTotal}</span>
                                    </div>
                                    <div className={`d-flex ${styles.genderBreakupContainer}`}>
                                    <div className={`${styles.maleContainer}`}>
                                        <img src={maleIcon} alt="male icon" className={`${styles.maleIcon}`}></img>
                                        <span className={`${styles.maleValue}`}> {edrdataMale}</span>
                                    </div>
                                    <div className={`${styles.femaleContainer}`}>
                                        <img src={femaleIcon} alt="male icon" className={`${styles.femaleIcon}`}></img>
                                        <span className={`${styles.femaleValue}`}> {edrdataFemale}</span>
                                    </div>
                                    </div> */}


                                <div className={`${styles.EmployeeDemographicGraphContainer}`}>
                                    <EmployeeDemographicGraph mangatf={mangatf} mangrefm={mangrefm} mangrefk={mangrefk} mangrefb={mangrefb} manginc={manginc} manglpg={manglpg} manglubes={manglubes} mangspo={mangspo} mangret={mangret} manggas={manggas} mangdep={mangdep}
                                        cleratf={cleratf} clerref={clerref} clerinc={clerinc} clerlpg={clerlpg} clerlubes={clerlubes} clerspo={clerspo} clerret={clerret}
                                        labatf={labatf} labrefm={labrefm} labrefk={labrefk} labrefb={labrefb} labinc={labinc} lablpg={lablpg} lablubes={lablubes} labspo={labspo} labret={labret} labgas={labgas} labdep={labdep}
                                    ></EmployeeDemographicGraph>
                                </div>
                            </div>
                            <div className={`${styles.mfsection}`}>
                                <h4>M:F</h4>
                                <p className={`${styles.mfratio}`}>{`${ratiomfret.male ? ratiomfret.male : '0'}:${ratiomfret.female ? ratiomfret.female : '0'}`}</p>
                                <p className={`${styles.mfratio}`}>{`${ratiomfatf.male ? ratiomfatf.male : '0'}:${ratiomfatf.female ? ratiomfatf.female : '0'}`}</p>
                                <p className={`${styles.mfratio}`}>{`${ratiomfinc.male ? ratiomfinc.male : '0'}:${ratiomfinc.female ? ratiomfinc.female : '0'}`}</p>
                                <p className={`${styles.mfratio}`}>{`${ratiomflpg.male ? ratiomflpg.male : '0'}:${ratiomflpg.female ? ratiomflpg.female : '0'}`}</p>
                                <p className={`${styles.mfratio}`}>{`${ratiomflubes.male ? ratiomflubes.male : '0'}:${ratiomflubes.female ? ratiomflubes.female : '0'}`}</p>
                                <p className={`${styles.mfratio}`}>{`${ratiomfgas.male ? ratiomfgas.male : '0'}:${ratiomfgas.female ? ratiomfgas.female : '0'}`}</p>
                                {/* <p className={`${styles.mfratio}`}>{`${ratiomfref.male ? ratiomfref.male : '0'}:${ratiomfref.female ? ratiomfref.female : '0'}`}</p> */}
                                <p className={`${styles.mfratio}`}>{`${ratiomfrefm.male ? ratiomfrefm.male : '0'}:${ratiomfrefm.female ? ratiomfrefm.female : '0'}`}</p>
                                <p className={`${styles.mfratio}`}>{`${ratiomfrefk.male ? ratiomfrefk.male : '0'}:${ratiomfrefk.female ? ratiomfrefk.female : '0'}`}</p>
                                <p className={`${styles.mfratio}`}>{`${ratiomfrefb.male ? ratiomfrefb.male : '0'}:${ratiomfrefb.female ? ratiomfrefb.female : '0'}`}</p>
                                {/* <p className={`${styles.mfratio}`}>{`${ratiomfenti.male ? ratiomfenti.male : '0'}:${ratiomfenti.female ? ratiomfenti.female : '0'}`}</p> */}
                                <p className={`${styles.mfratio}`}>{`${ratiomfspo.male ? ratiomfspo.male : '0'}:${ratiomfspo.female ? ratiomfspo.female: '0'}`}</p>
                                {/* <p className={`${styles.mfratio}`}>{`${ratiomfdep.male ? ratiomfdep.male : '0'}:${ratiomfdep.female ? ratiomfdep.female : '0'}`}</p> */}
                            </div>

                            <div className={`${styles.costCompositionContainer}`}>
                                <div className={`${styles.costsHeader}`}></div>
                                <div className={`${styles.costsTotals}`}>
                                    Total Employees:
                                    <span className={`${styles.totalsValue}`}> {edrdataTotal}</span>
                                </div>
                                <div className={`d-flex ${styles.genderBreakupContainer}`}>
                                    <div className={`${styles.maleContainer}`}>
                                        <img src={maleIcon} alt="male icon" className={`${styles.maleIcon}`}></img>
                                        <span className={`${styles.maleValue}`}> {edrdataMale}</span>
                                    </div>
                                    <div className={`${styles.femaleContainer}`}>
                                        <img src={femaleIcon} alt="male icon" className={`${styles.femaleIcon}`}></img>
                                        <span className={`${styles.femaleValue}`}> {edrdataFemale}</span>
                                    </div>
                                </div>
                                <div className={`d-flex ${styles.genderBreakupContainer}`}>
                                    <div className={`${styles.pieContainer}`}>
                                    <EmployeeDemographicPieChart refineries={edrRefineriesdata.male} bu={edrBudata.male} entities={edrEntitiesdata.male}></EmployeeDemographicPieChart>
                                    <div className={`${styles.mEmp}`}>Male Emp</div>
                                    <EmployeeDemographicPieChart refineries={edrRefineriesdata.female} bu={edrBudata.female} entities={edrEntitiesdata.female}></EmployeeDemographicPieChart>
                                    <div className={`${styles.fEmp}`}>Female Emp</div>
                                    </div>
                                </div>
                                {/* <CostCompositionGraph></CostCompositionGraph> */}
                            </div>
                        </div>
                        {/* <div className={`d-flex justify-content-between align-items-center ${styles.Footer}`}> */}
                            {/* <span className={`${styles.footerText}`}>Cost/Hire 35,450 INR</span> */}
                            {/* <span className={`${styles.footerText}`}>Infant Attrition {infant} </span> */}
                        {/* </div> */}
                    </div>
                }
            </div>
        </FullScreen>
    )
}
export default EmployeeDataAndRecruitment