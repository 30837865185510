import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
// import 'chartjs-plugin-datalabels';
import styles from './CracksGraph.module.scss'
// import {inrFormat, usdFormat} from "../../utility/utility"

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function CracksGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
    
        const xLabels = [
            'M1',
            'M2',
            'M3',
            'M4',
            'M5',
            'M6',
            'M7',
            'M8',
            'M9',
            'M10',
            'M11',
            'M12',
        ]

        let xTicks = []
        xTicks  = xLabels.slice(0, props.pr_nymex.length)

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let gradient = ctx.createLinearGradient(0, 0, 0, 450)
        gradient.addColorStop(1, 'rgba(0, 168, 236,0.3)')
        gradient.addColorStop(0, 'rgba(0, 224, 188,0.3)')
        let targetColor = ''
        let actualColor = ''

        //SET COLORS ACCORDING TO LOCATION
        if (props.location === 'mumbai') {
            targetColor = '#02d345' //green
            actualColor = '#06bee1' //blue
        }
        if (props.location === 'kochi') {
            targetColor = '#02d345' //pink
            actualColor = '#06bee1' //purple
        }
        if (props.location === 'bina') {
            targetColor = '#02d345' //pink
            actualColor = '#06bee1' //purple
        }
        if (props.location === 'all') {
            targetColor = '#02d345' //pink
            actualColor = '#06bee1' //purple
        }
        let nymex_data = []
        let icebrent_data = []
        let maxvalue = []
        props.pr_nymex && props.pr_nymex.forEach(getdata => {
            if(getdata.value !== null){
                nymex_data.push(Number(getdata.value).toFixed(2))
                maxvalue.push(getdata.value)
            }
        })
        props.pr_icebrent && props.pr_icebrent.forEach(getdata => {
            if(getdata.value !== null){
                icebrent_data.push(Number(getdata.value).toFixed(2))
                maxvalue.push(getdata.value)
            }
        })
        const chartConfig = {
            type: 'line',
            data: {
                labels: xTicks,
                datasets: [
                    {
                        label: 'ICE Brent',
                        fill: false,
                        //  data: [10, 40, 23, 37, 39, 47, 78, 57, 50, 100, 80, 120],
                        data: icebrent_data, 
                        pointBorderWidth: 4,
                        backgroundColor: targetColor,
                        pointBorderColor: targetColor,
                        borderColor: targetColor,
                        borderWidth: 4,
                        borderDash: [4],
                        datalabels: {
                            align: 'bottom',
                            color: 'rgb(161, 173, 187)',
                            font: {
                                size: 16,
                            },
                        },
                    },
                    {
                        label: 'NYMEX WTI',
                        fill: false,
                        data: nymex_data,
                        pointBorderWidth: 4,
                        backgroundColor: gradient,
                        pointBorderColor: gradient,
                        borderColor: gradient,
                        borderWidth: 4,
                        datalabels: {
                            align: 'top',
                            color: '#dee5ef',
                            font: {
                                size: 16
                            },
                        },
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.4,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                color: '#2f3b51',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            beginAtZero: true,
                            ticks: {
                                min: Math.floor(Math.min(...maxvalue)),
                                max: Math.floor(Math.max(...maxvalue))+2,
                                stepSize: Math.floor(Math.max(...maxvalue)/4),

                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineWidth: 1,
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.location, props.pr_nymex, props.pr_icebrent]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <canvas ref={chartContainer} />
        </div>
    )
}
export default CracksGraph
