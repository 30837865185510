import React, { useState, useEffect,useCallback } from 'react'
import styles from './OrgHsse.module.scss'
import DeltaValuesWithTotalOrg from '../DeltaValuesWithTotalOrg/DeltaValueWithTotalOrghsse'
import { fetchOrgHsseData } from '../../services/Org'
import { NoDataFound } from '../DataNotFound/nodatafound'
import HsseKpiGraph from '../../components/OrgHsse/OrgHsseGraph'
import HsseKpiGraph1 from '../../components/OrgHsse/OrgHsseGraph1'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ExpandIcon from "../../assets/images/expandIcon.png";
import greenTick from '../../assets/images/greenTick.png'
import { fetchupdatedStatus } from '../../../src/services/Org'
import { isEmpty } from 'lodash'
import "../../../src/Common.scss"

const OrgHsse = (props) => {
    const [ohsdropdown] = useState('YTM')
    //const [loading, setLoading] = useState(true)
    const [loadingdata, setLoadingdata] = useState(true)
    //const [refvalue, setrefvalue] = useState([])
    //const [makvalue, setmakvalue] = useState([])
    const [liftr, setliftr] = useState([])
    const [carbon, setCarbon] = useState([])
    const [audit, setAudit] = useState([])
    const [ghgemms, setGhgemms] = useState([])
    const [ltisr, setLtisr] = useState([])
    const [lpgcpic, setLpgcpic] = useState([])
    const [lpgtlic, setLpgtlic] = useState([])
    const [mmhrs, setMmhrs] = useState([])
    const [rtloutic, setRtloutic] = useState([])
    const [rtltlic, setRtltlic] = useState([])
    const [solar, setSolar] = useState([])
    const [incident, setIncident] = useState([])
    const [treesplanted, setTreesplanted] = useState([])
    const [capacmp, setcapacmp] = useState([])
    const [isa, setisa] = useState([])
    const [hsseData, setHsseData] = useState([])
    const handle = useFullScreenHandle();
    const [attr, setattr] = useState("handleenter");
    const [updatedStatus, setUpdatedStatus] = useState('')

    // const [esaref, setesaref] = useState([])
    // const [esamark, setesamark] = useState([])
    // const [isaref, setisaref] = useState([])
    // const [isamark, setisamark] = useState([])
    const fullScrn = () => {
        if (attr === "handleenter") {
          setattr("handleexit");
          handle.enter();
          document.body.style.zoom = window.innerWidth / window.outerWidth;
        } else if (attr === "handleexit") {
          setattr("handleenter");
          handle.exit();
          document.body.style.zoom = 0;
        }
      };
      const reportChange = useCallback(
        (state) => {
          try {
            if (state === false) {
              document.body.style.zoom = 0;
            }
          } catch (exception) {
            console.debug(exception);
          }
        },
        [handle]
      ); // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchupdatedStatus(props.year, "ORGANIZATION")
        .then(response => {
            if (!isEmpty(response)) {
                response.filter((res) => res.entity === "HSSE" && res.kpi_name === "HSSE" ? setUpdatedStatus(res.status) : false);
            } else {
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        // function incidentGraph() {
        //     let refdata = {}
        //     let makdata = {}
        //     let refmin = []
        //     let refmaj = []
        //     let refser = []
        //     let reftotal = []
        //     let makmin = []
        //     let makmaj = []
        //     let makser = []
        //     let maktotal = []
        //     fetchOrgHsseIncident(ohsdropdown.toLowerCase(), props.year).then(data => {
        //         if (data.length === 0) {
        //             setLoading(true)
        //         } else {
        //             data.forEach(getir => {
        //                 if (getir.bu === 'Refinery') {
        //                     if (getir.sub_kpi_type === 'minor') {
        //                         refmin.push(getir.actual)
        //                     } else if (getir.sub_kpi_type === 'major') {
        //                         refmaj.push(getir.actual)
        //                     } else if (getir.sub_kpi_type === 'serious') {
        //                         refser.push(getir.actual)
        //                     } else if (getir.sub_kpi_type === 'total') {
        //                         reftotal.push({
        //                             reftotals: getir.actual,
        //                             quater: getir.quaterly
        //                         })
        //                     }
        //                     refdata = {
        //                         minor: refmin[0],
        //                         major: refmaj[0],
        //                         serious: refser[0],
        //                         total: reftotal[0]
        //                     }
        //                     setrefvalue(refdata)
        //                 } else if (getir.bu === 'Marketing') {
        //                     if (getir.sub_kpi_type === 'minor') {
        //                         makmin.push(getir.actual)
        //                     } else if (getir.sub_kpi_type === 'major') {
        //                         makmaj.push(getir.actual)
        //                     } else if (getir.sub_kpi_type === 'serious') {
        //                         makser.push(getir.actual)
        //                     } else if (getir.sub_kpi_type === 'total') {
        //                         maktotal.push({
        //                             maktotals: getir.actual,
        //                             quater: getir.quaterly
        //                         })
        //                     }
        //                     makdata = {
        //                         minor: makmin[0],
        //                         major: makmaj[0],
        //                         serious: makser[0],
        //                         total: maktotal[0]
        //                     }
        //                     setmakvalue(makdata)
        //                 }
        //             })
        //             setLoading(false)
        //         }
        //     })
        // }
        function hsseData() {
            //let lftref = []
            let lftmak = []
            let lftdata = {}
            //let caparef = []
            let capamak = []
            let carbonmak = []
            let carbondata = {}
            let capadata = {}
            let isamak = []
            let isadata = {}
            let auditmak = []
            let auditdata = {}
            let ghgemmsmak = []
            let ghgdata = {}
            let ltisrmak = []
            let ltisrdata = {}
            let lpgcpmak = []
            let lpgcpdata = {}
            let lpgtlmak = []
            let lpgtldata = {}
            let mmhrsmak = []
            let mmhrsdata = {}
            let rtloutmak = []
            let rtloutdata = {}
            let rtltlmak = []
            let rtltldata = {}
            let solarmak = []
            let solardata = {}
            let incidentsmak = []
            let incidentsdata = {}
            let tressmak = []
            let tressdata = {}
            // let esarefinery = []
            // let esamak = []
            // let isarefinery = []
            // let isamak = []
            fetchOrgHsseData(ohsdropdown.toLowerCase(), props.year).then(data => {
                if (data.length === 0) {
                    setLoadingdata(true)
                } else {
                    data.forEach(getir => {
                        if (getir.kpi_name === 'LTIFR') {
                            lftmak.push({
                                value: getir.actual
                            })
                            lftdata = {
                                marketing: lftmak[0]
                            }
                            setliftr(lftdata)
                        } else if (getir.kpi_name === 'CAPA Compliance') {
                            capamak.push({
                                value: getir.actual
                            })
                            capadata = {
                                marketing: capamak[0]
                            }
                            setcapacmp(capadata)
                        } else if (getir.kpi_name === 'Pending Recommendation ESA') {
                            auditmak.push({
                                value: getir.actual,
                            })
                            auditdata = {
                                marketing: auditmak[0]
                            }
                            setAudit(auditdata)
                        } else if (getir.kpi_name === 'Pending Recommendation ISA') {
                            isamak.push({
                                value: getir.actual,
                            })
                            isadata = {
                                marketing: isamak[0]
                            }
                            setisa(isadata)
                        } else if (getir.kpi_name === 'Carbon Sequestered') {
                            carbonmak.push({
                                value: getir.actual,
                            })
                            carbondata = {
                                marketing: carbonmak[0]
                            }
                            setCarbon(carbondata)
                        } else if (getir.kpi_name === 'GHG Emissions') {
                            ghgemmsmak.push({
                                value: getir.actual,
                            })
                            ghgdata = {
                                marketing: ghgemmsmak[0]
                            }
                            setGhgemms(ghgdata)
                        } else if (getir.kpi_name === 'LTISR') {
                            ltisrmak.push({
                                value: getir.actual,
                            })
                            ltisrdata = {
                                marketing: ltisrmak[0]
                            }
                            setLtisr(ltisrdata)
                        } else if (getir.kpi_name === 'LPGCP Incident Count') {
                            lpgcpmak.push({
                                value: getir.actual,
                            })
                            lpgcpdata = {
                                marketing: lpgcpmak[0]
                            }
                            setLpgcpic(lpgcpdata)
                        } else if (getir.kpi_name === 'LPGTL Incident Count') {
                            lpgtlmak.push({
                                value: getir.actual,
                            })
                            lpgtldata = {
                                marketing: lpgtlmak[0]
                            }
                            setLpgtlic(lpgtldata)
                        } else if (getir.kpi_name === 'Million Man hrs') {
                            mmhrsmak.push({
                                value: getir.actual,
                            })
                            mmhrsdata = {
                                marketing: mmhrsmak[0]
                            }
                            setMmhrs(mmhrsdata)
                        } else if (getir.kpi_name === 'RTLOUT Incident Count') {
                            rtloutmak.push({
                                value: getir.actual,
                            })
                            rtloutdata = {
                                marketing: rtloutmak[0]
                            }
                            setRtloutic(rtloutdata)
                        } else if (getir.kpi_name === 'RTLTL Incident Count') {
                            rtltlmak.push({
                                value: getir.actual,
                            })
                            rtltldata = {
                                marketing: rtltlmak[0]
                            }
                            setRtltlic(rtltldata)
                        } else if (getir.kpi_name === 'Solar Energy Added') {
                            solarmak.push({
                                value: getir.actual,
                            })
                            solardata = {
                                marketing: solarmak[0]
                            }
                            setSolar(solardata)
                        } else if (getir.kpi_name === 'Incidents Reported') {
                            incidentsmak.push({
                                value: getir.actual,
                            })
                            incidentsdata = {
                                marketing: incidentsmak[0]
                            }
                            setIncident(incidentsdata)
                        } else if (getir.kpi_name === 'Trees Planted') {
                            tressmak.push({
                                value: getir.actual,
                            })
                            tressdata = {
                                marketing: tressmak[0]
                            }
                            setTreesplanted(tressdata)
                        }
                        // else if (getir.kpi_name === 'Pending recommendation ESA') {
                        //     if (getir.bu === 'Refinery') {
                        //         esarefinery.push({
                        //             value: getir.actual,
                        //             year: getir.year
                        //         })
                        //         setesaref(esarefinery)
                        //     } else if (getir.bu === 'Marketing') {
                        //         esamak.push({
                        //             value: getir.actual,
                        //             year: getir.year
                        //         })
                        //         setesamark(esamak)
                        //     }
                        // } else if (getir.kpi_name === 'Pending recommendation ISA') {
                        //     if (getir.bu === 'Refinery') {
                        //         isarefinery.push({
                        //             value: getir.actual,
                        //             year: getir.year
                        //         })
                        //         setisaref(isarefinery)
                        //     } else if (getir.bu === 'Marketing') {
                        //         isamak.push({
                        //             value: getir.actual,
                        //             year: getir.year
                        //         })
                        //         setisamark(isamak)
                        //     }
                        // }
                    })
                    setHsseData(data)
                    setLoadingdata(false)
                }
            }).catch(error => console.debug(error))
        }
        //incidentGraph()
        hsseData()
    }, [props.year, ohsdropdown])
    return (
        <FullScreen handle={handle} onChange={reportChange}>
        <div className={`${styles.hsseContainer}`}>
            <div className={`d-flex align-items-center justify-content-center ${styles.hsseHeader}`}>HSSE - Daily
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                {/* <Dropdown className={`${styles.hsheaderDropdown}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.hsdropdownButton}`}>
                        <span className={`${styles.hsdropdownName}`}>View as {ohsdropdown}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.hsdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.hsdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.hsmenuItem}`} onClick={() => setohsdropdown('YTM')} >YTM</Dropdown.Item>
                        <Dropdown.Item className={`${styles.hsmenuItem}`} onClick={() => setohsdropdown('MTD')} >MTD</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
            {/* <img
            src={ExpandIcon}
            alt="Expand Learning & Development"
            id="fullSrnehr"
            data-fs="handleenter"
            onClick={fullScrn}
          ></img> */}
            {/* <div className={`d-flex ${styles.hsseContentContainerTop}`}> */}
            {/* <div className={`d-flex flex-column justify-content-between ${styles.pendingReccContainer}`}>
                    <div className={`${styles.graphHeader}`}>
                        <div className={`${styles.graphTitle}`}>Pending ESA & ISA Recomm. </div>
                        <div className={`d-flex ${styles.graphLegendsContainer}`}>
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot1}`} style={{ backgroundColor: '#00cea4' }}></span> Refinery
                            </div>
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot2}`} style={{ backgroundColor: '#00b2e7' }}></span>Marketing
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.graphContainer}`}>
                        {loading
                            ?
                            <div className={'d-flex justify-content-center'}>
                                <Spinner animation='border' role='status'>
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            <PendingRecGraph esaref={esaref} isaref={isaref} esamark={esamark} isamark={isamark} year={props.year}></PendingRecGraph>
                        }
                    </div>
                </div> */}
            {/* {loading
                    ?
                    <div className={'d-flex justify-content-center'}>
                        <Spinner animation='border' role='status'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <div className={`d-flex flex-column justify-content-between ${styles.pendingReccContainer}`}>
                        <div className={`${styles.graphHeader}`}>
                            <div className={`${styles.graphTitle}`}>Incident Reported </div>
                            <div className={`d-flex ${styles.graphLegendsContainer}`}>
                                <div className="mr-4 d-flex align-items-center">
                                    <span className={`mr-2 ${styles.dot1}`} style={{ backgroundColor: '#f097a0' }}></span> Minor
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <span className={`mr-2 ${styles.dot2}`} style={{ backgroundColor: '#ff6271' }}></span>Serious
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <span className={`mr-2 ${styles.dot2}`} style={{ backgroundColor: '#b5424d' }}></span>Major
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.graphContainer}`}>
                            <div className={`${styles.barContainer}`}>
                                <div className={`d-flex justify-content-between align-items-center ${styles.barHeader}`}>
                                    <div className={'d-flex'}>
                                        <span className={`${styles.barHeaderTitle} mr-2`}>Refinery</span>
                                        <span>{refvalue.total.reftotals}</span>
                                    </div>
                                    <div className={`d-flex  ${styles.deltaValuesContainer}`}>
                                        <div className={`mr-4 ${styles.container}`}>
                                            <span className={`${styles.title}`}>
                                                <img className={`${styles.deltaIcon}`} src={deltaTriangle} alt="delta"></img>Q:
                                            </span>
                                            {
                                                Math.sign(refvalue.total.quater) === -1 ? <span className={`${styles.value}`} style={{ color: '#ff5b4f' }}>-{refvalue.total.quater}%</span> : <span className={`${styles.value}`} style={{ color: '#7df289' }}>+{refvalue.total.quater}%</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'d-flex pt-2 '} >
                                    <div className={`d-flex flex-column justify-content-center ${styles.progressBar}`} style={{ width: '35%' }}>
                                        <span className={`pl-3 ${styles.stack1}`} >{refvalue.minor}</span>
                                    </div>
                                    <div className={`d-flex flex-column  ${styles.progressBar}`} style={{ width: '40%' }}>
                                        <span className={`pl-3 ${styles.stack2}`}>{refvalue.serious}</span>
                                    </div>
                                    <div className={`d-flex flex-column  ${styles.progressBar}`} style={{ width: '45%' }}>
                                        <span className={`pl-3 ${styles.stack3}`}>{refvalue.major}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.barContainer} pt-5`}>
                                <div className={`d-flex justify-content-between align-items-center ${styles.barHeader}`}>
                                    <div className={'d-flex'}>
                                        <span className={`${styles.barHeaderTitle} mr-2`}>Marketing</span>
                                        <span>{makvalue.total.maktotals}</span>
                                    </div>
                                    <div className={`d-flex ${styles.deltaValuesContainer}`}>
                                        <div className={`mr-4 ${styles.container}`}>
                                            <span className={`${styles.title}`}>
                                                <img className={`${styles.deltaIcon}`} src={deltaTriangle} alt="delta"></img>Q:
                                            </span>
                                            {
                                                Math.sign(makvalue.total.quater) === -1 ? <span className={`${styles.value}`} style={{ color: '#ff5b4f' }}>-{makvalue.total.quater}%</span> : <span className={`${styles.value}`} style={{ color: '#7df289' }}>+{makvalue.total.quater}%</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={'d-flex pt-2 '} >
                                    <div className={`d-flex flex-column justify-content-center ${styles.progressBar}`} style={{ width: '35%' }}>
                                        <span className={`pl-3 ${styles.stack1}`} >{makvalue.minor}</span>
                                    </div>
                                    <div className={`d-flex flex-column  ${styles.progressBar}`} style={{ width: '40%' }}>
                                        <span className={`pl-3 ${styles.stack2}`}>{makvalue.serious}</span>
                                    </div>
                                    <div className={`d-flex flex-column  ${styles.progressBar}`} style={{ width: '45%' }}>
                                        <span className={`pl-3 ${styles.stack3}`}>{makvalue.major}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.barContainer} pt-5`}>
                                <div className={'d-flex'}>
                                    <div style={{width : '16.6666666667%'}}>
                                        <span className={`${styles.indicatorprogress}`}>20</span>
                                    </div>
                                    <div style={{width : '16.6666666667%'}}>
                                        <span className={`${styles.indicatorprogress}`}>40</span>
                                    </div>
                                    <div style={{width : '16.6666666667%'}}>
                                        <span className={`${styles.indicatorprogress}`}>60</span>
                                    </div>
                                    <div style={{width : '16.6666666667%'}}>
                                        <span className={`${styles.indicatorprogress}`}>80</span>
                                    </div>
                                    <div style={{width : '16.6666666667%'}}>
                                        <span className={`${styles.indicatorprogress}`}>100</span>
                                    </div>
                                    <div style={{width : '16.6666666667%'}}>
                                        <span className={`${styles.indicatorprogress}`}>120</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
            {/* </div> */}
            <div className={`${styles.graphContainer}`}>

            {loadingdata
                ?
                <NoDataFound />
                :props.year >= '2023'? 
                <div className={`${styles.graphContainerHsse}`}>
                <HsseKpiGraph hsseData={hsseData}></HsseKpiGraph>
                <div className={`${styles.graphContainerBorder}`}></div>
                <HsseKpiGraph1 hsseData={hsseData}></HsseKpiGraph1>
                </div>:
                <div className={`${styles.hsseContentContainerBottom}`}>
                    {/* <div className="d-flex justify-content-end align-items-center">
                        <div className={`d-flex justify-content-center ${styles.headingRefinery}`}>Refinery</div>
                        <div className={`d-flex justify-content-center ${styles.headingMarketing}`}>Marketing</div>
                    </div> */}
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Carbon Sequestered (MT)</div>
                        {/* <div className={`d-flex align-items-center justify-content-left ${styles.refineryKpi}`}>
                            <DeltaValuesWithTotalOrg></DeltaValuesWithTotalOrg>
                        </div> */}
                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={carbon.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Capa Compliance (%)</div>
                        {/* <div className={`d-flex align-items-center justify-content-left ${styles.refineryKpi}`}>
                            <DeltaValuesWithTotalOrg></DeltaValuesWithTotalOrg>
                        </div> */}
                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={capacmp.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Pending Recom. External Safety Audit</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={audit.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Pending Recom. Internal Safety Audit</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={isa.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>GHG Emissions (MT)</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={ghgemms.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Lost Time Incident Severity Rate</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={ltisr.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Lost Time Incident Frequency Rate</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={liftr.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>LPG Customer Premises Incident Count</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={lpgcpic.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>LPG Tank Lorry Incident Count</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={lpgtlic.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Million Man hrs</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={mmhrs.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Retail Outlet Incident Count</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={rtloutic.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Retail Tank Lorry Incident Count</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={rtltlic.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Total Solar Panel Capacity (KW)</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={solar.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Incidents Reported</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={incident.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.listItemContainer}`}>
                        <div className={`d-flex align-items-center ${styles.kpiTitle}`}>Trees Planted (Nos.)</div>

                        <div className={`d-flex align-items-center justify-content-left ${styles.marketingKpi}`}>
                            <DeltaValuesWithTotalOrg total={treesplanted.marketing}></DeltaValuesWithTotalOrg>
                        </div>
                    </div>
                </div>
            }
            </div>
        </div>
        </FullScreen>
    )
}
export default OrgHsse