import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import styles from './BuAtfCustomerGrowth.module.scss'
import AtfCustomerGrowthGraph from './AtfCustomerGrowthGraph'
import AtfCustomerGrowthGraph1 from './AtfCustomerGrowthGraph1'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchAtfCustomerTrend, fetchupdatedStatus } from '../../../services/bu'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isNumber, isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

function BuAtfCustomerGrowth(props) {
    const [graphData, setGraphData] = useState([])
    const [domesticData, setDomesticData] = useState([])
    const [domsTotal, setDomsTotal] = useState({})
    const [interTotal, setInterTotal] = useState({})
    const [internationalData, serInternationalData] = useState([])

    const [loading, setLoading] = useState(true)
    const handle = useFullScreenHandle()
    const [total, settotal] = useState([])
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const [isNoDataAvailable,setIsNoDataAvailable] = useState(false)

    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "AVIATION" && res.kpi_name === "Key Customer Trends" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        let totalkg_Array = []
        let domestic_Array = []
        let international_Array = []
        function prepareGraphData() {
            fetchAtfCustomerTrend(props.year)
                .then(response => {
                    let responseData = props.year > 2022 ? response.dataDom : response.data
                    if (responseData.length > 0) {
                        responseData.filter(getTotal => {
                            if (getTotal.kpi_type === 'total') {
                                settotal(getTotal)
                                setIsNoDataAvailable(false)
                                // setLoading(false)
                            } else {
                                //settotal({curr_year : 0, percentage_growth : 0})
                                totalkg_Array.push(getTotal)
                                setGraphData(totalkg_Array)
                                if (getTotal.company_type === "Domestic") {
                                    domestic_Array.push(getTotal)
                                    setDomesticData(domestic_Array)
                                    setDomsTotal(getTotal)
                                } if (getTotal.company_type === "International") {
                                    international_Array.push(getTotal)
                                    serInternationalData(international_Array)
                                    setInterTotal(getTotal)
                                }
                                setIsNoDataAvailable(true)
                                // setLoading(true)
                            }
                        })
                        setIsNoDataAvailable(false)
                        
                    } else {
                        setLoading(true)
                        setIsNoDataAvailable(true)
                    }
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData()
    }, [props.ActiveTab, props.year])

    return (
        <div className={`${styles.BuDepotInventoryContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex justify-content-between ${styles.BuCcsHeader}`}>
                    <div className={`d-flex flex-column justify-content-center ${styles.BuCcsHeaderLeft}`}>
                        <div className={`${styles.HeaderTop}`}> Key Customer Trends : Domestic - Daily
                        <div className={`d-flex ${styles.subHeader}`}>
                        <div className={`d-flex ${styles.sp1}`}>Cum. Domestic  = {Math.round(domsTotal.curr_month)} </div>
                        {/* <div className={`d-flex ${styles.sp2}`}>Cum. International  = {Math.round(interTotal.curr_month)} </div> */}
                        </div>
                        </div>
                        <div className={`d-flex ${styles.legendsContainer}`}>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot1}`}></span> Apr
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot2}`}></span>May
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot3}`}></span> Jun
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot4}`}></span>July
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot5}`}></span> Aug
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot6}`}></span>Sept
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot7}`}></span> Oct
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot8}`}></span>Nov
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot9}`}></span> Dec
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot10}`}></span>Jan
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot11}`}></span> Feb
                            </div>
                            <div className="mr-3 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot12}`}></span>Mar
                            </div>
                            {/* <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot3}`}></span> Gain
                            </div>
                            <div className="mr-4 d-flex align-items-center">
                                <span className={`mr-2 ${styles.dot4}`}></span> Loss
                            </div> */}
                        </div>
                    </div>
                    {
                        isNoDataAvailable ||
                        <div className={` d-flex justify-content-center align-items-center ${styles.BuCcsHeaderRight}`}>
                            {/* <span className={`${styles.BuCcsHeaderResolved}`}>Total: &#8377; {total && parseFloat(total.curr_year).toFixed(2)} Cr</span>
                            <span className="ml-3" style={{ color: '#74ce12' }}>{total && parseFloat(total.percentage_growth).toFixed(2)}%</span> */}
                        </div>
                    }
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                </div>
                <div className={`d-flex justify-content-between ${styles.graphContainer}`}>
                    {isNoDataAvailable
                        ?
                        <NoDataFound />
                        :
                        <>
                                <AtfCustomerGrowthGraph graphData={graphData}></AtfCustomerGrowthGraph>
                                {/* <AtfCustomerGrowthGraph1 graphData={graphData}></AtfCustomerGrowthGraph1> */}

                        <div className={`d-flex flex-column ${styles.revenueGrowthTableContainer}`}>
                            <div className={`d-flex flex-column ${styles.tableHeaderRowContainer}`}>
                                <div className={`${styles.tableHeaderRow}`}>
                                    <div className={`${styles.headerCell}`}>
                                        Cum_Revenue (Cr)
                                    </div>
                                    <div className={`${styles.headerCell}`}>
                                    Cum_Contribution (%)
                                    </div>
                                </div>
                                <div className={` d-flex flex-column ${styles.tableDataRowContainer}`}>
                                    {
                                        domesticData.map((item, index) => {
                                            return (
                                                <div key={index} className={`d-flex ${styles.tableDataRow}`}>
                                                    <div className={`${styles.dataCell}`}><span>{isNumber(item.curr_year) ? <> {(item.curr_year).toFixed(0)} </> : 'N/A'}</span></div> 
                                                    {/* <div className={`${styles.dataCell}`}><span style={{ color: `${Math.sign(item.percentage_growth) === 1 ? '#74ce12' : '#e13219'}` }}>{item.percentage_growth && (item.percentage_growth).toFixed(2)}%</span></div> */}
                                                    <div className={`${styles.dataCell}`}><span>{item.contribution_percentage && (item.contribution_percentage).toFixed(0)} %</span></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </div>
                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' onClick={fullScrn}></img>
                </div>
            </FullScreen>
        </div>
    )
}
export default BuAtfCustomerGrowth