import React, { useState, useEffect } from 'react'
import {
    Polygon,
    Polyline,
    Marker
} from 'react-google-maps'
import styles from './stateMap.module.scss'
import CustomInfoBox from './CustomInfoBox'
import statesJson from './indiaRegions.json'
import { getColourFromRegion } from '../../../utility/buMapUtility'
import { Spinner } from 'react-bootstrap'
import depotBlackIcon from '../../../assets/images/depot-alert-02.png'
import depotWhiteIcon from '../../../assets/images/depot-ok-02.png'
import { fetchRetailStateMap } from '../../../services/bu'
import heatmapLegend from '../../../assets/images/HMap.png'

function StateMap(props) {
    const [responseRegionData, setResponseRegionData] = useState([])
    const [loading, setLoading] = useState(true)

    let regionJson = props.statesJsonDb.regions.find((regionFromJson) => {
        return regionFromJson.name === props.regionName
    })

    useEffect(() => {
        fetchRetailStateMap(props.regionName, props.year)
            .then(response => {
                // console.debug(response);
                setResponseRegionData(response.data)
                setLoading(false)
            })
            .catch(error => console.debug(error))
    // return () => {
    //   cleanup
    // }
    }, [props.regionName, props.year])

    //Jugaad for top 5 states in North India
    //let topFiveStates = ['delhi', 'punjab', 'haryana', 'himachal pradesh', 'jammu & kashmir', 'uttrakhand', 'rajasthan']

    return (
        loading
            ?
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
            :
            regionJson.data.polygons.map((stateItem, index) => {
                let responseState = responseRegionData.find((responseDataItem) => {
                    return (responseDataItem.state.toLowerCase() === stateItem.name.toLowerCase())
                })
                // console.debug("State data that matched: ", responseState);
                
                return (
                    responseState && <React.Fragment key={index}>
                        {/* {props.kpiSelection === 'depot' && <Marker position={{ lat: 28, lng: 80 }} icon={depotBlackIcon}></Marker>}
                        {props.kpiSelection === 'depot' && <Marker position={{ lat: 28.7, lng: 77.4 }} icon={depotWhiteIcon}></Marker>}
                        {props.kpiSelection === 'depot' && <Marker position={{ lat: 30.4, lng: 78.75 }} icon={depotBlackIcon}></Marker>}
                        {props.kpiSelection === 'depot' && <Marker position={{ lat: 34.0, lng: 78.5 }} icon={depotWhiteIcon}></Marker>}
                        {props.kpiSelection === 'depot' && <Marker position={{ lat: 32.9, lng: 74.7 }} icon={depotBlackIcon}></Marker>} */}
                        {responseState.state &&
                            <CustomInfoBox
                                kpiSelection={props.kpiSelection}
                                locationData={responseState}
                                anchorPosition={stateItem.annotationMarkers[0].coordinates.slice(-1)[0]}
                                markerPixelOffset={{ x: 135, y: 122 }}
                            ></CustomInfoBox>
                        }{index===0 &&
                            // <div style={{ position: 'relative', bottom: '900px', left: '300px' }}><img style={{ height: '54px' }} src={heatmapLegend} />
                            //     <div>{min} TKL</div>
                            //     <div>{max} TKL</div>
                            // </div>
                            <div className={styles.heatmapLegend}><img className={styles.heatmapImg} src={heatmapLegend} />
                            <div className={styles.heatmapMax}>{responseRegionData[0].sales_actual !== null ? responseRegionData[0].sales_actual.toFixed(): 0} TKL</div>
                            {/* <div className={styles.heatmapMiddle}>{(responseData[0].sales_actual/2).toFixed()}</div> */}
                            <div className={styles.heatmapMin}>{responseRegionData[responseRegionData.length - 1].sales_actual !== null ? responseRegionData[responseRegionData.length - 1].sales_actual.toFixed() : 0} TKL</div>
                         </div>
                        }
                        <Polygon
                            path={stateItem.coordinates}
                            options={{
                                fillColor: `${getColourFromRegion(stateItem.name, stateItem.color)}`,
                                fillOpacity: 1,
                                strokeColor: '#ffffff',
                                strokeOpacity: 0.2,
                                strokeWeight: 1,
                            }}
                            onClick={(e) => { console.debug('FROM component', e.latLng) }}
                        >
                        </Polygon>
                        {responseState.state &&
                            <Polyline
                                path={
                                    stateItem.annotationMarkers[0].coordinates
                                }
                                options={{
                                    strokeColor: '#ffffff',
                                    strokeOpacity: 1,
                                    strokeWeight: 1,
                                    zIndex: 1
                                }}
                            >
                            </Polyline>
                        }
                    </React.Fragment>
                )
            })
            
    )
}
export default StateMap