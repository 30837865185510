import React, { useState, useCallback, useEffect } from 'react'
import { fetchProjectsData } from '../../../services/bu'
import expandIcon from '../../../assets/images/expandIcon.png'
import BuProjectGraph1 from './BuProjectGraph1'
import styles from './BuProjectGraphNumber.module.scss'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchupdatedStatus } from '../../../services/Org'
import { isEmpty, last } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuRetailProjects = (props) => {
    const [showExpenditure, setShowExpenditure] = useState(false)
    const handle = useFullScreenHandle()
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [graphData, setGraphData] = useState({})
    const [loading, setloading] = useState(true)
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
                response.filter((res) => res.entity === "Corp Planning" && res.kpi_name === "Projects" ? setUpdatedStatus(res.status) : false);
            } else {
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        setloading(false)
        fetchProjectsData(props.year).then(data => {
            // console.log(data)
            if (!isEmpty(data)) {

            setGraphData(data)
            setIsNoDataAvailable(false)
            }else {
                setIsNoDataAvailable(true)
            }
        }).catch(console.debug)
            .then(() => setloading(false))
    }, [props.product,props.year]) 
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps

        // <div className={`d-flex ${styles.graphContainer}`}>

        //  <BuProjectGraph1 graphData={graphData} product={props.product} isLoading={loading} />
        //  </div>
        return (
            <div className={`${styles.BuProjectGraphNumber}`}>
                <FullScreen handle={handle} onChange={reportChange}>
                    <div className={`d-flex align-items-center justify-content-between ${styles.headerContainer}`}>
                        <div className={'row'}>
                        {/* <div>
                            <span>Project Value & Number</span>
                        </div> */}
                        <div className={`${styles.headerLeft} flex-grow-1 `}>
                            <div className={`${styles.heading}`}>Number Of Projects &#40;&nbsp;&gt;150 Cr&nbsp;&#41;&nbsp;- Monthly </div>
                        </div>
                    </div>
                        {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                            <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                        </div>}
                    <div >
                        <div className={`d-flex flex-column align-items-baseline ${styles.headerLabels}`}>
                        {/* <div class="p-2 bg-info">Flex item 1</div>
  <div class="p-2 bg-warning">Flex item 2</div>
  <div class="p-2 bg-primary">Flex item 3</div> */}
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.bio}`}></span>Bio Fuel</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.gas}`}></span>Gas</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.lube}`}></span>HRS</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.lpg}`}></span>LPG</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.hrs}`}></span>Lubes</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.pipeline}`}></span>Pipeline</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.retail}`}></span>Retail</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.renewable}`}></span>Renewable</div>
                            <div className="d-flex mb-2 align-items-baseline "><span className={`mr-2 ${styles.refineries}`}></span>Refineries</div>
                        </div>
                    </div>
                        {/* <div className={'justify-content-end'}>
                            <div className={styles.toggleBtn}>
                                <span className={`${styles.toggleBtnSwitch} ${!showExpenditure ? styles.active : ''}`}>Projects</span>
                            </div>
                        </div> */}
                    </div>
                    {
                        props.isLoading ?
                            (
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: 507,
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            )
                            :
                            (
                                isNoDataAvailable ?
                                    <NoDataFound height={499} />
                                    :
                                    <BuProjectGraph1 graphData={graphData} product={props.product} isLoading={loading} fullScrn={fullScrn}  />
                                    )
                    }
                    <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                    <img className={`${styles.expandIcon}`} src={expandIcon} onClick={fullScrn} alt='Expand Icon'></img>
                </div>
                </FullScreen>
            </div>
        )
}
export default BuRetailProjects



