import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import classes from './BuProjectGraph1.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const xLabels = ['Refineries', 'Renewable', 'Retail', 'Pipeline', 'Lubes','LPG', 'HRS', 'Gas', 'Bio']

const BuProjectGraph1 = ({graphData}) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    const getChartConfig = () => {
        let graphProjectData = [] 
        let graphProjectCount = []
        if(graphData.length>0){
        graphData.forEach(item => {
                if(item.business_unit === 'refineries'){
                    graphProjectData[0] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[0] = item.no_of_project*100
                }
                if(item.business_unit === 'renewable energy'){
                    graphProjectData[1] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[1] = item.no_of_project *100
                }if(item.business_unit === 'retail'){
                    graphProjectData[2] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[2] = item.no_of_project *100
                }if(item.business_unit === 'pipeline'){
                    graphProjectData[3] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[3] = item.no_of_project *100
                }if(item.business_unit === 'lube'){
                    graphProjectData[4] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[4] = item.no_of_project *100
                }if(item.business_unit === 'lpg'){
                    graphProjectData[5] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[5] = item.no_of_project *100
                }
                if(item.business_unit === 'hrs'){
                    graphProjectData[6] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[6] = item.no_of_project *100
                }if(item.business_unit === 'gas'){
                    graphProjectData[7] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[7] = item.no_of_project *100
                }if(item.business_unit === 'bio fuel'){
                    graphProjectData[8] = Math.round(parseFloat(item.cost_of_project))
                    graphProjectCount[8] = item.no_of_project *100
                }
        })
    }
        const ctx = chartContainer.current.getContext('2d')
        let orange = ctx.createLinearGradient(0, 0, 0, 500)
        orange.addColorStop(1, '#ff8558')//orange
        // gradientForO_to_3.addColorStop(0, '#fa7a0a')

        let blue = ctx.createLinearGradient(0, 0, 0, 500)
        blue.addColorStop(0, '#1f75d1')//blue
        // gradientFor3_to_6.addColorStop(1, '#0a7cff')

        let green = ctx.createLinearGradient(0, 0, 0, 500)
        green.addColorStop(0, '#04b03b')//green
        // gradientForGt_6.addColorStop(1, '#50f283')

        let yellow = ctx.createLinearGradient(0, 0, 0, 500)
        yellow.addColorStop(0, '#c79638')//yellow
        // gradientFor_104.addColorStop(1, '#fcd00a')

        let pink = ctx.createLinearGradient(0, 0, 0, 500)
        pink.addColorStop(0, '#c953a8')//pink
        // gradientFor_105.addColorStop(1, '#fcd00a')

        let voilet = ctx.createLinearGradient(0, 0, 0, 500)
        voilet.addColorStop(0, '#7248bd')//voilet
        // gradientFor_106.addColorStop(1, '#fcd00a')

        let dark_pink = ctx.createLinearGradient(0, 0, 0, 500)
        dark_pink.addColorStop(0, '#ff588c')//dark_pink
        // gradientFor_107.addColorStop(1, '#fcd00a')

        let teal = ctx.createLinearGradient(0, 0, 0, 500)
        teal.addColorStop(0, '#059e92')//teal
        // gradientFor_108.addColorStop(1, '#fcd00a')

        let blueLit = ctx.createLinearGradient(0, 0, 0, 500)
        blueLit.addColorStop(0, '#46a0c7')//blueLit
        // gradientFor_109.addColorStop(1, '#fcd00a')

        const chartConfig = {
            type: 'doughnut',
            data: {
                labels: xLabels,
                datasets: [
                    //    {
                    //     label: 'Cost Of Project',
                    //     backgroundColor: gradientForO_to_3,
                    //     data: graphProjectData,
                    //     barThickness: 15,
                    //     datalabels: {
                    //         display: true,
                    //         anchor: 'end',
                    //         align: 'end',
                    //         color: '#dee5ef',
                    //         font: {
                    //             size: 15
                    //         }
                    //     }
                    // },
                    {
                        label: 'No of project',
                        backgroundColor: [voilet,blueLit,yellow,pink,green,orange,dark_pink,teal,blue],
                        data: graphProjectCount,
                        barThickness: 15,
                            datalabels: {
                                formatter: function (value, context) {
                                    return xLabels[context.dataIndex]
                                    
                                },
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        },
                    },
                    {
                        label: 'No of project',
                        backgroundColor: [voilet,blueLit,yellow,pink,green,orange,dark_pink,teal,blue],
                        data: graphProjectCount,
                        barThickness: 15,
                            datalabels: {
                                formatter: function (value, context) {
                                    return value > 0 ? `${graphProjectCount[context.dataIndex]/100}` : ""
                                },
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#dee5ef',
                            font: {
                                size: 15
                            }
                        },
                    },
                ]
            },
            options: {
                tooltips: {
                    enabled : false,
                    mode : 'label',
                    callbacks: {
                        label: (tooltipItems, data) => {
                            let valueOfProject
                            valueOfProject = data.datasets[tooltipItems.datasetIndex].label === 'Cost Of Project'? `${data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]}Cr`: data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]/100
                            return `${data.datasets[tooltipItems.datasetIndex].label}: ${valueOfProject} `
                        }
                    }
                },
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                // scales: {
                //     xAxes: [
                //         {
                //             gridLines: {
                //                 display: true,
                //             },
                //             scaleLabel: {
                //                 display: true,
                //                 // labelString: 'Cr',
                //                 fontColor: '#dee5ef',
                //                 fontSize: '20',
                //             },
                //             ticks: {
                //                 beginAtZero: true,
                //                 display: true,
                //                 padding: 0,
                //                 fontColor: '#dee5ef',
                //                 fontSize: '20',
                //                 maxTicksLimit : 6
                //                 // min : 0,
                //                 // max : Math.round(Math.max(...maxvalue)),
                //                 // stepSize :  Math.round(Math.max(...maxvalue)/6)
                //             }
                //         }
                //     ],
                //     yAxes: [
                //         {
                //             gridLines: {
                //                 display: true,
                //             },
                //             ticks: {
                //                 display: true,
                //                 padding: 20,
                //                 fontColor: '#dee5ef',
                //                 fontSize: '20',
                //             }

                //         }
                //     ]
                // },
            },
        }

        return chartConfig
    }

  
    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = getChartConfig()

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, graphData]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`d-flex ${classes.BuProjectsDelayGraph}`}>
            <div className='col'>
                <canvas ref={chartContainer} height={500} width={700} margin-left={-250}/>
            </div>
        </div>
    )
}
export default BuProjectGraph1