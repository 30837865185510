import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
// import 'chartjs-plugin-datalabels';
import styles from './Itrmpricegraph.module.scss'
// import {inrFormat, usdFormat} from "../../utility/utility"

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function ItrmPriceGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    const xLabels = [
        'M1',
        'M2',
        'M3',
        'M4',
        'M5',
        'M6',
        'M7',
        'M8',
        'M9',
        'M10',
        'M11',
        'M12',
    ]
    useEffect(() => {
    // prepareGraphData(props.graphData);

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let gradient = ctx.createLinearGradient(0, 0, 0, 450)
        gradient.addColorStop(1, 'rgba(0, 168, 236,0.3)')
        gradient.addColorStop(0, 'rgba(0, 224, 188,0.3)')
        let targetColor = ''
        let actualColor = ''

        //SET COLORS ACCORDING TO LOCATION
        if (props.location === 'mumbai') {
            targetColor = '#02d345' //green
            actualColor = '#06bee1' //blue
        }
        if (props.location === 'kochi') {
            targetColor = '#e640b7' //pink
            actualColor = '#b76df1' //purple
        }
        if (props.location === 'bina') {
            targetColor = '#e640b7' //pink
            actualColor = '#b76df1' //purple
        }
        if (props.location === 'all') {
            targetColor = '#e640b7' //pink
            actualColor = '#b76df1' //purple
        }

        let dubai_data = []
        let dated_data = []
        let maxvalue = []
        let difference_value = []
        props.pr_dubai && props.pr_dubai.forEach(getdata => {
            if(getdata.value !== null){
                dubai_data.push(Number(getdata.value).toFixed(2))
                maxvalue.push(getdata.value)
            }
        })
        props.pr_dated && props.pr_dated.forEach(getdata => {
            if(getdata.value !== null){
                dated_data.push(Number(getdata.value).toFixed(2))
                maxvalue.push(getdata.value)
                difference_value.push(Number(getdata.value) + 0.2)
            }
        })
        let diff_map = dubai_data.map((a,b) => {
            return dated_data[b] - a
        })
    
        const chartConfig = {
            type: 'line',
            data: {
                labels: xLabels,
                datasets: [
                    {
                        label: 'Dated Brent',
                        fill: true,
                        //  data: [10, 40, 23, 37, 39, 47, 78, 57, 50, 100, 80, 120],
                        data: dated_data,
                        pointBorderWidth: 4,
                        backgroundColor: gradient,
                        pointBorderColor: gradient,
                        borderColor: gradient,
                        borderWidth: 0,
                        //borderDash: [4],
                        datalabels: {
                            align: 'center',
                            anchor : 'end',
                            color: '#fff',
                            font: {
                                size: 16,
                            },
                        },
                        barThickness : 30,
                        order : 2,
                        type : 'bar'
                    },
                    {
                        label: 'Dubai',
                        fill: false,
                        //  data: [10, 40, 23, 37, 39, 47, 78, 57, 50, 100, 80, 120],
                        data: dubai_data,
                        pointBorderWidth: 4,
                        backgroundColor: 'rgb(255, 88, 139)',
                        pointBorderColor: 'rgb(255, 88, 139)',
                        borderColor: 'rgb(255, 88, 139)',
                        borderWidth: 2,
                        //borderDash: [4],
                        datalabels: {
                            align: 'bottom',
                            color: 'rgb(161, 173, 187)',
                            font: {
                                size: 16,
                            },
                        },
                        //barThickness : 30,
                        order : 1,
                        //type : 'bar'
                    },
                    {
                        label : 'Difference',
                        data : difference_value,
                        backgroundColor : 'transparent',
                        datalabels: {
                            color: '#02d345',
                            anchor: 'end',
                            align : 'end',
                            font : {
                                size : 15,
                            },
                            formatter : function(value, context){
                                if(Math.sign(diff_map[context.dataIndex]) === -1){
                                    return `${parseFloat(diff_map[context.dataIndex]).toFixed(2)}`
                                }
                                return `+${parseFloat(diff_map[context.dataIndex]).toFixed(2)}`
                            }
                        },
                    }
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.4,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            display: true,
                            offset : true,
                            gridLines: {
                                color: '#2f3b51',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                zeroLineBorderDash: [3],
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                                borderDash: [3],
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            beginAtZero: true,
                            ticks: {
                                min: Math.floor(Math.min(...maxvalue)),
                                max: Math.floor(Math.max(...maxvalue))+2,
                                stepSize: Math.floor(Math.max(...maxvalue)/4),

                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            gridLines: {
                                color: '#2f3b51',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                zeroLineBorderDash: [3],
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                                borderDash: [3],
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.location, props.pr_dated, props.pr_dubai]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <canvas ref={chartContainer} />
        </div>
    )
}
export default ItrmPriceGraph
