// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PerformanceAndProductivity_PerformanceAndProductivityContainer__bBGqm {\n  height: 315px;\n  background-color: #202c3f;\n  font-family: Titillium Web; }\n  .PerformanceAndProductivity_PerformanceAndProductivityContainer__bBGqm .PerformanceAndProductivity_PerformanceAndProductivityHeader__3nP7J {\n    height: 84px;\n    background-color: #181e2a;\n    font-size: 28px;\n    font-weight: bold;\n    color: white;\n    padding-left: 24px;\n    padding-right: 24px; }\n\n.PerformanceAndProductivity_statusUpdatedImg__2wU94 {\n  margin-left: 174px;\n  margin-top: -18px; }\n", ""]);
// Exports
exports.locals = {
	"PerformanceAndProductivityContainer": "PerformanceAndProductivity_PerformanceAndProductivityContainer__bBGqm",
	"PerformanceAndProductivityHeader": "PerformanceAndProductivity_PerformanceAndProductivityHeader__3nP7J",
	"statusUpdatedImg": "PerformanceAndProductivity_statusUpdatedImg__2wU94"
};
module.exports = exports;
