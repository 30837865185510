import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './BuDepotInventoryChart.module.scss'
import { numberFormatEnIn, numberFormatNoSeparator } from '../../../utility/utility'
import { get, isEmpty, isNumber, reduce, lowerCase, reverse } from 'lodash'
Chart.defaults.global.defaultFontFamily = 'Titillium Web'

export const prepareInventoryChartData = (data) =>
    isEmpty(data) ? null :
        reduce(reverse(data), (acc, doc) => {
            const region = get(doc, 'region')
            if (!isEmpty(region)) {
                acc[lowerCase(region)] = {
                    norm: get(doc, 'norm'),
                    actual: get(doc, 'actual'),
                    monthly_avg: get(doc, 'monthly_avg')
                }
            }
            return acc
        }, {})

const BuDepotInventoryChart = (props) => {
    const chartContainer = useRef('barGraph')
    const [Graphunit, setGraphunit] = useState('TKL')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        let actuals = []
        let monthlyAverages = []
        let norm = 0
        if(props.activeTab === 'lpg_tab' || props.activeTab === 'inc_tab'){
            setGraphunit('TMT')
        }else{
            setGraphunit('TKL')
        }
        if (!isEmpty(props.graphData)) {
            actuals = [
                get(props, 'graphData.north.actual') || 0,
                get(props, 'graphData.east.actual') || 0,
                get(props, 'graphData.west.actual') || 0,
                get(props, 'graphData.south.actual') || 0,
            ]

            monthlyAverages = [
                get(props, 'graphData.north.monthly_avg') || 0,
                get(props, 'graphData.east.monthly_avg') || 0,
                get(props, 'graphData.west.monthly_avg') || 0,
                get(props, 'graphData.south.monthly_avg') || 0,
            ]
        }

        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = {
            type: 'horizontalBar',
            data: {
                labels: ['North', 'East', 'West', 'South'],
                datasets: [
                    // {
                    //     yAxisID: 'bar-x-axis2',
                    //     label: 'Norm',
                    //     backgroundColor: 'rgb(0, 168, 236)',
                    //     barPercentage: 1,
                    //     categoryPercentage: 1,
                    //     data: Array(4).fill(norm),
                    //     borderColor: 'rgb(0, 168, 236)',
                    //     borderWidth: {
                    //         right: 1
                    //     },
                    //     borderSkipped: false,
                    //     datalabels: {
                    //         display: false,
                    //     },
                    // },
                    {
                        yAxisID: 'bar-x-axis3',
                        label: 'Monthly Average',
                        backgroundColor: 'rgba(250, 193, 89, 0.4)',
                        borderColor: 'rgba(250, 193, 89, 0.4)',
                        categoryPercentage: 0.8,
                        barPercentage: 1,
                        data: monthlyAverages,
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: 'white',
                            font: {
                                size: 20
                            },
                            formatter: value => isNumber(value) ? `${numberFormatNoSeparator(value)} ${Graphunit}` : 'N/A'
                        },
                    },
                    {
                        yAxisID: 'bar-x-axis1',
                        label: 'Actual',
                        backgroundColor: 'rgba(255, 191, 69, 0.9)',
                        categoryPercentage: 0.6,
                        data: actuals,
                        borderWidth: 0,
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            color: 'white',
                            font: {
                                size: 20
                            },
                            formatter: value => isNumber(value) ? `${numberFormatNoSeparator(value)} ${Graphunit}` : 'N/A'
                        },
                    }
                ]
            },
            options: {
                layout: {
                    padding: {
                        top: 18,
                        right: 100,
                    }
                },
                tooltips: {
                    enabled : true,
                    mode : 'label',
                    callbacks: {
                        label: (tooltipItems, data) => {
                            return `${data.datasets[tooltipItems.datasetIndex].label}: ${numberFormatEnIn(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])} ${Graphunit}`
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            display: true,
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                padding: 20,
                            },
                            gridLines: {
                                display: false,
                            }
                        },
                    ],

                    yAxes: [
                        {
                            id: 'bar-x-axis1',
                            beginAtZero: true,
                            display: true,
                            offset: true,
                            type: 'category',
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                        {
                            id: 'bar-x-axis3',
                            beginAtZero: true,
                            display: false,
                            offset: true,
                            type: 'category',
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                        {
                            display: false,
                            offset: true,
                            id: 'bar-x-axis2',
                            type: 'category',
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ]

                },
                legend: {
                    display: false
                }
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData, props.activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.graph}`}>
            <canvas
                ref={chartContainer}
                height={400}
                width={800}
            />
        </div>
    )
}
export default BuDepotInventoryChart