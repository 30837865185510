import React from 'react'
import styles from './DeltaValuesOrg.module.scss'
import deltaTriangle from '../../assets/images/deltaTriangle.png'

const DeltaValuesOrg = (props) => {
    return (
        <div className={`d-flex ${styles.deltaValuesContainer}`}>
            <div className={`mr-4 ${styles.container}`}>
                <span className={`${styles.title}`}>
                    <img className={`${styles.deltaIcon}`} src={deltaTriangle} alt="delta"></img>Q:
                </span>
                {props.quater ? 
                    Math.sign(props.quater) === -1 ? <span className={`${styles.value}`} style={{ color: '#ff5b4f' }}>{parseFloat(props.quater).toFixed(2)}%</span> :
                        <span className={`${styles.value}`} style={{ color: '#7df289' }}>+{parseFloat(props.quater).toFixed(2)}%</span>
                    :<span className={`${styles.value}`} style={{ color: '#7df289' }}></span>
                }
            </div>

            <div className={`${styles.container}`}>
                <span className={`${styles.title}`}>
                    <img className={`${styles.deltaIcon}`} src={deltaTriangle} alt="delta"></img>Y:
                </span>
                {props.year ? 
                    Math.sign(props.year) === -1 ? <span className={`${styles.value}`} style={{ color: '#ff5b4f' }}>{parseFloat(props.year).toFixed(2)}%</span> :
                        <span className={`${styles.value}`} style={{ color: '#7df289' }}>+{parseFloat(props.year).toFixed(2)}%</span>
                    :<span className={`${styles.value}`} style={{ color: '#ff5b4f' }}></span>
                }
            </div>
        </div>
    )
}
export default DeltaValuesOrg