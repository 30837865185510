// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EmployeeDemographicPieChart_graph__u__bV {\n  position: relative;\n  width: 322px;\n  margin-left: -85px;\n  margin-top: -44px;\n  height: 246px; }\n", ""]);
// Exports
exports.locals = {
	"graph": "EmployeeDemographicPieChart_graph__u__bV"
};
module.exports = exports;
