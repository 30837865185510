// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InfographicKpi1_cduContainer__2xTDr {\n  font-family: Titillium Web;\n  position: absolute;\n  height: 58px;\n  min-width: 197px;\n  background-color: #283040;\n  border-radius: 4px;\n  border: solid 1px #67758d;\n  font-size: 27px; }\n  .InfographicKpi1_cduContainer__2xTDr .InfographicKpi1_cduLeft__34pPz {\n    flex: 1 1;\n    padding: 8px;\n    height: 24px;\n    font-weight: bold;\n    color: #dee5ef;\n    border-right: 1px solid #67758d; }\n  .InfographicKpi1_cduContainer__2xTDr .InfographicKpi1_cduRight__2YjGr {\n    padding: 8px;\n    flex: 1 1;\n    height: 24px;\n    color: #06bee1; }\n", ""]);
// Exports
exports.locals = {
	"cduContainer": "InfographicKpi1_cduContainer__2xTDr",
	"cduLeft": "InfographicKpi1_cduLeft__34pPz",
	"cduRight": "InfographicKpi1_cduRight__2YjGr"
};
module.exports = exports;
