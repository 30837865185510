// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FinanceTreasurey_treasureyContainer__2N8g3 {\n  width: 1050px;\n  margin-right: 26px;\n  background-color: #202c3f;\n  font-family: Titillium Web; }\n  .FinanceTreasurey_treasureyContainer__2N8g3 .FinanceTreasurey_treasureyHeader__1i91p {\n    height: 84px;\n    background-color: #181e2a;\n    font-size: 28px;\n    font-weight: bold;\n    color: white;\n    padding-left: 24px;\n    padding-right: 24px; }\n  .FinanceTreasurey_treasureyContainer__2N8g3 .FinanceTreasurey_foreignCurrencyLoanGraphContainer__faVhw {\n    height: 530px;\n    background-color: #2f3d53;\n    margin: 24px; }\n  .FinanceTreasurey_treasureyContainer__2N8g3 .FinanceTreasurey_internationalBondGraphContainer__1n9Xd {\n    height: 530px;\n    background-color: #2f3d53;\n    margin: 0px 24px 48px 24px; }\n\n.FinanceTreasurey_statusUpdatedImg__2ra9k {\n  margin-left: 350px;\n  margin-top: -18px; }\n", ""]);
// Exports
exports.locals = {
	"treasureyContainer": "FinanceTreasurey_treasureyContainer__2N8g3",
	"treasureyHeader": "FinanceTreasurey_treasureyHeader__1i91p",
	"foreignCurrencyLoanGraphContainer": "FinanceTreasurey_foreignCurrencyLoanGraphContainer__faVhw",
	"internationalBondGraphContainer": "FinanceTreasurey_internationalBondGraphContainer__1n9Xd",
	"statusUpdatedImg": "FinanceTreasurey_statusUpdatedImg__2ra9k"
};
module.exports = exports;
