import React, { useEffect } from 'react'
import styles from './BuIncGraphs.module.scss'
import BuIncSalesRevenue from './BuIncSalesRevenue/BuIncSalesRevenue'
import BuIncMarketShare from './BuIncMarketShare/BuIncMarketShare'
import BuIncProfitAnalysis from './BuIncProfitAnalysis/BuIncProfitAnalysis'
import BuIncLossGain from './BuIncIndentationRatio/BuIncLossGain'
import BuIncInventory from './BuIncInventory/BuIncInventory'
import BuIncCcs from './BuIncCcs/BuIncCcs'
import BuIncHsse from './BuIncHsse/BuIncHsse'
import BuIncOutstanding from './BuIncOutstanding/BuIncOutstanding'
import BuIncOutstandingTerritory from './BuIncOutstandingTerritory/BuIncOutstandingTerritory'
import { Dropdown } from 'react-bootstrap'

function BuSections(props) {
    useEffect(() => {
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuGraphsContainer}`}>
            <div className={`d-flex justify-content-end ${styles.BuGraphsHeader}`}>
                <div className={`d-flex ${styles.mapHeader}`}>
                    <Dropdown className={`${styles.headerDropdown}`}>
                        <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.dropdownButton}`}>
                            <span className={`${styles.dropdownName}`}>YTM</span>
                            {/* <div className={`d-flex align-items-center justify-content-center ${styles.dropdownIconContainer}`}>
                                <img src={dropdownArrows} alt='All region dropdown'></img>
                            </div> */}
                        </Dropdown.Toggle>
                        {/* <Dropdown.Menu className={`${styles.dropdownMenuContainer}`}>
                            <Dropdown.Item className={`${styles.menuItem}`} >YTM</Dropdown.Item>
                            <Dropdown.Item className={`${styles.menuItem}`} >MTD</Dropdown.Item>
                        </Dropdown.Menu> */}
                    </Dropdown>
                </div>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow1}`}>
                <BuIncSalesRevenue year={props.year} mapOf={props.mapOf}></BuIncSalesRevenue>
                <BuIncHsse year={props.year} activeTab={props.activeTab}></BuIncHsse>
                {/* <BuIncMarketShare year={props.year}></BuIncMarketShare> */}
                <BuIncProfitAnalysis year={props.year}></BuIncProfitAnalysis>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow2}`}>
                <BuIncLossGain year={props.year} activeTab={props.activeTab}></BuIncLossGain>
                <BuIncCcs year={props.year} activeTab={props.activeTab}></BuIncCcs>
                <BuIncInventory year={props.year} activeTab={props.activeTab}></BuIncInventory>
            </div>
            <div className={`d-flex ${styles.BuGraphsRow3}`}>
                <BuIncMarketShare year={props.year}></BuIncMarketShare>
                {/* <BuIncHsse year={props.year} activeTab={props.activeTab}></BuIncHsse> */}
                <BuIncOutstanding year={props.year} activeTab={props.activeTab}></BuIncOutstanding>
                <BuIncOutstandingTerritory year={props.year} activeTab={props.activeTab}></BuIncOutstandingTerritory>
            </div>
        </div>
    )
}
export default BuSections