// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeltaValuesOrg_kpiOverallDetails__2MnLZ {\n  padding-right: 18px;\n  padding-top: 0px; }\n  .DeltaValuesOrg_kpiOverallDetails__2MnLZ .DeltaValuesOrg_totalValues__20rsP {\n    color: white;\n    font-size: 25px; }\n    .DeltaValuesOrg_kpiOverallDetails__2MnLZ .DeltaValuesOrg_totalValues__20rsP .DeltaValuesOrg_totVal__2bjLG {\n      color: #7df289;\n      font-weight: bold;\n      font-weight: 28px; }\n  .DeltaValuesOrg_kpiOverallDetails__2MnLZ .DeltaValuesOrg_deltaValuesContainer__XZNOg {\n    color: #97a4ba;\n    font-size: 22px; }\n    .DeltaValuesOrg_kpiOverallDetails__2MnLZ .DeltaValuesOrg_deltaValuesContainer__XZNOg .DeltaValuesOrg_container__3h8mA .DeltaValuesOrg_title__2QfjY {\n      margin-right: 8px; }\n      .DeltaValuesOrg_kpiOverallDetails__2MnLZ .DeltaValuesOrg_deltaValuesContainer__XZNOg .DeltaValuesOrg_container__3h8mA .DeltaValuesOrg_title__2QfjY .DeltaValuesOrg_deltaIcon__jol3Z {\n        margin-bottom: 6px; }\n", ""]);
// Exports
exports.locals = {
	"kpiOverallDetails": "DeltaValuesOrg_kpiOverallDetails__2MnLZ",
	"totalValues": "DeltaValuesOrg_totalValues__20rsP",
	"totVal": "DeltaValuesOrg_totVal__2bjLG",
	"deltaValuesContainer": "DeltaValuesOrg_deltaValuesContainer__XZNOg",
	"container": "DeltaValuesOrg_container__3h8mA",
	"title": "DeltaValuesOrg_title__2QfjY",
	"deltaIcon": "DeltaValuesOrg_deltaIcon__jol3Z"
};
module.exports = exports;
