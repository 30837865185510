// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".quarterlydatanotfound_datanotfound__1PJ2h {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  color: #fff; }\n  .quarterlydatanotfound_datanotfound__1PJ2h h4 {\n    font-size: 50px;\n    padding-top: 30px; }\n  .quarterlydatanotfound_datanotfound__1PJ2h p {\n    font-size: 30px;\n    padding-top: 15px;\n    margin-left: 69px; }\n", ""]);
// Exports
exports.locals = {
	"datanotfound": "quarterlydatanotfound_datanotfound__1PJ2h"
};
module.exports = exports;
