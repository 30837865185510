import React, { useState, useEffect } from 'react'
import { fetchRetailProjects } from '../../../services/bu'
import BuProjects from './BuProjects'
import BuProjectsCount from './BuProjectsCount'

const BuRetailProjects = (props) => {
    const [graphData, setGraphData] = useState({})
    const [loading, setloading] = useState(true)

    useEffect(() => {
        setloading(false)
        fetchRetailProjects(props.year).then(data => {
            const projectData = props.year >= '2023' ? data[data.length - 1]: data
            setGraphData(projectData)
        }).catch(console.debug)
            .then(() => setloading(false))
    }, [props.product,props.year]) 
    return (
        <div>{  props.year >= '2023'?
         <BuProjectsCount graphData={graphData} product={props.product} year={props.year} isLoading={loading} />
         :
         <BuProjects graphData={graphData} product={props.product} isLoading={loading} />
            }
        </div>
    )
}
export default BuRetailProjects