// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".stateMap_heatmapLegend__2eetk {\n  position: relative;\n  bottom: 893px;\n  left: 412px; }\n\n.stateMap_heatmapImg__pj_q6 {\n  height: 54px; }\n\n.stateMap_heatmapMin__2vhop {\n  margin-top: -38px;\n  margin-left: 18px;\n  font-size: 25px; }\n\n.stateMap_heatmapMax__2f8TP {\n  margin-left: 590px;\n  font-size: 25px; }\n\n.stateMap_heatmapMiddle__1At5Y {\n  margin-top: -35px;\n  margin-left: 300px;\n  font-size: 25px; }\n", ""]);
// Exports
exports.locals = {
	"heatmapLegend": "stateMap_heatmapLegend__2eetk",
	"heatmapImg": "stateMap_heatmapImg__pj_q6",
	"heatmapMin": "stateMap_heatmapMin__2vhop",
	"heatmapMax": "stateMap_heatmapMax__2f8TP",
	"heatmapMiddle": "stateMap_heatmapMiddle__1At5Y"
};
module.exports = exports;
