import React, { useEffect, useState, useCallback } from 'react'
import styles from './ProfitLoss.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import OrgListAccordian from '../../OrgListAccordian/OrgListAccordian'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchProfitLoss, fetchupdatedStatus } from '../../../services/Org'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const ProfitLoss = (props) => {
    const [rfo, setrfo] = useState([])
    const [oe, setoe] = useState([])
    const [ff, setff] = useState([])
    const [pbt, setpbt] = useState([])
    const [pat, setpat] = useState([])
    const [opm, setopm] = useState([])
    const [ebitda, setebitda] = useState([])
    const [ie, setie] = useState([])
    const [igl, setigl] = useState([])
    const [epes, setepes] = useState([])
    const [loading, setLoading] = useState(true)
    const [quarter, setquarter] = useState(0)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchupdatedStatus(props.year)
            .then(response => {
                if (!isEmpty(response)) {
                    let revenueData = response.findLast((res) => res.kpi_name === "revenue from operations");
                    let operatingData = response.findLast((res) => res.kpi_name === "operating expenses")
                    let grossData = response.findLast((res) => res.kpi_name === "gross profit marketing")
                    let inventoryData = response.findLast((res) => res.kpi_name === "inventory gain/loss")
                    let forexData = response.findLast((res) => res.kpi_name === "forex fluctuation")
                    let ebitdaData = response.findLast((res) => res.kpi_name === "EBITDA");
                    let expenditureData = response.findLast((res) => res.kpi_name === "interest expenditure")
                    let beforetaxData = response.findLast((res) => res.kpi_name === "profit before tax")
                    let aftertaxData = response.findLast((res) => res.kpi_name === "profit after tax")
                    let earningsData = response.findLast((res) => res.kpi_name === "earnings per equity share")
                    if (revenueData.status && operatingData.status && grossData.status && inventoryData.status && forexData.status &&
                        ebitdaData.status && expenditureData.status && beforetaxData.status && aftertaxData.status && earningsData.status === "Updated") {
                        setUpdatedStatus("Updated")
                    } else {
                        setUpdatedStatus("Not Updated")
                    }
                } else {
                    setUpdatedStatus("Not Found")
                }
            })
            .catch(error => console.debug(error))
        fetchProfitLoss(props.dropdown.toLowerCase(), props.year).then(data => {
            if (data.length === 0) {
                setLoading(true)
                setquarter(0)
            } else {
                let rfoarray = []
                let oearray = []
                let ffarray = []
                let pbtarray = []
                let patarray = []
                let opmarray = []
                let ebiarray = []
                let iearray = []
                let iglarray = []
                let epesarray = []
                data.map(getprldata => {
                    if (getprldata.sub_kpi_type === 'revenue from operations') {
                        rfoarray.push(getprldata)
                        setrfo(rfoarray[0])
                    }
                    if (getprldata.sub_kpi_type === 'operating expenses') {
                        oearray.push(getprldata)
                        setoe(oearray[0])
                    }
                    if (getprldata.sub_kpi_type === 'forex fluctuation') {
                        ffarray.push(getprldata)
                        setff(ffarray[0])
                    }
                    if (getprldata.sub_kpi_type === 'profit before tax') {
                        pbtarray.push(getprldata)
                        setpbt(pbtarray[0])
                    }
                    if (getprldata.sub_kpi_type === 'profit after tax') {
                        patarray.push(getprldata)
                        setpat(patarray[0])
                    }
                    if (getprldata.sub_kpi_type === 'gross profit marketing') {
                        opmarray.push(getprldata)
                        setopm(opmarray[0])
                    }
                    if (getprldata.sub_kpi_type === 'EBITDA') {
                        ebiarray.push(getprldata)
                        setebitda(ebiarray[0])
                    }
                    if (getprldata.sub_kpi_type === 'interest expenditure') {
                        iearray.push(getprldata)
                        setie(iearray[0])
                    }
                    if (getprldata.sub_kpi_type === 'inventory gain/loss') {
                        iglarray.push(getprldata)
                        setigl(iglarray[0])
                    }
                    if (getprldata.sub_kpi_type === 'earnings per equity share') {
                        epesarray.push(getprldata)
                        setepes(epesarray[0])
                    }
                    setLoading(false)
                })
                let lastQuarter = new Date(data[0].date).getMonth() + 1
                let quarterData
                switch (lastQuarter) {
                    case 6:
                        quarterData = 1
                        break
                    case 9:
                        quarterData = 2
                        break
                    case 12:
                        quarterData = 3
                        break
                    case 3:
                        quarterData = 4
                        break
                    default:
                        break
                }
                setquarter(quarterData)
            }

        }).catch(error => console.debug(error))
    }, [props.dropdown, props.year])
    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.profitLossContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.profitLossHeader}`}>
                    <span>Profit & Loss (Quarter {quarter}) - Quarterly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand Growth KPIs" id="fullSrnpl" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                {loading
                    ?
                    // <NoDataFound />
                    <QuarterlyDataNotFound />
                    :
                    <OrgListAccordian rfo={rfo} oe={oe} ff={ff} pbt={pbt} pat={pat} opm={opm} ebitda={ebitda} ie={ie} igl={igl} epes={epes} ></OrgListAccordian>
                }
            </div>
        </FullScreen>
    )
}
export default ProfitLoss