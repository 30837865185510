import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './LandedCostGraph.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function LandedCostGraph(props) {

    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)


    useEffect(() => {
        let crudeActualArray = []
        let freightActualArray = []
        let taxesActualArray = []
        let targetArray = []
        let targetArrayLabel = []
        //let maxvalue = []
        let targetArrayData = []

        const xLabels = ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar']
        let avgValue = 0

        function prepareGraphData(apiGraphData) {
            let count = apiGraphData.length
            let total = 0
            apiGraphData.forEach((item, index) => {
                let targetValue = 0
                let actualAvgValue = 0
                if (props.crudeBox) {
                    crudeActualArray.push(parseFloat(item.crude_cost_actual).toFixed(2))
                    if (item.crude_cost_actual) {
                        targetValue += item.crude_cost_target
                        actualAvgValue += item.crude_cost_actual
                    }
                    //maxvalue.push(item.crude_cost_actual,targetValue)
                }

                if (props.freightBox) {
                    freightActualArray.push(parseFloat(item.freight_cost_actual).toFixed(2))
                    if (item.freight_cost_actual) {
                        targetValue += item.freight_cost_target
                        actualAvgValue += item.freight_cost_actual
                    }
                    //maxvalue.push(item.freight_cost_actual,targetValue)
                }

                if (props.taxesBox) {
                    taxesActualArray.push(parseFloat(item.taxes_actual).toFixed(2))
                    if (item.taxes_actual) {
                        targetValue += item.taxes_target
                        actualAvgValue += item.taxes_actual
                    }
                    //maxvalue.push(item.taxes_actual,targetValue)
                }

                targetArrayLabel.push(actualAvgValue)
                targetArray.push(targetValue)
                targetArrayData.push(0)
                total += actualAvgValue
                return
            })
            avgValue = total / count
        }
        prepareGraphData(props.landedCostData)
        let avgColor = ''
        if (props.location === 'mumbai') {
            avgColor = '#06bee1'
        }
        if (props.location === 'kochi') {
            avgColor = '#b76df1'
        }
        if (props.location === 'bina') {
            avgColor = '#b76df1'
        }
        if (props.location === 'all') {
            avgColor = '#99eb6a'
        }
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        let crudeGradientMumbai = ctx.createLinearGradient(0, 0, 0, 500)
        crudeGradientMumbai.addColorStop(1, '#0340bd')
        crudeGradientMumbai.addColorStop(0, '#007aff')

        let crudeGradientKochi = ctx.createLinearGradient(0, 0, 0, 500)
        crudeGradientKochi.addColorStop(1, '#632897')
        crudeGradientKochi.addColorStop(0, '#a241f7')

        let crudeGradientAll = ctx.createLinearGradient(0, 0, 0, 500)
        crudeGradientAll.addColorStop(0, '#99eb6a')
        crudeGradientAll.addColorStop(1, '#5bb048')

        let freightGradientMumbai = ctx.createLinearGradient(0, 0, 0, 500)
        freightGradientMumbai.addColorStop(1, '#5eff91')
        freightGradientMumbai.addColorStop(0, '#02d345')

        let freightGradientKochi = ctx.createLinearGradient(0, 0, 0, 500)
        freightGradientKochi.addColorStop(1, '#e640b7')
        freightGradientKochi.addColorStop(0, '#ff7bda')

        let freightGradientAll = ctx.createLinearGradient(0, 0, 0, 500)
        freightGradientAll.addColorStop(1, '#e640b7')
        freightGradientAll.addColorStop(0, '#ff7bda')

        let taxGradientMumbai = ctx.createLinearGradient(0, 0, 0, 500)
        taxGradientMumbai.addColorStop(1, '#00c7ec')
        taxGradientMumbai.addColorStop(0, '#c1f5ff')

        let taxGradientKochi = ctx.createLinearGradient(0, 0, 0, 500)
        taxGradientKochi.addColorStop(1, '#d883ff')
        taxGradientKochi.addColorStop(0, '#f4dbff')

        let taxGradientAll = ctx.createLinearGradient(0, 0, 0, 500)
        taxGradientAll.addColorStop(1, '#d883ff')
        taxGradientAll.addColorStop(0, '#f4dbff')

        let crudeGradient = ''
        let freightGradient = ''
        let taxGradient = ''

        ////SET COLORS ACCORDING TO LOCATION
        if (props.location === 'mumbai') {
            crudeGradient = crudeGradientMumbai
            freightGradient = freightGradientMumbai
            taxGradient = taxGradientMumbai
        }
        if (props.location === 'kochi') {
            crudeGradient = crudeGradientKochi
            freightGradient = freightGradientKochi
            taxGradient = taxGradientKochi
        }
        if (props.location === 'bina') {
            crudeGradient = crudeGradientKochi
            freightGradient = freightGradientKochi
            taxGradient = taxGradientKochi
        }
        if (props.location === 'all') {
            crudeGradient = crudeGradientAll
            freightGradient = freightGradientAll
            taxGradient = taxGradientAll
        }
        const chartConfig = {
            type: 'bar',
            data: {
                labels: xLabels,
                datasets: [
                    {
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        categoryPercentage: 1,
                        barPercentage: 0.2,
                        label: 'Crude Actual',
                        data: crudeActualArray,
                        backgroundColor: crudeGradient,
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        },
                    },
                    {
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        categoryPercentage: 1,
                        barPercentage: 0.2,
                        label: 'Frieght Actual',
                        data: freightActualArray,
                        backgroundColor: freightGradient,
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        },
                    },
                    {
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        label: 'Taxes Actual',
                        categoryPercentage: 1,
                        barPercentage: 0.2,
                        data: taxesActualArray,
                        backgroundColor: taxGradient,
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            align: 'start',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        },
                    },

                    //X-AXIS 2 WITH TARGET DATA AND ONLY ONE DATASET
                    {
                        yAxisID: 'bar-y-axis2',
                        xAxisID: 'bar-x-axis2',
                        categoryPercentage: 0.6,
                        label: 'Target',
                        backgroundColor: '#2f3b51',
                        data: targetArray,
                        borderWidth: 0,
                        pointRadius: 0,
                        datalabels: {
                            display: false,
                            anchor: 'end',
                            offset: 0,
                            align: 'top',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            },
                            formatter: function (value, context) {
                                if (value === 0) {
                                    value = ''
                                }
                                return value
                            }
                        },
                    },
                    {
                        label: targetArrayLabel,
                        data: targetArrayData,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        datalabels: {
                            anchor: 'end',
                            align: 'top',
                            color: '#dee5ef',
                            font: {
                                size: 15,
                            },
                            formatter: function (value, context) {
                                value = context.dataset.label[context.dataIndex]
                                return parseFloat(value).toFixed(2)
                            }
                        },
                    },
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                // annotation: {
                //     annotations: [{
                //         type: 'line',
                //         drawTime: 'afterDatasetsDraw',
                //         mode: 'horizontal',
                //         scaleID: 'bar-y-axis1',
                //         value: avgValue,
                //         borderColor: avgColor,
                //         borderWidth: 2,
                //         borderDash: [3],
                //         label: {
                //             enabled: true,
                //             content: ''
                //         }
                //     }]
                // },
                scales: {
                    xAxes: [
                        {
                            id: 'bar-x-axis1',
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                        {
                            id: 'bar-x-axis2',
                            // barPercentage: 1,
                            display: false,
                            offset: true,
                            type: 'category',
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            display: true,
                            id: 'bar-y-axis1',
                            stacked: true,
                            beginAtZero: true,
                            ticks: {
                                // max: 75,
                                // min: 0,
                                // stepSize: 15,
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit: 4
                                // min : 0,
                                // max : props.crudeBox ? Math.floor(Math.max(...maxvalue))+10 : Math.floor(Math.max(...maxvalue))+1,
                                // stepSize : Math.floor(Math.max(...maxvalue)/4)
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1
                            }
                        },
                        {
                            display: false,
                            id: 'bar-y-axis2',
                            beginAtZero: true,
                            ticks: {
                                // max: 75,
                                // min: 0,
                                // stepSize: 15,
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit: 4
                                // min : 0,
                                // max : props.crudeBox ? Math.floor(Math.max(...maxvalue))+10 : Math.floor(Math.max(...maxvalue))+1,
                                // stepSize : Math.floor(Math.max(...maxvalue)/4)
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#2f3b51',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.landedCostData, props.location, props.crudeBox, props.freightBox, props.taxesBox])// eslint-disable-next-line react-hooks/exhaustive-deps


    return (
        <div className={`${styles.grmGraph}`}>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}
export default LandedCostGraph