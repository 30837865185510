import React, { useEffect, useState, useCallback } from 'react'
import styles from './LearningDevelopment2.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import LearningDevelopmentLineGraph from './LearningDevelopmentLineGraph'
import LearningDevelopmentGraph from './LearningDevelopmentGraph'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchLearningDevelopment, fetchupdatedStatus } from '../../../services/Org'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import Radio from '../../Itrm/ItrmDemurrage/Radio'
import {last, isEmpty} from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const LearningDevelopment = (props) => {
    const [loading, setLoading] = useState(true)
    const [alldata, setalldata] = useState(true)
    const [retaildata, setretaildata] = useState(false)
    const [atfdata, setatfdata] = useState(false)
    const [lpgdata, setlpgdata] = useState(false)
    const [lubesdata, setlubesdata] = useState(false)
    const [refinerydata, setrefinerydata] = useState(false)
    const [incdata, setincdata] = useState(false)
    const [entidata, setentidata] = useState(false)
    const [graphdata, setgraphdata] = useState([])
    // average count
    const [retirecountr, setretirecountr] = useState(0)
    const [retirecountall, setretirecountall] = useState(0)
    const [retirecounta, setretirecounta] = useState(0)
    const [retirecountl, setretirecountl] = useState(0)
    const [retirecountlu, setretirecountlu] = useState(0)
    const [retirecountref, setretirecountref] = useState(0)
    const [retirecountinc, setretirecountinc] = useState(0)
    const [retirecountenti, setretirecountenti] = useState(0)
    const [retirecountrYtm, setretirecountrYtm] = useState(0)
    const [retirecountallYtm, setretirecountallYtm] = useState(0)
    const [retirecountaYtm, setretirecountaYtm] = useState(0)
    const [retirecountlYtm, setretirecountlYtm] = useState(0)
    const [retirecountluYtm, setretirecountluYtm] = useState(0)
    const [retirecountrefYtm, setretirecountrefYtm] = useState(0)
    const [retirecountincYtm, setretirecountincYtm] = useState(0)
    const [retirecountentiYtm, setretirecountentiYtm] = useState(0)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps
    const onCheckStateChangedehr = (event) => {
        if (event.name === 'allBox') {
            setalldata(true)
            setretaildata(false)
            setlpgdata(false)
            setatfdata(false)
            setlubesdata(false)
            setrefinerydata(false)
            setincdata(false)
            setentidata(false)
        }
        if (event.name === 'retBox') {
            setretaildata(true)
            setlpgdata(false)
            setatfdata(false)
            setlubesdata(false)
            setrefinerydata(false)
            setincdata(false)
            setalldata(false)
            setentidata(false)
        }
        if (event.name === 'lpgBox') {
            setretaildata(false)
            setlpgdata(true)
            setatfdata(false)
            setlubesdata(false)
            setrefinerydata(false)
            setincdata(false)
            setalldata(false)
            setentidata(false)
        }
        if (event.name === 'atfBox') {
            setretaildata(false)
            setlpgdata(false)
            setatfdata(true)
            setlubesdata(false)
            setrefinerydata(false)
            setincdata(false)
            setalldata(false)
            setentidata(false)
        }
        if (event.name === 'lubBox') {
            setretaildata(false)
            setlpgdata(false)
            setatfdata(false)
            setlubesdata(true)
            setrefinerydata(false)
            setincdata(false)
            setalldata(false)
        }
        if (event.name === 'refBox') {
            setretaildata(false)
            setlpgdata(false)
            setatfdata(false)
            setlubesdata(false)
            setrefinerydata(true)
            setincdata(false)
            setalldata(false)
            setentidata(false)
        }
        if (event.name === 'incBox') {
            setretaildata(false)
            setlpgdata(false)
            setatfdata(false)
            setlubesdata(false)
            setrefinerydata(false)
            setincdata(true)
            setalldata(false)
            setentidata(false)
        }
        if (event.name === 'entiBox') {
            setretaildata(false)
            setlpgdata(false)
            setatfdata(false)
            setlubesdata(false)
            setrefinerydata(false)
            setincdata(false)
            setalldata(false)
            setentidata(true)
        }
    }
    const GetLatestRec = (data) => {
        try{
            let funman = []
            let behman = []
            let funcd = data.map(getman => {
                if(getman.training_type === 'Functional'){
                    funman.push(getman.mandays)
                }else if(getman.training_type === 'Behavioral'){
                    behman.push(getman.mandays)
                }
            })
            let sum_all = Number(funman[0]) + Number(behman[0])
            return sum_all
        }catch(error){
            console.debug(error)
        }
    }
    const GetLatestRecForOfficer = (data) => {
        try{
            let funman = []
            let behman = []
            let funcd = data.map(getman => {
                if(getman.training_type === 'Functional'){
                    funman.push(getman.officers)
                }else if(getman.training_type === 'Behavioral'){
                    behman.push(getman.officers)
                }
            })
            let sum_all = Number(funman[0]) + Number(behman[0])
            return sum_all
        }catch(error){
            console.debug(error)
        }
    }
    const getSumLD = (data, total) => {
        try {
            let result_count = Number(data)/Number(total)
            // let count = data.length
            // let result_sum = data.reduce((a,b) => a+b, 0)
            // let result_count = Number(result_sum)/Number(count)
            return result_count.toFixed(2)
        } catch (error) {
            console.debug(error)
        }
    }
    useEffect(() => {
        fetchupdatedStatus(props.year)
            .then(response => {
                if (!isEmpty(response)) {
                    response.filter((res) => res.entity === "HRD" && res.kpi_name === "Learning & Development" ? setUpdatedStatus(res.status) : false);
                } else {
                    setUpdatedStatus("Not Found")
                }
            })
            .catch(error => console.debug(error))
        function getLD(){
            let allkpi = []
            let retail = []
            let atf = []
            let lpg = []
            let lubes = []
            let inc = []
            let refinery = []
            let entities = []
            fetchLearningDevelopment(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length === 0) {
                    setLoading(true)
                } else {
                    setgraphdata(data)
                    data.forEach(gethrdata => {
                        if(gethrdata.business_unit === 'all'){
                                allkpi.push(gethrdata)
                                let getLastestRecords = GetLatestRec(allkpi)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecountall(getSumLD(getLastestRecords, getTotalemployees[0]))
                        } else if(gethrdata.business_unit === 'retail'){
                                retail.push(gethrdata)
                                let getLastestRecords = GetLatestRec(retail)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecountr(getSumLD(getLastestRecords, getTotalemployees[0]))
                        } else if(gethrdata.business_unit === 'atf'){
                                atf.push(gethrdata)
                                let getLastestRecords = GetLatestRec(atf)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecounta(getSumLD(getLastestRecords, getTotalemployees[0]))
                        } else if(gethrdata.business_unit === 'lpg'){
                                lpg.push(gethrdata)
                                let getLastestRecords = GetLatestRec(lpg)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecountl(getSumLD(getLastestRecords, getTotalemployees[0]))
                        } else if(gethrdata.business_unit === 'lubes'){
                                lubes.push(gethrdata)
                                let getLastestRecords = GetLatestRec(lubes)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecountlu(getSumLD(getLastestRecords, getTotalemployees[0]))
                        } else if(gethrdata.business_unit === 'refinery'){
                                refinery.push(gethrdata)
                                let getLastestRecords = GetLatestRec(refinery)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecountref(getSumLD(getLastestRecords, getTotalemployees[0]))
                        } else if(gethrdata.business_unit === 'inc'){
                                inc.push(gethrdata)
                                let getLastestRecords = GetLatestRec(inc)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecountinc(getSumLD(getLastestRecords, getTotalemployees[0]))
                        } else if(gethrdata.business_unit === 'entities'){
                                entities.push(gethrdata)
                                let getLastestRecords = GetLatestRec(entities)
                                let getTotalemployees = []
                                getTotalemployees.push(gethrdata.total_no_of_employees === null ? 0 : gethrdata.total_no_of_employees)
                                setretirecountenti(getSumLD(getLastestRecords, getTotalemployees[0]))
                        }
                        if(gethrdata.business_unit === 'all'){
                            allkpi.push(gethrdata)
                            let getLastestRecords = GetLatestRec(allkpi)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(allkpi)
                            setretirecountallYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        } else if(gethrdata.business_unit === 'retail'){
                            retail.push(gethrdata)
                            let getLastestRecords = GetLatestRec(retail)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(retail)
                            setretirecountrYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        } else if(gethrdata.business_unit === 'atf'){
                            atf.push(gethrdata)
                            let getLastestRecords = GetLatestRec(atf)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(atf)
                            setretirecountaYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        } else if(gethrdata.business_unit === 'lpg'){
                            lpg.push(gethrdata)
                            let getLastestRecords = GetLatestRec(lpg)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(lpg)
                            setretirecountlYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        } else if(gethrdata.business_unit === 'lubes'){
                            lubes.push(gethrdata)
                            let getLastestRecords = GetLatestRec(lubes)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(lubes)
                            setretirecountluYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        } else if(gethrdata.business_unit === 'refinery'){
                            refinery.push(gethrdata)
                            let getLastestRecords = GetLatestRec(refinery)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(refinery)
                            setretirecountrefYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        } else if(gethrdata.business_unit === 'inc'){
                            inc.push(gethrdata)
                            let getLastestRecords = GetLatestRec(inc)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(inc)
                            setretirecountincYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        } else if(gethrdata.business_unit === 'entities'){
                            entities.push(gethrdata)
                            let getLastestRecords = GetLatestRec(entities)
                            let getLastestRecordsOfficer = GetLatestRecForOfficer(entities)
                            setretirecountentiYtm(getSumLD(getLastestRecords, getLastestRecordsOfficer))
                        }
                    })
                    setLoading(false)
                }
            }).catch(error => console.debug(error))
        }
        getLD()
    }, [props.dropdown, props.year])
    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.LearningDevelopmentContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.LearningDevelopmentHeader}`}>
                    <span>Learning & Development (YTM) - Monthly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand Learning & Development" id="fullSrnld" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                {loading
                    ?
                    <NoDataFound />
                    :
                    <div className={`d-flex align-items-center justify-content-between ${styles.LearningDevelopmentContentContainer}`}>
                        <div className={`d-flex ${styles.graphContainer}`}>
                            <div className={`${styles.gContainer}`}>
                                <div className={`d-flex ${styles.legendehr}`} style={{opacity : alldata ? '1' : '0'}}>
                                    <p>No. of training mandays/officers : 
                                        {/* <b>{alldata ? retirecountall : retaildata ? retirecountr : lpgdata ? retirecountl : atfdata ? retirecounta : lubesdata ? retirecountlu : refinerydata ? retirecountref : incdata ? retirecountinc : entidata ? retirecountenti : 0}</b> */}
                                        <b>{alldata ? retirecountallYtm : retaildata ? retirecountrYtm : lpgdata ? retirecountlYtm : atfdata ? retirecountaYtm : lubesdata ? retirecountluYtm : refinerydata ? retirecountrefYtm : incdata ? retirecountincYtm : entidata ? retirecountentiYtm : 0}</b></p>
                                </div>
                                <div className={`d-flex align-items-center justify-content-even`}>
                                    <div className={`${styles.graphSectionpie}`}><LearningDevelopmentGraph graphdata={graphdata} title={'Training Mandays'} allGraph={alldata} retailGraph={retaildata} lpgGraph={lpgdata} atfGraph={atfdata} lubesGraph={lubesdata} refineryGraph={refinerydata} incGraph={incdata} entiGraph={entidata}></LearningDevelopmentGraph></div>
                                    <div className={`${styles.graphlegend}`}>
                                    <p className={`d-flex align-items-center`}><span className={`mr-2 ${styles.staffCostDot}`}></span> Functional</p>
                                    <p className={`d-flex align-items-center`}><span className={`mr-2 ${styles.rmDot}`}></span> Behavioral</p>
                                    </div>
                                    <div className={`${styles.graphSection}`}><LearningDevelopmentLineGraph graphdata={graphdata} title={'Officers Covered'} allGraph={alldata} retailGraph={retaildata} lpgGraph={lpgdata} atfGraph={atfdata} lubesGraph={lubesdata} refineryGraph={refinerydata} incGraph={incdata} entiGraph={entidata}></LearningDevelopmentLineGraph></div>
                                </div>
                                {/* <div className={`d-flex ${styles.legendehr}`}>
                                    <p className={`d-flex mr-3 align-items-center`}><span className={`mr-2 ${styles.staffCostDot}`}></span> Mandays Behaviour</p>
                                    <p className={`d-flex mr-3 align-items-center`}><span className={`mr-2 ${styles.rmDot}`}></span> Mandays Functional</p>
                                    <p className={`d-flex mr-3 align-items-center`}><span className={`mr-2 ${styles.staffCostDot}`}></span>Officers Behaviour</p>
                                    <p className={`d-flex mr-3 align-items-center`}><span className={`mr-2 ${styles.rmDot}`}></span>Officers Functional</p>
                                </div> */}
                            </div>
                            <div className={`d-flex flex-column justify-content-center ${styles.graphFilters}`}>
                                <Radio
                                    size="sm"
                                    name="allBox"
                                    label="All"
                                    checked={alldata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                                <Radio
                                    size="sm"
                                    name="retBox"
                                    label="Retail"
                                    checked={retaildata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                                <Radio
                                    size="sm"
                                    name="lpgBox"
                                    label="LPG"
                                    checked={lpgdata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                                <Radio
                                    size="sm"
                                    name="atfBox"
                                    label="Aviation"
                                    checked={atfdata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                                <Radio
                                    size="sm"
                                    name="lubBox"
                                    label="Lubes"
                                    checked={lubesdata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                                <Radio
                                    size="sm"
                                    name="refBox"
                                    label="Refineries"
                                    checked={refinerydata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                                <Radio
                                    size="sm"
                                    name="incBox"
                                    label="I&C"
                                    checked={incdata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                                <Radio
                                    size="sm"
                                    name="entiBox"
                                    label="Entities"
                                    checked={entidata}
                                    onClick={(e) => onCheckStateChangedehr(e)}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </FullScreen>
    )
}
export default LearningDevelopment