import React from 'react'
import styles from './OrgListAccordian.module.scss'
import { Accordion, Card } from 'react-bootstrap'
import DeltaValuesOrg from '../DeltaValuesOrg/DeltaValuesOrg'
import { numberFormatEnIn } from '../../utility/utility'

const OrgListAccordian = (props) => {
    return (
        <Accordion className={`${styles.listContainer}`}>
            <Card className={`${styles.cardContainer}`}>
                <Accordion.Toggle as={Card.Header} eventKey="1" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Revenue from operations</div>
                    <div className={`${styles.kpiValue}`}>{props.rfo.value ? <> INR {numberFormatEnIn(parseFloat(props.rfo.value).toFixed(2))} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.rfo.quaterly ? parseFloat(props.rfo.quaterly).toFixed(2) : ''} year={props.rfo.yearly ? parseFloat(props.rfo.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="1" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="2" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Operating Expenses</div>
                    <div className={`${styles.kpiValue}`}>{props.oe.value ? <> INR {Math.sign(props.oe.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.oe.value).toFixed(2)))})` : numberFormatEnIn(parseFloat(props.oe.value).toFixed(2))} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.oe.quaterly ? parseFloat(props.oe.quaterly).toFixed(2) : ''} year={props.oe.yearly ? parseFloat(props.oe.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="2" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="6" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Gross Profit Marketing</div>
                    <div className={`${styles.kpiValue}`}>{props.opm.value ? <>INR {Math.sign(props.opm.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.opm.value).toFixed(2)))})` : numberFormatEnIn(parseFloat(props.opm.value).toFixed(2))} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.opm.quaterly ? parseFloat(props.opm.quaterly).toFixed(2) : ''} year={props.opm.yearly ? parseFloat(props.opm.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="6" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="9" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Inventory Gain/Loss</div>
                    <div className={`${styles.kpiValue}`}>{props.igl.value ? <>INR {Math.sign(props.igl.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.igl.value).toFixed(2)))})` : `+${numberFormatEnIn(parseFloat(props.igl.value).toFixed(2))}`} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.igl.quaterly ? parseFloat(props.igl.quaterly).toFixed(2) : ''} year={props.igl.yearly ? parseFloat(props.igl.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="9" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="3" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Forex Fluctuation</div>
                    <div className={`${styles.kpiValue}`}>{props.ff.value ? <>INR {Math.sign(props.ff.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.ff.value).toFixed(2)))})` : `+${numberFormatEnIn(parseFloat(props.ff.value).toFixed(2))}`} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.ff.quaterly ? parseFloat(props.ff.quaterly).toFixed(2) : ''} year={props.ff.yearly ? parseFloat(props.ff.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="3" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="7" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>EBITDA</div>
                    <div className={`${styles.kpiValue}`}>{props.ebitda.value ? <>INR {Math.sign(props.ebitda.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.ebitda.value).toFixed(2)))})` : numberFormatEnIn(parseFloat(props.ebitda.value).toFixed(2))} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.ebitda.quaterly ? parseFloat(props.ebitda.quaterly).toFixed(2) : ''} year={props.ebitda.yearly ? parseFloat(props.ebitda.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="7" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="8" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Interest Expenditure</div>
                    <div className={`${styles.kpiValue}`}>{props.ie.value ? <>INR {Math.sign(props.ie.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.ie.value).toFixed(2)))})` : numberFormatEnIn(parseFloat(props.ie.value).toFixed(2))} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.ie.quaterly ? parseFloat(props.ie.quaterly).toFixed(2) : ''} year={props.ie.yearly ? parseFloat(props.ie.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="8" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="4" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Profit/(Loss) Before Tax</div>
                    <div className={`${styles.kpiValue}`}>{props.pbt.value ? <>INR {Math.sign(props.pbt.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.pbt.value).toFixed(2)))})` : numberFormatEnIn(parseFloat(props.pbt.value).toFixed(2))} Cr </> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.pbt.quaterly ? parseFloat(props.pbt.quaterly).toFixed(2) : ''} year={props.pbt.yearly ? parseFloat(props.pbt.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="4" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="5" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Profit/(Loss) After Tax</div>
                    <div className={`${styles.kpiValue}`}>{props.pat.value ? <>INR {Math.sign(props.pat.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.pat.value).toFixed(2)))})` : numberFormatEnIn(parseFloat(props.pat.value).toFixed(2))} Cr</> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.pat.quaterly ? parseFloat(props.pat.quaterly).toFixed(2) : ''} year={props.pat.yearly ? parseFloat(props.pat.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="5" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
                <Accordion.Toggle as={Card.Header} eventKey="10" className={`d-flex align-items-center ${styles.CollapsedListItemContainer}`}>
                    <div className={`${styles.kpiTitle}`}>Earning Per Equity Share</div>
                    <div className={`${styles.kpiValue}`}>{props.epes.value ? <>{Math.sign(props.epes.value) === -1 ? `(${numberFormatEnIn(Math.abs(parseFloat(props.epes.value).toFixed(2)))})` : numberFormatEnIn(parseFloat(props.epes.value).toFixed(2))} per share</> : ''}</div>
                    <div className={`${styles.deltaComponent}`}>
                        <DeltaValuesOrg quater={props.epes.quaterly ? parseFloat(props.epes.quaterly).toFixed(2) : ''} year={props.epes.yearly ? parseFloat(props.epes.yearly).toFixed(2) : ''}></DeltaValuesOrg>
                    </div>
                    {/* <FontAwesomeIcon className={`${styles.expandButton}`} icon={faChevronDown}></FontAwesomeIcon> */}
                </Accordion.Toggle>
                {/* <Accordion.Collapse eventKey="10" className={`${styles.ExpandedListItemContainer}`}>
                    <span>Expanded State</span>
                </Accordion.Collapse> */}
            </Card>
        </Accordion>
    )
}
export default OrgListAccordian
