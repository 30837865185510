import React, { useState, useCallback, useEffect } from "react";
import { fetchProjectsData } from "../../../services/bu";
import BuProjectGraph1 from "./BuProjectGraph1";
import styles from "./BuProjectGraphProgress.module.scss";
import { Spinner } from "react-bootstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { isEmpty, last } from "lodash";
import { NoDataFound } from "../../DataNotFound/nodatafound";
import expandIcon from "../../../assets/images/expandIcon.png";
const BuRetailProjects = (props) => {
  const [showExpenditure, setShowExpenditure] = useState(false);
  const handle = useFullScreenHandle();
  const [isNoDataAvailable, setIsNoDataAvailable] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [loading, setloading] = useState(true);
  const [attr, setattr] = useState("handleEnter");

  useEffect(() => {
    setloading(false);
    fetchProjectsData(props.year)
      .then((data) => {
        // console.log(data);
        if (!isEmpty(data)) {
          setGraphData(data);
          setIsNoDataAvailable(false);
        } else {
          setIsNoDataAvailable(true);
        }
      })
      .catch(console.debug)
      .then(() => setloading(false));
  }, [props.product, props.year]);
  const fullScrn = () => {
    if (attr === "handleEnter") {
      setattr("handleExit");
      handle.enter();
      document.body.style.zoom = window.innerWidth / window.outerWidth;
    } else if (attr === "handleExit") {
      setattr("handleEnter");
      handle.exit();
      document.body.style.zoom = 0;
    }
  };
  const reportChange = useCallback(
    (state) => {
      try {
        if (state === false) {
          document.body.style.zoom = 0;
        }
      } catch (exception) {
        console.debug(exception);
      }
    },
    [handle]
  ); // eslint-disable-next-line react-hooks/exhaustive-deps

  // <div className={`d-flex ${styles.graphContainer}`}>

  //  <BuProjectGraph1 graphData={graphData} product={props.product} isLoading={loading} />
  //  </div>
  return (
    <div className={`${styles.BuProjectGraphCost}`}>
      <FullScreen handle={handle} onChange={reportChange}>
        <div
          className={`d-flex align-items-center justify-content-between ${styles.headerContainer}`}
        >
          <div className={"row"}>
            {/* <div>
                            <span>Project Value & Number</span>
                        </div> */}
            <div className={`${styles.headerLeft} flex-grow-1 `}>
              <div className={`${styles.heading}`}>Project Progress</div>
            </div>
          </div>
          <div className={"row pt-2"}>
            <div className={`d-flex align-items-center ${styles.headerLabels}`}>
            <div className="d-flex mr-4 align-items-center ">
                <span className={`mr-2 ${styles.delayedProjects}`}></span>Delayed Projects
              </div>
              <div className="d-flex mr-4 align-items-center ">
                <span className={`mr-2 ${styles.percent25}`}></span>0-25%
              </div>
              <div className="d-flex mr-4 align-items-center ">
                <span className={`mr-2 ${styles.percent50}`}></span>{">"}25-50%
              </div>
              <div className="d-flex mr-4 align-items-center ">
                <span className={`mr-2 ${styles.percent75}`}></span>{">"}50-75%
              </div>
              <div className="d-flex mr-4 align-items-center ">
                <span className={`mr-2 ${styles.percent100}`}></span>{">"}75-100%
              </div>
            </div>
          </div>
          {/* <div className={'justify-content-end'}>
                            <div className={styles.toggleBtn}>
                                <span className={`${styles.toggleBtnSwitch} ${!showExpenditure ? styles.active : ''}`}>Projects</span>
                            </div>
                        </div> */}
        </div>
        {props.isLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: 507,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : isNoDataAvailable ? (
          <NoDataFound height={499} />
        ) : (
          <BuProjectGraph1
            graphData={graphData}
            product={props.product}
            isLoading={loading}
            fullScrn={fullScrn}
          />
        )}
        <div
          className={`d-flex justify-content-end ${styles.expandIconContainer}`}
        >
          <img
            className={`${styles.expandIcon}`}
            src={expandIcon}
            onClick={fullScrn}
            alt="Expand Icon"
          ></img>
        </div>
      </FullScreen>
    </div>
  );
};
export default BuRetailProjects;
