import React, { useEffect, useState, useRef } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './AtfCustomerGrowthGraph.module.scss'
import { isEmpty } from 'lodash'
import { numberFormatEnIn } from '../../../utility/utility'

const BuLubesInventoryGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
  
  
    useEffect(() => {
        const prevYearArray = []
        const currYearArray = []
        const companyNames = []
        const growthArray = []
        const growthColor = 'rgba(0, 168, 236, 0.3)'
        const actualGrowths = []
        const aprArray = []
        const mayArray = []
        const junArray = []
        const julyArray = []
        const augArray = []
        const septArray = []
        const octArray = []
        const novArray = []
        const decArray = []
        const janArray = []
        const febArray = []
        const marArray = []
        const dates = new Date()
        const { graphData } = props
        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const janColor = '#37c7cc' //dark_teal
        const febColor = '#ed6868' //peach
        const marColor = '#d98175' //Brick
        const aprColor = '#059e92' //teal
        const mayColor = '#1f75d1' //blue
        const junColor = '#7248bd' //voilet
        const julColor = '#46a0c7' //blueLit
        const augColor = '#c79638' //yellow
        const sepColor = '#ff588c' //dark_pink 
        const octColor = '#c953a8' //pink  
        const novColor = '#ff8558' //orange
        const decColor = '#04b03b' //green


        const d = new Date();
        let currentMonthName = month[d.getMonth()];
        if (!isEmpty(graphData)) {
            graphData.forEach((item) => {

                let itemMonth = month[new Date(item.date).getMonth()]
                if(item.company_type === "Domestic"){
                companyNames.push(item.company_name)
                }
                // if (month.indexOf(itemMonth) <= month.indexOf(currentMonthName)) {
                    aprArray.push(parseInt(item.month_Apr))
                // 
                    mayArray.push(item.month_May)
                    junArray.push(item.month_Jun)
                    julyArray.push(item.month_Jul)
                    augArray.push(item.month_Aug)
                    septArray.push(item.month_Sep)
                    octArray.push(item.month_Oct)
                    novArray.push(item.month_Nov)
                    decArray.push(item.month_Dec)
                    janArray.push(item.month_Jan)
                    febArray.push(item.month_Feb)
                    marArray.push(item.month_Mar)
                // const growth = item.curr_year - item.prev_year
                // actualGrowths.push(growth)

                // const currentWithoutGrowth = (growth > 0) ? item.curr_year - Math.abs(growth) : item.curr_year
                // prevYearArray.push(item.prev_year)
                // // currYearArray.push(currentWithoutGrowth)

                // // growthArray.push(Math.abs(growth))
                // growthColor.push(growth >= 0 ? '#74ce12' :  '#e13219')
            })
        }

        if (chartInstance) {
            chartInstance.destroy()
        }

    //    Chart.Tooltip.positioners.left = function(items){
    //     let pos = Chart.Tooltip.positioners.average(items);
    //     if (pos=false){
    //         return false;
    //     }
    //     return{
    //         x: 20,
    //         y: 200,
    //         xAlign: 'left',
    //         yAlign: 'center'
    //     }
    //    }

        const ctx = chartContainer.current.getContext('2d')
        let chartConfig = {
            type: 'horizontalBar',
            data: {
                // labels: ["Air India", "Spice Jet", "Vistara", "Indigo", "Indigo", "Indigo", "Lufthansa", "British Airways", "Indigo", "Indigo"],
                labels: companyNames,
                datasets: [
                    // {
                    //     label: 'Last Year',
                    //     stack: 'Stack 1',
                    //     backgroundColor: 'rgba(0, 168, 236, 0.3)',
                    //     // data: [5500, 5200, 5100, 5000, 4692, 4312, 4116, 4182, 3920, 2940],
                    //     data:prevYearArray,
                    //     barThickness: 15,
                    //     datalabels: {
                    //         display: false
                    //     }
                    // },
                    {
                        label: 'Apr',
                        stack: 'Stack 2',
                        backgroundColor: aprColor,
                        data:aprArray,
                        // data: [5390, 5096, 5000, 4900, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 20,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'May',
                        stack: 'Stack 2',
                        backgroundColor: mayColor, 
                        data: mayArray,
                        // backgroundColor: ["#74ce12","#74ce12","#e13219", "#74ce12","#e13219","#74ce12","#74ce12","#e13219","#74ce12","#74ce12"],
                        // data: [110, 104, 100, 100, 92, 88, 84, 82, 80, 60],
                        // categoryPercentage: 1,
                        // barPercentage: 1,
                        barThickness: 20,
                        datalabels: {
                            display: false,
                        }
                    },
                    {
                        label: 'Jun',
                        stack: 'Stack 2',
                        backgroundColor: junColor,
                        data:junArray,
                        // data: [5390, 5096, 5000, 4900, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 20,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Jul',
                        stack: 'Stack 2',
                        backgroundColor: julColor, 
                        data: julyArray,
                        // backgroundColor: ["#74ce12","#74ce12","#e13219", "#74ce12","#e13219","#74ce12","#74ce12","#e13219","#74ce12","#74ce12"],
                        // data: [110, 104, 100, 100, 92, 88, 84, 82, 80, 60],
                        // categoryPercentage: 1,
                        // barPercentage: 1,
                        barThickness: 20,
                        datalabels: {
                            display: false,
                        }
                    },
                    {
                        label: 'Aug',
                        stack: 'Stack 2',
                        backgroundColor: augColor,
                        data:augArray,
                        // data: [5390, 5096, 5000, 4900, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 20,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Sept',
                        stack: 'Stack 2',
                        backgroundColor: sepColor, 
                        data: septArray,
                        // backgroundColor: ["#74ce12","#74ce12","#e13219", "#74ce12","#e13219","#74ce12","#74ce12","#e13219","#74ce12","#74ce12"],
                        // data: [110, 104, 100, 100, 92, 88, 84, 82, 80, 60],
                        // categoryPercentage: 1,
                        // barPercentage: 1,
                        barThickness: 20,
                        datalabels: {
                            display: false,
                        }
                    },
                    {
                        label: 'Oct',
                        stack: 'Stack 2',
                        backgroundColor: octColor,
                        data:octArray,
                        // data: [5390, 5096, 5000, 4900, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 20,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Nov',
                        stack: 'Stack 2',
                        backgroundColor: novColor, 
                        data: novArray,
                        // backgroundColor: ["#74ce12","#74ce12","#e13219", "#74ce12","#e13219","#74ce12","#74ce12","#e13219","#74ce12","#74ce12"],
                        // data: [110, 104, 100, 100, 92, 88, 84, 82, 80, 60],
                        // categoryPercentage: 1,
                        // barPercentage: 1,
                        barThickness: 20,
                        datalabels: {
                            display: false,
                        }
                    },
                    {
                        label: 'Dec',
                        stack: 'Stack 2',
                        backgroundColor: decColor,
                        data:decArray,
                        // data: [5390, 5096, 5000, 4900, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 20,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Jan',
                        stack: 'Stack 2',
                        backgroundColor: janColor, 
                        data: janArray,
                        // backgroundColor: ["#74ce12","#74ce12","#e13219", "#74ce12","#e13219","#74ce12","#74ce12","#e13219","#74ce12","#74ce12"],
                        // data: [110, 104, 100, 100, 92, 88, 84, 82, 80, 60],
                        // categoryPercentage: 1,
                        // barPercentage: 1,
                        barThickness: 20,
                        datalabels: {
                            display: false,
                        }
                    },
                    {
                        label: 'Feb',
                        stack: 'Stack 2',
                        backgroundColor: febColor,
                        data:febArray,
                        // data: [5390, 5096, 5000, 4900, 4600, 4400, 4200, 4100, 4000, 3000],
                        barThickness: 20,
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Mar',
                        stack: 'Stack 2',
                        backgroundColor: marColor, 
                        data: marArray,
                        // backgroundColor: ["#74ce12","#74ce12","#e13219", "#74ce12","#e13219","#74ce12","#74ce12","#e13219","#74ce12","#74ce12"],
                        // data: [110, 104, 100, 100, 92, 88, 84, 82, 80, 60],
                        // categoryPercentage: 1,
                        // barPercentage: 1,
                        barThickness: 20,
                        datalabels: {
                            display: false,
                        }
                    },
                ],
            },
            options: {
                tooltips: {
                    // position: 'left',
                    mode: 'label',
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label
                            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

                            const actualGrowth = actualGrowths[tooltipItem.index]
                            if (label === 'Current Year') {
                                value = (actualGrowth > 0) ? value + Math.abs(actualGrowth) : value
                            } else if (label === 'Gain/Loss') {
                                label = (actualGrowth > 0) ? 'Gain' : 'Loss'
                            }

                            return  `${label} : ${numberFormatEnIn(value)}`
                        }
                    }
                },
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 9,
                                fontColor: 'white',
                                fontSize: '20',
                            }

                        }
                    ]
                },
            }
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${styles.grmGraph}`}>
            <span className={`${styles.graphHeader}`}>Domestic Customers</span>
            <canvas
                ref={chartContainer}
            />
        </div>
    )
}

export default BuLubesInventoryGraph
