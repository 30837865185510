import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import ErrorBoundary from '../../ErrorBoundary'

/* Gradient direction: Left to Right */
const SALES_ACTUAL_GRADIENT_START = 'rgba(0, 168, 236, 1)'
const SALES_ACTUAL_GRADIENT_END = 'rgba(0, 224, 188, 1)'
const SALES_TARGET_GRADIENT_START = 'rgba(0, 168, 236, 0.4)'
const SALES_TARGET_GRADIENT_END = 'rgba(0, 224, 188, 0.4)'

const REVENUE_ACTUAL_GRADIENT_START = 'rgba(32, 176, 16, 1)'
const REVENUE_ACTUAL_GRADIENT_END = 'rgba(48, 255, 24, 1)'
const REVENUE_TARGET_GRADIENT_START = 'rgba(32, 176, 16, 0.4)'
const REVENUE_TARGET_GRADIENT_END = 'rgba(48, 255, 24, 0.4)'


const SalesRevenueGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
    const [error, setError] = useState(null);


    useEffect(() => {
        let actualArray = []
        let targetArray = []
        let labelPosition = ''
        let labelAlign = ''
        let paddingRight = 0
        let paddingLeft = 0
try{
        function prepareGraphData(apiGraphData) {
            apiGraphData.forEach((item) => {
                if (item.kpi_name === 'sales') {
                    actualArray.push((item.actual ? parseFloat(item.actual).toFixed(2) : '0') * -1)
                    targetArray.push((item.target ? parseFloat(item.target).toFixed(2) : '0') * -1)
                    labelPosition = 'start'
                    labelAlign = 'start'
                    paddingRight = 0
                    paddingLeft = 60
                }
                else if (item.kpi_name === 'revenue') {
                    actualArray.push((item.actual ? parseFloat(item.actual).toFixed(2) : '0'))
                    targetArray.push((item.target ? parseFloat(item.target).toFixed(2) : '0'))
                    labelPosition = 'end'
                    labelAlign = 'end'
                    paddingRight = 60
                    paddingLeft = 0
                }
            })
        }
        prepareGraphData(props.graphData)
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = {
            type: 'horizontalBar',
            data: {
                labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar'],
                datasets: [
                    {
                        yAxisID: 'bar-x-axis1',
                        categoryPercentage: 1,
                        barPercentage: 0.3,
                        label: 'Actual',
                        backgroundColor: '#a241f7',
                        // data: [-10, -20, -30, -40, -50, -60, -70, -80, -90, -10, -40, -50],
                        data: actualArray,
                        borderWidth: 0,
                        datalabels: {
                            //display:false,
                            anchor: labelPosition,
                            align: labelAlign,
                            color: '#dee5ef',
                            font: {
                                size: 14
                            },
                            formatter: function (_, context) {
                                let value
                                if (props.graphData[0].kpi_name === 'sales') {
                                    // value = `${actualArray[context.dataIndex] * -1} | ${targetArray[context.dataIndex] * -1}`
                                    value = `${actualArray[context.dataIndex] * -1}`
                                } else if (props.graphData[0].kpi_name === 'revenue') {
                                    // value = `${actualArray[context.dataIndex]} | ${targetArray[context.dataIndex]}`
                                    value = `${actualArray[context.dataIndex]}`
                                }
                                return value
                            }
                        },
                    },
                    {
                        yAxisID: 'bar-x-axis2',
                        categoryPercentage: 0.8,

                        label: 'Target',
                        // data: [-60, -70, -80, -90, -90, -90, -90, -90, -90, -90, -90, -90],
                        data: targetArray,
                        backgroundColor: '#2f3b51',
                        datalabels: {
                            display: false,
                            // anchor: labelPosition,
                            // align: labelAlign,
                            // color: "#dee5ef",
                            // font: {
                            //   size: 12
                            // },
                            // formatter: function (value, context) {
                            //   // console.debug(context.dataIndex)
                            //   if (props.graphData[0].kpi_name === "sales")
                            //     return `${actualArray[context.dataIndex] * -1} | ${value * -1}`
                            //   else if (props.graphData[0].kpi_name === "revenue")
                            //     return `${actualArray[context.dataIndex]} | ${value}`
                            // }
                        },
                        borderWidth: 0,
                        // datalabels: {
                        //     display: false,
                        // },
                    },
                ]
            },
            options: {
                layout: {
                    padding: {
                        right: paddingRight,
                        left: paddingLeft,
                        // top: 20,
                        // bottom: 20,
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                        label: function (tooltipItems, data) {
                            let value
                            if (props.graphData[0].kpi_name === 'sales') {
                                // value = `${actualArray[tooltipItems.index] * -1} | ${targetArray[tooltipItems.index] * -1}`
                                value = `${actualArray[tooltipItems.index] * -1}`
                            } else if (props.graphData[0].kpi_name === 'revenue') {
                                // value = `${actualArray[tooltipItems.index]} | ${targetArray[tooltipItems.index]}`
                                value = `${actualArray[tooltipItems.index]}`
                            }
                            return value
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 10,
                                fontColor: '#dee5ef',
                                fontSize: '16',
                                callback: function (value) {
                                    if (props.graphData[0].kpi_name === 'sales')
                                        return (value * -1)
                                    else if (props.graphData[0].kpi_name === 'revenue')
                                        return value
                                },
                                maxTicksLimit: 5
                            },
                            gridLines: {
                                display: true,
                                color: '#67758d',
                                // display: true,
                                drawBorder: true,
                                // offsetGridLines: true,
                                tickMarkLength: 0,
                                // color: "grey",
                                // tickMarkLength: 0,
                                borderDash: [3],

                                // tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                                zeroLineBorderDash: [3],
                            }
                        },
                    ],

                    yAxes: [
                        {
                            id: 'bar-x-axis1',
                            // beginAtZero: true,
                            display: true,
                            offset: true,
                            type: 'category',
                            gridLines: {
                                // display: true,
                                // drawBorder: true,
                                // color: "grey",
                                // tickMarkLength: 0,
                                // borderDash: [3],

                                display: true,
                                color: '#67758d',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                                zeroLineBorderDash: [3],
                                // tickMarkLength: 0,
                                // zeroLineColor: "#67758d",
                                // zeroLineWidth: 1
                            },
                            ticks: {
                                display: false,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22'
                            },
                        },
                        {
                            display: false,
                            offset: true,
                            id: 'bar-x-axis2',
                            type: 'category',
                            gridLines: {
                                display: true,
                                color: 'grey',
                                // drawBorder: true,
                                // tickMarkLength: 0,
                                // borderDash: [3],
                            },
                            ticks: {
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22'
                            },
                        },
                    ]

                },
                legend: {
                    display: false
                }
            },
            plugins: [{
                afterUpdate: function (chart) {
                    for (let i in chart.config.data.datasets) {
                        let dataset = chart.config.data.datasets[i]
                        if (dataset.label === 'Actual') {
                            for (let j = chart.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                                let model = chart.getDatasetMeta(i).data[j]._model
                                let start = model.x
                                // let end = model.x + dataset.data[0];
                                let end = model.x
                                if (start && end !=0){
                                if (props.graphData[0].kpi_name === 'sales') {
                                    let gradient = ctx.createLinearGradient(start + 10, 0, end + 200, 0)
                                    gradient.addColorStop(0, SALES_ACTUAL_GRADIENT_START)
                                    gradient.addColorStop(1, SALES_ACTUAL_GRADIENT_END)
                                    model.backgroundColor = gradient
                                }
                                else {
                                    let gradient = ctx.createLinearGradient(start + 10, 0, end - 200, 0)
                                    gradient.addColorStop(0, REVENUE_ACTUAL_GRADIENT_START)
                                    gradient.addColorStop(1, REVENUE_ACTUAL_GRADIENT_END)
                                    model.backgroundColor = gradient
                                }
                            }
                            else{console.log("Somthing went wrong!!")}
                            }
                        } else {
                            for (let j = chart.config.data.datasets[i].data.length - 1; j >= 0; --j) {
                                let model = chart.getDatasetMeta(i).data[j]._model
                                let start = model.x
                                let end = model.x
                                if (start && end !=0){
                                if (props.graphData[0].kpi_name === 'sales') {
                                    let gradient = ctx.createLinearGradient(start - 100, 0, end + 300, 0)
                                    gradient.addColorStop(0, SALES_TARGET_GRADIENT_START)
                                    gradient.addColorStop(1, SALES_TARGET_GRADIENT_END)
                                    model.backgroundColor = gradient
                                }
                                else {
                                    let gradient = ctx.createLinearGradient(start + 100, 0, end - 300, 0)
                                    gradient.addColorStop(0, REVENUE_TARGET_GRADIENT_START)
                                    gradient.addColorStop(1, REVENUE_TARGET_GRADIENT_END)
                                    model.backgroundColor = gradient
                                }
                            }
                            else{console.log("Somthing went wrong!!")}
                            }
                        }
                    }
                }
            }]
        }
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    } catch (err){
        if (err) setError("errorcaught");
        console.log('FullError'+error)
    }
    }, [chartContainer, props.graphData]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ErrorBoundary fallback='Somthing went wrong,please reload'>
        <canvas ref={chartContainer} />
        </ErrorBoundary>
    )
}

export default SalesRevenueGraph