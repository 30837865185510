// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomInfoBox_infoWindowContainer__299Kq {\n  padding: 0;\n  font-family: Titillium Web;\n  width: 287px;\n  height: 255px;\n  background-color: rgba(24, 30, 42, 0);\n  color: \"white\";\n  border: none; }\n  .CustomInfoBox_infoWindowContainer__299Kq .CustomInfoBox_markerDot__2jMP- {\n    height: 16px;\n    width: 16px;\n    border-radius: 50%;\n    background-color: #b9c8e1;\n    margin-bottom: 5px; }\n  .CustomInfoBox_infoWindowContainer__299Kq .CustomInfoBox_infoWindowTitle__jxi_j {\n    font-size: 28px;\n    color: #b9c8e1; }\n  .CustomInfoBox_infoWindowContainer__299Kq .CustomInfoBox_titleLine__2Uhe- {\n    border-color: #b9c8e1;\n    width: 40px;\n    height: 1px;\n    margin: 6px 0px 18px 0px; }\n  .CustomInfoBox_infoWindowContainer__299Kq .CustomInfoBox_infoWindowContent__1xsZl {\n    color: #b9c8e1;\n    font-size: 22px;\n    margin-bottom: 2px; }\n  .CustomInfoBox_infoWindowContainer__299Kq .CustomInfoBox_depotInfoWindow__3Hb7y {\n    color: #b9c8e1;\n    font-size: 22px;\n    width: 178px; }\n  .CustomInfoBox_infoWindowContainer__299Kq .CustomInfoBox_contentTableTitle__82pGy {\n    border-bottom: #b9c8e1 1px dashed;\n    border-top: #b9c8e1 1px dashed;\n    font-size: 22px;\n    font-weight: bold;\n    margin-right: 10px;\n    margin-top: 10px; }\n  .CustomInfoBox_infoWindowContainer__299Kq .CustomInfoBox_contentTitle__2XDup {\n    margin-right: 10px;\n    color: #b9c8e1;\n    font-size: 22px; }\n", ""]);
// Exports
exports.locals = {
	"infoWindowContainer": "CustomInfoBox_infoWindowContainer__299Kq",
	"markerDot": "CustomInfoBox_markerDot__2jMP-",
	"infoWindowTitle": "CustomInfoBox_infoWindowTitle__jxi_j",
	"titleLine": "CustomInfoBox_titleLine__2Uhe-",
	"infoWindowContent": "CustomInfoBox_infoWindowContent__1xsZl",
	"depotInfoWindow": "CustomInfoBox_depotInfoWindow__3Hb7y",
	"contentTableTitle": "CustomInfoBox_contentTableTitle__82pGy",
	"contentTitle": "CustomInfoBox_contentTitle__2XDup"
};
module.exports = exports;
